import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {withStyles} from '@material-ui/core/styles';

const ProgressBar = withStyles({
	colorPrimary: {
		backgroundColor: '#FFFFFF',
	},
	barColorPrimary: {
		backgroundColor: '#FEF200',
	},
})(LinearProgress);

export default function LinearLoading() {
	return (
		<ProgressBar/>
	)
}
