import React, {useContext, useEffect, useReducer, useState} from "react";
import io from 'socket.io-client';
import Button from "@material-ui/core/Button";
import {databridge, databridge_emit, databridge_listen} from "../../Helper/Constants";
import {asJson, de} from "../../Helper/Helper";
import DataBridgeEventTranslator from "../../Helper/DataBridgeEventTranslator";
import {ops, tables} from "../../Services/indexedDB";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {FixedSizeList} from "react-window";
import {makeStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Chip from "@material-ui/core/Chip";
import _ from "lodash";
import BlinkContainer from "../BlinkContainer";
import {Grid} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import AppContext from "../../Services/AppContext";

const useStyles = makeStyles((theme) => ({
	paper: {
		border: '0px',
		zIndex: 99999,
		marginTop: 20
	},
	sidePanel: {
		backgroundColor: theme.palette.primary.main,
		alignItems: 'center',
		flexDirection: 'column'
	}
}));
export default function LiveUpdate({client = '', device_no = '', username = ''}) {
	const classes = useStyles();
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [data, setData] = useState([]);
	const [groupedData, setGroupedData] = useState({});
	const [gState, setGState] = useContext(AppContext);
	const [popperEl, setPopperEl] = useState(null);
	const onDataBridgePopperClick = (event) => {
		setPopperEl(event.currentTarget);
	};
	const onDataBridgePopperClose = () => {
		setPopperEl(null);
	};
	useEffect(() => {
		loadPreviousData();
		if (client && device_no && username) {
			const dataBridge_socket = io(databridge, {
				withCredentials: false,
				reconnection: true,
				forceNew: true
			});
			dataBridge_socket.on(databridge_listen.connect, () => {
				console.log("DataBridge Established", new Date());
			});
			dataBridge_socket.on(databridge_listen.signup, (message) => {
				/**
				 * Server is ready to communicate with you. Send your ID for authentication
				 */
				dataBridge_socket.emit(databridge_emit.signin, {
					username: username,
					client: client,
					device_id: `DV_${device_no}`
				});
			});
			dataBridge_socket.on(databridge_listen.signin, (message) => {
				dataBridge_socket.on(databridge_listen.consume, (message) => {
					if (!_.isEmpty(message)) {
						const data_to_send = asJson(message);
						const translated_msg = new DataBridgeEventTranslator(message, new Date().toISOString()).translate();
						data.push(translated_msg);
						setData(data.reverse());
						setImmediate(() => {
							forceUpdate();
						});
						ops.insert(tables.databridge, {
							content: data_to_send,
							date_time: new Date().toISOString()
						});
						// send a signal to transaction screen
						if (gState && `emitter` in gState) {
							gState.emitter.publish("live-query", data_to_send);
						}
					}
				});
			});
		}
	}, [client, device_no, username]);
	const loadPreviousData = async () => {
		await ops.purge(tables.databridge, 'date_time');
		const previousData = await ops.limit(tables.databridge, 'date_time', 100);
		for (let i = 0; i < previousData.length; i++) {
			const {content, date_time} = previousData[i];
			const translated_msg = new DataBridgeEventTranslator(content, date_time).translate();
			data.push(translated_msg);
		}
		setData(data.reverse());
		setImmediate(() => {
			forceUpdate();
		});
	}
	const listData = _.filter(_.orderBy(data, ['date_time'], ['desc']), (o) => {
		return o.message?.main;
	});
	const rowItem = (style, index, current_v) => {
		if (!current_v.message?.main) {
			de(index, '=====index', current_v);
			return null;
		}
		return (
			<ListItem style={style} key={index}>
				<ListItemText
					primary={current_v.message?.main || ''}
					secondary={_.truncate(current_v.message?.content || '', {length: 150})}
					secondaryTypographyProps={{
						style: {
							fontSize: 12
						}
					}}
					primaryTypographyProps={{
						style: {
							color: blue[500]
						}
					}}
				/>
				<Chip label={current_v.date_time} variant={"outlined"} size={"small"}/>
				{/*current_v?.group*/}
			</ListItem>
		)
	}
	const renderRow = (props) => {
		const {index, style} = props;
		const current_v = listData[index];
		if (index !== listData.length) {
			if (index === 0) {
				return (
					<BlinkContainer>
						{rowItem(style, index, current_v)}
					</BlinkContainer>
				)
			}
			return (
				<div key={index + '--s'}>
					{rowItem(style, index, current_v)}
				</div>
			);
		} else {
			return null;
		}
	}
	const openPopper = Boolean(popperEl);
	const listHeight = 70 * listData.length > 600 ? 600 : 70 * listData.length;
	return (
		<div>
			<Button
				color="inherit"
				onClick={onDataBridgePopperClick}
				size="small" variant={"text"}>
				<Typography component="p" style={{fontSize: 11}}>
					Live Updates(Beta)
				</Typography>
			</Button>
			<Popover
				id={'--live-updates'}
				open={openPopper}
				anchorEl={popperEl}
				onClose={onDataBridgePopperClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<Grid container={true} spacing={1}>
					{/*<Grid item={true} xs={1.5} className={classes.sidePanel}>
                        <Button>
                            <CreateNewFolderIcon htmlColor={'white'}/>
                        </Button>
                    </Grid>*/}
					<Grid item={true} xs={12}>
						<List dense={true} className={classes.paper}>
							<FixedSizeList
								height={listHeight}
								width={600}
								itemSize={70}
								itemCount={listData ? listData.length : 0}>
								{renderRow}
							</FixedSizeList>
						</List>
					</Grid>
				</Grid>
			</Popover>
		</div>
	)
}
