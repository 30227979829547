import Dexie from "dexie";

export default function indexedDB() {
	const db = new Dexie('EasySales-LiveQuery');
	db.version(1)
		.stores({
			databridge_updates: '++id,client,fingerprint,content,date_time',
			uniques: '++id,fingerprint,date_time'
		});
	return db;
}
export const tables = {
	databridge: 'databridge_updates',
	uniques: 'uniques'
}
export const ops = {
	limit: function (name, orderBy = '', limit = 100) {
		return indexedDB().table(name).orderBy(orderBy).reverse().limit(limit).toArray();
	},
	insert: function (name, obj) {
		indexedDB().table(name).put(obj);
	},
	get: function (name, id = 1) {
		return indexedDB().table(name).get(id);
	},
	getUpdates: function () {
		const today = new Date();
		const previous = new Date(
			today.getUTCFullYear(),
			today.getMonth() + 1,
			today.getDate()
		);
		return indexedDB().table(tables.databridge).where('date_time').between(previous, today).toArray()
	},
	purge: async function (name, orderBy = '') {
		const db = indexedDB();
		const table = db.table(name);
		const total = await table.count();
		const left = total - 1000;
		if (left > 1000) {
			const rest = await table.orderBy(orderBy).limit(left).toArray();
			for (let i = 0; i < rest.length; i++) {
				await table.delete(rest[i].id);
			}
		}
	}
}
