import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const Field = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#FEF200',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#FEF200',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#FEF200',
			},
			'&:hover fieldset': {
				borderColor: 'yellow',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#FEF200',
			},
		},
	},
})(TextField);

const useStyles = makeStyles(theme => ({
	input: {
		width: '100%',
		marginTop: theme.spacing(0.5)
	}
}));
/**
 * @deprecated
 * @param {Object} param0
 * @returns React.ReactNode
 */
export default function TextInput({placeholder, className, isPassword, isError, helperText, onSubmit, ...rest}) {
	const styles = useStyles();

	placeholder = placeholder || 'Your text';
	helperText = helperText || '';

	var class_name = className || styles.input;

	return (
		<Field
			{...rest}
			onKeyPress={(ev) => {
				if (ev.key === 'Enter') {
					onSubmit && onSubmit()
					ev.preventDefault();
				}
			}}
			InputLabelProps={{
				style: {
					color: 'black'
				}
			}}
			error={isError}
			type={isPassword ? 'password' : 'text'}
			className={class_name}
			id="custom-css-standard-input"
			label={isError ? helperText : placeholder}
			helperText={helperText}/>
	)
}
