import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { de } from '../../Helper/Helper';
import { makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { empty, req_stock_batch } from '../../Helper/Constants';
import DateFnsUtils from '@date-io/date-fns';
import API_Service, { axiosPutConfig, axiosURIencode } from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	}
});

export default function EditBatch({open, onClose, language, data, product_code, onUpdate}) {
    const classes = useStyles();
    const [gState] = useContext(AppContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [batchNo, setBatchNo] = useState(empty);
    const [oriBatchNo, setOriBatchNo] = useState(empty);
    const [expiryDate, setExpiryDate] = useState(new Date());
    const [manufacturedDate, setManufacturedDate] = useState(new Date());

	useEffect(() => {
        if (data !== empty) {
            setBatchNo(data.batch_no);
            setOriBatchNo(data.batch_no);
            setExpiryDate(new Date(data.expiry_date));
            setManufacturedDate(new Date(data.manufactured_date));
        }
	}, [open]);

    const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

    const closeDialog = () => {
        setBatchNo(empty);
        setOriBatchNo(empty);
        setExpiryDate(new Date());
        setManufacturedDate(new Date());
		onClose();
	}

    const save = () => {
        const params = axiosURIencode({
			product_code: product_code,
			batch_no: batchNo,
            ori_batch_no: oriBatchNo,
			expiry_date: moment(expiryDate).format('YYYY-MM-DD'),
			manufactured_date: moment(manufacturedDate).format('YYYY-MM-DD')
		});
        if (batchNo === empty) {
            snack('Batch No is required.', 'warning');
        } else {
            if (oriBatchNo !== empty) {
                API_Service.put(req_stock_batch, params, axiosPutConfig(gState.client)).then(({data}) => {
                    const {result, message} = data;
                    if (parseInt(result) === 1) {
                        snack(message);
                        closeDialog();
                        onUpdate();
                    } else {
                        snack(message, 'warning');
                    }
                });
            } else {
                API_Service.post(req_stock_batch, params, axiosPutConfig(gState.client)).then(({data}) => {
                    const {result, message} = data;
                    if (parseInt(result) === 1) {
                        snack(message);
                        closeDialog();
                        onUpdate();
                    } else {
                        snack(message, 'error');
                    }
                });
            }
        }
    }

	return (
		<Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
                {language.new_batch_no}
            </DialogTitle>
            <TextField
                className={classes.dlg}
                id="input-batch_no"
                defaultValue={batchNo}
                placeholder={'Batch No'}
                onChange={(e) => setBatchNo(e.target.value)}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.dlg}
                    variant="inline"
                    label={"Expiry Date"}
                    format="yyyy-MM-dd"
                    value={expiryDate}
                    onChange={setExpiryDate}
                />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    className={classes.dlg}
                    variant="inline"
                    label={'Manufactured Date'}
                    format="yyyy-MM-dd"
                    value={manufacturedDate}
                    onChange={setManufacturedDate}
                />
            </MuiPickersUtilsProvider>
            <DialogActions>
                <Button
                    onClick={() => {
                        save()
                    }}
                    variant={'text'}
                    color={'primary'}>
                    {language.add}
                </Button>
            </DialogActions>
		</Dialog>
	)
}
