import {Dialog, DialogContent, Typography} from '@material-ui/core';
import React from 'react';
import {no_image} from "../../Helper/Constants"

export default function ImageViewer({
										open,
										onDismiss,
										src,
										title,
										description,
										imageStyle,
										titleStyle,
										descriptionStyle
									}) {
	return (
		<Dialog
			fullWidth={false}
			maxWidth={'lg'}
			open={open}
			aria-labelledby="form-dialog-title"
			onClose={() => {
				onDismiss()
			}}>
			<DialogContent>
				<img
					alt={'=='}
					src={src || no_image}
					className={imageStyle}/>
				{
					title && (
						<Typography className={titleStyle} component={'p'} variant={'body2'}>
							{title}
						</Typography>
					)
				}
				{
					description && (
						<Typography className={descriptionStyle} component={'p'} variant={'body2'}>
							{description}
						</Typography>
					)
				}
			</DialogContent>
		</Dialog>
	)
}
