import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import CheckBox from '../CheckBox';
import JString from '@easytech-international-sdn-bhd/jstring';
import _, {now} from 'lodash';
import {empty} from '../../Helper/Constants';
import useStateCallback from '../../Services/StateCallback';
import Button from "@material-ui/core/Button";
import LinearLoading from '../LinearLoading';
import Image from 'material-ui-image';
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function InputDialog({title, children, onChange, onPress, open, onClose, showLoading, large, ...rest}) {

	const [state, setState] = useStateCallback({}, state => {
		if (!_.isEmpty(state)) {
			onChange && onChange(state);
		}
	});

	const [date, setDate] = useState(new Date());
	const {contentChildren, actionChildren, contentText} = children;
	const content = JString(contentText);
	const hasContentText = content.isValid();

	const onTextChange = prop => event => {
		var _length = prop?.item?.lengthLimit;
		var text = event.target.value;
		if (text.length > _length) {
			text = text.slice(0, _length);
			setState({...state, [prop.item.code]: text});
		} else {
			setState({...state, [prop.item.code]: text});
		}
	};

	const onCheckboxChange = event => {
		const isChecked = event.target.checked;
		setState({...state, [event.target.name]: isChecked});
	};

	const onDateChange = prop => event => {
		setState({...state, [prop]: event});
	};

	const getStateValue = (item) => {
		var {code} = item;
		var hasValue = _.has(state, `${code}`);
		var userValue = hasValue ?
			JString(state[`${code}`])
				.isValid() ?
				state[`${code}`] :
				item?.default_value :
			item?.default_value;
		return JString(userValue).isValid() ? userValue : empty;
	};

	const getCondition = (item) => {
		let disabled = false;
		if (_.has(item, 'condition')) {
			var condition = item.condition;
			if (condition === 'disabled') {
				disabled = true;
			} else {
				var dependency = Object.keys(condition)[0];
				var onCondition = condition[dependency];
				disabled = _.isUndefined(state[`${dependency}`]) ? onCondition === false : state[`${dependency}`] === onCondition;
			}
		}
		return disabled;
	};

	const DrawCheckBox = ({item}) => {
		var {name, code} = item;
		var hasValue = _.has(state, `${code}`);
		var isChecked = hasValue ? state[`${code}`] : (_.isUndefined(item?.default_value) ? false : item?.default_value);
		return (
			<CheckBox
				checked={isChecked}
				onChange={onCheckboxChange}
				name={code}
				color="primary"
				text={name}
				key={`${code}`}
			/>
		)
	};

	const clearAndClose = () => {
		setState({});
		onClose && onClose();
	};

	function CircularProgressWithLabel({value, targetValue}) {
		const label = `${value}/${targetValue}`;
		const completion = value === 0 ? 0 : (value / targetValue) * 100;

		return (
			<Box position="relative" display="inline-flex" style={{marginBottom: 5}}>
				<CircularProgress size={35} variant="static" value={completion}/>
				<Box
					top={0}
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center">
					<p style={{fontSize: 10}}>{label}</p>
				</Box>
			</Box>
		);
	}

	const buttonAction = item => {
		if (!item.action) {
			setState({});
		}
		onPress(
			//item.action ? state : item.action
			item.action ? item?.withKey ? {name: item.code, value: state} : state : item.action
		);
	};

	const isBtnDisabled = (item) => {
		var disabled = false;
		if (_.has(item, 'condition')) {
			var condition = item.condition;
			if (condition === 'disabled') {
				disabled = true;
			} else {
				if (condition instanceof Array) {
					var dCounter = 0;
					for (let i = 0; i < condition.length; i++) {
						const el = condition[i];
						var dependency = Object.keys(el)[0];
						var onCondition = el[dependency];
						var found = _.isUndefined(state[`${dependency}`]) ? true : JString(state[`${dependency}`]).length < onCondition;
						if (found) {
							dCounter++;
						}
					}
					if (dCounter <= condition.length) {
						disabled = true;
					}
					if (dCounter === 0) {
						disabled = false;
					}
				} else {
					var dependency = Object.keys(condition)[0];
					var onCondition = condition[dependency];
					disabled = _.isUndefined(state[`${dependency}`]) ? true : JString(state[`${dependency}`]).length < onCondition;
				}
			}
		}
		return disabled;
	};

	return (
		<Dialog
			fullWidth={large || false}
			maxWidth={large ? 'md' : 'sm'}
			open={open}
			aria-labelledby="form-dialog-title"
			onClose={() => {
				clearAndClose()
			}}>
			<DialogTitle id="form-dialog-title">{title}</DialogTitle>
			<DialogContent>
				{showLoading && <LinearLoading/>}
				{
					hasContentText ?
						<DialogContentText>
							{content.str}
						</DialogContentText> : null
				}
				{
					contentChildren && contentChildren.map((item, index) => {
						var isGroup = _.has(item, 'group');
						if (isGroup) {
							var group = item.group;
							if (group instanceof Array && group.length > 0) {
								for (let i = 0; i < group.length; i++) {
									const grp = group[i];
									return (
										<div key={String(i)}>
											{
												grp.map((rule) => {
													var {component} = rule;
													if (component === 'textinput') {
														var {name, code} = rule;
														var userValue = getStateValue(rule);
														var disabled = getCondition(rule);

														return (
															<TextField
																disabled={disabled}
																margin="dense"
																label={`${name}`}
																value={`${userValue}`}
																type={rule?.type || 'text'}
																fullWidth
																name={`${code}`}
																key={`${code}`}
																id={`${code}`}
																// onChange={onTextChange(`${code}`)}
																onChange={onTextChange({item: rule})}
															/>
														)
													}
													if (component === 'checkbox') {
														return (
															<DrawCheckBox
																item={rule}
																key={`${code}`}
															/>
														)
													}
												})
											}
										</div>
									)
								}
							}
						} else {
							var {component} = item;
							if (component === 'textinput') {
								var {name, code} = item;
								var userValue = getStateValue(item);
								var disabled = getCondition(item);

								return (
									<TextField
										InputProps={item.showLengthLimit ?
											{
												endAdornment: <CircularProgressWithLabel
													key={`${code}`}
													value={userValue.length}
													targetValue={item.lengthLimit}/>
											} : {}}

										disabled={disabled}
										margin="dense"
										label={`${name}`}
										value={`${userValue}`}
										type={item?.type || 'text'}
										fullWidth
										name={`${code}`}
										key={`${code}`}
										id={`${code}`}
										// onChange={onTextChange(`${code}`)}
										//onChange={onTextChange({ code:item.code, lengthLimit:item.lengthLimit })}
										onChange={onTextChange({item})}
									/>
								)
							}
							if (component === 'checkbox') {
								return (
									<DrawCheckBox
										item={item}
									/>
								)
							}
							if (component === 'image') {
								return (
									<div
										key={`${item.default_value}`}
										style={item.condition || {}}
									>
										<Image
											key={`${item.default_value}`}
											src={`${item.default_value}`}
										/>
									</div>
								)
							}
							if (component === 'datepicker') {
								var {name, code, default_value} = item;
								var userValue = getStateValue(item);

								return (
									<MuiPickersUtilsProvider utils={DateFnsUtils} key={`${code}`}>
										<DatePicker
											id={`${code}`}
											key={`${code}`}
											label={`${name}`}
											value={`${userValue}` ? `${userValue}`.empty ? now() : `${userValue}` : now()}
											onChange={onDateChange(`${code}`)}
										/>
									</MuiPickersUtilsProvider>
								)
							}
						}
						/**
						 * later we can add text, dropdown and many more
						 */
					})
				}
			</DialogContent>
			<DialogActions>
				{
					actionChildren && actionChildren.map((item, index) => {
						const disabled = isBtnDisabled(item);
						return (
							<Button
								disabled={disabled}
								key={String(index) + '-button'}
								onClick={() => buttonAction(item)}
								color="primary">
								{item.name}
							</Button>
						)
					})
				}
			</DialogActions>
		</Dialog>
	)
}
