import Button from "@material-ui/core/Button";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Typography from '@material-ui/core/Typography';
import _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useCommonStyles} from "../../Styles";
import format from "date-fns/format";
import {
    checkin_report_url,
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_delete_file,
    req_report_exportMileage,
    req_report_mileage,
    req_report_mileagedetails,
    req_salesman
} from "../../Helper/Constants";
import API_Service, {axiosDefaultConfig, axiosURIencode} from "../../Services/API_Service";
import moment from "moment/moment";
import AppContext from "../../Services/AppContext";
import useCookieHook from "../../Services/useCookieHook";
import {useSnackbar} from "notistack";
import PersistantData from "../../Services/PersistantData";
import VirtualListCB from "../../Components/VirtualListCB";
import JString from "@easytech-international-sdn-bhd/jstring";
import {de} from "../../Helper/Helper";
import DateRangePickerModal from "../../Components/DateRangePickerModal";
import MUIDataTable from "mui-datatables";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {IconButton, Tooltip} from "@material-ui/core";
import {Assessment as AssessmentIcon} from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles((theme) => ({
    viewInRow: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    appBar: {
        position: 'relative',
    },
    tableContent: {
        marginTop: theme.spacing(1)
    }
}));

const tableHead = [
    {
        name: "salesperson_id",
        label: "Salesperson ID",
        options: {
            display: false
        }
    },
    {
        name: "name",
        label: "Saleperson Name",
        options: {
            display: true
        }
    },
    // {
    //     name: "log_date",
    //     label: "Log Date",
    //     options: {
    //         display: true
    //     }
    // },
    {
        name: "start_time",
        label: "Start Time",
        options: {
            display: true
        }
    },
    {
        name: "end_time",
        label: "End Time",
        options: {
            display: true
        }
    },
    {
        name: "distance_travel",
        label: "Distance Traveled (km)",
        options: {
            display: true
        }
    }
];

const detailsHead = [
    {
        name: "cust_code",
        label: "Customer Code",
        options: {
            display: true
        }
    },
    {
        name: "cust_company_name",
        label: "Customer Name",
        options: {
            display: true
        }
    },
    {
        name: "person_met",
        label: "Person Meet",
        options: {
            display: true
        }
    },
    {
        name: "checkin_time",
        label: "Checkin Time",
        options: {
            display: true
        }
    },
    {
        name: "checkin_location",
        label: "Checkin Location",
        options: {
            display: true
        }
    },
    {
        name: "location_coordinates",
        label: "Checkin Coordinates",
        options: {
            display: true
        }
    },
    {
        name: "checkin_time_last_update",
        label: "Checkout Time",
        options: {
            display: true
        }
    },
    {
        name: "checkout_location",
        label: "Checkout Location",
        options: {
            display: true
        }
    },
    {
        name: "checkout_coordinates",
        label: "Checkout Coordinates",
        options: {
            display: true
        }
    }
]

export default function RMileage() {
    const commonStyle = useCommonStyles();
    const classes = useStyles();
    const [gState, setGState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language, client} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang()
    }
    const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const snack = (msg, type = 'success') => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,
            autoHideDuration: type === 'success' ? 2000 : 3000
        });
    }

    // report selection button state
    const [salePersonList, setSalePersonlist] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [dateRangeModalView, setDateRangeModalView] = useState(false);
    // modal view trigger
    const [salePersonView, setMileageSPView] = useState(false);
    const [outletView, setMileageOutletView] = useState(false);
    // sale person settings
    const [mileageSPSelectedID, setMileageSPSelectedID] = useState(empty);
    const [mileageSPSelectedName, setMileageSPSelectedName] = useState(empty);
    // outlet settings
    const [mileageOutletSelectedID, setMileageOutletSelectedID] = useState(empty);
    const [mileageOutletSelectedName, setMileageOutletSelectedName] = useState(empty);
    // table row onClick function state
    const [detailDialogShow, setDetailDialogShow] = useState(false);
    const [selectedMileageDialog, setSelectedMileageDialog] = useState({});
    const [detailsList, setDetailsList] = useState([]);

    const [mileageDateRange, setMileageDateRange] = useState({});

    const [mileageList, setMileageList] = useState([]);

    const [tableHeader, setTableHeader] = useState([]);

    const typeOptions = [
        {name: 'Basic Version', value: 'simply'},
        {name: 'Detailed Version', value: 'normal'}
    ]
    const [printView, setPrintView] = useState();
    const openPrintSelectView = () => {
        setPrintView(true);
    }
    const printViewClose = (type) => {
        generateMileageReport(type);
        setPrintView(false);
    }
    const generateMileageReport = (type, _client = client) => {
        var st_date = '';
        var st_date_to = '';
        if (!_.isEmpty(mileageDateRange)) {
            st_date = format(mileageDateRange.startDate, defDateFormat);
            st_date_to = format(mileageDateRange.endDate, defDateFormat);
        }
        const param = axiosURIencode({
            client: client,
            date_from: st_date,
            date_to: st_date_to,
            salespersonId: mileageSPSelectedID,
            username: cookies(ck_u_username),
        });
        const date_range = `${st_date} to ${st_date_to}`;

        const url = checkin_report_url(client, mileageSPSelectedID, date_range, type, cookies(ck_u_username));
        API_Service.get(`${req_report_exportMileage}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
            var {result, message, extraData} = data;
            if(result){
                window.open(extraData.url, '_blank');
                const delay = setTimeout(() => {
                    API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                    clearTimeout(delay);
                }, 5000);
            }
        });
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        // getMileage();
        if (JString(gState.client).isValid()) {
            getSalesmanList(gState.client);
            getOutletList(gState.client);
        }
        setTableHeader(tableHead);
    }, [gState.client, gState.translation, selectedMileageDialog])

    const getMileage = (_client = client) => {
        var st_date = '';
        var st_date_to = '';
        if (!_.isEmpty(mileageDateRange)) {
            st_date = format(mileageDateRange.startDate, defDateFormat);
            st_date_to = format(mileageDateRange.endDate, defDateFormat);
        }
        const param = axiosURIencode({
            client: client,
            date_from: st_date,
            date_to: st_date_to,
            salespersonId: mileageSPSelectedID,
            username: cookies(ck_u_username),
            // outlet: mileageOutletSelectedID
        });
        API_Service.get(`${req_report_mileage}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    var _arr = extraData[idx];
                    if (_arr['start_time']) {
                        _arr['start_time'] = moment(_arr['start_time']).format('DD-MM-YYYY hh:mmA')
                    }
                    if (_arr['end_time']) {
                        _arr['end_time'] = moment(_arr['end_time']).format('DD-MM-YYYY hh:mmA');
                    }
                    if (_arr['distance_travel']) {
                        _arr['distance_travel'] = (_arr['distance_travel'] / 1000).toFixed(2);
                    }
                    _data.push(_arr);
                }
                setMileageList(mileageList => (_data));
            } else {
                setMileageList(mileageList => ([]));
                snack(language.no_data, 'warning');
            }
        });
    }

    const mileageSPView = (boolean) => {
        setMileageSPView(boolean);
    }

    const mileageOutletView = (boolean) => {
        setMileageOutletView(boolean);
    }

    const mileageSPViewClose = (data) => {
        mileageSPView(false);
        if(data){
            var selectedID = '';
            var selectedName = '';
            for (let i = 0; i < data.length; i++) {
                if (i == data.length - 1) {
                    selectedID += data[i].value;
                    selectedName += data[i].name;
                } else {
                    selectedID += data[i].value + ",";
                    selectedName += data[i].name + ",";
                }
            }
            de("mileageSPViewClose", selectedID, selectedName);
            setMileageSPSelectedID(selectedID);
            setMileageSPSelectedName(selectedName);
        }
    }

    const mileageOutletViewClose = (data) => {
        mileageOutletView(false);
        if (data) {
            var selectedID = '';
            var selectedName = '';
            for (let i = 0; i < data.length; i++) {
                if (i == data.length - 1) {
                    selectedID += data[i].value;
                    selectedName += data[i].name;
                } else {
                    selectedID += data[i].value + ",";
                    selectedName += data[i].name + ",";
                }
            }
            setMileageOutletSelectedID(selectedID);
            setMileageOutletSelectedName(selectedName);
        }
    }

    const viewDateRange = () => {
        return !_.isEmpty(mileageDateRange) ? moment(mileageDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(mileageDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
    }

    const getSalesmanList = (_client = client) => {
        API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    const {name, login_id, login, login_status} = extraData[idx];
                    _data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
                }
                setSalePersonlist(salePersonList => (_data));
            }
        });
    }

    const getOutletList = (_client = client) => {
        API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            var {customer} = extraData;
            if (parseInt(result) === 1) {
                var arr = [];
                for (let i = 0; i < customer.length; i++) {
                    const {cust_code, cust_company_name} = customer[i];
                    arr.push({
                        name: cust_company_name,
                        value: cust_code
                    });
                }
                setOutletList(arr);
            }
        });
    }

    const getMileageReportDetail = (rowData, _client = client) => {
        de("getMileageReportDetail", rowData);
        const param = axiosURIencode({
            client: client,
            date_from: rowData.log_date,
            date_to: rowData.log_date,
            salespersonId: rowData.salesperson_id,
            username: cookies(ck_u_username)
        });
        API_Service.get(`${req_report_mileagedetails}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
            de("api back", data);
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    var _arr = extraData[idx];
                    if (_arr['checkin_time']) {
                        _arr['checkin_time'] = moment(_arr['checkin_time']).format('DD-MM-YYYY hh:mmA')
                    }
                    if (_arr['checkin_time_last_update']) {
                        _arr['checkin_time_last_update'] = moment(_arr['checkin_time_last_update']).format('DD-MM-YYYY hh:mmA');
                    }
                    _arr['location_coordinates'] = _arr['location_lat'] + ', ' + _arr['location_lng'];
                    _arr['checkout_coordinates'] = _arr['checkout_lat'] + ', ' + _arr['checkout_lng'];
                    _data.push(_arr);
                }
                setDetailsList(detailsList => (_data));
            } else {
                setDetailsList(detailsList => ([]));
                snack(language.no_data, 'warning');
            }
        });
    }

    return(
        <div>
            <div className={classes.viewInRow}>
                <Button
                    style={{width: 300}}
                    variant="outlined"
                    onClick={() => mileageSPView(true)}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {mileageSPSelectedName ? _.truncate(mileageSPSelectedName, {length: 20}) : language.salesperson}
                    </Typography>
                </Button>
                <div style={{width: 10}}/>
                {/*<Button*/}
                {/*    style={{width: 300}}*/}
                {/*    variant="outlined"*/}
                {/*    onClick={() => mileageOutletView(true)}*/}
                {/*    endIcon={<KeyboardArrowDownRoundedIcon/>}>*/}
                {/*    <Typography className={commonStyle.searchBtnFont}>*/}
                {/*        {mileageOutletSelectedName ? _.truncate(mileageOutletSelectedName, {length: 20}) : language.customer}*/}
                {/*    </Typography>*/}
                {/*</Button>*/}
                <div style={{width: 10}}/>
                <Button
                    style={{width: 300}}
                    variant="outlined"
                    onClick={() => setDateRangeModalView(true)}
                >
                    <Typography className={commonStyle.searchBtnFont}>
                        {viewDateRange()}
                    </Typography>
                </Button>
                <div style={{width: 10}}/>
                <Button
                    size="small"
                    color="primary"
                    variant={'contained'}
                    onClick={() => getMileage()}
                >
                    <Typography className={commonStyle.searchBtnFont}>
                        {language.view}
                    </Typography>
                </Button>
            </div>
            <div style={{height: 20}}/>
            { mileageList.length > 0 && (
                <MUIDataTable
                    title={language.mileage}
                    data={mileageList}
                    columns={tableHeader}
                    options={{
                        search: false,
                        print: false,
                        filter: false,
                        download: false,
                        selectableRows: 'none',
                        rowsPerPage: 25,
                        rowsPerPageOptions: [25, 40, 65, 100],
                        responsive: 'scrollMaxHeight',
                        onColumnViewChange: (changedColumn, action) => {
                            if(tableHeader.some(item => changedColumn === item.name)){
                                var index = tableHeader.findIndex(item => changedColumn === item.name);
                            }
                            switch(action){
                                case 'add':
                                    tableHeader[index].options.display = true;
                                case 'remove':
                                    tableHeader[index].options.display = false;
                            }
                            setTableHeader(tableHeader);
                        },
                        onRowClick: (rowData, {dataIndex}) => {
                            setDetailDialogShow(true);
                            setSelectedMileageDialog(selectedMileageDialog => (
                                {
                                    id: rowData[0],
                                    name: rowData[1]
                                }
                            ));
                            getMileageReportDetail(mileageList[dataIndex]);
                        },
                        customToolbar: () =>
                            <>
                                <GenerateReportButton onGenerateReport={generateMileageReport}/>
                            </>
                    }}
                />
            )}

            <VirtualListCB
                open={salePersonView}
                setClose={data => mileageSPViewClose(data)}
                title={language.salesperson}
                data={salePersonList}
                language={language}
                filteration={1}
            />

            {/*<VirtualListCB*/}
            {/*    open={outletView}*/}
            {/*    setClose={data => mileageOutletViewClose(data)}*/}
            {/*    title={"Outlet"}*/}
            {/*    data={outletList}*/}
            {/*    language={language}*/}
            {/*/>*/}

            <DateRangePickerModal
                setPositive={range => {
                    setMileageDateRange(range);
                    setDateRangeModalView(false);
                    de("setPositive", range);
                }}
                setNegative={() => {
                    setDateRangeModalView(false)
                }}
                setClose={() => {
                    setDateRangeModalView(false)
                }}
                open={dateRangeModalView}
                message={language.date_range}
                positive={language.ok}
                input={mileageDateRange}
            />

            <Dialog
                fullScreen={true}
                maxWidth={'lg'}
                open={detailDialogShow}
                onClose={() => {
                    setDetailDialogShow(false);
                    setSelectedMileageDialog(selectedMileageDialog=>({}));
                    setDetailsList(detailsList => ([]));
                }}
                aria-labelledby="responsive-dialog-title">
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setDetailDialogShow(false);
                            setDetailsList(detailsList => ([]));
                        }} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {selectedMileageDialog.name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.tableContent}>
                    <div style={{flexDirection: 'column'}}>
                        <MUIDataTable
                            title={language.mileage}
                            data={detailsList}
                            columns={detailsHead}
                            options={{
                                search: false,
                                print: false,
                                filter: false,
                                download: false,
                                selectableRows: 'none',
                                rowsPerPage: 25,
                                rowsPerPageOptions: [25, 40, 65, 100],
                                responsive: 'scrollMaxHeight',
                                onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
                                    var link = "https://www.google.com/search?q=";
                                    if (detailsHead[colIndex].name == 'location_coordinates') {
                                        window.open(link+colData, "_blank");
                                    }
                                    if (detailsHead[colIndex].name == 'checkout_coordinates') {
                                        window.open(link+colData, "_blank");
                                    }
                                },
                            }}
                        />
                    </div>
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button*/}
                {/*        onClick={() => {*/}
                {/*            setDetailDialogShow(false);*/}
                {/*            setDetailsList(detailsList => ([]));*/}
                {/*        }}*/}
                {/*        color="inherit"*/}
                {/*        autoFocus>*/}
                {/*        {language.ok}*/}
                {/*    </Button>*/}
                {/*</DialogActions>*/}
            </Dialog>

            {/*<GenerateView*/}
            {/*    classes={{*/}
            {/*        paper: commonStyle.listView,*/}
            {/*    }}*/}
            {/*    id="ringtone-menu"*/}
            {/*    keepMounted*/}
            {/*    open={printView}*/}
            {/*    onClose={() => setPrintView(false)}*/}
            {/*    onSelect={printViewClose}*/}
            {/*    value={'simply'}*/}
            {/*    title={language.generate_type || ''}*/}
            {/*    language={language}*/}
            {/*    options={typeOptions}*/}
            {/*/>*/}
        </div>
    )
}

function GenerateReportButton({onGenerateReport}) {
    return (
        <React.Fragment>
            <Tooltip title={'Generate Report'}>
                <IconButton
                    edge={'end'}
                    color={'primary'}
                    onClick={() => onGenerateReport()} aria-label="addnew">
                    <AssessmentIcon/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}