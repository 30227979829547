import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {fade, lighten, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import red from '@material-ui/core/colors/red';
import JString from '@easytech-international-sdn-bhd/jstring';
import {empty} from '../../Helper/Constants';
import {de, sanitize_regx} from '../../Helper/Helper';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: '1 1 100%',
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
}));
const useSearchStyle = makeStyles((theme) => ({
	margin: {
		marginTop: theme.spacing(1)
	},
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.10),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.15),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '50ch',
			'&:focus': {
				width: '80ch',
			},
		},
	},
}));
export default function VirtualList({
										setClose,
										open,
										title,
										data,
										language,
										target = '',
										tag = '',
										cancel = '',
										hideValue = false
									}) {
	const [custList, setCustList] = useState(data);
	const [selected, setSelected] = useState(empty);
	const classes = useToolbarStyles();
	const searchClass = useSearchStyle();

	useEffect(() => {
		setCustList(custList => (data));
	}, [data]);

	const renderRow = (props) => {
		const {index, style} = props;
		const _cur = custList[index];
		if (index !== custList.length) {
			let strvalue = '';
			if (target == 'true' && tag == 'true') {
				for (let i = 0; i < _cur.value.length; i++) {
					if (i == _cur.value.length - 1) {
						strvalue = strvalue + _cur.value[i];
					} else {
						strvalue = strvalue + _cur.value[i] + ', ';
					}
				}
			}
			return (
				<ListItem button style={style} key={index} className={searchClass.margin} onClick={() => {
					setClose(_cur);
					setSelected(_cur);
					setCustList(data);
				}}>
					<ListItemText
						primary={_cur?.name}
						secondary={hideValue === true ? '' : target == 'true' && tag == 'true' ? strvalue : _cur?.value}/>
				</ListItem>
			);
		} else {
			return (
				null
			);
		}
	}

	const onChangeSearchText = props => event => {
		var value = event.target.value;
		// value = sanitize_regx(value);
		var text = JString(value);
		var final = [];
		if (text.isValid()) {
			for (let idx = 0; idx < data.length; idx++) {
				const row = data[idx];
				var searchString = row.value.toLowerCase() +' '+ row.name.toLowerCase();
				var isFound = searchString.includes(text.str.toLowerCase());
				if (isFound) {
					final.push(row);
				}
			}
			// final.push(data[data.length - 1]);
			setCustList(final);
		} else {
			setCustList(data);
		}
	}

	return (
		<React.Fragment>
			<Dialog
				fullScreen={false}
				maxWidth={'lg'}
				open={open}
				onClose={() => {
				}}
				aria-labelledby="max-width-dialog-title">
				<DialogTitle id="max-width-dialog-title">
					{title}
					<div style={{marginTop: 15}}/>
					<div className={classes.viewInRow}>
						<div className={searchClass.search}>
							<div className={searchClass.searchIcon}>
								<SearchIcon/>
							</div>
							<InputBase
								placeholder={language.search}
								classes={{
									root: searchClass.inputRoot,
									input: searchClass.inputInput,
								}}
								inputProps={{'aria-label': 'search'}}
								onInput={onChangeSearchText('search')}
							/>
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<FixedSizeList
						height={600}
						width={600}
						itemSize={70}
						itemCount={custList ? custList.length : 0}>
						{renderRow}
					</FixedSizeList>
				</DialogContent>
				<DialogActions>
					<Button
						color="inherit"
						onClick={() => target != "true" ? setClose(custList[custList.length - 1]) : setClose('')}
						size="small">
						<Typography component="p" style={{color: red[400]}}>
							{language.reset_selection}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={() => {
							target != "true" ? (cancel != "true" ? setClose(custList[custList.length - 1]) : setClose(selected)) : setClose(empty);
							setCustList(data);
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.cancel}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
