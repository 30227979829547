import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from "@material-ui/core/colors";

export const useCommonStyles = makeStyles(theme => ({
	mediaBoxImage: {
		width: 150,
		height: 120,
		marginLeft: 10
	},
	dropzoneText: {
		color: grey[500]
	},
	lottieStyle: {
		flex: 1,
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '10%'
	},
	listView: {
		width: '80%',
		maxHeight: 435,
		zIndex: theme.zIndex + 1
	},
	searchBtnSize: {
		width: theme.spacing(27),
		height: theme.spacing(5),
		justifyContent: 'left'
	},
	dateBtnSize: {
		minWidth: theme.spacing(27),
		justifyContent: 'left',
		height: theme.spacing(5)
	},
	dialogFilterBtn: {
		width: theme.spacing(20),
		justifyContent: 'left',
		height: theme.spacing(5)
	},
	dialogFilterBtnBig: {
		width: theme.spacing(35),
		justifyContent: 'left',
		height: theme.spacing(5)
	},
	searchBtnFont: {
		fontSize: 12
	},
	spacingTop: {
		marginTop: theme.spacing(2),
		flex: 1,
		zIndex: 10
	},
	tableExHeight: {
		height: 200
	},
	gridList: {
		flexWrap: 'nowrap',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)',
	},
	bottomNav: {
		position: 'fixed',
		bottom: 0,
		top: 'auto',
		width: '100%',
		marginLeft: -theme.spacing(3),
		zIndex: 999
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(5),
		right: theme.spacing(2),
		zIndex: 9999999
	},
	fab2nd: {
		position: 'fixed',
		bottom: theme.spacing(12),
		right: theme.spacing(2),
		zIndex: 9999999
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		margin: theme.spacing(0.5)
	},
	deleteBtn: {
		backgroundColor: theme.palette.error.main,
		margin: theme.spacing(0.5)
	},
	input: {
		width: "fit-content",
		margin: theme.spacing(0.5),
		marginLeft: 0,
		marginRight: 0,
	},
	wrapper: {
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(1),
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
		margin: theme.spacing(0.5)
	},
	wrapperNoSpace: {
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(1),
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		width: '100%',
		margin: theme.spacing(0.5)
	},
	inputWrapper: {
		width: 170
	},
	radioWrapper: {
		width: 220,
		paddingTop: theme.spacing(1)
	},
	textAreaWrapper: {
		margin: theme.spacing(1),
		width: '100%',
		paddingRight: theme.spacing(1)
	},
	dropZoneWrapper: {
		margin: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: '100%',
		color: grey[500]
	},
	formSelect: {
		margin: theme.spacing(0.5)
	},
	label: {
		fontSize: 11,
		color: theme.palette.text.secondary,
		width: '100%'
	},
	value: {
		fontSize: 13,
		width: '100%'
	},
	circle: {
		height: 30, width: 30, borderRadius: '50%'
	},
	clTooltipContent: {
		marginTop: theme.spacing(1),
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(1),
		alignItems: 'center',
	},
	clPoper: {
		borderRadius: 10,
		padding: theme.spacing(1),
		minWidth: 400,
		paddingBottom: theme.spacing(2)
	},
	clPoperTxt: {
		marginLeft: theme.spacing(1),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 20,
		fontWeight: 'bold'
	},
	clPoperTime: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: 14,
		color: 'textPrimary',
		marginTop: theme.spacing(0.2),
		marginLeft: theme.spacing(1)
	}
}));
