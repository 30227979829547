import axios from 'axios';
import {api_url, api_url_dev, xHeaderClient, xUserName} from '../Helper/Constants';
import {isDebug} from "../Helper/Helper";
import PersistantData from './PersistantData';

const axiosURIencode = params => Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');

export default axios.create({
	baseURL: isDebug() ? `${api_url_dev}` : `${api_url}`,
	proxy: true
});

const axiosDefaultConfig = (client) => {
	const __username = PersistantData.getInstance().getUsername();
	return {
		headers: {
			'Authorization': 'Bearer [EasySales][App][Web]',
			'Accept': 'application/json',
			'Content-Type': 'multipart/form-data',
			[xHeaderClient]: client,
			[xUserName]: __username
		}
	};
};


const axiosPutConfig = (client, username) => {
	const __username = PersistantData.getInstance().getUsername();

	return {
		headers: {
			'Authorization': 'Bearer [EasySales][App][Web]',
			'Accept': 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
			[xHeaderClient]: client,
			[xUserName]: __username
		}
	};
};

export {axiosURIencode, axiosDefaultConfig, axiosPutConfig};
