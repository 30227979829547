import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {empty, req_cust_category} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color'
import { de } from '../../Helper/Helper';

const useStyles = makeStyles({
	dlg: {
		minWidth: '90%',
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	dlg2: {
		minWidth: '85%',
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	}
});

export default function EditCustCategory({open, onClose, language, cust_category_code, cust_category_name, category_udf, mobileCustomization, marker_color}) {
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [code, setCode] = useState(cust_category_code);
	const [name, setName] = useState(cust_category_name);
	// const [standardSKUQty, setStandardSKUQty] = useState(empty);
	const [color, setColor] = useState(marker_color ? marker_color : '');
	const [axiosPut, setAxiosPutConfig] = useState({});
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	useEffect(() => {
		if(category_udf){
			var udf = JSON.parse(category_udf);
			// if (mobileCustomization.hasOwnProperty('skustandard') && udf.hasOwnProperty(mobileCustomization.skustandard.code)) {
			// 	setStandardSKUQty(udf[mobileCustomization.skustandard.code]);
			// }
		}
		var axiosPut = axiosPutConfig(gState.client);
		setAxiosPutConfig(axiosPut);
	}, [open]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const saveCurrentChanges = () => {
		const params = axiosURIencode({
			ori_code: cust_category_code,
			cust_category_code: code,
			cust_category_name: name,
			// standard_sku_qty: standardSKUQty,
			marker_color: color
		});
		API_Service.put(req_cust_category, params, axiosPut).then(({data}) => {
			const {result, extraData, message} = data;
			if (result) {
				snack(gState.translation.update_success);
				onClose(extraData);
			} else {
				snack(message, 'warning');
			}
		});
	}

	const handleColorChange = (input) => {
		setColor(input.hex);
	}

	return (
		<Dialog onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<div style={{width: 400}}>
				<DialogTitle id="simple-dialog-title">
					{language.edit_cust_category}
				</DialogTitle>
				<TextField
					className={classes.dlg}
					id="input-code"
					label={language.cust_category_code}
					name="input-code"
					defaultValue={cust_category_code}
					onChange={(e) => setCode(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-name"
					label={language.cust_category_name}
					name="input-name"
					defaultValue={cust_category_name}
					onChange={(e) => setName(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-color"
					label={'Marker Color'}
					name="input-color"
					value={color}
					disabled={true}
				/>
				<SketchPicker
					className={classes.dlg2}
					color={color}
					onChange={handleColorChange}
				/>
				{/* {
					mobileCustomization.hasOwnProperty('skustandard') && mobileCustomization.skustandard.enabled ? (
						<TextField
							className={classes.dlg}
							id="input-skustandard"
							label={mobileCustomization.skustandard.title}
							name="input-skustandard"
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
							defaultValue={standardSKUQty}
							onChange={(e) => setStandardSKUQty(e.target.value)}
						/>
					) : <></>
				} */}
				<DialogActions>
					<Button
						onClick={() => {
							saveCurrentChanges()
						}}
						variant={'text'}
						color={'primary'}>
						{language.update}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			allowLeadingZeros={true}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}
