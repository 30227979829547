import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service from '../../Services/API_Service';
import {empty, req_module_setting, req_stock_insert} from '../../Helper/Constants';
import {useMediaQuery, useTheme} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import {getSetting} from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	input:{
		width: '48%',
		height: 50,
		margin:5
	}
}));

export default function AddProduct({open, onClose, language, onSnack, axiosConfig, category_list, brand_list}) {
	const classes = useStyles();

	const [rows, setRows] = useState([{uom: "", price: 0, barcode: "", rate: ""}]);
	const [productCode, setProductCode] = useState(empty);
	const [productName, setProductName] = useState(empty);
	const [productDesc, setProductDesc] = useState(empty);
	const [categoryId, setCategoryId] = useState(0);
	const [categoryName, setCategoryName] = useState('');
	const [brandId, setBrandId] = useState(0);
	const [brandName, setBrandName] = useState('');
	const [chosenCategory, setChosenCategory] = useState({});
	const [chosenBrand, setChosenBrand] = useState({});
	const [productCodeProps, setProductCodeProps] = useState(20);

	useEffect(() => {
		loadConfig();
	}, [open]);

	const closeRemove = (data) => {
		setProductCode(empty);
		setProductName(empty);
		setProductDesc(empty);
		setRows([{uom: "", price: 0, barcode: "", rate: ""}]);
		if (data) {
			onClose(data[0]);
		}
	}

	const loadConfig = () => {
		API_Service.get(req_module_setting, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _availableModules = getSetting(extraData, 'customization', {});
				if(_availableModules.product_code){
					setProductCodeProps(_availableModules.product_code);
				}
			}
		});
	}

	const addProduct = () => {
		let formData = new FormData();
		formData.append('product_code', productCode);
		formData.append('product_name', productName);
		formData.append('product_desc', productDesc);
		formData.append('uom', JSON.stringify(rows));
		formData.append('category_id', categoryId);
		formData.append('brand_id', brandId);

		if (productName === empty || productCode === empty) {
			onSnack('Insert Product Code and Product Name', 'warning');
		} else {
			API_Service.post(req_stock_insert, formData, axiosConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					closeRemove(extraData);
					onSnack(language.insert_success);
				} else {
					onSnack(language.insert_fail, 'warning');
				}
			});
		}
	}

	const handleChange = (e, i) => {
		const {name, value} = e.target;
		const list = [...rows];
		list[i][name] = value;
		setRows(list);
	}

	const handleRemoveClick = index => {
		const list = [...rows];
		list.splice(index, 1);
		setRows(list);
	};

	const handleAddRow = () => {
		setRows([...rows, {uom: '', price: 0, barcode: '', rate: ''}]);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog fullScreen={fullScreen} onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{language.new_product}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						id="input-item-code"
						style={{width: '48%', height: 50}}
						inputProps={{maxLength: productCodeProps, pattern: "[a-z]{1,15}"}}
						label={language.product_code}
						value={productCode}
						onChange={(e) => setProductCode(e.target.value)}
					/>
					<TextField
						id="input-item-name"
						style={{width: '48%', height: 50}}
						label={language.product_name}
						value={productName}
						onChange={(e) => setProductName(e.target.value)}
					/>
					<TextField
						id="input-item-desc"
						style={{width: '100%', height: 50}}
						label={language.product_desc}
						value={productDesc}
						onChange={(e) => setProductDesc(e.target.value)}
					/>
				</div>
				<div className={classes.viewInRowSpace} style={{marginTop:10}}>
					<Autocomplete
						className={classes.input}
						options={category_list || []}
						onChange={(event, newValue) => {
							if (!_.isEmpty(newValue)) {
								setCategoryName(newValue['category_name']);
								setChosenCategory(newValue);
								setCategoryId(newValue['category_id']);
							}
						}}
						onInputChange={(event, newInputValue) => {
							setCategoryName(newInputValue);
						}}
						value={chosenCategory}
						inputValue={categoryName}
						getOptionLabel={(option) => option.category_name || ""}
						renderInput={(params) => <TextField {...params} label="Category" variant="outlined"/>}
					/>
					<Autocomplete
						className={classes.input}
						options={brand_list || []}
						onChange={(event, newValue) => {
							if (!_.isEmpty(newValue)) {
								setBrandName(newValue['brand_name']);
								setChosenBrand(newValue);
								setBrandId(newValue['brand_id']);
							}
						}}
						value={chosenBrand}
						inputValue={brandName}
						onInputChange={(event, newInputValue) => {
							setBrandName(newInputValue);
						}}
						getOptionLabel={(option) => option.brand_name || ""}
						renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
					/>
				</div>
				<div style={{height: 20}}/>
				{
					rows.map((d, i) => {
						return (
							<div key={i} className={classes.viewInRowSpace}>
								<TextField
									id={"input-item-uom"}
									style={{width: '20%', height: 50}}
									label={language.product_uom}
									name="uom"
									value={d.uom}
									onChange={(e) => handleChange(e, i)}
								/>
								<TextField
									id={"input-item-price"}
									style={{width: '20%', height: 50}}
									label={language.price}
									InputProps={{
										inputComponent: CurrencyFormatCustom,
									}}
									name="price"
									value={parseFloat(d.price).toFixed(2)}
									onChange={(e) => handleChange(e, i)}
								/>
								<TextField
									id={"input-item-barcode"}
									style={{width: '20%', height: 50}}
									label={language.barcode}
									name="barcode"
									value={d.barcode}
									onChange={(e) => handleChange(e, i)}
								/>
								<TextField
									id={"input-item-rate"}
									style={{width: '10%', height: 50}}
									label={language.uomrate}
									name="rate"
									InputProps={{
										inputComponent: NumberFormatCustom,
									}}
									value={d.rate}
									onChange={(e) => handleChange(e, i)}
								/>
								<Button
									style={{marginTop: 5, height: 45}}
									variant="outlined"
									color={'primary'}
									onClick={() => handleRemoveClick(i)}>
									<DeleteIcon/>
								</Button>
								<div style={{height: 10}}/>
							</div>
						)
					})
				}
				<div style={{height: 5}}/>
				<Button
					variant="outlined"
					color={'primary'}
					onClick={() => handleAddRow()}>
					{language.add_uom}
				</Button>
				<div style={{height: 20}}/>
				<div style={{display: "flex"}}>
					<Button
						style={{marginLeft: 'auto'}}
						variant="outlined"
						disabled={productCode === empty || productName === empty}
						onClick={() => {
							addProduct()
						}}
						color={'primary'}>
						{language.add}
					</Button>
				</div>
				<div style={{height: 20}}/>
			</div>
		</Dialog>
	)
}

function CurrencyFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={true}
			isNumericString
			prefix={'RM'}
			min={0}
			max={999999}
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}
