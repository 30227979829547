import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {usePaperStyles} from '../../Styles/StylePaper';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    checkin_report_url,
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    oos_report_url,
    outlet_visit_frequency_report_url,
    outlet_visit_report_url,
    req_cust,
    req_module_setting,
    req_salesman,
    req_stock,
    scheduled_visit_report_url,
    stock_near_expiry_report_url,
    stock_take_report_url
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import API_Service, {axiosDefaultConfig} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import VirtualList from '../../Components/VirtualList';
import format from 'date-fns/format';
import {de, getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import GenerateView from "../../Components/GenerateView";

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1)
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

export default function Report() {
	const commonStyle = useCommonStyles();
	const paperStyles = usePaperStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const scheduleStatusList = [
		{
			"name": language.pending,
			"value": "1"
		},
		{
			"name": language.attended,
			"value": "2"
		},
		{
			"name": language.missed,
			"value": "0"
		},
		{
			"name": language.all,
			"value": "-1"
		}
	]

	const typeOptions = [
		{name: 'Basic Version', value: 'simply'},
		{name: 'Detailed Version', value: 'normal'}
	]

	const expiry_date = [
		{name: '=<60 days', value: '=<60'},
		{name: '=<90 days', value: '=<90'},
		{name: '=<180 days', value: '=<180'},
		{name: '>180 days', value: '>180'}
	]

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const [agentAttendDateRange, setAgentAttendDateRange] = useState({});
	const [outletVisitDateRange, setOutletVisitDateRange] = useState({});
	const [oosDocDateRange, setOosoosDocDateRange] = useState({});
	const [outletVisitFDateRange, setoutletVisitFDateRange] = useState({});
	const [scheduleVisitDateRange, setScheduleVisitDateRange] = useState({});
	const [stockTakeDateRange, setStockTakeDateRange] = useState({});
	const [stockNearDateRange, setStockNearDateRange] = useState({});

	const [openAgentAttendDateDoc, setOpenAgentAttendDateDoc] = useState(false);
	const [openOutletVisitDateDoc, setOpenOutletVisitDateDoc] = useState(false);
	const [openOosDateDoc, setOpenOosDateDoc] = useState(false);
	const [openOutletVisitFDateDoc, setopenOutletVisitFDateDoc] = useState(false);
	const [openScheduleVisitDateDoc, setOpenScheduleVisitDateDoc] = useState(false);
	const [openStockTakeDateDoc, setOpenStockTakeDateDoc] = useState(false);
	const [openStockNearDateDoc, setOpenStockNearDateDoc] = useState(false);

	const [outletVisitSpView, setOutletVisitSpView] = useState(false);
	const [outletVisitSpSelect, setOutletVisitSpSelected] = useState(empty);
	const [outletVisitSalesperson, setOutletVisitSalesperson] = useState(empty);
	const [outletVisitRegionView, setoutletVisitRegionView] = useState(false);
	const [outletVisitStateView, setoutletVisitStateView] = useState(false);
	const [outletVisitStateSelected, setoutletVisitStateSelected] = useState(empty);
	const [outletVisitState, setoutletVisitState] = useState(empty);
	const [outletVisitRegionSelected, setoutletVisitRegionSelected] = useState(empty);
	const [outletVisitRegion, setoutletVisitRegion] = useState(empty);
	const [outletVisitOutletView, setOutletVisitOutletView] = useState(false);
	const [outletVisitOutlet, setOutletVisitOutlet] = useState(empty);
	const [outletVisitOutletSelect, setOutletVisitOutletSelected] = useState(empty);

	const [splist, setSPlist] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [regionList, setRegionList] = useState([]);

	const [printView, setPrintView] = useState();
	const [agentAttendSpView, setAgentAttendSpView] = useState(false);
	const [agentAttendOutletView, setAgentAttendOutletView] = useState(false);
	const [agentAttendOutlet, setAgentAttendOutlet] = useState(empty);
	const [agentAttendOutletSelect, setAgentAttendOutletSelected] = useState(empty);
	const [agentAttendSpSelect, setAgentAttendSpSelected] = useState(empty);
	const [agentAttendSalesperson, setAgentAttendSalesperson] = useState(empty);

	const [oosSpView, setOosSpView] = useState(false);
	const [oosOutletView, setOosOutletView] = useState(false);
	const [oosItemView, setOosItemView] = useState(false);
	const [spOosSelect, setOosSpSelected] = useState(empty);
	const [oosSalesperson, setOosSalesperson] = useState(empty);
	const [oosOutletSelect, setOosOutletSelected] = useState(empty);
	const [oosOutlet, setOosOutlet] = useState(empty);
	const [oosItemSelect, setOosItemSelected] = useState(empty);
	const [oosItem, setOosItem] = useState(empty);

	const [outletVisitFSPView, setoutletVisitFSPView] = useState(false);
	const [outletVisitFRegionView, setoutletVisitFRegionView] = useState(false);
	const [outletVisitFStateView, setoutletVisitFStateView] = useState(false);
	const [outletVisitFStateSelected, setoutletVisitFStateSelected] = useState(empty);
	const [outletVisitFState, setoutletVisitFState] = useState(empty);
	const [outletVisitFRegionSelected, setoutletVisitFRegionSelected] = useState(empty);
	const [outletVisitFRegion, setoutletVisitFRegion] = useState(empty);
	const [outletVisitFSPSelect, setoutletVisitFSPSelected] = useState(empty);
	const [outletVisitFSP, setoutletVisitFSP] = useState(empty);
	const [outletVisitFOutletView, setOutletVisitFOutletView] = useState(false);
	const [outletVisitFOutlet, setOutletVisitFOutlet] = useState(empty);
	const [outletVisitFOutletSelect, setOutletVisitFOutletSelected] = useState(empty);

	const [scheduleSpView, setScheduleSpView] = useState(false);
	const [scheduleOutletView, setScheduleOutletView] = useState(false);
	const [scheduleStatusMenu, setScheduleStatusMenu] = useState(false);
	const [scheduleStatus, setScheduleStatus] = useState(empty);
	const [scheduleOutlet, setScheduleOutlet] = useState(empty);
	const [scheduleOutletSelect, setScheduleOutletSelected] = useState(empty);
	const [scheduleSpSelect, setScheduleSpSelected] = useState(empty);
	const [scheduleSalesperson, setScheduleSalesperson] = useState(empty);

	const [stockTakeSpView, setStockTakeSpView] = useState(false);
	const [stockTakeOutletView, setStockTakeOutletView] = useState(false);
	const [stockTakeOutlet, setStockTakeOutlet] = useState(empty);
	const [stockTakeOutletSelect, setStockTakeOutletSelected] = useState(empty);
	const [stockTakeSpSelect, setStockTakeSpSelected] = useState(empty);
	const [stockTakeSalesperson, setStockTakeSalesperson] = useState(empty);
	const [stockTakeProductView, setStockTakeProductView] = useState(false);
	const [stockTakeProduct, setStockTakeProduct] = useState(empty);
	const [stockTakeProductSelect, setStockTakeProductSelected] = useState(empty);

	const [stockNearSpView, setStockNearSpView] = useState(false);
	const [stockNearOutletView, setStockNearOutletView] = useState(false);
	const [stockNearOutlet, setStockNearOutlet] = useState(empty);
	const [stockNearOutletSelect, setStockNearOutletSelected] = useState(empty);
	const [stockNearSpSelect, setStockNearSpSelected] = useState(empty);
	const [stockNearSalesperson, setStockNearSalesperson] = useState(empty);
	const [stockNearProductView, setStockNearProductView] = useState(false);
	const [stockNearProduct, setStockNearProduct] = useState(empty);
	const [stockNearProductSelect, setStockNearProductSelected] = useState(empty);
	const [stockNearExpiryView, setStockNearExpiryView] = useState(false);
	const [stockNearExpiry, setStockNearExpiry] = useState(empty);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadStocksList(gState.client);
			getCountry(gState.client);
		}
	}, [gState.client, gState.translation]);

	const getCountry = (_client = nClient) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const country = getSetting(extraData, 'country');
				let country_arr = [JSON.parse(JSON.stringify(country))];
				var status = country_arr[0].states;
				var region = country_arr[0].regions;
				var _status = [];
				var _region = [];
				for (let i = 0; i < status?.length; i++) {
					const {name} = status[i];
					_status.push({name: name, secondary: name, value: name, status: 1});
				}
				for (let i = 0; i < region?.length; i++) {
					const {name} = region[i];
					_region.push({name: name, secondary: name, value: name, status: 1});
				}
				setStateList(_status);
				setRegionList(_region);
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = nClient) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const loadStocksList = (_client = nClient) => {
		var req = `${req_stock}-1`;
		API_Service.get(req, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code, product_status} = stockApi[idx];
					_data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(product_status)});
				}
				setItemList(itemList => (_data));
			}
			de(data);
		}).catch(de);
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	//agent attend
	const AgentAttendSPViewClick = () => {
		setAgentAttendSpView(true);
	}

	const AgentAttendSPViewClose = (newValue) => {
		setAgentAttendSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setAgentAttendSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setAgentAttendSalesperson(spname);
		}
	}

	const AgentAttendOutletViewClick = () => {
		setAgentAttendOutletView(true);
	}

	const AgentAttendOutletViewClose = (newValue) => {
		setAgentAttendOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setAgentAttendOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setAgentAttendOutlet(spname);
		}
	}

	const openPrintSelectView = () => {
		setPrintView(true);
	}

	const printViewClose = (type) => {
		generateAgentAttendReport(type);
		setPrintView(false);
	}

	//outlet visit
	const OutletVisitSPViewClick = () => {
		setOutletVisitSpView(true);
	}

	const OutletVisitSPViewClose = (newValue) => {
		setOutletVisitSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setOutletVisitSalesperson(spname);
		}
	}

	const OutletVisitRegionViewClick = () => {
		setoutletVisitRegionView(true);
	}

	const OutletVisitRegionViewClose = (newValue) => {
		setoutletVisitRegionView(false);
		if (newValue) {
			var regionvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionvalue += newValue[i].value;
				} else {
					regionvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitRegionSelected(regionvalue);

			var regionname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionname += newValue[i].name;
				} else {
					regionname += newValue[i].name + ",";
				}
			}
			setoutletVisitRegion(regionname);
		}
	}

	const OutletVisitStateViewClick = () => {
		setoutletVisitStateView(true);
	}

	const OutletVisitStateViewClose = (newValue) => {
		setoutletVisitStateView(false);
		if (newValue) {
			var statevalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statevalue += newValue[i].value;
				} else {
					statevalue += newValue[i].value + ",";
				}
			}
			setoutletVisitStateSelected(statevalue);

			var statename = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statename += newValue[i].name;
				} else {
					statename += newValue[i].name + ",";
				}
			}
			setoutletVisitState(statename);
		}
	}

	const OutletVisitOutletViewClick = () => {
		setOutletVisitOutletView(true);
	}

	const OutletVisitOutletViewClose = (newValue) => {
		setOutletVisitOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitOutletSelected(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletVisitOutlet(custname);
		}
	}

	//oos
	const OosSpViewClick = () => {
		setOosSpView(true);
	}

	const OosOutletViewClick = () => {
		setOosOutletView(true);
	}

	const OosSpViewClose = (newValue) => {
		setOosSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setOosSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setOosSalesperson(spname);
		}
	}

	const OosOutletViewClose = (newValue) => {
		setOosOutletView(false);
		if (newValue) {
			var outletvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletvalue += newValue[i].value;
				} else {
					outletvalue += newValue[i].value + ",";
				}
			}
			setOosOutletSelected(outletvalue);

			var outletname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletname += newValue[i].name;
				} else {
					outletname += newValue[i].name + ",";
				}
			}
			setOosOutlet(outletname);
		}
	}

	const OosItemViewClick = () => {
		setOosItemView(true);
	}

	const OosItemViewClose = (newValue) => {
		setOosItemView(false);
		if (newValue) {
			var itemvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemvalue += newValue[i].value;
				} else {
					itemvalue += newValue[i].value + ",";
				}
			}
			setOosItemSelected(itemvalue);

			var item = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					item += newValue[i].name;
				} else {
					item += newValue[i].name + ",";
				}
			}
			setOosItem(item);
		}
	}

	//oulet visit frequency
	const outletVisitFSPViewClick = () => {
		setoutletVisitFSPView(true);
	}

	const outletVisitFSPViewClose = (newValue) => {
		setoutletVisitFSPView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFSPSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setoutletVisitFSP(spname);
		}
	}

	const outletVisitFRegionViewClick = () => {
		setoutletVisitFRegionView(true);
	}

	const outletVisitFRegionViewClose = (newValue) => {
		setoutletVisitFRegionView(false);
		if (newValue) {
			var regionvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionvalue += newValue[i].value;
				} else {
					regionvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFRegionSelected(regionvalue);

			var regionname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionname += newValue[i].name;
				} else {
					regionname += newValue[i].name + ",";
				}
			}
			setoutletVisitFRegion(regionname);
		}
	}

	const outletVisitFStateViewClick = () => {
		setoutletVisitFStateView(true);
	}

	const outletVisitFStateViewClose = (newValue) => {
		setoutletVisitFStateView(false);
		if (newValue) {
			var statevalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statevalue += newValue[i].value;
				} else {
					statevalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFStateSelected(statevalue);

			var statename = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statename += newValue[i].name;
				} else {
					statename += newValue[i].name + ",";
				}
			}
			setoutletVisitFState(statename);
		}
	}

	const OutletVisitFOutletViewClick = () => {
		setOutletVisitFOutletView(true);
	}

	const OutletVisitFOutletViewClose = (newValue) => {
		setOutletVisitFOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitFOutletSelected(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletVisitFOutlet(custname);
		}
	}

	//schedule visit
	const ScheduleVisitOutletViewClick = () => {
		setScheduleOutletView(true);
	}

	const ScheduleVisitOutletViewClose = (newValue) => {
		setScheduleOutletView(false);
		if (newValue) {
			var outletvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletvalue += newValue[i].value;
				} else {
					outletvalue += newValue[i].value + ",";
				}
			}
			setScheduleOutletSelected(outletvalue);

			var outletname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletname += newValue[i].name;
				} else {
					outletname += newValue[i].name + ",";
				}
			}
			setScheduleOutlet(outletname);
		}
	}

	const ScheduleVisitSPViewClick = () => {
		setScheduleSpView(true);
	}

	const ScheduleVisitSPViewClose = (newValue) => {
		setScheduleSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setScheduleSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setScheduleSalesperson(spname);
		}
	}

	const ScheduleStatusMenuClick = (event) => {
		setScheduleStatusMenu(true);
	}

	const ClosescheduleStatusMenu = (props) => {
		setScheduleStatus(props);
		setScheduleStatusMenu(false);
	}

	//stock take
	const StockTakeSPViewClick = () => {
		setStockTakeSpView(true);
	}

	const StockTakeSPViewClose = (newValue) => {
		setStockTakeSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockTakeSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockTakeSalesperson(spname);
		}
	}

	const StockTakeOutletViewClick = () => {
		setStockTakeOutletView(true);
	}

	const StockTakeOutletViewClose = (newValue) => {
		setStockTakeOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockTakeOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockTakeOutlet(spname);
		}
	}

	const StockTakeProductViewClick = () => {
		setStockTakeProductView(true);
	}

	const StockTakeProductViewClose = (newValue) => {
		setStockTakeProductView(false);
		if (newValue) {
			var itemsvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsvalue += newValue[i].value;
				} else {
					itemsvalue += newValue[i].value + ",";
				}
			}
			setStockTakeProductSelected(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsname += newValue[i].name;
				} else {
					itemsname += newValue[i].name + ",";
				}
			}
			setStockTakeProduct(itemsname);
		}
	}

	//stock near
	const StockNearSPViewClick = () => {
		setStockNearSpView(true);
	}

	const StockNearSPViewClose = (newValue) => {
		setStockNearSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockNearSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockNearSalesperson(spname);
		}
	}

	const StockNearOutletViewClick = () => {
		setStockNearOutletView(true);
	}

	const StockNearOutletViewClose = (newValue) => {
		setStockNearOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setStockNearOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setStockNearOutlet(spname);
		}
	}

	const StockNearProductViewClick = () => {
		setStockNearProductView(true);
	}

	const StockNearProductViewClose = (newValue) => {
		setStockNearProductView(false);
		if (newValue) {
			var itemsvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsvalue += newValue[i].value;
				} else {
					itemsvalue += newValue[i].value + ",";
				}
			}
			setStockNearProductSelected(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemsname += newValue[i].name;
				} else {
					itemsname += newValue[i].name + ",";
				}
			}
			setStockNearProduct(itemsname);
		}
	}

	const StockNearExpiryViewClick = () => {
		setStockNearExpiryView(true);
	}

	const StockNearExpiryViewClose = (props) => {
		setStockNearExpiryView(false);
		setStockNearExpiry(props);
	}

	//generate
	const generateAgentAttendReport = (type) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(agentAttendDateRange)) {
			st_date = format(agentAttendDateRange.startDate, defDateFormat);
			st_date_to = format(agentAttendDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`;

		const url = checkin_report_url(client, agentAttendSpSelect, date_range, type, cookies(ck_u_username), agentAttendOutletSelect);
		window.open(url, '_blank');
	}

	const generateOutletVisitReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitDateRange)) {
			st_date = format(outletVisitDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(outlet_visit_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', outletVisitSpSelect)
			.replaceInfinity('@cust_code', outletVisitOutletSelect)
			.replaceInfinity('@state', outletVisitStateSelected)
			.replaceInfinity('@region', outletVisitRegionSelected)
			.replaceInfinity('@username', cookies(ck_u_username))
			.str;
		window.open(link, "_blank");
	}

	const generateOosReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(oosDocDateRange)) {
			st_date = format(oosDocDateRange.startDate, defDateFormat);
			st_date_to = format(oosDocDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(oos_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', spOosSelect)
			.replaceInfinity('@cust_code', oosOutletSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.replaceInfinity('@product_code', oosItemSelect)
			.str;
		window.open(link, "_blank");
	}

	const generateOutletVisitFrequencyReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitFDateRange)) {
			st_date = format(outletVisitFDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitFDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(outlet_visit_frequency_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', outletVisitFSPSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.replaceInfinity('@state', outletVisitFStateSelected)
			.replaceInfinity('@region', outletVisitFRegionSelected)
			.replaceInfinity('@cust_code', outletVisitFOutletSelect)
			.str;
		window.open(link, "_blank");
	}

	const generateScheduleVisitReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(scheduleVisitDateRange)) {
			st_date = format(scheduleVisitDateRange.startDate, defDateFormat);
			st_date_to = format(scheduleVisitDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(scheduled_visit_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', scheduleSpSelect)
			.replaceInfinity('@cust_code', scheduleOutletSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.replaceInfinity('@status', scheduleStatus.value)
			.str;
		window.open(link, "_blank");
	}

	const generateStockTakeReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(stockTakeDateRange)) {
			st_date = format(stockTakeDateRange.startDate, defDateFormat);
			st_date_to = format(stockTakeDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(stock_take_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', stockTakeSpSelect)
			.replaceInfinity('@cust_code', stockTakeOutletSelect)
			.replaceInfinity('@product_code', stockTakeProductSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.str;
		window.open(link, "_blank");
	}

	const generateStockNearReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(stockNearDateRange)) {
			st_date = format(stockNearDateRange.startDate, defDateFormat);
			st_date_to = format(stockNearDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(stock_near_expiry_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', stockNearSpSelect)
			.replaceInfinity('@cust_code', stockNearOutletSelect)
			.replaceInfinity('@expiry_date', stockNearExpiry.value)
			.replaceInfinity('@product_code', stockNearProductSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.str;
		window.open(link, "_blank");
	}
	//date
	const getAgentAttendDate = () => {
		return !_.isEmpty(agentAttendDateRange) ? moment(agentAttendDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(agentAttendDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getOosDate = () => {
		return !_.isEmpty(oosDocDateRange) ? moment(oosDocDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(oosDocDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getOutletVisitFDate = () => {
		return !_.isEmpty(outletVisitFDateRange) ? moment(outletVisitFDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitFDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getScheduleVisitDate = () => {
		return !_.isEmpty(scheduleVisitDateRange) ? moment(scheduleVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(scheduleVisitDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getOutletVisitDate = () => {
		return !_.isEmpty(outletVisitDateRange) ? moment(outletVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getStockTakeDate = () => {
		return !_.isEmpty(stockTakeDateRange) ? moment(stockTakeDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(stockTakeDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	const getStockNearDate = () => {
		return !_.isEmpty(stockNearDateRange) ? moment(stockNearDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(stockNearDateRange.endDate).format('DD MMMM YYYY') : language.date_range;
	}

	return (
		<div>
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography className={classes.heading}>{language.generate_agent_attend_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => AgentAttendSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{agentAttendSalesperson ? _.truncate(agentAttendSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => AgentAttendOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{agentAttendOutlet ? _.truncate(agentAttendOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenAgentAttendDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getAgentAttendDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => openPrintSelectView()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography className={classes.heading}>{language.generate_stock_take_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockTakeSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockTakeSalesperson ? _.truncate(stockTakeSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockTakeOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockTakeOutlet ? _.truncate(stockTakeOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockTakeProductViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockTakeProduct ? _.truncate(stockTakeProduct, {length: 20}) : language.items}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenStockTakeDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getStockTakeDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateStockTakeReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography className={classes.heading}>{language.generate_oos_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OosSpViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{oosSalesperson ? _.truncate(oosSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OosOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{oosOutlet ? _.truncate(oosOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300, height: 40}}
							variant="outlined"
							onClick={() => OosItemViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{oosItem ? _.truncate(oosItem, {length: 20}) : language.items}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenOosDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getOosDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateOosReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography className={classes.heading}>{language.generate_outlet_visit_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OutletVisitSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitSalesperson ? _.truncate(outletVisitSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OutletVisitOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitOutlet ? _.truncate(outletVisitOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OutletVisitRegionViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitRegion ? _.truncate(outletVisitRegion, {length: 20}) : language.region}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OutletVisitStateViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitState ? _.truncate(outletVisitState, {length: 20}) : language.state}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenOutletVisitDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getOutletVisitDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateOutletVisitReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography className={classes.heading}>{language.generate_schedule_visit_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => ScheduleVisitSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{scheduleSalesperson ? _.truncate(scheduleSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => ScheduleVisitOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{scheduleOutlet ? _.truncate(scheduleOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={ScheduleStatusMenuClick}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography component="p" className={commonStyle.searchBtnFont}>
								{scheduleStatus ? scheduleStatus.name : language.schedule_status}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenScheduleVisitDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getScheduleVisitDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateScheduleVisitReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography
						className={classes.heading}>{language.generate_stock_near_expiry_date_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockNearSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockNearSalesperson ? _.truncate(stockNearSalesperson, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockNearOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockNearOutlet ? _.truncate(stockNearOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockNearProductViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockNearProduct ? _.truncate(stockNearProduct, {length: 20}) : language.items}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => StockNearExpiryViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{stockNearExpiry ? _.truncate(stockNearExpiry.name, {length: 20}) : language.expiry_date}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setOpenStockNearDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getStockNearDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateStockNearReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon/>}
					aria-controls="panel1a-content"
					id="panel1a-header">
					<Typography
						className={classes.heading}>{language.generate_outlet_visit_frequency_report}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={paperStyles.paperView}>
					<div className={classes.viewInRow}>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => outletVisitFSPViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitFSP ? _.truncate(outletVisitFSP, {length: 20}) : language.salesperson}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => OutletVisitFOutletViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitFOutlet ? _.truncate(outletVisitFOutlet, {length: 20}) : language.customer}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => outletVisitFStateViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitFState ? _.truncate(outletVisitFState, {length: 20}) : language.state}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => outletVisitFRegionViewClick()}
							endIcon={<KeyboardArrowDownRoundedIcon/>}>
							<Typography className={commonStyle.searchBtnFont}>
								{outletVisitFRegion ? _.truncate(outletVisitFRegion, {length: 20}) : language.region}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							style={{width: 300}}
							variant="outlined"
							onClick={() => setopenOutletVisitFDateDoc(true)}>
							<Typography className={commonStyle.searchBtnFont}>
								{getOutletVisitFDate()}
							</Typography>
						</Button>
						<div style={{width: 10}}/>
						<Button
							size="small"
							color="primary"
							variant={'contained'}
							onClick={() => generateOutletVisitFrequencyReport()}>
							<Typography className={commonStyle.searchBtnFont}>
								{language.generate}
							</Typography>
						</Button>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<VirtualListCB
				open={agentAttendSpView}
				setClose={data => AgentAttendSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={agentAttendOutletView}
				setClose={data => AgentAttendOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitSpView}
				setClose={data => OutletVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitOutletView}
				setClose={data => OutletVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitRegionView}
				setClose={data => OutletVisitRegionViewClose(data)}
				title={"Region"}
				data={regionList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitStateView}
				setClose={data => OutletVisitStateViewClose(data)}
				title={"State"}
				data={stateList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={oosSpView}
				setClose={data => OosSpViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={oosOutletView}
				setClose={data => OosOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={oosItemView}
				setClose={data => OosItemViewClose(data)}
				title={"Item"}
				data={itemList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFSPView}
				setClose={data => outletVisitFSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFStateView}
				setClose={data => outletVisitFStateViewClose(data)}
				title={"State"}
				data={stateList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFRegionView}
				setClose={data => outletVisitFRegionViewClose(data)}
				title={"Region"}
				data={regionList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFOutletView}
				setClose={data => OutletVisitFOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={scheduleSpView}
				setClose={data => ScheduleVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={scheduleOutletView}
				setClose={data => ScheduleVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualList
				open={scheduleStatusMenu}
				setClose={data => ClosescheduleStatusMenu(data)}
				title={"Status"}
				data={scheduleStatusList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockTakeSpView}
				setClose={data => StockTakeSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockTakeOutletView}
				setClose={data => StockTakeOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockTakeProductView}
				setClose={data => StockTakeProductViewClose(data)}
				title={"Product"}
				data={itemList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockNearOutletView}
				setClose={data => StockNearOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockNearSpView}
				setClose={data => StockNearSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={stockNearProductView}
				setClose={data => StockNearProductViewClose(data)}
				title={"Product"}
				data={itemList}
				language={language}
				filteration={0}
			/>

			<VirtualList
				open={stockNearExpiryView}
				setClose={data => StockNearExpiryViewClose(data)}
				title={"Expiry Date"}
				data={expiry_date}
				language={language}
			/>
			<DateRangePickerModal
				setPositive={range => {
					setAgentAttendDateRange(range);
					setOpenAgentAttendDateDoc(false)
				}}
				setNegative={() => {
					setOpenAgentAttendDateDoc(false)
				}}
				setClose={() => {
					setOpenAgentAttendDateDoc(false)
				}}
				open={openAgentAttendDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOutletVisitDateRange(range);
					setOpenOutletVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenOutletVisitDateDoc(false)
				}}
				open={openOutletVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOosoosDocDateRange(range);
					setOpenOosDateDoc(false)
				}}
				setNegative={() => {
					setOpenOosDateDoc(false)
				}}
				setClose={() => {
					setOpenOosDateDoc(false)
				}}
				open={openOosDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setoutletVisitFDateRange(range);
					setopenOutletVisitFDateDoc(false)
				}}
				setNegative={() => {
					setopenOutletVisitFDateDoc(false)
				}}
				setClose={() => {
					setopenOutletVisitFDateDoc(false)
				}}
				open={openOutletVisitFDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setScheduleVisitDateRange(range);
					setOpenScheduleVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenScheduleVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenScheduleVisitDateDoc(false)
				}}
				open={openScheduleVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setStockTakeDateRange(range);
					setOpenStockTakeDateDoc(false)
				}}
				setNegative={() => {
					setOpenStockTakeDateDoc(false)
				}}
				setClose={() => {
					setOpenStockTakeDateDoc(false)
				}}
				open={openStockTakeDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setStockNearDateRange(range);
					setOpenStockNearDateDoc(false)
				}}
				setNegative={() => {
					setOpenStockNearDateDoc(false)
				}}
				setClose={() => {
					setOpenStockNearDateDoc(false)
				}}
				open={openStockNearDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<GenerateView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={typeOptions}
			/>
		</div>
	)
}
