import React, {useContext, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {de} from "../../Helper/Helper";
import JString from "@easytech-international-sdn-bhd/jstring";
import {Chip} from "@material-ui/core";
import AppContext from "../../Services/AppContext";
import PersistantData from "../../Services/PersistantData";
import API_Service, {axiosDefaultConfig, axiosURIencode} from "../../Services/API_Service";
import {req_user_list} from "../../Helper/Constants";
import UserSettingsDetails from "../../Components/UserSettingDetails";

export default function UserSettings() {
    const [gState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang();
    }

    useEffect(() => {
        if (JString(gState.client).isValid()) {
            var axiosGetConfig = axiosDefaultConfig(gState.client);
            loadUsers(axiosGetConfig);
        }
    }, [gState.client]);

    const headCells = [
        {
            name: "login",
            label: "Login",
            options: {
                display: true,
                filterType: 'textField'
            }
        },
        {
            name: "staff_code",
            label: "Staff Code",
            options: {
                display: true,
                filterType: 'textField'
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                display: true,
                filterType: 'textField'
            }
        },
        {
            name: "role_name",
            label: "Role",
            options: {
                display: true,
                filterType: 'textField',
                customBodyRender: (value, tableMeta, updateValue) => {
                    switch (value) {
                        case "Manager" :
                            return (<Chip size="small" style={{backgroundColor: "orangered"}} label={value}/>);
                            break;
                        case "Agent" :
                            return (<Chip size="small" style={{backgroundColor: "yellowgreen"}} label={value}/>);
                            break;
                        default :
                            return ("-");
                            break;
                    }
                }
            }
        },
        {
            name: "group",
            label: "Group",
            options: {
                display: true,
                filterType: 'textField',
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(value){
                        return(
                            <>
                                {
                                    value.map((data) => {
                                        switch (data.role) {
                                            case "Manager" :
                                                return (<Chip size="small" style={{backgroundColor: "orangered"}} label={data.name}/>);
                                                break;
                                            case "Agent" :
                                                return (<Chip size="small" style={{backgroundColor: "yellowgreen"}} label={data.name}/>);
                                                break;
                                        }
                                    })
                                }
                            </>
                        );
                    }else{
                        return ("-");
                    }

                }
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                display: true,
                filterType: 'textField'
            }
        },
        {
            name: "contact_no",
            label: "Contact No.",
            options: {
                display: true,
                filterType: 'textField'
            }
        },
        {
            name: "company",
            label: "Branch",
            options: {
                display: true,
                filterType: 'textField',
                customBodyRender: (value) => value ? value.toUpperCase() : ''
            }
        },
    ];

    const [usersList, setUsersList] = useState([]);
    const [userDDialogShow, setUDDialogShow] = useState(false);
    const [selectedUserDetails, setSelectedUD] = useState({});

    const loadUsers = (axiosRequestConfig) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_user_list}${parameters}`, axiosRequestConfig).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    var _arr = extraData[idx];
                    if (!_arr['email']) {
                        _arr['email'] = '-';
                    }
                    if (!_arr['contact_no']) {
                        _arr['contact_no'] = '-';
                    }
                    _data.push(_arr);
                }
                setUsersList(usersList => (_data));
            }
        }).catch(de);
    }

    return(
        <div>
            <MUIDataTable
                title={language.user_settings}
                data={usersList}
                columns={headCells}
                options={{
                    selectableRows: 'none',
                    print: false,
                    filter: false,
                    download: false,
                    rowsPerPage: 150,
                    rowsPerPageOptions: [150, 300, 600, 900],
                    responsive: 'simple',
                    setTableProps: () => ({size: 'small'}),
                    onRowClick: (rowData, {dataIndex}) => {
                        setUDDialogShow(userDDialogShow => (true));
                        setSelectedUD(selectedUserDetails => (usersList[dataIndex]));
                    },
                }}
            />

            <UserSettingsDetails
                open={userDDialogShow}
                setClose={() => setUDDialogShow(() => (false))}
                selectedUserDetails={selectedUserDetails}
                language={language}
            />
        </div>
    )
}
