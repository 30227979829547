import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
	root: {
		maxWidth: 285,
		marginLeft: 10
	},
	media: {
		height: 80,
	},
});

export default function MediaBox({
									 image,
									 Component = null,
									 title,
									 description,
									 primaryBtn,
									 secondaryBtn,
									 viewBtn,
									 primaryBtnAction,
									 secondaryBtnAction,
									 viewBtnAction,
									 ...rest
								 }) {
	const classes = useStyles();
	const arbitary = {...rest};
	return (
		<Card className={arbitary?.style || classes.root} onClick={() => {
			arbitary.onClick && arbitary.onClick()
		}}>
			<CardActionArea>
				<CardMedia
					className={classes.media}
					component={() => Component ? <Component/> : <Image aspectRatio={(1 / 1)} src={image}/>}
					title={title || ''}
				/>
				{
					(title || description) ?
						<CardContent>
							{
								title ?
									<Typography variant={'subtitle2'} color="textPrimary" component="p">
										{title || ''}
									</Typography> : null
							}
							{
								description ?
									<Typography variant={'subtitle2'} color="textSecondary" component="p">
										{description || ''}
									</Typography> : null
							}
						</CardContent> : null
				}
			</CardActionArea>
			{
				(primaryBtn || secondaryBtn || viewBtn) ?
					<CardActions>
						{
							primaryBtn ?
								<Button
									onClick={() => primaryBtnAction && primaryBtnAction()}
									size="small"
									color="primary">
									{primaryBtn || ''}
								</Button> : null
						}
						{
							secondaryBtn ?
								<Button
									onClick={() => secondaryBtnAction && secondaryBtnAction()}
									size="small"
									color="secondary">
									{secondaryBtn || ''}
								</Button> : null
						}
						{
							viewBtn ?
								<Button
									onClick={() => viewBtnAction && viewBtnAction()}
									size="small"
									color="primary">
									{viewBtn || ''}
								</Button> : null
						}
						{
							arbitary?.default ?
								<Chip label={arbitary.default} color={'primary'}/>
								: null
						}
					</CardActions> : null
			}
		</Card>
	);
}
