import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {de} from "../../Helper/Helper";
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    checkin_report_url,
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    outlet_visit_frequency_report_url,
    req_cust,
    req_cust_area_name, req_delete_file,
    req_module_setting, req_report_exportMileage,
    req_report_outletvisitfrequency, req_report_posm, req_report_posmreport,
    req_salesman
} from '../../Helper/Constants';
import _, {forEach} from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    viewInRow: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'left',
        marginTop: theme.spacing(1),
        rowGap: 10
    }, card: {
        maxWidth: 200,
        minWidth: 200,
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium,
    },
    textleft: {
        textAlign: "left"
    },
    dropzone: {
        width: '50%',
        borderRadius: 5
    },
    dropzoneText: {
        color: grey[600]
    },
    adverPaper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5)
    },
    adverView: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1)
    },
    textarea: {
        width: '100%'
    },
    dateBtn: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    statusBtn: {
        marginLeft: 30,
        width: 150
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    alignRight: {
        display: 'flex',
        marginBottom: 10,
        flex: 1,
        flexDirection: 'row'
    }
}));

const tableHead = [
    {
        name: "create_date",
        label: "Date",
        options: {
            display: true
        }
    },
    {
        name: "name",
        label: "Agent Name",
        options: {
            display: true
        }
    },
    {
        name: "cust_area",
        label: "Outlet Area",
        options: {
            display: true
        }
    },
    {
        name: "cust_company_name",
        label: "Outlet",
        options: {
            display: true
        }
    }
]

export default function RPOSM() {
    const commonStyle = useCommonStyles();
    const classes = useStyles();

    const singleton = PersistantData.getInstance();
    const [gState, setGState] = useContext(AppContext);
    var {translation: language, client} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang()
    }

    const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [nClient, setClient] = useState(gState.client);

    const [splist, setSPlist] = useState([]);
    const [outletList, setOutletList] = useState([]);
    const [posmList, setPosmList] = useState([]);

    const [posmDateRange, setPosmDateRange] = useState({});
    const [openPosmDateDoc, setopenPosmDateDoc] = useState(false);
    const [posmSPView, setPosmSPView] = useState(false);
    const [posmSPSelect, setPosmSPSelected] = useState(empty);
    const [posmSP, setPosmSP] = useState(empty);
    const [posmOutletView, setPosmOutletView] = useState(false);
    const [posmOutlet, setPosmOutlet] = useState(empty);
    const [posmOutletSelect, setPosmOutletSelected] = useState(empty);
    const [tableHeader, setTableHeader] = useState([]);

    // const [searchByArea, setSearchByArea] = useState(false);
    // const [areaList, setAreaList] = useState([]);
    // const [areaView, setAreaView] = useState(false);
    // const [selectedArea, setSelectedArea] = useState(empty);


    useEffect(() => {
        if (JString(gState.client).isValid()) {
            getSalesmanList(gState.client);
            getOutletList(gState.client);
        }
        setTableHeader(tableHead);
    }, [gState.client, gState.translation]);

    const snack = (msg, type = 'success') => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,
            autoHideDuration: type === 'success' ? 2000 : 3000
        });
    }

    // const getAreaList = (_client = client) => {
    //     API_Service.get(req_cust_area_name, axiosDefaultConfig(_client)).then(({data}) => {
    //         const {result, extraData} = data;
    //         if (parseInt(result) === 1) {
    //             setAreaList(extraData);
    //         }
    //     });
    // }
    //
    // const AreaViewClick = () => {
    //     setAreaView(true);
    // }
    //
    // const AreaViewClose = (newValue) => {
    //     setAreaView(false);
    //     if (newValue) {
    //         var value = '';
    //         for (let i = 0; i < newValue.length; i++) {
    //             if (i == newValue.length - 1) {
    //                 value += newValue[i].value;
    //             } else {
    //                 value += newValue[i].value + ",";
    //             }
    //         }
    //         setSelectedArea(value);
    //     }
    // }

    const getPosmList = (_client = client) => {
        var st_date = '';
        var st_date_to = '';
        if (!_.isEmpty(posmDateRange)) {
            st_date = format(posmDateRange.startDate, defDateFormat);
            st_date_to = format(posmDateRange.endDate, defDateFormat);
        }
        const param = axiosURIencode({
            client: client,
            date_from: st_date,
            date_to: st_date_to,
            salespersonId: posmSPSelect,
            cust_code: posmOutletSelect,
            // area: selectedArea,
            username: cookies(ck_u_username)
        });

        API_Service.get(`${req_report_posm}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData, message} = data;
            const posmOptions = extraData['posm_options'];

            if (result) {
                if (!(tableHead.length > 4)) {
                    for (let i = 0; i < posmOptions.length; i++) {
                        tableHead.push({
                            name: posmOptions[i],
                            label: posmOptions[i],
                            options: {
                                display: true
                            }
                        })
                    }
                }
    
                const requiredTableData = extraData['posm_data1'];
                setPosmList(posmList => (requiredTableData));
            } else {
                setPosmList(posmList => ([]));
                snack(message, 'warning');
            }
        });
    }

    const getSalesmanList = (_client = nClient) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                var _data = [];
                for (let idx = 0; idx < extraData.length; idx++) {
                    const {name, login_id, login_status} = extraData[idx];
                    _data.push({name: name, value: login_id, status: parseInt(login_status)});
                }
                setSPlist(splist => (_data));
            } else {
                snack(gState.translation.not_found_salesperson, 'warning');
            }
        });
    }

    const getOutletList = (_client = nClient) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
            const {result, extraData} = data;
            var {customer, inactive_customer} = extraData;
            if (parseInt(result) === 1) {
                var arr = [];
                for (let i = 0; i < customer.length; i++) {
                    const {cust_code, cust_company_name} = customer[i];
                    arr.push({
                        name: cust_company_name,
                        secondary: cust_code,
                        value: cust_code,
                        status: 1
                    });
                }
                for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
                setOutletList(arr);
            }
        });
    }

    const posmSPViewClick = () => {
        setPosmSPView(true);
    }

    const posmSPViewClose = (newValue) => {
        setPosmSPView(false);
        if (newValue) {
            var spvalue = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    spvalue += newValue[i].value;
                } else {
                    spvalue += newValue[i].value + ",";
                }
            }
            setPosmSPSelected(spvalue);

            var spname = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    spname += newValue[i].name;
                } else {
                    spname += newValue[i].name + ",";
                }
            }
            setPosmSP(spname);
        }
    }

    const posmOutletViewClick = () => {
        setPosmOutletView(true);
    }

    const OutletViewClose = (newValue) => {
        setPosmOutletView(false);
        if (newValue) {
            var custvalue = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    custvalue += newValue[i].value;
                } else {
                    custvalue += newValue[i].value + ",";
                }
            }
            setPosmOutletSelected(custvalue);

            var custname = '';
            for (let i = 0; i < newValue.length; i++) {
                if (i == newValue.length - 1) {
                    custname += newValue[i].name;
                } else {
                    custname += newValue[i].name + ",";
                }
            }
            setPosmOutlet(custname);
        }
    }

    const getOutletVisitFDate = () => {
        return !_.isEmpty(posmDateRange) ? moment(posmDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(posmDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
    }

    const generatePosmReport = (_client = client) => {
        var st_date = '';
        var st_date_to = '';
        if (!_.isEmpty(posmDateRange)) {
            st_date = format(posmDateRange.startDate, defDateFormat);
            st_date_to = format(posmDateRange.endDate, defDateFormat);
        }
        const date_range = `${st_date} to ${st_date_to}`

        const param = axiosURIencode({
            client: client,
            cust_code: posmOutletSelect,
            date_from: st_date,
            date_to: st_date_to,
            salespersonId: posmSPSelect,
            username: cookies(ck_u_username)
        });

        API_Service.get(`${req_report_posmreport}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
            var {result, message, extraData} = data;
            if(result){
                window.open(extraData.url, '_blank');
                const delay = setTimeout(() => {
                    API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                    clearTimeout(delay);
                }, 5000);
            }
        });
    }


    return (
        <div>
            <div className={classes.viewInRow}>
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => posmSPViewClick()}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {posmSP ? _.truncate(posmSP, {length: 20}) : language.salesperson}
                    </Typography>
                </Button>
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => posmOutletViewClick()}
                    endIcon={<KeyboardArrowDownRoundedIcon/>}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {posmOutlet ? _.truncate(posmOutlet, {length: 20}) : language.customer}
                    </Typography>
                </Button>
                {/*{*/}
                {/*    searchByArea && (*/}
                {/*        <Button*/}
                {/*            style={{width: 300, marginRight: 10}}*/}
                {/*            variant="outlined"*/}
                {/*            onClick={() => AreaViewClick()}*/}
                {/*            endIcon={<KeyboardArrowDownRoundedIcon/>}>*/}
                {/*            <Typography className={commonStyle.searchBtnFont}>*/}
                {/*                {selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}*/}
                {/*            </Typography>*/}
                {/*        </Button>*/}
                {/*    )*/}
                {/*}*/}
                <Button
                    style={{width: 300, marginRight: 10}}
                    variant="outlined"
                    onClick={() => setopenPosmDateDoc(true)}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {getOutletVisitFDate()}
                    </Typography>
                </Button>
                <Button
                    size="small"
                    color="primary"
                    variant={'contained'}
                    onClick={() => getPosmList()}>
                    <Typography className={commonStyle.searchBtnFont}>
                        {language.view}
                    </Typography>
                </Button>
            </div>
            <div style={{height: 20}}/>
            {
                posmList.length > 0 && (
                    <MUIDataTable
                        title={language.posm}
                        data={posmList}
                        columns={tableHeader}
                        options={{
                            search: false,
                            print: false,
                            filter: false,
                            download: false,
                            selectableRows: 'none',
                            rowsPerPage: 25,
                            rowsPerPageOptions: [25, 40, 65, 100],
                            responsive: 'scrollMaxHeight',
                            onColumnViewChange: (changedColumn, action) => {
                                if(tableHeader.some(item => changedColumn === item.name)){
                                    var index = tableHeader.findIndex(item => changedColumn === item.name);
                                }
                                switch(action){
                                    case 'add':
                                        tableHeader[index].options.display = true;
                                    case 'remove':
                                        tableHeader[index].options.display = false;
                                }
                                setTableHeader(tableHeader);
                            },
                            customToolbar: () =>
                                <>
                                    <GenerateReportButton onGenerateReport={generatePosmReport}/>
                                </>
                        }}
                    />
                )
            }

            <VirtualListCB
                open={posmSPView}
                setClose={data => posmSPViewClose(data)}
                title={"Salesperson"}
                data={splist}
                language={language}
                filteration={1}
            />

            <VirtualListCB
                open={posmOutletView}
                setClose={data => OutletViewClose(data)}
                title={"Outlet"}
                data={outletList}
                language={language}
                filteration={1}
            />

            {/*<VirtualListCB*/}
            {/*    open={areaView}*/}
            {/*    setClose={data => AreaViewClose(data)}*/}
            {/*    title={"Area"}*/}
            {/*    data={areaList}*/}
            {/*    language={language}*/}
            {/*/>*/}

            <DateRangePickerModal
                setPositive={range => {
                    setPosmDateRange(range);
                    setopenPosmDateDoc(false)
                }}
                setNegative={() => {
                    setopenPosmDateDoc(false)
                }}
                setClose={() => {
                    setopenPosmDateDoc(false)
                }}
                open={openPosmDateDoc}
                message={language.date_range}
                positive={language.ok}
                negative={language.cancel}
            />
        </div>
    )
}

function GenerateReportButton({onGenerateReport}) {
    return (
        <React.Fragment>
            <Tooltip title={'Generate Report'}>
                <IconButton
                    edge={'end'}
                    color={'primary'}
                    onClick={() => onGenerateReport()} aria-label="addnew">
                    <AssessmentIcon/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}
