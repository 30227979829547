import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Alert({
								  setPositive,
								  setNegative,
								  setClose,
								  open,
								  title,
								  message,
								  positive,
								  negative,
								  autoDispossal,
								  autoDispossalLength,
								  unmount
							  }) {

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [timeoutVar, setTimeoutVar] = useState(null);
	const [dispossalProgress, setDispossalProgress] = useState(0);

	useEffect(() => {
		if (open) {
			const interval = autoDispossalLength ? autoDispossalLength : 50;
			const dispossal = setInterval(() => {
				setDispossalProgress(dispossalProgress => (dispossalProgress >= 100 ? autoClose() : dispossalProgress + 1));
			}, interval);
			setTimeoutVar(dispossal);
			return () => {
				clearInterval(dispossal);
			};
		}
	}, [open]);

	const autoClose = () => {
		const delay = setTimeout(() => {
			setClose();
			clearTimeout(delay);
		}, 100);
		return 0;
	}

	const onClose = () => {
		timeoutVar && clearTimeout(timeoutVar);
		setClose();
		setDispossalProgress(0);
	}

	if (unmount === true && open === false) return null;

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={() => onClose()}
			aria-labelledby="responsive-dialog-title">
			<DialogTitle
				id="responsive-dialog-title">
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => setNegative()} color="inherit">
					{negative}
				</Button>
				<Button
					onClick={() => setPositive()}
					color="inherit"
					autoFocus
					endIcon={autoDispossal && (
						<CircularProgress
							variant={'determinate'}
							value={dispossalProgress}
							size={18}/>
					)}>
					{positive}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
