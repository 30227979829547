import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import {google_fcm_key} from './Helper/Constants';
import {isDebug} from "./Helper/Helper";

export function Init() {
	if (!isDebug()) {
		var firebaseConfig = {
			apiKey: "AIzaSyBY8lsiyUeKQ6aB041ZxMw2GQs48xAOT68",
			authDomain: "easysales-admin.firebaseapp.com",
			databaseURL: "https://easysales-admin.firebaseio.com",
			projectId: "easysales-admin",
			storageBucket: "easysales-admin.appspot.com",
			messagingSenderId: "206650195765",
			appId: "1:206650195765:web:2adef034318c7968d4167c",
			measurementId: "G-E5Q6DVT2YZ"
		};
		if(firebase.messaging.isSupported()){
			firebase.initializeApp(firebaseConfig);
			firebase.analytics();
			initNotification();
		}
	}
}

export function isSupported(){
	return firebase.messaging.isSupported();
}

export function initNotification(getToken = null, getMessage = null) {
	if (isDebug()) {
		getToken && getToken('debug');
		getMessage && getMessage('debug');
	} else if (!isSupported()) {
		getToken && getToken("--notification--not--supported");
		getMessage && getMessage("--notification--not--supported");
	} else {
		const messaging = firebase.messaging();
		messaging.getToken({vapidKey: google_fcm_key}).then((currentToken) => {
			if (currentToken) {
				getToken && getToken(currentToken);
			} else {
				alert('No registration for push notification available. Request permission.');
				LogEvent('No registration token available. Request permission to generate one.');
			}
		}).catch((err) => {
			alert('An error occurred while requesting notification permission ');
			LogEvent('An error occurred while retrieving token. ');
		});
		messaging.onMessage((payload) => {
			console.log('Message received. ', payload);
			const notificationTitle = payload.notification.title;
			const notificationOptions = {
				body: payload.notification.body,
				icon: './logo192.png',
			};

			if (!("Notification" in window)) {
				console.log("This browser does not support system notifications.");
			} else if (Notification.permission === "granted") {
				// If it's okay let's create a notification
				var notification = new Notification(notificationTitle, notificationOptions);
				notification.onclick = function (event) {
					event.preventDefault();
					notification.close();
				}
			}
			getMessage && getMessage(payload);
		});
	}
}

export function LogEvent(message) {
	if (!isDebug()) {
		firebase.analytics().logEvent(message);
	}
}
