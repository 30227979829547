import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import _ from 'lodash';
import {empty} from '../../Helper/Constants';
import {useCommonStyles} from '../../Styles';
import VirtualListCB from '../VirtualListCB';

export default function GenerateView(props) {

	const {onClose, onSelect, value: valueProp, open, title, language, options, list, onCategory, ...other} = props;
	const [value, setValue] = React.useState(valueProp);
	const radioGroupRef = React.useRef(null);
	const commonStyle = useCommonStyles();
	const [categoryView, setCategoryView] = React.useState(false);
	const [selectedCategoryName, setSelectedCategoryName] = React.useState(empty);
	const [selectedCategoryValue, setSelectedCategoryValue] = React.useState(empty);

	React.useEffect(() => {
		if (!open) {
			setValue(valueProp);
		}
	}, [valueProp, open]);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		if(value == 'category'){
			onSelect({
				type: value,
				categories: selectedCategoryValue
			});
		}else{
			onSelect(value);
		}
	};

	const handleChange = (event) => {
		if (event.target) {
			setValue(value => (event.target.value));
		}
		event.persist();
	};

	const categoryOnClosed = (value) => {
        setCategoryView(false);
        if (value) {
            var data = '';
            for (let i = 0; i < value.length; i++) {
                if (i == value.length - 1) {
                    data += "'" + value[i].name.toString() + "'";
                } else {
                    data += "'" + value[i].name.toString() + "'" + ",";
                }
            }
            setSelectedCategoryName(data);
        }

        if (value) {
            var data = '';
            for (let i = 0; i < value.length; i++) {
                if (i == value.length - 1) {
                    data += "" + value[i].value.toString() + "";
                } else {
                    data += "" + value[i].value.toString() + "" + ",";
                }
            }
            setSelectedCategoryValue(data);
        }
    }

	return (
		<>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				onEntering={handleEntering}
				aria-labelledby="confirmation-dialog-title"
				open={open}
				{...other}>
				<DialogTitle id="confirmation-dialog-title">
					{title || ''}
				</DialogTitle>
				<DialogContent dividers>
					<RadioGroup
						ref={radioGroupRef}
						value={value}
						onChange={handleChange}>
						{options.map((option, idx) => {
							if (idx !== options.length) {
								return (
									<FormControlLabel
										value={option.value}
										key={String(idx)}
										control={<Radio/>}
										label={option.name}/>
								)
							} else {
								return (
									null
								)
							}
						})}
					</RadioGroup>
					{
						value == 'category' && (
							<Button
								style={{width: '-webkit-fill-available'}}
								variant="outlined"
								onClick={() => setCategoryView(true)}
								endIcon={<KeyboardArrowDownRoundedIcon/>}>
								<Typography className={commonStyle.searchBtnFont}>
									{selectedCategoryName ? _.truncate(selectedCategoryName, {length: 20}) : "Category"}
								</Typography>
							</Button>
						)
					}
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} color="primary">
						<Typography component="p" style={{color: red[400]}}>
							{language.cancel}
						</Typography>
					</Button>
					<Button onClick={handleOk} color="primary">
						<Typography component="p">
							{language.generate}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
			<VirtualListCB
                open={categoryView}
                setClose={data => categoryOnClosed(data)}
                title={"Category"}
                data={list}
                language={language}
				filteration={0}
            />
		</>
	);
}

GenerateView.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	language: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired
};
