import {useEffect, useLayoutEffect, useState} from 'react';

/**
 * @copyright https://github.com/the-road-to-learn-react/use-state-with-callback/blob/master/src/index.js
 */
const useStateCallback = (initialState, callback) => {
	const [state, setState] = useState(initialState);

	useEffect(() => callback(state), [state, callback]);

	return [state, setState];
};

const useStateCallbackFire = (initialState, callback) => {
	const [state, setState] = useState(initialState);

	useLayoutEffect(() => callback(state), [state, callback]);

	return [state, setState];
};

export {useStateCallbackFire};

export default useStateCallback;
