import React, {useEffect, useState} from "react";
import {Router} from 'react-router-dom';
import Routes from './Routes';
import History from './Services/History';
import AppContext from './Services/AppContext';
import {CookiesProvider} from 'react-cookie';
import SocketContext from "./Services/SocketContext";
import * as firebase from './Firebase';

export default function App() {
	const [translation, setTranslation] = useState({});
	const [socket, setSocket] = useState(undefined);
	useEffect(() => {
		console.log("window", ("Notification" in window));
		console.log("firebase", firebase.isSupported);
		if(("Notification" in window)){
			Notification.requestPermission();
		}
		firebase.Init();
	}, []);
	return (
		<AppContext.Provider value={[translation, setTranslation]}>
			<SocketContext.Provider value={[socket, setSocket]}>
				<CookiesProvider>
					<Router history={History}>
						<Routes/>
					</Router>
				</CookiesProvider>
			</SocketContext.Provider>
		</AppContext.Provider>
	);
}
