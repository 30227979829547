import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import 'mapbox-gl/dist/mapbox-gl.css';
import IconButton from "@material-ui/core/IconButton";
import moment from 'moment';
import JString from '@easytech-international-sdn-bhd/jstring';
import jstring from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {
    Button,
    Chip,
    CircularProgress,
    DialogContent,
    Fab,
    Grid,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField
} from '@material-ui/core';
import {
    req_cust,
    req_salesman,
    req_stock_category,
    req_stock_def,
    req_stock_itemcategory,
    req_stock_itempackage,
    req_stock_pkg,
    req_stocktemplate,
    req_stocktemplate_bind,
    req_stocktemplate_dtl,
    req_stocktemplate_dtlseq,
    reset
} from '../../Helper/Constants';
import MUIDataTable from 'mui-datatables';
import VirtualListSTI from '../VirtualListSTI';
import {de} from '../../Helper/Helper';
import {useCommonStyles} from '../../Styles';
import ItemTemplateEnhancedTable from '../ItemTemplateEnhancedTable';
import EditIcon from '@material-ui/icons/Edit';
import AnyDialog from '../../Components/AnyDialog';

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	appBar: {
		position: 'relative',
	},
	root: {
		flexGrow: 1
	},
	grid: {
		marginTop: 10,
		paddingLeft: 20,
		paddingRight: 20
	},
	dlgContent: {margin: -20, marginTop: -10},
	paper: {
		padding: 10
	},
	paperMaxHeight: {
		padding: 10,
		maxHeight: 145
	},
	dialogEdit: {
		width: 400,
		paddingBottom: 100
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function StockTemplateItems({
											   open,
											   setClose,
											   language,
											   data,
											   gState,
											   onSnack
										   }) {
	const classes = useStyles();
	const commonStyles = useCommonStyles();
	const [axiosConfig, setAxiosConfig] = useState({});
	const [tabPosition, setTabPosition] = useState(0);
	const [dtlItems, setDtlItems] = useState([]);
	const [itemTypeOpen, openItemType] = useState(false);
	const [itemType, setItemType] = useState(0);
	const [itemList, setItemList] = useState([]);
	const [categoryList, setCategoryList] = useState([]);
	const [packageList, setPackageList] = useState([]);
	const [listOpen, showList] = useState(false);
	const [previewList, setPreviewList] = useState({category: [], package: [], items: []});
	const [savingPreview, setSavingPreview] = useState(false);
	const [customerList, setCustomerList] = useState([]);
	const [customerListOpen, showCustomerList] = useState(false);
	const [agentListOpen, showAgentList] = useState(false);
	const [stockTmpltCustomer, setStockTmpltCustomer] = useState([]);
	const [stockTmpltAgent, setStockTmpltAgent] = useState([]);
	const [agentList, setAgentList] = useState([]);
	const [tempName, setTempName] = useState(null);
	const [tempStatus, setTempStatus] = useState(null);
	const [editDialog, setEditDialog] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [itemCategoryList, setItemCategoryList] = useState([]);
	const [itemCategories, setItemCategories] = useState([]);
	const [itemPackageList, setItemPackageList] = useState([]);
	const [itemPackage, setItemPackage] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
			loadItemList(axiosGetConfig);
			loadPackageList(axiosGetConfig);
			loadCategoryList(axiosGetConfig);
			loadCustomerList(axiosGetConfig);
			loadAgents(axiosGetConfig);
			loadItemCategoryList(axiosGetConfig);
			// loadPackageCategoryList(axiosGetConfig);
		}
	}, [gState?.client]);

	useEffect(() => {
		if (data.id) {
			loadStockTemplateItems();
			loadStockTemplateCustomer();
			loadStockTemplateAgents();
			setTempName(data.tmplt_name);
			setTempStatus(data.active_status);
		}
	}, [data.id]);

	const onChange = (e) => {
		setTempStatus(e.target.value);
	}

	const loadStockTemplateCustomer = (config = axiosConfig) => {
		setStockTmpltCustomer([]);
		var params = axiosURIencode({
			id: data.id,
			type: 'customers'
		});
		API_Service.get(`${req_stocktemplate_bind}?${params}`, config).then(({data}) => {
			const {result, extraData} = data;
			de('template customer', data);
			if (parseInt(result) === 1) {
				setStockTmpltCustomer(extraData);
			}
		});
	}

	const loadStockTemplateAgents = (config = axiosConfig) => {
		setStockTmpltAgent([]);
		var params = axiosURIencode({
			id: data.id,
			type: 'agents'
		});
		API_Service.get(`${req_stocktemplate_bind}?${params}`, config).then(({data}) => {
			const {result, extraData} = data;
			de('template agent', extraData);
			if (parseInt(result) === 1) {
				setStockTmpltAgent(extraData);
			}
		});
	}

	const loadStockTemplateItems = (config = axiosConfig) => {
		setDtlItems([]);
		var params = axiosURIencode({
			id: data.id
		});
		API_Service.get(`${req_stocktemplate_dtl}?${params}`, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				setDtlItems(extraData);
			}
		});
	}

	const loadAgents = (config = axiosConfig) => {
		setAgentList([]);
		API_Service.get(`${req_salesman}`, config).then(({data}) => {
			if (data) {
				var {result, extraData: api_Sp} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const {name, login_id} = api_Sp[idx];
						_data.push({name: name, value: login_id});
					}
					_data.push({name: gState.translation?.salesman, value: reset});
					setAgentList(_data);
				}
			}
		});
	}

	const loadCustomerList = (config = axiosConfig) => {
		setCustomerList([]);
		API_Service.get(req_cust, config).then(({data}) => {
			const {result, extraData} = data;
			var {customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						value: cust_code
					});
				}
				setCustomerList(arr);
			}
		});
	}

	const loadItemList = (config = axiosConfig) => {
		setItemList([]);
		API_Service.get(req_stock_def, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				const {stock} = extraData;
				var _arr = [];
				for (let i = 0; i < stock.length; i++) {
					const {product_code, product_name, product_desc, product_status} = stock[i];
					if (product_status === '1') {
						_arr.push({
							name: product_name,
							value: product_code,
							description: product_desc
						});
					}
				}
				setItemList(_arr);
			}
		});
	}

	const loadPackageList = (config = axiosConfig) => {
		setPackageList([]);
		API_Service.get(req_stock_pkg, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _arr = [];
				for (let i = 0; i < extraData.length; i++) {
					const {pkg_code, pkg_name} = extraData[i];
					_arr.push({
						name: pkg_name,
						value: pkg_code
					});
				}
				setPackageList(_arr);
			}
		});
	}

	const loadCategoryList = (config = axiosConfig) => {
		setCategoryList([]);
		API_Service.get(req_stock_category, config).then(({data}) => {
			if (data.length) {
				var _arr = [];
				for (let i = 0; i < data.length; i++) {
					const {categoryIdentifierId, category_name} = data[i];
					_arr.push({
						name: category_name,
						value: categoryIdentifierId
					});
				}
				setCategoryList(_arr);
			}
		});
	}

	const loadItemCategoryList = (config = axiosConfig) => {
		setItemCategoryList([]);
		API_Service.get(`${req_stock_itemcategory}`, config).then(({data}) => {
			if (data.length) {
				var itemcat_data = [];
				var cat_data = [];
				var dummy = [];

				for (let i = 0; i < data.length; i++) {
					const {product_code, categoryIdentifierId, category_name} = data[i];

					itemcat_data.push({name: product_code, value: categoryIdentifierId});

					if (dummy.indexOf(categoryIdentifierId) === -1){
						cat_data.push({label: category_name, value: categoryIdentifierId})
						dummy.push(categoryIdentifierId);
					}
				}
				setItemCategoryList(itemcat_data);
				setItemCategories(cat_data);
			}
		});
	};

	//TODO Logic for package not complete as relevant data table needs to be figured out
	const loadPackageCategoryList = (config = axiosConfig) => {
		setItemPackageList([]);
		API_Service.get(`${req_stock_itempackage}`, config).then(({data}) => {
			if (data.length) {
				var itempkg_data = [];
				var pkg_data = [];
				var dummy = [];

				for (let i = 0; i < data.length; i++) {                                         //
					const {product_code, categoryIdentifierId, category_name} = data[i];        //

					itempkg_data.push({name: product_code, value: categoryIdentifierId});       //

					if (dummy.indexOf(categoryIdentifierId) === -1){                            //
						pkg_data.push({label: category_name, value: categoryIdentifierId})      //
						dummy.push(categoryIdentifierId);                                       //
					}
				}
				setItemCategoryList(itempkg_data);
				setItemCategories(pkg_data);
			}
		});
	};

	const onClose = () => {
		setTempName(null);
		setTempStatus(null);
		setPreviewList({category: [], package: [], items: []});
		setDtlItems([]);
		setClose();
	}

	const getActiveItemList = () =>{
		for (let j = 0; j < (itemList || []).length; j++){
			const {value} = itemList[j];
			let hide = false;
			for (let i = 0; i < (dtlItems || []).length; i++){
				const {dtl_code} = dtlItems[i];
				if (jstring(dtl_code).isEqualDeep(value)){
					hide = true;
				}
			}
			itemList[j] = {
				...itemList[j],
				hide
			};
		}
		return itemList;
	}

	const getoptionlist = (index) => [
		getActiveItemList(),
		getActiveItemList(),
		getActiveItemList()
	][index];

	const getoptionname = (index) => [
		language.sku_item_list,
		language.package,
		language.category
	][index];

	const onSelectChange = (index) => {
		setItemType(index);
	}

	const onConfirmCustomer = (props) => {
		if (props !== false) {
			var arr = [];
			for (let i = 0; i < props.length; i++) {
				const {value} = props[i];
				arr.push({
					tmplt_id: data.id,
					cust_code: value,
					updated_by: gState.username,
					active_status: 1
				});
			}
			const formdata = new FormData();
			formdata.append('batch', JSON.stringify(arr));
			API_Service.post(req_stocktemplate_bind, formdata, axiosConfig).then(({data}) => {
				const {result} = data;
				if (parseInt(result) === 1) {
					loadStockTemplateCustomer();
				} else {
					onSnack(language.operation_fail, 'error');
				}
			});
		}
		showCustomerList(false);
	}

	const onConfirmAgent = (props) => {
		if (props !== false) {
			var arr = [];
			for (let i = 0; i < props.length; i++) {
				const {value} = props[i];
				arr.push({
					tmplt_id: data.id,
					salesperson_id: value,
					updated_by: gState.username,
					active_status: 1
				});
			}
			const formdata = new FormData();
			formdata.append('batch', JSON.stringify(arr));
			API_Service.post(req_stocktemplate_bind, formdata, axiosConfig).then(({data}) => {
				const {result} = data;
				if (parseInt(result) === 1) {
					loadStockTemplateAgents();
				} else {
					onSnack(language.operation_fail, 'error');
				}
			});
		}
		showAgentList(false);
	}

	const onInactiveBind = (data, type = 'customer') => {
		var ids_to_delete = [];
		for (let i = 0; i < (data || []).length; i++){
			const {dataIndex} = data[i];
			const {id} = type === 'customer' ? stockTmpltCustomer[dataIndex] : stockTmpltAgent[dataIndex];
			ids_to_delete.push(id);
		}
		/*const {dataIndex} = data[0];
		const {id} = type === 'customer' ? stockTmpltCustomer[dataIndex] : stockTmpltAgent[dataIndex];*/
		var params = axiosURIencode({
			id: ids_to_delete.join(",")
		});
		var axiosPut = axiosPutConfig(gState.client);
		API_Service.put(req_stocktemplate_bind, params, axiosPut).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				if (type === 'customer') {
					loadStockTemplateCustomer();
				} else {
					loadStockTemplateAgents();
				}
			} else {
				onSnack(language.operation_fail, 'error');
			}
		});
	}

	const onConfirmList = (props) => {
		if (props !== false) {
			switch (itemType) {
				case 0: {
					previewList.items = props;
					break;
				}
				case 1: {
					previewList.package = props;
					break;
				}
				case 2: {
					previewList.category = props;
					break;
				}
				default:
					break;
			}
			setPreviewList(previewList);
		}
		showList(false);
	}

	const makePreviewData = () => {
		const {package: _package, category: _category, items: _items} = previewList;
		var res = [];
		for (let i = 0; i < _package.length; i++) {
			const obj = _package[i];
			obj.type = 'ITEMS_P';
			res.push(obj);
		}
		for (let i = 0; i < _category.length; i++) {
			const obj = _category[i];
			obj.type = 'ITEMS_C';
			res.push(obj);
		}
		for (let i = 0; i < _items.length; i++) {
			const obj = _items[i];
			obj.type = 'ITEMS';
			res.push(obj);
		}
		return res;
	}

	const previewData = makePreviewData();

	const onDeletePreviewRow = (data) => {
		var {dataIndex} = data[0];
		var _package = [], _category = [], _items = [];
		for (let i = 0; i < previewData.length; i++) {
			if (i === dataIndex) continue;
			const {name, value, type} = previewData[i];
			const obj = {name, value};
			switch (type) {
				case 'ITEMS_P': {
					_package.push(obj);
					break;
				}
				case 'ITEMS_C': {
					_category.push(obj);
					break;
				}
				case 'ITEMS': {
					_items.push(obj);
					break;
				}
				default:
					break;
			}
		}
		previewList.category = _category;
		previewList.items = _items;
		previewList.package = _package;

		setPreviewList(previewList);
	}

	const onSavePreview = () => {
		setSavingPreview(true);
		var batch = [];
		for (let i = 0; i < previewData.length; i++) {
			const {name: dtl_name, value: dtl_code, type: dtl_type} = previewData[i];
			var should_add = true;
			for (let j = 0; j < dtlItems.length; j++) {
				const {dtl_code: lcode, dtl_type: ltype} = dtlItems[j];
				if (JString(dtl_code).isEqualDeep(lcode) && JString(ltype).isEqualDeep(dtl_type)) {
					should_add = false;
				}
			}
			var params = {
				tmplt_id: data.id,
				updated_by: gState.username,
				dtl_name,
				dtl_code,
				dtl_type
			}
			if (should_add) {
				batch.push(params);
			}
		}
		const formadata = new FormData();
		formadata.append('batch', JSON.stringify(batch));
		API_Service.post(req_stocktemplate_dtl, formadata, axiosConfig).then(({data}) => {
			de(data);
			const {result} = data;
			if (parseInt(result) === 1) {
				setPreviewList({category: [], package: [], items: []});
				loadStockTemplateItems();
				onSnack(language.operation_success);
			} else {
				onSnack(language.operation_fail, 'error');
			}
			setSavingPreview(false);
		}).catch(r => {
			onSnack(language.operation_fail, 'error');
			setSavingPreview(false);
		});
	}

	const updateItemStatus = (id) =>{
		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			id: id.join(',')
		});

		API_Service.put(req_stocktemplate_dtl, params, axiosPut).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {

			} else {
				onSnack(language.operation_fail, 'error');
			}
			loadStockTemplateItems();
		});
	}

	const changeItemSequence = (e) =>{
		let array = [];
		e.map((each,index)=>{
			array.push({
				id : each.id,
				sequence_no : index + 1
			})
		});
		const formdata = new FormData();
		formdata.append('array', JSON.stringify(array));
		API_Service.post(req_stocktemplate_dtlseq, formdata, axiosConfig).then(({data}) => {
			de(data, 'tmeplate_seq')
			const {result} = data;
			if (parseInt(result) === 1) {
				onSnack(language.operation_success);
			} else {
				onSnack(language.operation_fail, 'error');
			}
		});
	}

	const getCustomerList = () =>{
		for (let i = 0; i < (customerList || []).length; i++){
			const {value} = customerList[i];
			let hide = false;
			for (let j = 0; j < (stockTmpltCustomer || []).length; j++){
				const {cust_code} = stockTmpltCustomer[j];
				if (jstring(value).isEqualDeep(cust_code)){
					hide = true;
				}
			}
			customerList[i] = {
				...customerList[i],
				hide
			};
		}
		return customerList;
	}

	const updateStatus = () =>{
		var id = data.id;
		var active_status = tempStatus;
		var tmplt_name = tempName;
		var params = axiosURIencode({
			id,
			active_status,
			tmplt_name
		});
		API_Service.put(req_stocktemplate, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				setEditDialog(false);
				onSnack(language.operation_success);
			} else {
				onSnack(language.operation_fail, 'error');
			}
		});
	}

	const showButtonList = (index) => {
		setItemType(index);
		showList(true);
	}

	const getItemTypeListOptions = (index) => [
		[[], []],
		[itemPackageList, itemPackage],
		[itemCategoryList, itemCategories]
	][index];

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{data?.tmplt_name}
						</Typography>
						{
							tabPosition == 0 && (
								<Button
									onClick={() => setEditDialog(true)}
									size="small">
									<EditIcon style={{color: 'black'}}/>
								</Button>
							)
						}
					</Toolbar>
				</AppBar>
				<DialogContent className={classes.dlgContent}>
					<Tabs
						indicatorColor={'primary'}
						textColor={'inherit'}
						variant={'fullWidth'}
						value={tabPosition}
						centered
						onChange={(e, v) => setTabPosition(v)}
						aria-label="---">
						<Tab label={language.create_new}/>
						<Tab label={language.items}/>
						<Tab label={language.customer}/>
						{/* <Tab label={'Agents'}/> */}
					</Tabs>
					{
						tabPosition == 0 && (
							<Grid container spacing={2} className={classes.grid}>
								<Grid component={Paper} item xs={4} className={classes.paperMaxHeight}>
									<Button onClick={() => showButtonList(0)} variant={'outlined'}  color={'primary'} fullWidth>
										<Typography>
											{getoptionname(0)}
										</Typography>
									</Button>
									<Space/>
									<Button onClick={() => showButtonList(2)} variant={'outlined'} color={'primary'} fullWidth>
										<Typography>
											{getoptionname(2)}
										</Typography>
									</Button>
								</Grid>
								<Grid item xs={1}/>
								<Grid item xs={7} className={classes.paper}>
									<MUIDataTable
										title={language.preview}
										data={previewData}
										columns={previewCell}
										options={{
											download: false,
											print: false,
											filter: false,
											sort: false,
											viewColumns: false,
											selectableRows: previewData.length > 0 ? 'single' : 'none',
											rowsPerPage: 99,
											rowsPerPageOptions: [125, 140, 165, 200],
											responsive: 'simple',
											onRowsDelete: ({data}) => onDeletePreviewRow(data),
											customToolbar: () =>
												<Button
													endIcon={savingPreview ?
														<CircularProgress size={15} color={'primary'}/> : undefined}
													onClick={() => onSavePreview()}
													disabled={previewData.length === 0 || savingPreview}
													variant={'outlined'}
													color={'primary'}>
													<Typography>
														{language.save}
													</Typography>
												</Button>,
											setTableProps: () => ({size: 'small'})
										}}
									/>
								</Grid>
							</Grid>
						)
					}
					{
						tabPosition == 1 && (
							<div>
								{/* <ItemList
								data={dtlItems}
								language={language}
								gState={gState}
								onEvent={() => loadStockTemplateItems()}
								onSnack={onSnack}
							/> */}
								<ItemTemplateEnhancedTable
									headCell={tableHeadCell}
									title={language.template_item_list}
									data={dtlItems}
									changeSequence={(e)=>changeItemSequence(e,axiosConfig)}
									updateStatus={(e)=>updateItemStatus(e)}
								/>
							</div>
						)
					}
					{
						tabPosition == 2 && (
							<Fab
								className={commonStyles.fab}
								onClick={() => {
									showCustomerList(true)
								}}
								variant={'round'}
								size="small"
								color="primary"
								aria-label="add">
								<AddIcon color={'inherit'}/>
							</Fab>
						)
					}
					{
						tabPosition == 3 && (
							<Fab
								className={commonStyles.fab}
								onClick={() => {
									showAgentList(true)
								}}
								variant={'round'}
								size="small"
								color="primary"
								aria-label="add">
								<AddIcon color={'inherit'}/>
							</Fab>
						)
					}
					{
						tabPosition == 2 && (
							<MUIDataTable
								title={undefined}
								data={stockTmpltCustomer}
								columns={customerCell}
								options={{
									download: false,
									print: false,
									filter: false,
									sort: false,
									viewColumns: false,
									rowsSelected: stockTmpltCustomer.length > 0 ? undefined : [],
									selectableRows: stockTmpltCustomer.length > 0 ? 'multiple' : 'none',
									rowsPerPage: 99,
									rowsPerPageOptions: [125, 140, 165, 200],
									responsive: 'simple',
									onRowsDelete: ({data}) => {
										onInactiveBind(data)
									},
									setTableProps: () => ({size: 'small'})
								}}
							/>
						)
					}
					{
						tabPosition == 3 && (
							<MUIDataTable
								title={undefined}
								data={stockTmpltAgent}
								columns={agentCell}
								options={{
									download: false,
									print: false,
									filter: false,
									sort: false,
									viewColumns: false,
									rowsSelected: stockTmpltAgent.length > 0 ? undefined : [],
									selectableRows: stockTmpltAgent.length > 0 ? 'single' : 'none',
									rowsPerPage: 99,
									rowsPerPageOptions: [125, 140, 165, 200],
									responsive: 'simple',
									onRowsDelete: ({data}) => {
										onInactiveBind(data, 'agent')
									},
									setTableProps: () => ({size: 'small'})
								}}
							/>
						)
					}
					{
						listOpen && (
							<VirtualListSTI
								setClose={(d) => onConfirmList(d)}
								open={listOpen}
								title={getoptionname(itemType)}
								data={getoptionlist(itemType)}
								language={language}
								itemItemType={getItemTypeListOptions(itemType)[0]}
								optionsList={getItemTypeListOptions(itemType)[1]}
								itemType={itemType}
							/>
						)
					}
					{
						customerListOpen && (
							<VirtualListSTI
								setClose={(d) => onConfirmCustomer(d)}
								open={customerListOpen}
								title={language.cust_list}
								data={getCustomerList()}
								language={language}
							/>
						)
					}
					{
						agentListOpen && (
							<VirtualListSTI
								setClose={(d) => onConfirmAgent(d)}
								open={agentListOpen}
								title={language.salesperson_list}
								data={agentList}
								language={language}
							/>
						)
					}
				</DialogContent>
			</Dialog>
			<AnyDialog
				onDismiss={() => setEditDialog(false)}
				open={editDialog}
				title={''}
				footer={() =>
					<Button
						onClick={() => updateStatus()}
						fullWidth
						variant={'contained'}
						color={"primary"}>
						<Typography color={'inherit'}>
							{language.update}
						</Typography>
					</Button>

				}>
				<DialogContent className={classes.dialogEdit}>
					<TextField
						value={tempName}
						placeholder={language.new_tmplt}
						fullWidth
						rowsMax={2}
						variant={'filled'}
						onChange={e => setTempName(String(e.target.value).toUpperCase())}
					/>
					<Select
						style={{marginRight:20,marginTop:15}}
						fullWidth
						margin={'dense'}
						variant={'outlined'}
						labelId="active_status-label"
						id="active_status"
						open={selectOpen}
						onOpen={() => setSelectOpen(true)}
						onClose={() => setSelectOpen(false)}
						value={tempStatus}
						onChange={e => onChange(e)}>
						<MenuItem value={1}>Active Templates</MenuItem>
						<MenuItem value={0}>Inactive Templates</MenuItem>
					</Select>
				</DialogContent>
			</AnyDialog>
		</div>
	)
}

function Space({height = 10}) {
	return (
		<div style={{height}}/>
	)
}

function ItemList({data, language, gState, onEvent, onSnack}) {
	const onRowDelete = (row) => {
		var {dataIndex} = row[0];
		var {id} = data[dataIndex];
		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			id
		});
		API_Service.put(req_stocktemplate_dtl, params, axiosPut).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {

			} else {
				onSnack(language.operation_fail, 'error');
			}
			onEvent();
		});
	}
	return (
		<MUIDataTable
			title={language.template_list}
			data={data}
			columns={itemDataCell}
			options={{
				print: false,
				filter: true,
				selectableRows: data.length > 0 ? 'single' : 'none',
				rowsPerPage: 99,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'simple',
				onRowsDelete: ({data: row}) => onRowDelete(row),
				setTableProps: () => ({size: 'small'})
			}}
		/>
	)
}

const agentCell = [
	{
		name: 'staff_code',
		label: 'Staff code',
		options: {
			display: true
		}
	},
	{
		name: 'name',
		label: 'Name',
		options: {
			display: true
		}
	},
	{
		name: "updated_by",
		label: "Updated By",
		options: {
			display: true
		}
	},
	{
		name: "updated_at",
		label: "Updated By",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
			}
		}
	}
];
const customerCell = [
	{
		name: "cust_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "updated_by",
		label: "Updated By",
		options: {
			display: true
		}
	},
	{
		name: "updated_at",
		label: "Updated By",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
			}
		}
	}
];

const previewCell = [
	{
		name: "value",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "type",
		label: "Type",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Chip label={value}/>
				)
			}
		}
	}
];

const itemDataCell = [
	{
		name: "dtl_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "dtl_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "dtl_type",
		label: "Type",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Chip label={value}/>
				)
			}
		}
	},
	{
		name: "updated_by",
		label: "Updated By",
		options: {
			display: true
		}
	},
	{
		name: "updated_at",
		label: "Updated At",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
			}
		}
	}
];

const tableHeadCell = [
	{
		id: "sequence_no",
		label: "Seq",
		numeric: false,
		disablePadding: false
	},
	{
		id: "dtl_code",
		label: "Code",
		numeric: false,
		disablePadding: true
	},
	{
		id: "dtl_name",
		label: "Name",
		numeric: false,
		disablePadding: false
	},
	{
		id: "dtl_type",
		label: "Type",
		numeric: false,
		disablePadding: false
	},
	{
		id: "updated_by",
		label: "Updated By",
		numeric: false,
		disablePadding: false
	},
	{
		id: "updated_at",
		label: "Updated At",
		numeric: false,
		disablePadding: false
	}
];
