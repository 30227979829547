import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import jstring from '@easytech-international-sdn-bhd/jstring';
import {DropzoneArea} from 'material-ui-dropzone';

export default function SingleUpload({
										 openModal,
										 title,
										 language,
										 onClose,
										 onSave,
										 dropzoneStyle,
										 onChange,
										 filesLimit
									 }) {
	useEffect(() => {
		setOpen(openModal);
	}, [openModal]);

	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		onClose && onClose();
	};

	const handleSave = () => {
		setOpen(false);
		onSave && onSave();
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				{
					jstring(title).isValid() ?
						<DialogTitle id="form-dialog-title">
							{title}
						</DialogTitle>
						:
						null
				}
				<DialogContent>
					<DropzoneArea
						onChange={(files) => {
							onChange(files)
						}}
						dropzoneParagraphClass={dropzoneStyle}
						acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
						filesLimit={filesLimit}
						clearOnUnmount={true}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						{language.cancel}
					</Button>
					<Button onClick={handleSave} color="primary">
						{language.upload}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
