import React, {useContext, useEffect, useState} from 'react';
import ReactMapboxGl, {Marker, Popup} from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useTheme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';
import {
    checkin_report_url,
    ck_checkin_columns,
    ck_u_username,
    defDateFormat,
    empty,
    mapbox_key,
    req_acc_project,
    req_branch_per_cust,
    req_checkin,
    req_checkin_merchandising,
    req_checkin_sched,
    req_checkin_sched_amend,
    req_checkin_summary,
    req_cust,
    req_custby_salesman,
    req_merchandiser,
    req_module_setting,
    req_region,
    req_salesman,
	req_cust_category,
	req_cust_chain,
    reset,
	req_cust_approve,
	req_totalcustnotinlistcount,
	req_custnotinlist,
	req_checkin_delete
} from '../../Helper/Constants';
import {red} from '@material-ui/core/colors';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import moment from 'moment';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import PersistantData from '../../Services/PersistantData';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import format from 'date-fns/format';
import {
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    Assessment as AssessmentIcon,
    ClearAll,
    FilterList as FilterListIcon,
    FindInPage,
    KeyboardArrowRightRounded,
    Person as PersonIcon,
    PinDrop,
    ViewList
} from '@material-ui/icons';
import {pure} from 'recompose';
import MUIDataTable from 'mui-datatables';
import CheckinDetails from '../../Components/CheckinDetails';
import _, { stubFalse } from 'lodash';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import VirtualList from '../../Components/VirtualList';
import BusinessIcon from '@material-ui/icons/Business';
import {de, getSetting} from '../../Helper/Helper';
import TvIcon from '@material-ui/icons/Tv';
import ListView from '../../Components/ListView';
import {useFullScreenHandle} from "react-full-screen";
import Fab from '@material-ui/core/Fab';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AnyDialog from '../../Components/AnyDialog';
import AddAppointmentMerchandiser from './AddAppointmentMerchandiser';
import AddVisitSched from './AddVisitSched';
import ColorRandomizer from '../../Helper/ColorRandomizer';
import WarningIcon from '@material-ui/icons/Warning';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MapIcon from '@material-ui/icons/Map';
import {useCookies} from 'react-cookie';
import SendSMS from "../../Components/SendSMS";
import GenerateView from "../../Components/GenerateView";
import useCookieHook from "../../Services/useCookieHook";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import VirtualListMB from '../../Components/VirtualListMB';
import AddCustomer from '../../Components/AddCustomer';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const ClearButton = withStyles(theme => ({
	root: {
		borderColor: red[500]
	}
}))(Button);

const CustomDotRender = ({times}) => {
	const dummy = new Array(times).fill(0);
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'flex-start',
				paddingLeft: 5,
				alignItems: 'center',
				flexDirection: 'row'
			}}>
			{
				dummy.map((item, index) =>
					<Badge variant={'dot'} color={'primary'} style={{marginRight: 10}}/>
				)
			}
		</div>
	)
}
const workloadCell = [
	{
		name: "name",
		label: "Assignee"
	},
	{
		name: "date",
		label: "Date"
	},
	{
		name: "08:00",
		label: "08:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "09:00",
		label: "09:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "10:00",
		label: "10:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "11:00",
		label: "11:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "12:00",
		label: "12:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "13:00",
		label: "13:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "14:00",
		label: "14:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "15:00",
		label: "15:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "16:00",
		label: "16:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "17:00",
		label: "17:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "18:00",
		label: "18:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	},
	{
		name: "19:00",
		label: "19:00",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<CustomDotRender
						times={value}
					/>
				)
			}
		}
	}
];

const schedHeadCell = [
	{
		name: "sched_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "name",
		label: "Assignee",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Customer",
		options: {
			display: true
		}
	},
	{
		name: "sched_datetime",
		label: "Appointment At",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
				)
			}
		}
	},
	{
		name: "sched_note",
		label: "Note",
		options: {
			display: true
		}
	},
	{
		name: "site_in_charge",
		label: "In-charge",
		options: {
			display: true,
			filter: false
		}
	},
	{
		name: "site_in_charge_contact",
		label: "In-charge Contact",
		options: {
			display: false,
			filter: false
		}
	},
	{
		name: "sched_status",
		label: "Appointment Status",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Completed"/>);
						break;
					case "2" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Reject"/>);
						break;
					default :
						return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>);
						break;
				}
			}
		}
	},
	{
		name: "in_progress",
		label: "Progress",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<WarningIcon/>} label="Pending"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<ScheduleIcon color={'primary'}/>} label="Running"/>);
						break;
					case "2" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Done"/>);
						break;
					default :
						return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>);
						break;
				}
			}
		}
	},
	{
		name: "project_no",
		label: "Projects",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				if (JString(value).isJson()) {
					const json = JString(value).toJson();
					return (
						<div>
							{
								json.map((item, index) =>
									<Chip
										style={{marginBottom: 2}}
										size="small"
										label={item.project_code}
										key={'chip-project-' + index}/>
								)
							}
						</div>
					)
				}
			}
		}
	},
	{
		name: "tags",
		label: "-",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				if (JString(value).isJson()) {
					const json = JString(value).toJson();
					const colors = ColorRandomizer({
						luminosity: 'bright',
						hue: 'blue',
						count: Object.keys(json).length
					});
					return (
						<div>
							{
								Object.keys(json).map((key, index) =>
									<div key={String(index) + '--div'}>
										<span style={{color: 'grey', fontSize: 14}}>{String(key).toUpperCase()}</span>
										<div style={{display: 'flex', flexDirection: 'row'}}>
											{
												json[key].map((item, nidx) =>
													<Chip
														style={{backgroundColor: colors[index], color: 'white'}}
														size="small"
														label={item}
														key={'chip' + nidx}/>
												)
											}
										</div>
									</div>)
							}
						</div>
					)
				}
			}
		}
	},
	{
		name: "updated_at",
		label: "Updated At",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
				)
			}
		}
	},
	{
		name: "created_by",
		label: "Created By",
		options: {
			display: true,
			filter: false
		}
	}
];

const statusList = [
	{
		name: 'Pending',
		value: '0'
	},
	{
		name: 'Running',
		value: '1'
	},
	{
		name: 'Complete',
		value: '2'
	},
	{
		name: 'Missed',
		value: '3'
	}
];
const appointmentHeadCell = [
	{
		name: "staff_code",
		label: "Agent"
	},
	{
		name: "num_of_appointments",
		label: "Visit Count",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography variant={'caption'} color={'textPrimary'}>
						{value} Places
					</Typography>
				)
			}
		}
	},
	{
		name: "start_date",
		label: "Start Date",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "end_date",
		label: "End Date",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
				)
			}
		}
	}
];

const scheduleTableHead = [
	{
		name: "technician",
		label: "Technician",
		options: {
			display: true
		}
	},
	{
		name: "eight",
		label: "8AM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "nine",
		label: "9AM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "ten",
		label: "10AM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "eleven",
		label: "11AM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "twelve",
		label: "12AM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "one",
		label: "1PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "two",
		label: "2PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "three",
		label: "3PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "four",
		label: "4PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "five",
		label: "5PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "six",
		label: "6PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	},
	{
		name: "seven",
		label: "7PM",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					<Typography style={{whiteSpace: 'pre-line'}}>
						{
							value
						}
					</Typography>
				)
			}
		}
	}
]

const makebranchname = (obj) => {
	return JString(obj.branch_name)
			.replaceInfinity('[\(\)]', '')
			.replaceInfinity(obj.branch_code, '')
			.str.trim()
		||
		'-';
}
const makeaddress = (obj) => {
	return `${obj.billing_address1} ${obj.billing_address2} ${obj.billing_address3} ${obj.billing_address4}`;
}
export default function Checkin() {
	const headCell = [
		{
			name: "mobile_checkin_id",
			label: "Doc ID",
			options: {
				display: false
			}
		},
		{
			name: "name",
			label: "Agent",
			options: {
				display: false
			}
		},
		{
			name: "person_met",
			label: "Person Met",
			options: {
				display: false
			}
		},
		{
			name: "cust_company_name",
			label: "Outlet",
			options: {
				display: true
			}
		},
		{
			name: "area_name",
			label: "Area",
			options: {
				display: true
			}
		},
		{
			name: "company",
			label: "Branch",
			options: {
				display: true,
				customBodyRender: (value) => value ? value.toUpperCase() : ''
			}
		},
		{
			name: "create_date",
			label: "Date",
			options: {
				display: false,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
					)
				}
			}
		},
		{
			name: "checkin_time",
			label: "Checked-In",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
					)
				}
			}
		},
		{
			name: "checkout_time",
			label: "Checked-Out",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
					)
				}
			}
		},
		{
			name: "checkin_location_map",
			label: "Checked-In At",
			options: {
				display: false
			}
		},
		{
			name: "checkout_location_map",
			label: "Checked-Out At",
			options: {
				display: false
			}
		},
		{
			name: "cust_reference",
			label: "Customer Ref",
			options: {
				display: false
			}
		},
		{
			name: "remark1",
			label: "Remark",
			options: {
				display: false
			}
		},
		{
			name: "remark2",
			label: "Remark 2",
			options: {
				display: false
			}
		},
		{
			name: "remark3",
			label: "Remark 3",
			options: {
				display: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const isjson = JString(value).isJson();
					var remark = '';
					for (let i = 0; i < value?.length; i++) {
						if (value[i]['code'] === 'collection') {
							remark += value[i]['value'];
							if (value[i]['selected'] !== '') {
								remark += ' Yes\n';
							}
							remark += '';
						}

					}
					if (isjson) {
						return isjson.toJson()?.str || '';
					} else {
						return (
							<Typography style={{whiteSpace: 'pre-line'}}>
								{
									remark
								}
							</Typography>
						)
					}
				}
			}
		},
		{
			name: "schedule",
			label: "Scheduled For",
			options: {
				display: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					if(Object.keys(value).length > 0){
						return moment(value.appmnt_date).format('DD/MM/YYYY');
					}else{
						return "-";
					}
					// const isjson = JString(value).isJson();
					// if (isjson) {
					// 	const json = isjson.toJson();
					// 	return json.sched_note + ' ' + moment(json.sched_date).format('DD/MM/YYYY');
					// } else {
					// 	return value;
					// }
				}
			}
		},
		// {
		// 	name: "st_id",
		// 	label: "Stock Take ID",
		// 	options: {
		// 		display: false,
		// 		customBodyRender: (value, tableMeta, updateValue) => {
		// 			var id = '';
		// 			const isjson = JString(value).isJson();
		// 			if (value) {
		// 				for (let i = 0; i < value.length; i++) {
		// 					id += value[i]['st_id'];
		// 					id += '\n';
		// 				}
		// 				if (isjson) {
		// 					return isjson.toJson()?.str || '';
		// 				} else {
		// 					return (
		// 						<Typography style={{whiteSpace: 'pre-line'}}>
		// 							{
		// 								id
		// 							}
		// 						</Typography>
		// 					)
		// 				}
		// 			}
		// 		}
		// 	}
		// },
		{
			name: "action",
			label: "Action",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					if(showPending){
						return (
							<>
								<Chip size="small" style={{backgroundColor: "yellowgreen", marginRight: 5}}
									onClick={()=>
										approve(tableMeta.rowData[0])
									}
									label={'Confirm'}
								/>
								<Chip size="small" style={{backgroundColor: red[500]}}
									onClick={()=>
										decline(tableMeta.rowData[0])
									}
									label={'Delete'}
								/>
							</>
						);
					}else{
						return <></>
					}
				}
			}
		}
	];
	const paperStyle = usePaperStyles();
	const commonStyles = useCommonStyles();
	const fullscreenManager = useFullScreenHandle();

	const [cookies, setCookie] = useCookies([ck_checkin_columns]);
	const [xCookies] = useCookieHook([ck_u_username]);
	const current_username = xCookies(ck_u_username);

	const theme = useTheme();
	const lightTheme = theme.palette.type === 'light';

	const [gState, setGState] = useContext(AppContext);
	const [isSearching, setSearching] = useState(false);
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [configLoading, setConfigLoading] = useState(true);

	const [filterOpen, showFilter] = useState(false);
	const [addnewSched, showNewSched] = useState(false);

	const [regionList, setRegionList] = useState([]);
	const [regionView, setRegionView] = useState(false);
	const [regionSelected, setRegionSelected] = useState(empty);

	const [tchList, setTchList] = useState([]);
	const [mchList, setMchList] = useState([]);
	const [spList, setSpList] = useState([]);
	const [spValue, setSpValue] = useState(empty);
	const [spView, setSpView] = useState(false);
	const [statusView, setStatusView] = useState(false);
	const [status, setStatus] = useState(empty);

	const [rawCustomers, setRawCustomers] = useState([]);

	const [branchPercustomer, setBranchPerCustomer] = useState({});
	const [csList, setCsList] = useState([]);
	const [csOutstanding, setCsOutstanding] = useState({});
	const [csValue, setCsValue] = useState('');
	const [isCustomerView, showCustomerView] = useState(false);

	const [currentPage, setCurrentPage] = useState(0);

	const [projectData, setProjectData] = useState([]);
	const [checkinData, setCheckinData] = useState([]);
	const [pendingData, setPendingData] = useState([]);
	const [scheduleData, setScheduleData] = useState([]);
	const [schedData, setSchedData] = useState([]);

	const [checkinDataToview, setCheckinDataToView] = useState([]);
	const [latlongList, setLatlongList] = useState([]);
	const [axiosApiConfig, setAxiosApiConfig] = useState({});

	const [summaryData, setSummaryData] = useState([]);
	const [presentationData, setPresentationData] = useState([]);
	const [workloadData, setWorkloadData] = useState([]);
	const [appointmentData, setAppointmentData] = useState({});

	const [selectedMch, setSelectedMch] = useState('');
	const [schedTabPosition, setSchedTabPosition] = useState(0);

	const [openDateDoc, setOpenDateDoc] = useState(false);
	const [docDateRange, setDocDateRange] = useState({});

	const [customModule, setCustomModule] = useState({});
	const [addVisitSchedRules, setAddVisitSchedRules] = useState({});
	const [appointmentGroups, setAppointmentGroups] = useState([]);

	const [checkinConfig, setCheckinConfig] = useState('');

	const [editSched, setEditSched] = useState({open: false, editData: {}});

	const [smsView, setSMSView] = useState(false);
	const [schedCode, setSchedCode] = useState([]);

	const [printView, setPrintView] = useState();

	const [showPending, setShowPending] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [showApprovementDialog, setShowApprovementDialog] = useState(false);
	const [showApprovementList, setShowApprovementList] = useState([]);
	const [showCreateCustomerDialog, setShowCreateCustomerDialog] = useState(false);
	const [approveData, setApproveData] = useState(null);
	const [activeCustCategoryList, setActiveCustCategoryList] = useState([]);
	const [activeCustChainList, setActiveCustChainList] = useState([]);
	const [pendingCustomerCount, setPendingCustomerCount] = useState(0);

	const singleton = PersistantData.getInstance();
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const typeOptions = [
		{name: 'Basic Version', value: 'simply'},
		{name: 'Detailed Version', value: 'normal'}
	]

	const dateMenuOptions = {name: language.doc_date, value: reset};

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			loadRegion(axiosGetConfig)
			loadCustomerList(axiosGetConfig);
			loadSalesmanList(axiosGetConfig);
			loadMerchandiserList(axiosGetConfig);
			loadCheckConfig(axiosGetConfig);
			loadBranchPerCustomer(axiosGetConfig);
			loadProjectData(axiosGetConfig);
			setAxiosApiConfig(axiosGetConfig);
			loadCustCategoryList(axiosGetConfig);
			loadCustChainList(axiosGetConfig);
			loadTotalRecord(axiosGetConfig);
			statusList.push({name: language.status, value: reset});
		}
	}, [gState.client]);

	const redefineHeaders = (columns) => {
		const lastSelection = cookies[ck_checkin_columns];
		if (_.isEmpty(lastSelection)) {
			return columns;
		}
		columns = columns.map((item) => {
			const key = item.name;
			var objCopy = _.merge({}, item);
			objCopy.options.display = lastSelection[`${key}`];
			return objCopy;
		});
		return columns;
	}

	const tableHead = redefineHeaders(headCell);

	const loadCustCategoryList = (axiosRequestConfig) => {
		API_Service.get(`${req_cust_category}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _inact_data = [];
					var _act_data = [];
					for (let i = 0; i < extraData.length; i++) {
						const element = extraData[i];
						if(element['cust_category_status'] == 1){
							_act_data.push(element);
						}else{
							_inact_data.push(element);
						}
					}
					setActiveCustCategoryList(()=>(_act_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const loadCustChainList = (axiosRequestConfig) => {
		API_Service.get(`${req_cust_chain}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _inact_data = [];
					var _act_data = [];
					for (let i = 0; i < extraData.length; i++) {
						const element = extraData[i];
						if(element['cust_chain_status'] == 1){
							_act_data.push(element);
						}else{
							_inact_data.push(element);
						}
					}
					setActiveCustChainList(()=>(_act_data));
				}
			}
		}).catch(err => de(err.message, 'customer chain list'));
	}

	const loadTotalRecord = (axiosRequestConfig = axiosApiConfig) => {
		API_Service.get(`${req_totalcustnotinlistcount}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				result = JString(result).toNumber();
				if (result === 1) {
					setPendingCustomerCount(extraData);
				}
			}
		}).catch(err => de(err.message, 'total record pending customer'));
	}

	const loadCustNotInListCheckinData = (axiosRequestConfig = axiosApiConfig) => {
		setPendingData([]);
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}

		API_Service.get(`${req_custnotinlist}${parameters}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				const tablePendingData = [];
				if (extraData.length > 0) {
					for (let index = 0; index < extraData.length; index++) {
						const obj = extraData[index];
						const {mobile_checkin_id, location_lng, location_lat, name, remark3} = obj;
						var tmp = remark3;
						var cust_ref = '';
						const isjson = JString(remark3).isJson();
						if (tmp !== '' && tmp != null) {
							tmp = JSON.parse(tmp);
							if(tmp.hasOwnProperty('obj')){
								tmp = tmp['obj'];
								for (let i = 0; i < tmp.length; i++) {
									if (tmp[i]['code'] === 'newcustomer') {
										cust_ref = tmp[i]['value'];
									}
								}
							}
						}

						obj.remark3 = tmp;
						obj.cust_ref = cust_ref;

						if (obj?.schedule) {
							tmp = obj?.schedule;
							const isjson = JString(tmp).isJson();
							if (isjson) {
								tmp = isjson.toJson()?.sched_note || '';
							}
							obj.schedule = tmp;
						}
						if(obj.customer_not_in_list_info){
							const customer_not_in_list_info = JString(obj.customer_not_in_list_info).isJson();
							const new_cust_info = customer_not_in_list_info.toJson();
							if(new_cust_info.length > 0){
								obj.pending_customer = new_cust_info;
							}
						}
						if(obj.cust_company_name == '--'){
							if(obj.pending_customer.length > 0){
								obj.cust_company_name = obj.pending_customer[0]['outlet_name'];
								obj.checkin_location = obj.pending_customer[0]['outlet_address'];
							}
							tablePendingData.push(obj);
						}
					}
					setPendingData(tablePendingData);
					snack(language.checkin_loaded);
				} else {
					snack(language.no_data, "warning");
				}
			}
		}).catch(err => de(err.message, 'total record customer not in list checkin data'));
	}

	const docTableColumnsChanged = (changedColumns, action) => {
		const lastSelection = cookies[ck_checkin_columns];
		var obj = {};
		var a = headCell.map((item) => {
			if (item.name == changedColumns) {
				obj[`${item.name}`] = action == 'add';
				return {[`${item.name}`]: action == 'add'};
			}
			obj[`${item.name}`] = (lastSelection && _.has(lastSelection, `${item.name}`)) ? lastSelection[`${item.name}`] : item.options.display;
			return {[`${item.name}`]: item.options.display};
		});
		setCookie(ck_checkin_columns, JSON.stringify(obj), {path: '/', sameSite: true, maxAge: 36000 * 3600});
	}

	const decideAndLoad = (type = checkinConfig, axiosConfig = axiosApiConfig) => {
		loadCheckinData(axiosConfig, type);
		loadCustNotInListCheckinData(axiosConfig);
		if (type === 'merchandising') {
			de('loading appointment')
			// loadAppointmentData(axiosConfig);
			loadAppointmentGroup(axiosConfig);
		} else {
			loadSchedData(axiosConfig);
			// loadSummaryData(axiosConfig);
		}
	}

	const loadProjectData = (axiosGetConfig) => {
		API_Service.get(req_acc_project, axiosGetConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setProjectData(extraData);
			}
		});
	}

	const loadCheckConfig = (axiosGetConfig) => {
		API_Service.get(req_module_setting, axiosGetConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const config = getSetting(extraData, 'checkin', {schedule: ''});
				const custommodule = getSetting(extraData, 'sched_tags');
				const visitSchedRules = getSetting(extraData, 'add_visit_sched');
				decideAndLoad(config, axiosGetConfig);
				setCheckinConfig(config);
				setCustomModule(custommodule);
				setAddVisitSchedRules(visitSchedRules);
			} else {
				decideAndLoad('', axiosGetConfig);
				setCheckinConfig('')
			}
			setConfigLoading(false);
		}).catch(err => {
			setConfigLoading(false);
		});
	}
	const makeSearchProp = () => {
		var salesperson_id, date_from, date_to, region;
		var cust_code = JString(csValue?.value).isSafe();
		if (JString(spValue).isValid()) {
			salesperson_id = spValue;
		} else {
			salesperson_id = 0;
		}
		if (!_.isEmpty(docDateRange)) {
			date_from = format(docDateRange.startDate, defDateFormat);
			date_to = format(docDateRange.endDate, defDateFormat);
		} else {
			date_from = '';
			date_to = '';
		}

		if (cust_code) {
			cust_code = csValue?.value || '';
		}

		if (JString(regionSelected).isValid()) {
			region = regionSelected;
		}

		date_from = date_from === reset ? '' : date_from;
		date_to = date_to === reset ? '' : date_to;
		cust_code = cust_code === reset ? '' : cust_code;
		salesperson_id = salesperson_id === reset ? '' : salesperson_id;
		region = region === reset ? '' : region;

		return {date_from, date_to, salesperson_id, cust_code, region};
	}

	const loadAppointmentGroup = (axiosConfig = axiosApiConfig) => {
		setAppointmentGroups([]);
		const params = axiosURIencode({group: 1});
		API_Service.get(`${req_checkin_merchandising}?${params}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) == 1) {
				setAppointmentGroups(extraData);
			}
		});
	}
	const loadAppointmentData = (axiosConfig = axiosApiConfig) => {
		setAppointmentData([]);
		setSearching(true);
		const {date_from, date_to, salesperson_id, cust_code, region} = makeSearchProp();
		var obj = {
			active_status: 1,
			date_from,
			date_to,
			salesperson_id,
			cust_code,
			region
		};
		if (!date_from) {
			obj.date_from = moment().startOf('month').format('YYYY-MM-DD');
			obj.date_to = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD');
		}
		if (obj.region === undefined || obj.region === null) {
			obj.region = '';
		}
		var params = axiosURIencode(obj);
		API_Service.get(`${req_checkin_merchandising}?${params}`, axiosConfig).then(({data}) => {
			const {extraData} = data;
			const {pending, appointments} = extraData;
			setScheduleData(pending);
			setAppointmentData(appointments);
			setSearching(false);
			showFilter(false);
		}).catch(e => setSearching(true));
	}

	const loadSummaryData = (axiosConfig = axiosApiConfig) => {
		setSummaryData([]);
		setSearching(true);
		const {date_from, date_to, salesperson_id, cust_code} = makeSearchProp();
		var obj = {
			active_status: 1,
			date_from,
			date_to,
			salesperson_id,
			cust_code,
			status
		};
		var params = axiosURIencode(obj);
		API_Service.get(`${req_checkin_summary}?${params}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const {summary, presentation, workload} = extraData;
				setSummaryData(summary);
				setPresentationData(presentation);
				setWorkloadData(workload);
				showFilter(false);
				setSearching(false);
				if (summary.length < 0 && currentPage === 2) {
					snack(language.no_data, 'warning');
				}
				if (workload.length < 0 && currentPage === 3) {
					snack(language.no_data, 'warning');
				}
				var _arr = [];
				for (let idx = 0; idx < summary.length; idx++) {
					var arr = [];
					var obj = {};

					obj = Object.assign(obj, {technician: summary[idx]['title'] + '(' + summary[idx]['label'].toString() + ')'});
					arr.push({technician: summary[idx]['title'] + '(' + summary[idx]['label'].toString() + ')'});

					var cards = summary[idx]['cards'];
					for (let c = 0; c < cards.length; c++) {
						var time = moment(cards[c]['date']).format('H');
						if (time === '08') {
							arr['08.00'] = arr['08.00'] ? arr['08.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'eight': arr['08.00']});
						} else if (time === '09') {
							arr['09.00'] = arr['09.00'] ? arr['09.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'nine': arr['09.00']});
						} else if (time === '10') {
							arr['10.00'] = arr['10.00'] ? arr['10.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'ten': arr['10.00']});
						} else if (time === '11') {
							arr['11.00'] = arr['11.00'] ? arr['11.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'eleven': arr['11.00']});
						} else if (time === '12') {
							arr['12.00'] = arr['12.00'] ? arr['12.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'twelve': arr['12.00']});
						} else if (time === '13') {
							arr['13.00'] = arr['13.00'] ? arr['13.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'one': arr['13.00']});
						} else if (time === '14') {
							arr['14.00'] = arr['14.00'] ? arr['14.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'two': arr['14.00']});
						} else if (time === '15') {
							arr['15.00'] = arr['15.00'] ? arr['15.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'three': arr['15.00']});
						} else if (time === '16') {
							arr['16.00'] = arr['16.00'] ? arr['16.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'four': arr['16.00']});
						} else if (time === '17') {
							arr['17.00'] = arr['17.00'] ? arr['17.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'five': arr['17.00']});
						} else if (time === '18') {
							arr['18.00'] = arr['18.00'] ? arr['18.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'six': arr['18.00']});
						} else if (time === '19') {
							arr['19.00'] = arr['19.00'] ? arr['19.00'] + ' \n ' + cards[c]['title'] + '[' + cards[c]['client'] + ']' : cards[c]['title'] + '[' + cards[c]['client'] + ']';
							obj = Object.assign(obj, {'seven': arr['19.00']});
						}
					}
					_arr.push(obj);
				}
				setSchedData(_arr);
			} else {
				setSummaryData([]);
				setPresentationData([]);
				setWorkloadData([]);
				snack(language.no_data, 'error');
				showFilter(false);
				setSearching(false);
			}

		}).catch(e => setSearching(true));
	}
	const generateCheckinReport = (type) => {
		setPrintView(false);
		const {date_from, date_to, salesperson_id} = makeSearchProp();
		const date_range = `${date_from} to ${date_to}`;
		const client = gState.client;
		const url = checkin_report_url(client, salesperson_id, date_range, type);
		window.open(url, '_blank');
	}
	const loadSchedData = (axiosConfig = axiosApiConfig) => {
		setScheduleData([]);
		setSearching(true);
		var {date_from, date_to, salesperson_id, cust_code} = makeSearchProp();
		if (!date_from) {
			date_from = moment().format('YYYY-MM-DD');
			date_to = moment().add(2, 'months').format('YYYY-MM-DD');
		}
		var params = {
			date_from,
			date_to,
			sched_status: 0,
			active_status: 1,
			status
		};
		if (salesperson_id) {
			params = {...params, tech_assigned: salesperson_id}
		}
		if (cust_code) {
			params = {...params, cust_code}
		}
		if (regionSelected) {
			var region = getRegion(regionSelected);
			params = {...params, region};
		}
		params = axiosURIencode(params);
		API_Service.get(`${req_checkin_sched}?${params}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				if (extraData.length > 0) {
					setScheduleData(extraData);
				} else {
					snack(language.no_data, "warning");
				}
			}

			setSearching(false);
		});
	}

	const loadCheckinData = (axiosConfig = axiosApiConfig, type = checkinConfig) => {
		setCheckinData([]);
		setSearching(true);

		var {date_from, date_to, salesperson_id, cust_code} = makeSearchProp();
		var parameters = {
			date_from,
			date_to,
			cust_code,
			salesperson_id,
			type
		};
		if (gState.company) {
			parameters = {
				...parameters, 
				company: gState.company
			};
		}
		var params = axiosURIencode(parameters);
		API_Service.get(`${req_checkin}?${params}`, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const list = [];
				const tableData = [];
				if (extraData.length > 0) {
					for (let index = 0; index < extraData.length; index++) {
						const obj = extraData[index];
						const {mobile_checkin_id, location_lng, location_lat, name, remark3, marker_color} = obj;
						var tmp = remark3;
						var cust_ref = '';
						const isjson = JString(remark3).isJson();
						// if(isjson){
						if (tmp !== '' && tmp != null) {
							// tmp = isjson.toJson()?.str || '';
							tmp = JSON.parse(tmp);
							if(tmp.hasOwnProperty('obj')){
								tmp = tmp['obj'];
								for (let i = 0; i < tmp.length; i++) {
									if (tmp[i]['code'] === 'newcustomer') {
										cust_ref = tmp[i]['value'];
									}
								}
							}
						}

						obj.remark3 = tmp;
						obj.cust_ref = cust_ref;

						if (obj?.schedule) {
							tmp = obj?.schedule;
							const isjson = JString(tmp).isJson();
							if (isjson) {
								tmp = isjson.toJson()?.sched_note || '';
							}
							obj.schedule = tmp;
						}
						if(obj.customer_not_in_list_info){
							const customer_not_in_list_info = JString(obj.customer_not_in_list_info).isJson();
							const new_cust_info = customer_not_in_list_info.toJson();
							if(new_cust_info.length > 0){
								obj.pending_customer = new_cust_info;
							}
						}
						list.push({checkin_id: mobile_checkin_id, agent: name, loc: [location_lng, location_lat], marker_color: marker_color});
						if(obj.cust_company_name != '--'){
							tableData.push(obj);
						}
					}
					setLatlongList(list);
					setCheckinData(tableData);
					snack(language.checkin_loaded);
				} else {
					snack(language.no_data, "warning");
				}
			} else {
				currentPage === 0 && snack(language.no_data, 'error');
				setCheckinData([]);
			}
			showFilter(false);
			setSearching(false);
		}).catch(err => {
			setSearching(false);
		});
	}
	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}
	const clearValues = () => {
		setCsValue(csValue => (empty));
		setSpValue(spValue => (empty));
		setRegionSelected(regionSelected => (empty));
		setDocDateRange({});
		setStatus(status => (empty));
	}
	const showCheckinData = (mobile_checkin_id) => {
		var dataToShow = undefined;
		if(showPending){
			for (let i = 0; i < pendingData.length; i++) {
				const {mobile_checkin_id: toFind} = pendingData[i];
				if (mobile_checkin_id == toFind) {
					dataToShow = pendingData[i];
					break;
				}
			}
		}else{
			for (let i = 0; i < checkinData.length; i++) {
				const {mobile_checkin_id: toFind} = checkinData[i];
				if (mobile_checkin_id == toFind) {
					dataToShow = checkinData[i];
					break;
				}
			}
		}
		setCheckinDataToView(dataToShow);
		return dataToShow;
	}
	const columnChecking = (columnIndex) => {
		if(columnIndex != 15){
			setShowDetails(true);
		}
	}
	const loadBranchPerCustomer = (axiosGetConfig) => {
		API_Service.get(req_branch_per_cust, axiosGetConfig).then(({data}) => {
			const {result, extraData} = data;
			const rawData = rawCustomers || [];
			if (result == 1) {
				setBranchPerCustomer(extraData);
				for (const cust_code in extraData) {
					if (Object.hasOwnProperty.call(extraData, cust_code)) {
						const branch_arr = extraData[cust_code];
						for (let ii = 0; ii < branch_arr.length; ii++) {
							const branch_obj = branch_arr[ii];
							const branchname = makebranchname(branch_obj.branch_name) || '-';
							const rawObj = {
								key: rawData.length,
								value: branchname,
								code: cust_code,
								branch_code: branch_obj.branch_code,
								branch_name: branchname,
								description: ''
							};
							for (const column_name in branch_obj) {
								if (Object.hasOwnProperty.call(branch_obj, column_name)) {
									const column_value = branch_obj[column_name];
									if (
										JString(column_name).searchTokens(['bill', 'shipp', 'name'], true) &&
										JString(column_value).isValid()
									) {
										rawObj.description += column_value;
									}
								}
							}
							rawData.push(rawObj);
						}
					}
				}
			}
			setRawCustomers(rawCustomers => (rawData));
		});
	}
	const loadRegion = (axiosRequestConfig) => {
		API_Service.get(`${req_region}`, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [{name: '-', value: '0'}];
				for (let idx = 1; idx < extraData.length; idx++) {
					_data.push({name: extraData[idx]['name'], value: idx.toString()});
				}
				_data.push({name: language.region, value: reset});
				setRegionList(regionList => (_data));
			}
		});
	}

	const regionClick = () => {
		setRegionView(true);
	};

	const regionViewClose = (newValue) => {
		setRegionView(false);
		if (newValue) {
			setRegionSelected(newValue);
		}
	};

	const getRegion = props => {
		for (let i = 0; i < regionList.length; i++) {
			const {name, value} = regionList[i];
			if (value == props) {
				return name;
			}
		}
		return empty;
	}

	const loadCustomerList = (axiosRequestConfig, salesman = 0) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		var req = salesman ? `${req_custby_salesman}${salesman}` : `${req_cust}${parameters}`;
		API_Service.get(req, axiosRequestConfig).then(({data}) => {
			const rawData = rawCustomers || [];
			if (data) {
				var {result, extraData: apiCust} = data;
				var {customer} = apiCust;
				var __data = apiCust;
				if (customer) {
					__data = customer;
				}
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					var _outstanding = {};
					for (let idx = 0; idx < __data.length; idx++) {
						const obj = __data[idx];
						const {cust_company_name, cust_code} = obj;
						_data.push({name: cust_company_name, value: cust_code, address: makeaddress(obj), status: 1});
						const rawObj = {
							key: rawData.length,
							value: cust_company_name,
							code: cust_code,
							branch_code: '',
							branch_name: '',
							description: ''
						};
						_outstanding[cust_code] = obj.current_balance;
						for (const column_name in obj) {
							if (Object.hasOwnProperty.call(obj, column_name)) {
								const column_value = obj[column_name];
								if (
									JString(column_name).searchTokens(['bill', 'shipp', 'name'], true) &&
									JString(column_value).isValid()
								) {
									rawObj.description += column_value;
								}
							}
						}
						rawData.push(rawObj);
					}
					// _data.push({name: language.customer, value: reset});
					setCsList(csList => (_data));
					setCsOutstanding(_outstanding);
				}
			}
			setRawCustomers(rawCustomers => (rawData));
		});
	}

	const loadSalesmanList = (axiosRequestConfig, salesman = 0) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData: api_Sp} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					var _tchList = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const {login, login_id, name} = api_Sp[idx];
						_data.push({name: "("+login+") "+name, value: login_id});
						_tchList.push({key: login_id, value: name});
					}
					_data.push({name: language.salesman, value: reset});
					setSpList(spList => (_data));
					setTchList(tchList => (_tchList));
				}
			}
		});
	}

	const loadMerchandiserList = (axiosRequestConfig) => {
		API_Service.get(`${req_merchandiser}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData: api_Sp} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const {login_id, staff_code} = api_Sp[idx];
						_data.push({key: login_id, value: staff_code});
					}
					setMchList(spList => (_data));
				}
			}
		});
	}

	const spViewClick = () => {
		setSpView(true);
	};

	const spViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			setSpValue(newValue);
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			loadCustomerList(axiosGetConfig, newValue == reset ? 0 : newValue);
		}
	};

	const statusViewClick = () => {
		setStatusView(true);
	}

	const onStatusViewClose = (newValue) => {
		setStatusView(false);
		if (newValue) {
			setStatus(newValue);
		}
	}

	const getStatus = props => {
		for (let i = 0; i < statusList.length; i++) {
			const {name, value} = statusList[i];
			if (value == props) {
				return name;
			}
		}
		return empty;
	}

	const getSpName = props => {
		for (let i = 0; i < spList.length; i++) {
			const {name, value} = spList[i];
			if (value == props) {
				return name;
			}
		}
		return empty;
	}

	const onCustomerViewClose = (newValue) => {
		showCustomerView(false);
		if (typeof newValue === 'object') {
			setCsValue(newValue);
		} else {
			setCsValue(empty);
		}
	}

	const onChangedTechAssigned = (obj) => {
		const config = axiosPutConfig(gState.client);
		const params = axiosURIencode(obj);
		API_Service.put(req_checkin_sched_amend, params, config).then(({data}) => {
		});
	}

	const datefromto = (range) => {
		const {startDate, endDate} = range;
		return moment(startDate).format('DD MMMM YY') + ' / ' + moment(endDate).format('DD MMMM YY');
	}

	const onSearchClick = () => {
		[0].includes(currentPage) && loadCheckinData();
		[1].includes(currentPage) && decideAndLoad();
		[2, 3].includes(currentPage) && loadSummaryData();
	}

	const getAppointmentData = () => {
		if (_.isEmpty(selectedMch)) {
			for (let i = 0; i < mchList.length; i++) {
				const {value} = mchList[i];
				if (appointmentData && appointmentData[value]) {
					setSelectedMch(value);
					return appointmentData[value];
				}
			}
		}
		return appointmentData[selectedMch];
	}

	const onSelectMch = (value) => {
		setSelectedMch(value);
	}

	const openPrintSelectView = () => {
		setPrintView(true);
	}

	const printViewClose = (type) => {
		generateCheckinReport(type);
	}

	const approve = (doc_id) => {
		const list = [];
		const data = showCheckinData(doc_id);
		const searchTerms = data.pending_customer[0].outlet_name.split(/[\s-]+/).map(term => term.replace(/[^a-zA-Z0-9]/g, ""));
		for (let idx = 0; idx < csList.length; idx++) {
			const row = csList[idx];
			var searchString = row.value +" "+ row.name;
			// var isFound = JString(searchString).searchDeep(data.pending_customer[0].outlet_name);
			var isFound = searchTerms.some(term => JString(searchString).searchDeep(term));
			if (isFound) {
				list.unshift(row);
			} else {
				list.push(row);
			}
		}
		setShowApprovementDialog(true);
		setShowApprovementList(list);
		setApproveData({
			mobile_checkin_id: data.mobile_checkin_id,
			outlet_name: data.pending_customer[0].outlet_name,
			outlet_address: data.pending_customer[0].outlet_address,
			salespersonID: data.salesperson_id,
		});
	}

	const decline = (doc_id) => {
		const data = showCheckinData(doc_id);
		var params = axiosURIencode({
			mobile_checkin_id: data.mobile_checkin_id,
		});
		const isConfirmed = window.confirm("Are you sure you want to delete?");
		if (isConfirmed) {
			API_Service.put(req_checkin_delete, params, axiosApiConfig).then(({data}) => {
				const {result, message} = data;
				if (parseInt(result) == 1) {
					loadCheckinData();
					loadCustNotInListCheckinData();
					loadTotalRecord();
					snack(message);
				} else {
					snack(message, 'error');
				}
			});
		}
	}

	const confirmApprove = (newValue) => {
		setShowApprovementDialog(false);
		if (newValue.value != 'RESET') {
			var params = {
				checkin_id: approveData.mobile_checkin_id,
				salesperson_id: approveData.salespersonID,
				cust_code: newValue.value
			};
			params = axiosURIencode(params);
			API_Service.put(req_cust_approve, params, axiosApiConfig).then(({data}) => {
				const {result, message} = data;
				if (result == 1) {
					loadCheckinData();
					loadCustNotInListCheckinData();
					loadTotalRecord();
					snack(language.update_success);
				} else {
					snack(message, 'warning');
				}
			});
		}
	}

	return (
		<div>
			{
				currentPage == 0 && (
					<>
						{
							(checkinData?.length > 0 || pendingData?.length > 0) && (
								<CheckinTable
									tableHead={tableHead}
									language={language}
									checkinData={checkinData}
									pendingsData={pendingData}
									onRowClick={(props) => {
										showCheckinData(props)
									}}
									onCellClick={(props) => {
										columnChecking(props)
									}}
									setPrintView={() => {
										openPrintSelectView()
									}}
									docTableColumnsChanged={docTableColumnsChanged}
									showPending={showPending}
									onShowPending={(e) => {
										setShowPending(e);
									}}
									pendingCount={pendingCustomerCount}
								/>
							)
						}
					</>
				)
			}
			{
				currentPage == 1 && (
					<>
						{
							checkinData?.length == 0 && (
								<RenderMap
									lightTheme={lightTheme}
									latlongList={latlongList}
									height={'100%'}
									width={'100%'}
									mapHeight={'80vh'}
									pointerEvents={"none"}
									showCheckinData={(checkin_id) => showCheckinData(checkin_id)}
								/>
							)
						}
						{checkinData?.length > 0 && (<div style={{height: 20}}/>)}
						{
							checkinData?.length > 0 && (
								<RenderMap
									lightTheme={lightTheme}
									latlongList={latlongList}
									height={'100%'}
									width={'100%'}
									mapHeight={'40vh'}
									showCheckinData={(checkin_id) => showCheckinData(checkin_id)}
								/>
							)
						}
						{checkinData?.length > 0 && (<div style={{height: 80}}/>)}
					</>
				)
			}
			{
				[0, 1].includes(currentPage) && (
					<Fab
						className={currentPage === 3 ? commonStyles.fab2nd : commonStyles.fab}
						onClick={() => showFilter(true)}
						variant={'round'}
						size="small"
						color="primary"
						aria-label="add">
						{
							isSearching ?
								<CircularProgress color={'inherit'}/> :
								<FilterListIcon/>
						}
					</Fab>
				)
			}
			<CheckinDetails
				checkinData={checkinDataToview}
				open={showDetails}
				setClose={() => {
					setCheckinDataToView([]);
					setShowDetails(false);
				}}
				language={language}
				axiosApiConfig={axiosApiConfig}
				current_username={current_username}
				snack={snack}
				customerList={csList}
			/>
			<VirtualList
				open={isCustomerView}
				setClose={data => onCustomerViewClose(data)}
				title={language.cust_list}
				data={csList}
				language={language}
				target={'true'}
			/>
			<ListView
				classes={{
					paper: commonStyles.listView,
				}}
				id="ringtone-menu"
				open={spView}
				onClose={() => setSpView(false)}
				onSelect={spViewClose}
				value={spValue}
				title={language.salesman || ''}
				language={language}
				options={spList}
			/>
			<ListView
				classes={{
					paper: commonStyles.listView,
				}}
				id="ringtone-menu"
				open={statusView}
				onClose={() => setStatusView(false)}
				onSelect={onStatusViewClose}
				value={status}
				title={language.status || ''}
				language={language}
				options={statusList}
			/>
			<ListView
				classes={{
					paper: commonStyles.listView,
				}}
				id="ringtone-menu"
				open={regionView}
				onClose={() => setRegionView(false)}
				onSelect={regionViewClose}
				value={regionSelected}
				title={language.region || ''}
				language={language}
				options={regionList}
			/>
			<BottomNavigation
				value={currentPage}
				onChange={(event, newValue) => {
					setCurrentPage(newValue);
				}}
				showLabels
				className={commonStyles.bottomNav}>
				<BottomNavigationAction label="CheckIn List" icon={<PinDrop/>}/>
				<BottomNavigationAction label="Map" icon={<MapIcon/>}/>
				{/* <BottomNavigationAction label="Manage Schedule" icon={<AccessAlarm />} /> */}
				{
					JString(checkinConfig).isEqualDeep('merchandising') ? null :
						<BottomNavigationAction
							label="Schedule Summary"
							icon={<ViewList/>}/>
				}
				{
					JString(checkinConfig).isEqualDeep('merchandising') ? null :
						<BottomNavigationAction
							label="Presentation View"
							icon={<TvIcon/>}/>
				}
			</BottomNavigation>
			{
				openDateDoc && (
					<DateRangePickerModal
						setPositive={range => {
							setDocDateRange(range);
							setOpenDateDoc(false)
						}}
						setNegative={() => {
							setOpenDateDoc(false)
						}}
						setClose={() => {
							setOpenDateDoc(false)
						}}
						open={openDateDoc}
						message={language.date_range}
						positive={language.ok}
						negative={language.cancel}
					/>
				)
			}
			{
				editSched.open && (
					<AddVisitSched
						projectData={projectData}
						isEditing={editSched.open}
						editData={editSched.editData}
						requestReload={() => loadSchedData()}
						outstandingBalance={csOutstanding}
						customModules={customModule}
						addVisitSchedRules={addVisitSchedRules}
						onSnack={snack}
						axiosGet={axiosApiConfig}
						customerList={csList}
						open={editSched.open}
						branchList={branchPercustomer}
						onDismiss={() => setEditSched({open: false, editData: {}})}
						language={language}
						technicianList={tchList}/>
				)
			}
			{
				JString(checkinConfig).isEqualDeep('merchandising') ?
					<AddAppointmentMerchandiser
						onSnack={snack}
						axiosGet={axiosApiConfig}
						customerList={csList}
						open={addnewSched}
						onDismiss={() => showNewSched(false)}
						language={language}
						branchList={branchPercustomer}
						merchandiserList={mchList}
						requestReload={() => decideAndLoad()}/>
					:
					addnewSched && (
						<AddVisitSched
							projectData={projectData}
							requestReload={() => loadSchedData()}
							outstandingBalance={csOutstanding}
							customModules={customModule}
							addVisitSchedRules={addVisitSchedRules}
							onSnack={snack}
							axiosGet={axiosApiConfig}
							customerList={csList}
							open={addnewSched}
							branchList={branchPercustomer}
							onDismiss={(data) => {
								showNewSched(false);
								setSchedCode(data);
								data ? setSMSView(true) : setSMSView(false)
							}}
							language={language}
							technicianList={tchList}/>
					)
			}
			<GenerateView
				classes={{
					paper: commonStyles.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={typeOptions}
			/>
			<AnyDialog maxWidth={'lg'} keepMounted open={filterOpen} onDismiss={() => showFilter(false)}>
				<div className={paperStyle.viewInRowDialog} style={{width: 1000}}>
					<Button
						className={commonStyles.dialogFilterBtnBig}
						color="inherit"
						onClick={() => setOpenDateDoc(true)}
						size="small"
						endIcon={<KeyboardArrowRightRounded/>}
						startIcon={<EventNoteIcon/>}>
						<Typography style={{flex: 1, textAlign: 'left'}} component="p"
									className={commonStyles.searchBtnFont}>
							{!_.isEmpty(docDateRange) ? datefromto(docDateRange) : dateMenuOptions?.name}
						</Typography>
					</Button>
					<div style={{width: 20}}/>
					<Button
						disabled={csList.length === 0}
						className={commonStyles.dialogFilterBtn}
						color="inherit"
						onClick={() => showCustomerView(true)}
						size="small"
						endIcon={csList.length === 0 ? <CircularProgress size={20}/> : <KeyboardArrowDownRoundedIcon/>}
						startIcon={<BusinessIcon/>}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{csValue?.name ? _.truncate(csValue?.name, {length: 15}) : language.customer}
						</Typography>
					</Button>
					<div style={{width: 20}}/>
					<Button
						className={commonStyles.dialogFilterBtn}
						color="inherit"
						onClick={() => spViewClick()}
						size="small"
						endIcon={<KeyboardArrowDownRoundedIcon/>}
						startIcon={<PersonIcon/>}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{getSpName(spValue) || language.salesman}
						</Typography>
					</Button>
					<div style={{width: 20}}/>
					{
						currentPage !== 0 && (
							<Button
								className={commonStyles.dialogFilterBtn}
								color="inherit"
								onClick={() => regionClick()}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<MapIcon/>}>
								<Typography component="p" className={commonStyles.searchBtnFont}>
									{regionSelected !== empty ? getRegion(regionSelected) : language.region}
								</Typography>
							</Button>
						)
					}
					<div style={{width: 20}}/>
					{
						currentPage != 0 && (
							<Button
								className={commonStyles.dialogFilterBtn}
								color="inherit"
								onClick={() => statusViewClick()}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<WorkOutlineIcon/>}>
								<Typography component="p" className={commonStyles.searchBtnFont}>
									{getStatus(status) || language.status}
								</Typography>
							</Button>
						)
					}

				</div>
				<Divider variant={'middle'}/>
				<div className={paperStyle.viewInRowDialogEnd}>
					<ClearButton
						color="inherit"
						onClick={() => clearValues()}
						size="small"
						startIcon={<ClearAll style={{color: red[400]}}/>}>
						<Typography component="p" className={commonStyles.searchBtnFont} style={{color: red[400]}}>
							{language.clear}
						</Typography>
					</ClearButton>

					<div style={{width: 10}}/>

					<Button
						disabled={isSearching}
						color="inherit"
						onClick={() => {
							onSearchClick()
						}}
						size="small"
						startIcon={isSearching ? <CircularProgress size={20}/> : <FindInPage/>}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{language.search_cap}
						</Typography>
					</Button>
				</div>
			</AnyDialog>
			<SendSMS
				open={smsView}
				setClose={() => {
					setSMSView(false);
					setSchedCode([]);
				}}
				language={language}
				data={schedCode}
			/>
			<VirtualListMB
				open={showApprovementDialog}
				onConfirm={data=>confirmApprove(data)}
				onCreate={()=>{setShowApprovementDialog(false);setShowCreateCustomerDialog(true);}}
				onCancel={()=>setShowApprovementDialog(false)}
				title={'Approval'}
				description={approveData?.outlet_name}
				data={showApprovementList}
				language={language}
			/>

			<AddCustomer
				open={showCreateCustomerDialog}
				onClose={(data) => {
					setShowCreateCustomerDialog(false);
					loadCheckinData();
					loadCustNotInListCheckinData();
					loadTotalRecord();
				}}
				onCancel={()=>setShowCreateCustomerDialog(false)}
				language={language}
				currentInput={approveData}
				custCategoryList={activeCustCategoryList}
				custChainList={activeCustChainList}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

function CheckinTable ({
									 tableHead,
									 language,
									 checkinData,
									 pendingsData,
									 onRowClick,
									 onCellClick,
									 setPrintView,
									 docTableColumnsChanged,
									 showPending,
									 onShowPending,
									 pendingCount
								 }) {
	if (checkinData.length === 0 && pendingsData.length === 0) {
		return null;
	}
	return (
		<MUIDataTable
			title={language.checkin}
			data={showPending ? pendingsData : checkinData}
			columns={tableHead}
			options={{
				print: false,
				filter: true,
				download: false,
				selectableRows: 'none',
				rowsPerPage: 25,
				rowsPerPageOptions: [25, 40, 65, 100],
				responsive: 'scrollMaxHeight',
				onRowClick: (rowData) => {
					onRowClick(rowData[0])
				},
				onCellClick: (cellData, cellMeta) => {
					onCellClick(cellMeta.colIndex)
				},
				setTableProps: () => ({size: 'small'}),
				// customToolbar:()=>
				//     <>
				//         <CheckinTableReportButton onGenerateReport={setPrintView}/>
				//     </>,
				onColumnViewChange: (changedColumns, action) => {
					docTableColumnsChanged(changedColumns, action);
				},
				customToolbar: () => {
					return (
						<>
						{
							showPending ? (
								<Tooltip title={'Show Active'}>
									<IconButton
										onClick={() => {
											onShowPending(false);
										}}>
										<NewReleasesIcon/>
									</IconButton>
								</Tooltip>
							) : (
								<Tooltip title={'Show Pending Only'}>
									<IconButton
										onClick={() => {
											onShowPending(true);
										}}>
										{
											pendingCount > 0 ? <NewReleasesIcon color={'primary'}/> : <NewReleasesIcon color={'secondary'}/>
										}
									</IconButton>
								</Tooltip>
							)
						}
						<span>Pending: {pendingCount}</span>
						</>
					);
				}
			}}
		/>
	)
}

const AppointmentTable = React.memo(({language, appointmentData, onRowClick}) => {
	if (appointmentData.length == 0) {
		return null;
	}
	return (
		<MUIDataTable
			title={language.schedules}
			data={appointmentData}
			columns={appointmentHeadCell}
			options={{
				print: false,
				filter: true,
				selectableRows: 'none',
				rowsPerPage: 125,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'simple',
				onRowClick: (rowData, {dataIndex}) => {
					onRowClick(dataIndex);
				},
				rowsSelected: [],
				setTableProps: () => ({size: 'small'})
			}}
		/>
	)
}, (prevProps, nextProps) => prevProps.appointmentData.length === nextProps.appointmentData.length);

const ScheduleTable = React.memo(({language, scheduleData, onRowClick}) => {
	if (scheduleData.length === 0) {
		return null;
	}
	const onClick = (dataIndex) => {
		onRowClick && onRowClick({
			open: true,
			editData: scheduleData[dataIndex]
		})
	}

	return (
		<MUIDataTable
			title={language.schedules}
			data={scheduleData}
			columns={schedHeadCell}
			options={{
				print: false,
				filter: true,
				selectableRows: 'none',
				rowsPerPage: 125,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'simple',
				onRowClick: (rowData, {dataIndex}) => {
					onClick(dataIndex);
				},
				rowsSelected: [],
				setTableProps: () => ({size: 'small'})
			}}
		/>
	)
}, areEqualSched);

function areEqualSched(prevProps, nextProps) {
	return prevProps.scheduleData.length === nextProps.scheduleData.length;
}

function areEqual(prevProps, nextProps) {
	return prevProps.checkinData.length === nextProps.checkinData.length;
}

const RenderMap = pure(({
							lightTheme,
							latlongList,
							height = '100%',
							width = '20%',
							mapHeight = '50vh',
							mapWidth = '100%',
							showCheckinData,
							...others
						}) => {
	const Map = ReactMapboxGl({accessToken: mapbox_key});
	const [currentLocation, setCurrentLocation] = useState([101.621420, 3.142540]);

	const clickmark = (item) => {
		showCheckinData(item.checkin_id);
	}

	useEffect(() => {
		position();
	}, [latlongList]);

	const position = () => {
		navigator.geolocation.getCurrentPosition(
			position => setCurrentLocation([position.coords.longitude, position.coords.latitude]),
			err => de(err)
		);
	}

	return (
		<div style={{height: height, width: width, ...others}}>
			<Map
				center={currentLocation}
				style={lightTheme ? "mapbox://styles/mapbox/light-v10" : "mapbox://styles/mapbox/dark-v10"}
				containerStyle={{
					height: mapHeight,
					width: mapWidth
				}}>
				<RenderMarkerPopup
					latlongList={latlongList}
					onClick={(item) => clickmark(item)}
				/>
			</Map>
		</div>
	)
});
const RenderMarkerPopup = pure(({latlongList, onClick}) => {
	const [popupData, setPopupData] = useState({show: false, loc: [], text: '', id: ''});
	return (
		<div>
			{
				latlongList && latlongList.map((item, index) =>
					<Marker
						onMouseEnter={() => setPopupData({
							show: true,
							loc: item.loc,
							text: item.agent,
							id: item.checkin_id
						})}
						onMouseLeave={() => setPopupData({show: false, loc: [], text: ''})}
						key={String(index)}
						coordinates={item.loc}
						anchor="bottom">
						<RoomIcon color={'primary'} onClick={() => onClick(item)} style={{ color: item.marker_color }}/>
					</Marker>
				)
			}
			{
				popupData.show && (
					<Popup
						coordinates={popupData.loc}
						anchor={'top'}>
						<Typography color={"error"} style={{alignItems: 'center', display: 'flex', whiteSpace: 'pre-line'}}>
							{popupData.text}
						</Typography>
					</Popup>
				)
			}
		</div>
	)
});
