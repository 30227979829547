import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {req_module_setting, req_stock_info} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import JString from '@easytech-international-sdn-bhd/jstring';
import _ from 'lodash';
import {useTheme} from '@material-ui/core';
import {getSetting} from '../../Helper/Helper';

const useStyles = makeStyles({
	dlg: {
		width: '48%',
		height: 50,
		margin:5
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	}
});

export default function EditProductName({
											open,
											onClose,
											language,
											product_code,
											product_name,
											category_id,
											brand_id,
											category_list,
											brand_list,
											product_desc,
											qr_code
										}) {
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [code, setCode] = useState(product_code);
	const [name, setName] = useState(product_name);
	const [desc, setDesc] = useState(product_desc);
	const [qrCode, setQrCode] = useState(qr_code);
	const [categoryId, setCategoryId] = useState(category_id);
	const [categoryName, setCategoryName] = useState('');
	const [brandId, setBrandId] = useState(brand_id);
	const [brandName, setBrandName] = useState('');
	const [axiosPut, setAxiosPutConfig] = useState({});
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [chosenCategory, setChosenCategory] = useState({});
	const [chosenBrand, setChosenBrand] = useState({});
	const [productCodeProps, setProductCodeProps] = useState(20);
	const theme = useTheme();
	useEffect(() => {
		var axiosPut = axiosPutConfig(gState.client);
		var axiosGetConfig = axiosDefaultConfig(gState.client);
		setAxiosPutConfig(axiosPut);
		loadConfig(axiosGetConfig);
		// setNewBarcode('');
	}, [open]);

	useEffect(() => {
		var cat_id = category_id ? JString(category_id).toNumber() : 0;
		var bra_id = brand_id ? JString(brand_id).toNumber() : 0;

		if (cat_id != 0 && category_list.length > 0) {
			for (let i = 0; i < category_list.length; i++) {
				const {category_id, category_name} = category_list[i];
				if (category_id == cat_id) {
					setChosenCategory(category_list[i]);
					setCategoryName(category_name);
					break;
				}
			}
		} else {
			setChosenCategory({});
			setCategoryName('');
		}
		if (bra_id != 0 && brand_list.length > 0) {
			for (let i = 0; i < brand_list.length; i++) {
				const {brand_id, brand_name} = brand_list[i];
				if (brand_id == bra_id) {
					setChosenBrand(brand_list[i]);
					setBrandName(brand_name);
					break;
				}
			}
		} else {
			setChosenBrand({});
			setBrandName('');
		}
	}, [category_list, brand_list, category_id, brand_id])

	const loadConfig = (axiosConfig) => {
		API_Service.get(req_module_setting, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _availableModules = getSetting(extraData, 'customization', {});
				if(_availableModules.product_code){
					setProductCodeProps(_availableModules.product_code);
				}
			}
		});
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const saveCurrentChanges = () => {
		const params = axiosURIencode({
			ori_code: product_code,
			product_code: code,
			product_name: name,
			category_id: categoryId ? categoryId : 0,
			brand_id: brandId ? brandId : 0,
			product_desc : desc,
			barcode: qrCode
		});
		API_Service.put(req_stock_info, params, axiosPut).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				snack(gState.translation.update_success);
				onClose(extraData);
			} else {
				snack(gState.translation.update_fail, 'warning');
			}
		});
	}

	return (
		<Dialog onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{language.edit_product_name}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						className={classes.dlg}
						id="input-code"
						inputProps={{maxLength: productCodeProps, pattern: "[a-z]{1,15}"}}
						label={language.product_code}
						name="input-code"
						defaultValue={product_code}
						onChange={(e) => setCode(e.target.value)}
					/>
					<TextField
						className={classes.dlg}
						id="input-name"
						label={language.product_name}
						name="input-name"
						defaultValue={product_name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<TextField
					className={classes.dlg}
					id="input-desc"
					label={language.product_desc}
					name="input-desc"
					defaultValue={product_desc}
					onChange={(e) => setDesc(e.target.value)}
					style={{width: '98%'}}
				/>
				<TextField
					className={classes.dlg}
					id="input-qr-code"
					label={'Barcode Code'}
					name="input-qr-code"
					defaultValue={qr_code}
					onChange={(e) => setQrCode(e.target.value)}
					style={{width: '98%'}}
				/>
				<div className={classes.viewInRowSpace} style={{marginTop:10}}>
					<Autocomplete
						className={classes.dlg}
						options={category_list || []}
						onChange={(event, newValue) => {
							if (!_.isEmpty(newValue)) {
								setCategoryName(newValue['category_name']);
								setChosenCategory(newValue);
								setCategoryId(newValue['category_id']);
							}
						}}
						onInputChange={(event, newInputValue) => {
							setCategoryName(newInputValue);
						}}
						value={chosenCategory}
						inputValue={categoryName}
						getOptionLabel={(option) => option.category_name || ""}
						renderInput={(params) => <TextField {...params} label="Category" variant="outlined"/>}
					/>
					<Autocomplete
						className={classes.dlg}
						options={brand_list || []}
						onChange={(event, newValue) => {
							if (!_.isEmpty(newValue)) {
								setBrandName(newValue['brand_name']);
								setChosenBrand(newValue);
								setBrandId(newValue['brand_id']);
							}
						}}
						value={chosenBrand}
						inputValue={brandName}
						onInputChange={(event, newInputValue) => {
							setBrandName(newInputValue);
						}}
						getOptionLabel={(option) => option.brand_name || ""}
						renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"/>}
					/>
				</div>

				<DialogActions>
					<Button
						onClick={() => {
							saveCurrentChanges()
						}}
						variant={'text'}
						color={'primary'}>
						{language.update}
					</Button>
				</DialogActions>

			</div>
		</Dialog>
	)
}
