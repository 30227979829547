import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import SearchBox from '../SearchBox';
import JString from '@easytech-international-sdn-bhd/jstring';
import {sanitize_regx} from '../../Helper/Helper';
import CheckIcon from '@material-ui/icons/Check';

export default function FixedList({ listData, onClick, height = 500, selected }){
	const [ listItems, setListItems ] = useState([]);
	useEffect(()=>{
		setListItems(listData);
	},[listData]);

	const renderRow = (props) => {
		const { index, style } = props;
		const obj = listItems[index];

		return index !== listItems.length ? (
			<ListItem
				button
				style={{
					...style,
					backgroundColor: selected == obj?.value ? '#333' : 'transparent',
					borderLeft: selected == obj?.value ? '2px solid yellow' : 'transparent',
				}}
				key={index}
				onClick={()=>{
					// setTappedItem(obj);
					onClick && onClick(obj);
				}}>
				<ListItemText
					primary={obj?.name}/>
				{selected == obj?.value && (<CheckIcon style={{color: 'yellow'}}/>)}
			</ListItem>
		):null;
	}

	const onSearchList = (text) =>{
		if(text){
			const newArr = [];
			const query = String(sanitize_regx(text));
			for (let i = 0; i < listData.length; i++) {
				const obj = listData[i];
				const { value, key } = obj;
				if(
					JString(value).searchDeep(query) ||
					JString(key).searchDeep(query)
				){
					newArr.push(obj);
				}
			}
			setListItems(newArr);
		}else{
			setListItems(listData);
		}
	}

	return (
		<div>
			<SearchBox
				onChangeText={(e)=>{onSearchList(e)}}
			/>
			<FixedSizeList
				margin={10}
				height={height}
				width='100%'
				itemSize={80}
				itemCount={listData ? listData.length : 0}>
				{renderRow}
			</FixedSizeList>
		</div>
	)
}
