import {asJson} from "./Helper";
import moment from "moment";

export default class DataBridgeEventTranslator {
	str = '';
	json = {};
	as_message = {};
	date = '';
	table_map = {
		cms_product: "Product Info",
		cms_product_category: "Product Category",
		cms_product_image: "Product Image",
		cms_customer: "Customer Info",
		cms_order: "Orders",
		cms_order_item: "Order Items",
		cms_product_uom_price_v2: "Pricing Info",
		cms_product_price_v2: "Price Tags",
		cms_warehouse: "Stock Location",
		cms_warehouse_stock: "Stock QTY",
		cms_payment: "Payments",
		cms_visit_report: "Check-Ins",
		cms_visit_report_documents: "Check-In Orders",
		cms_user_location: "User Location",
		cms_project: "Accounting Projects"
	}
	column_map = {
		cms_product: {
			product_code: "Code",
			product_name: "Name",
			product_desc: "Description",
			product_remark: "Remark",
			product_current_quantity: "Current QTY",
			product_available_quantity: "Available QTY",
			product_status: "Status",
			QR_code: "QR/Barcode"
		},
		cms_product_category: {
			categoryIdentifierId: "Code",
			category_name: "Name",
			category_status: "Status"
		},
		cms_product_image: {
			image_url: "Image Link",
			active_status: "Status"
		},
		cms_customer: {
			cust_code: "Code",
			cust_company_name: "Name",
			customer_status: "Status",
			current_balance: "Balance"
		},
		cms_order: {
			order_id: "ID",
			cust_code: "Customer Code",
			cust_company_name: "Customer Name",
			order_status: "Order Type",
			cancel_status: "Status",
			grand_total: "Total Amount",
			pack_confirmed: "Packing Status",
			packing_status: "Picking Status"
		},
		cms_order_item: {
			product_code: "Product Code",
			product_name: "Product Name",
			cancel_status: "Status",
			quantity: "QTY",
			unit_price: "Price",
			unit_uom: "UOM",
			packing_status: "Picking Status",
			packed_qty: "Picked QTY",
			packed_by: "Picked By",
			pack_confirmed_by: "Packed By",
			pack_confirm_status: "Packed Status",
			updated_at: "Updated At"
		},
		cms_product_uom_price_v2: {
			product_code: "Code",
			product_std_price: "STD Price",
			product_min_price: "MIN Price",
			active_status: "Status",
			product_uom: "UOM"
		},
		cms_product_price_v2: {
			product_code: "Code",
			product_price: "Price"
		},
		cms_warehouse: {
			wh_code: "Location Code",
			wh_name: "Location Name"
		},
		cms_warehouse_stock: {
			wh_code: "Location Code",
			product_code: "Product Code",
			ready_st_qty: "Ready Stock",
			available_st_qty: "Available Stock",
			active_status: "Status"
		},
		cms_payment: {
			payment_id: "ID",
			cust_code: "Customer Code",
			payment_amount: "Amount",
			payment_status: "Status"
		},
		cms_visit_report: {
			person_met: "Person Met",
			checkin_time: "Checked-In At",
			checkout_time: "Checked-Out At"
		},
		cms_visit_report_documents: "",
		cms_user_location: "",
		cms_project: {
			project_code: "Code",
			project_desc: "Description"
		}
	}
	status_map = {
		cms_order: {
			packing_status: {
				"-1": "Picking",
				"1": "Picked",
				"0": "Pending",
				"2": "No stock"
			},
			pack_confirmed: {
				"1": "Packed",
				"0": "Pending"
			},
			order_status: {
				"0": "In-App",
				"1": "Confirmed",
				"2": "Transferred",
				"3": "Posted"
			},
			cancel_status: {
				"0": "Active",
				"1": "Cancelled"
			}
		},
		cms_order_item: {
			packing_status: {
				"1": "Picked",
				"0": "Pending",
				"2": "No stock"
			},
			pack_confirm_status: {
				"1": "Packed",
				"0": "Pending"
			},
			cancel_status: {
				"0": "Active",
				"1": "Cancelled"
			}
		},
		cms_warehouse_stock: {
			active_status: {
				"1": "Active",
				"0": "Inactive"
			}
		},
		cms_payment: {
			payment_status: {
				"1": "Active",
				"0": "Cancelled"
			}
		},
		cms_product: {
			product_status: {
				"1": "Active",
				"0": "Inactive"
			}
		},
		cms_product_category: {
			category_status: {
				"1": "Active",
				"0": "Inactive"
			}
		},
		cms_product_image: {
			active_status: {
				"1": "Active",
				"0": "Inactive"
			}
		},
		cms_customer: {
			customer_status: {
				"1": "Active",
				"0": "Inactive"
			}
		}
	}
	table_func = {
		cms_product: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_product;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_product');
			return this.create_message(affected_row.product_name, msg);
		},
		cms_product_category: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_product_category;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_product_category');
			return this.create_message(affected_row.category_name, msg);
		},
		cms_product_image: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_product_image;
			return this.create_message(
				'Product Image',
				this.converted(affected_columns, affected_row, column_naming, 'cms_product_image')
			);
		},
		cms_customer: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_customer;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_customer');
			return this.create_message(affected_row.cust_company_name, msg);
		},
		cms_order: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_order;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_order');
			return this.create_message(affected_row.order_id, msg);
		},
		cms_order_item: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_order_item;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_order_item');
			return this.create_message(affected_row.order_id, `${affected_row.product_name} ${msg}`);
		},
		cms_product_uom_price_v2: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_product_uom_price_v2;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_product_uom_price_v2');
			return this.create_message(affected_row.product_code, msg);
		},
		cms_product_price_v2: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_product_price_v2;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_product_price_v2');
			return this.create_message(affected_row.product_code, msg);
		},
		cms_warehouse: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_warehouse;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_warehouse');
			return this.create_message(affected_row.wh_name, msg);
		},
		cms_warehouse_stock: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_warehouse_stock;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_warehouse_stock');
			return this.create_message(affected_row.product_code, msg);
		},
		cms_payment: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_payment;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_payment');
			return this.create_message(affected_row.payment_id, msg);
		},
		cms_visit_report: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_visit_report;
			let msg = this.converted(affected_columns, affected_row, column_naming, 'cms_visit_report');
			return this.create_message(affected_row.mobile_checkin_id, msg);
		},
		cms_visit_report_documents: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_visit_report_documents;
			return this.create_message(
				'Checkin-Document',
				this.converted(affected_columns, affected_row, column_naming, 'cms_visit_report_documents')
			);
		},
		cms_user_location: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_user_location;
			return this.create_message(
				'User Location',
				this.converted(affected_columns, affected_row, column_naming, 'cms_user_location')
			);
		},
		cms_project: (affected_columns = [], affected_row = {}) => {
			const column_naming = this.column_map.cms_project;
			return this.create_message(
				'Project',
				this.converted(affected_columns, affected_row, column_naming, 'cms_project')
			);
		}
	}

	constructor(props, date) {
		if (typeof props !== "string") {
			props = JSON.stringify(props);
		}
		this.str = props;
		this.json = asJson(props);
		this.date = moment(date).format('DD MMM | hh:mm:ss A');
	}

	translate() {
		/**
		 * data format
		 * {
		 *     table:string,
		 *     database:string,
		 *     affected_columns:array<string>,
		 *     affected_row:array<object>,
		 *     exchange:string
		 * }
		 */
		const {table, affected_columns, affected_row} = this.json;
		if (table in this.table_map) {
			const readable_table_name = this.table_map[table];
			const message = this.table_func[table](affected_columns, affected_row);
			this.as_message = {
				id: table,
				group: readable_table_name,
				message,
				date_time: this.date
			};
		}
		return this.as_message;
	}

	converted(affected_columns, affected_row, column_naming, table) {
		let msg = '[1] Record was updated';
		if (column_naming) {
			let counter = 0;
			const status_name = this.status_map[table];
			for (const columnsKey in column_naming) {
				if (affected_columns.includes(columnsKey)) {
					if (counter === 0) msg = "";
					let affected_value = affected_row[columnsKey];
					if (status_name && columnsKey in status_name && affected_value in status_name[columnsKey]) {
						affected_value = status_name[columnsKey][affected_value];
					}
					const name = column_naming[columnsKey];
					msg += `${name} was updated. New value '${affected_value || 'EMPTY'}' \n`;
					counter++;
				}
			}
			return msg;
		} else {
			return msg;
		}
	}

	create_message(main = '', content = '') {
		return {
			main,
			content
		};
	}
}
