import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {req_stock_brand} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles({
	dlg: {
		minWidth: '90%',
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	}
});

export default function EditBrand({open, onClose, language, brand_code, brand_name}) {
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [code, setCode] = useState(brand_code);
	const [name, setName] = useState(brand_name);
	const [axiosPut, setAxiosPutConfig] = useState({});
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	useEffect(() => {
		var axiosPut = axiosPutConfig(gState.client);
		setAxiosPutConfig(axiosPut);
		// setNewBarcode('');
	}, [open]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const saveCurrentChanges = () => {
		const params = axiosURIencode({
			ori_code: brand_code,
			brand_code: code,
			brand_name: name
		});
		API_Service.put(req_stock_brand, params, axiosPut).then(({data}) => {
			const {result, extraData, msg} = data;
			if (result) {
				snack(gState.translation.update_success);
				onClose(extraData);
			} else {
				snack(gState.translation.update_fail_brand, 'warning');
			}
		});
	}

	return (
		<Dialog onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<div style={{width: 400}}>
				<DialogTitle id="simple-dialog-title">
					{language.edit_brand}
				</DialogTitle>
				<TextField
					className={classes.dlg}
					id="input-code"
					label={language.brand_code}
					name="input-code"
					defaultValue={brand_code}
					onChange={(e) => setCode(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-name"
					label={language.brand_name}
					name="input-name"
					defaultValue={brand_name}
					onChange={(e) => setName(e.target.value)}
				/>
				<DialogActions>
					<Button
						onClick={() => {
							saveCurrentChanges()
						}}
						variant={'text'}
						color={'primary'}>
						{language.update}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}
