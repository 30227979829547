import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
	ck_u_password,
	ck_u_username,
	defDateFormat,
	empty,
	outlet_visit_frequency_report_url,
	req_cust,
	req_cust_area,
	req_module_setting,
	req_report_outletvisitfrequency,
	req_salesman
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Outlet Area",
		options: {
			display: true
		}
	},
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "billing_state",
		label: "State",
		options: {
			display: true
		}
	},
	{
		name: "region",
		label: "Region",
		options: {
			display: true
		}
	},
	{
		name: "checkin_time",
		label: "Last Check In Date",
		options: {
			display: true
		}
	},
	{
		name: "total",
		label: "Day Since Last Visit",
		options: {
			display: true
		}
	}
]

export default function ROutletVisitFrequency() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [outletVisitFList, setOutletVisitFList] = useState([]);

	const [outletVisitFDateRange, setoutletVisitFDateRange] = useState({});
	const [openOutletVisitFDateDoc, setopenOutletVisitFDateDoc] = useState(false);
	const [outletVisitFSPView, setoutletVisitFSPView] = useState(false);
	const [outletVisitFRegionView, setoutletVisitFRegionView] = useState(false);
	const [outletVisitFStateView, setoutletVisitFStateView] = useState(false);
	const [outletVisitFStateSelected, setoutletVisitFStateSelected] = useState(empty);
	const [outletVisitFState, setoutletVisitFState] = useState(empty);
	const [outletVisitFRegionSelected, setoutletVisitFRegionSelected] = useState(empty);
	const [outletVisitFRegion, setoutletVisitFRegion] = useState(empty);
	const [outletVisitFSPSelect, setoutletVisitFSPSelected] = useState(empty);
	const [outletVisitFSP, setoutletVisitFSP] = useState(empty);
	const [outletVisitFOutletView, setOutletVisitFOutletView] = useState(false);
	const [outletVisitFOutlet, setOutletVisitFOutlet] = useState(empty);
	const [outletVisitFOutletSelect, setOutletVisitFOutletSelected] = useState(empty);
	const [tableHeader, setTableHeader] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);


	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			getCountry(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: area_status});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getOutletVisitFList = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitFDateRange)) {
			st_date = format(outletVisitFDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitFDateRange.endDate, defDateFormat);
		}
		const param = axiosURIencode({
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: outletVisitFSPSelect,
			cust_code: outletVisitFOutletSelect,
			region: outletVisitFRegionSelected,
			state: outletVisitFStateSelected,
			area: selectedArea,
			username: cookies(ck_u_username)
		});
		API_Service.get(`${req_report_outletvisitfrequency}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setOutletVisitFList(outletVisitFList => (extraData));
			} else {
				setOutletVisitFList(outletVisitFList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getCountry = (_client = nClient) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const country = getSetting(extraData, 'country');
				let country_arr = [JSON.parse(JSON.stringify(country))];
				var status = country_arr[0].states;
				var region = country_arr[0].regions;
				var _status = [];
				var _region = [];
				for (let i = 0; i < status?.length; i++) {
					const {name} = status[i];
					_status.push({name: name, secondary: name, value: name, status: 1});
				}
				for (let i = 0; i < region?.length; i++) {
					const {name} = region[i];
					_region.push({name: name, secondary: name, value: name, status: 1});
				}
				setStateList(_status);
				setRegionList(_region);
				const _config = getSetting(extraData, 'customization', {});
			} else {
				snack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = nClient) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const outletVisitFSPViewClick = () => {
		setoutletVisitFSPView(true);
	}

	const outletVisitFSPViewClose = (newValue) => {
		setoutletVisitFSPView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFSPSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setoutletVisitFSP(spname);
		}
	}

	const outletVisitFRegionViewClick = () => {
		setoutletVisitFRegionView(true);
	}

	const outletVisitFRegionViewClose = (newValue) => {
		setoutletVisitFRegionView(false);
		if (newValue) {
			var regionvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionvalue += newValue[i].value;
				} else {
					regionvalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFRegionSelected(regionvalue);

			var regionname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					regionname += newValue[i].name;
				} else {
					regionname += newValue[i].name + ",";
				}
			}
			setoutletVisitFRegion(regionname);
		}
	}

	const outletVisitFStateViewClick = () => {
		setoutletVisitFStateView(true);
	}

	const outletVisitFStateViewClose = (newValue) => {
		setoutletVisitFStateView(false);
		if (newValue) {
			var statevalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statevalue += newValue[i].value;
				} else {
					statevalue += newValue[i].value + ",";
				}
			}
			setoutletVisitFStateSelected(statevalue);

			var statename = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					statename += newValue[i].name;
				} else {
					statename += newValue[i].name + ",";
				}
			}
			setoutletVisitFState(statename);
		}
	}

	const OutletVisitFOutletViewClick = () => {
		setOutletVisitFOutletView(true);
	}

	const OutletVisitFOutletViewClose = (newValue) => {
		setOutletVisitFOutletView(false);
		if (newValue) {
			var custvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custvalue += newValue[i].value;
				} else {
					custvalue += newValue[i].value + ",";
				}
			}
			setOutletVisitFOutletSelected(custvalue);

			var custname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					custname += newValue[i].name;
				} else {
					custname += newValue[i].name + ",";
				}
			}
			setOutletVisitFOutlet(custname);
		}
	}

	const getOutletVisitFDate = () => {
		return !_.isEmpty(outletVisitFDateRange) ? moment(outletVisitFDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(outletVisitFDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateOutletVisitFrequencyReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(outletVisitFDateRange)) {
			st_date = format(outletVisitFDateRange.startDate, defDateFormat);
			st_date_to = format(outletVisitFDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(outlet_visit_frequency_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', outletVisitFSPSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.replaceInfinity('@state', outletVisitFStateSelected)
			.replaceInfinity('@region', outletVisitFRegionSelected)
			.replaceInfinity('@cust_code', outletVisitFOutletSelect)
			.str;
		window.open(link, "_blank");
	}


	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_outlet_visit_frequency_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => outletVisitFSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitFSP ? _.truncate(outletVisitFSP, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OutletVisitFOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitFOutlet ? _.truncate(outletVisitFOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => outletVisitFStateViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitFState ? _.truncate(outletVisitFState, {length: 20}) : language.state}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => outletVisitFRegionViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletVisitFRegion ? _.truncate(outletVisitFRegion, {length: 20}) : language.region}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setopenOutletVisitFDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOutletVisitFDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getOutletVisitFList()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				outletVisitFList.length > 0 && (
					<MUIDataTable
						title={language.outlet_visit_frequency}
						data={outletVisitFList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={generateOutletVisitFrequencyReport}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={outletVisitFSPView}
				setClose={data => outletVisitFSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={outletVisitFStateView}
				setClose={data => outletVisitFStateViewClose(data)}
				title={"State"}
				data={stateList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFRegionView}
				setClose={data => outletVisitFRegionViewClose(data)}
				title={"Region"}
				data={regionList}
				language={language}
				filteration={0}
			/>

			<VirtualListCB
				open={outletVisitFOutletView}
				setClose={data => OutletVisitFOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={0}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setoutletVisitFDateRange(range);
					setopenOutletVisitFDateDoc(false)
				}}
				setNegative={() => {
					setopenOutletVisitFDateDoc(false)
				}}
				setClose={() => {
					setopenOutletVisitFDateDoc(false)
				}}
				open={openOutletVisitFDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}