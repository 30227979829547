import React from 'react';
import Lottie from 'lottie-react-web';
import LottieAnim from '../../Assets/emptysearch.json';

export function SearchLottie({...rest}) {
	const defaultLottieOptions = {
		loop: true,
		autoplay: true,
		animationData: LottieAnim,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<Lottie
			options={defaultLottieOptions}
			forceSegment={true}
			width={rest?.width || '15%'}
			height={rest?.height || '15%'}
		/>
	)
}
