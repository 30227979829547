import React, {useContext, useEffect, useState} from 'react';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import API_Service, { axiosDefaultConfig } from '../../Services/API_Service';
import { Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Typography, makeStyles } from '@material-ui/core';
import { req_module_setting } from '../../Helper/Constants';
import JString from '@easytech-international-sdn-bhd/jstring';
import { de, getSetting } from '../../Helper/Helper';
import { useCommonStyles } from '../../Styles';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(1),
        height: '150px'
    },
    paper: {
        display: 'flex',
        width: 270,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        zIndex: theme.zIndex + 1,
    },
    font: {
        cursor: 'pointer'
    }
}));

export default function Tutorials() {
    const classes = useStyles();
    const [gState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang();
    }

    const [tutorialList, setTutorialsList] = useState([]);
    const [videoViewer,openVideoViewer] = useState('');
    const [videoTitle, openVideoTitle] = useState('');

    useEffect(() => {
        if (JString(gState.client).isValid()) {
            var axiosGetConfig = axiosDefaultConfig(gState.client);
            loadConfig(axiosGetConfig);
        }
    }, [gState.client]);

    const loadConfig = (axiosRequestConfig) => {
        API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
            const tut = [];
			if (result == 1) {
				const _tutorial = getSetting(extraData, 'tutorial', {});
                Object.entries(_tutorial).forEach(([index, data]) => {
                    tut.push({
                        title: index,
                        data: data
                    });
                });
				setTutorialsList(tut);
			}
		});
    }

    return(
        <div>
            {
                tutorialList.length > 0 && (
                    <Grid container spacing={3}>
                        {tutorialList.map((data) => (
                            <Grid item className={classes.grid}>
                                <Paper className={classes.paper} onClick={() => {openVideoViewer(data.data);openVideoTitle(data.title);}}>
                                    <Typography variant="inherit" component="h3" className={classes.font}>
                                        {data.title}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    
                )
            }
            <Dialog
                maxWidth={'lg'}
                open={!_.isEmpty(videoViewer)}
                onClose={() => {openVideoViewer('');openVideoTitle('');}}
                aria-labelledby="responsive-dialog-title">
                <DialogActions>
                    <IconButton edge="start" color="inherit" onClick={() => {openVideoViewer('');openVideoTitle('');}}
                                aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                </DialogActions>
                <DialogContent>
                    <iframe width="1173" height="650" src={videoViewer} title={videoTitle} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </DialogContent>
            </Dialog>
        </div>
    )
}
