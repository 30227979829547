import React, {useContext, useEffect, useReducer, useState} from "react";
import {Switch} from "react-router-dom";
import Route from "./Route";
import SignIn from "../Pages/SignIn";
import MainView from "../Pages/MainView";
import useCookieHook from "../Services/useCookieHook";
import {withCookies} from "react-cookie";
import {ck_u_client} from "../Helper/Constants";
import JString from "@easytech-international-sdn-bhd/jstring";
import AppContext from "../Services/AppContext";
import {isEmpty} from "lodash";

function Routes(props) {
	const [isPrivate, setIsPrivate] = useState(false);
	const [cookies] = useCookieHook([ck_u_client]);
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [gState, setGState] = useContext(AppContext);
	useEffect(() => {
		const session = JString(sessionStorage.getItem('session'));
		if (session.isValid() && isEmpty(gState)) {
			setGState(session.toJson());
			setIsPrivate(true);
		} else {
			setIsPrivate(session.isValid() || JString(cookies(ck_u_client)).isValid());
			if (JString(cookies(ck_u_client)).isValid()) {
				sessionStorage.setItem('session', JSON.stringify(gState));
			}
			forceUpdate();
		}
	}, [props.allCookies, sessionStorage.getItem('client')]);
	return (
		<Switch>
			<Route path="/" exact component={SignIn}/>

			<Route path="/main" component={MainView} isPrivate={isPrivate}/>

			<Route component={SignIn}/>
		</Switch>
	);
}

export default withCookies(Routes);
