import React, {useContext, useEffect, useState} from "react";
import {Button, IconButton, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AppContext from "../../Services/AppContext";
import PersistantData from "../../Services/PersistantData";
import {useCommonStyles} from "../../Styles";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_delete_file,
    req_report_exportincentive,
    req_report_incentive,
    req_salesman
} from "../../Helper/Constants";
import moment from "moment";
import DateRangePickerModal from "../../Components/DateRangePickerModal";
import _ from "lodash";
import VirtualListCB from "../../Components/VirtualListCB";
import API_Service, {axiosDefaultConfig, axiosURIencode} from "../../Services/API_Service";
import JString from "@easytech-international-sdn-bhd/jstring";
import {format} from "date-fns";
import useCookieHook from "../../Services/useCookieHook";
import {useSnackbar} from "notistack";
import MUIDataTable from "mui-datatables";
import {Assessment as AssessmentIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1)
	}
}));

const tableHead = [
	{
		name: "checkin_id",
		label: "Check-In ID",
		options: {
			display: true
		}
	},
	{
		name: "salesperson_name",
		label: "Agent Name",
		options: {
			display: false
		}
	},
	{
		name: "promoter_name",
		label: "Promoter Name",
		options: {
			display: true
		}
	},
	{
		name: "promotion_date",
		label: "Promotion Date",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "company",
		label: "Branch",
		options: {
			display: true,
			customBodyRender: (value) => value ? value.toUpperCase() : ''
		}
	},
	{
		name: "cust_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "product_code",
		label: "Product Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Product Name",
		options: {
			display: true
		}
	},
	{
		name: "opening_qty",
		label: "Opening Qty",
		options: {
			display: true
		}
	},
	{
		name: "closing_qty",
		label: "Closing Qty",
		options: {
			display: true
		}
	},
	{
		name: "total",
		label: "Total Qty",
		options: {
			display: true
		}
	},
	{
		name: "checkin_datetime",
		label: "Checkin Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta) => {
				if (moment(value, 'YYYY-MM-DD HH:mm:ss').format("YYYY") === "1970"){
					return "-";
				}
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "checkin_datetime",
		label: "Checkin Time",
		options: {
			display: true,
			customBodyRender: (value, tableMeta) => {
				if (moment(value, 'YYYY-MM-DD HH:mm:ss').format("YYYY") === "1970"){
					return "-";
				}
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('HH:mm A')
				)
			}
		}
	}
]

export default function RIncentive() {
    const classes = useStyles();
    const commonStyle = useCommonStyles();
    const [gState, setGState] = useContext(AppContext);
    var {translation: language, client} = gState;
    const singleton = PersistantData.getInstance();
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const [spList, setSPList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [incentiveList, setIncentiveList] = useState([]);

    const [salespersonID, setSalespersonID] = useState(empty);
	const [salespersonName, setSalespersonName] = useState(empty);
	const [outletID, setOutletID] = useState(empty);
    const [outletName, setOutletName] = useState(empty);
    const [dateRange, setDateRange] = useState({});

	const [spView, setSPView] = useState(false);
	const [outletView, setOutletView] = useState(false);
    const [dateDoc, setDateDoc] = useState(false);
	const [tableHeader, setTableHeader] = useState([]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const getSalesmanList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPList(spList => (_data));
			}
		});
	}

	const getOutletList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_id, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_id,
						value: cust_id,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

    const getDateRange = () => {
		return !_.isEmpty(dateRange) ? moment(dateRange.startDate).format('DD MMMM YYYY') + " / " + moment(dateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

    const SPViewClick = () => {
		setSPView(true);
	}

	const SPViewOnClose = (newValue) => {
		setSPView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setSalespersonID(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setSalespersonName(spname);
		}
	}

    const OutletViewClick = () => {
		setOutletView(true);
	}

	const OutletViewOnClose = (newValue) => {
		setOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setOutletID(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setOutletName(spname);
		}
	}

    const getIncentiveData = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}

		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: salespersonID,
			cust_id: outletID,
			type: 'simple',
			username: cookies(ck_u_username)
		};
		if (gState.company) {
			parameters.company = gState.company;
		}

		const param = axiosURIencode(parameters);

		API_Service.get(`${req_report_incentive}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, message, extraData} = data;
			if (parseInt(result) === 1) {
				setIncentiveList(extraData);
			}else{
				setIncentiveList([]);
				snack(message, 'warning');
			}
		});
	}

	const onGenerateReport = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		const param = axiosURIencode({
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: salespersonID,
			cust_id: outletID,
			type: 'simple',
			username: cookies(ck_u_username)
		});
		API_Service.get(`${req_report_exportincentive}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, message, extraData} = data;
			if (parseInt(result) === 1) {
				window.open(extraData.url, '_blank');
                const delay = setTimeout(() => {
                    API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
                    clearTimeout(delay);
                }, 5000);
			}
		});
	}

    return (
        <div>
            <div className={classes.viewInRow}>
                <Button
					style={{width: 300}}
					variant="outlined"
					onClick={() => SPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{salespersonName ? _.truncate(salespersonName, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<div style={{width: 10}}/>
				<Button
					style={{width: 300}}
					variant="outlined"
					onClick={() => OutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{outletName ? _.truncate(outletName, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<div style={{width: 10}}/>
				<Button
					style={{width: 300}}
					variant="outlined"
					onClick={() => setDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getDateRange()}
					</Typography>
				</Button>
				<div style={{width: 10}}/>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getIncentiveData()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
            </div>
			<div style={{height: 20}}/>
			{
				incentiveList.length > 0 && (
					<MUIDataTable
						title={language.incentive}
						data={incentiveList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<React.Fragment>
									<Tooltip title={'Generate Report'}>
										<IconButton
											edge={'end'}
											color={'primary'}
											onClick={() => onGenerateReport()} aria-label="addnew">
											<AssessmentIcon/>
										</IconButton>
									</Tooltip>
								</React.Fragment>
						}}
					/>
				)
			}

			<VirtualListCB
				open={spView}
				setClose={data => SPViewOnClose(data)}
				title={language.salesperson}
				data={spList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={outletView}
				setClose={data => OutletViewOnClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setDateRange(range);
					setDateDoc(false)
				}}
				setNegative={() => {
					setDateDoc(false)
				}}
				setClose={() => {
					setDateDoc(false)
				}}
				open={dateDoc}
				message={language.date_range}
				positive={language.ok}
			/>
        </div>
    )
}
