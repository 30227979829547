import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {lighten, makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ReorderIcon from "@material-ui/icons/Reorder";
import {Chip, FormControl, IconButton, Tooltip, Typography} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import UndoIcon from '@material-ui/icons/Undo';
import moment from 'moment';
import SearchBox from "../SearchBox";
import jstring from "@easytech-international-sdn-bhd/jstring";
import {isEmpty} from "lodash";

function EnhancedTableHead(props){
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableHeadCell } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell align="left">&nbsp;</TableCell>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all' }}
					/>
				</TableCell>

				{ tableHeadCell.length > 0 ?
					tableHeadCell.map((headCell) => (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? 'right' : 'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							{headCell.label}
							{/* <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                {headCell.label}
                {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
                </TableSortLabel> */}
						</TableCell>
					)) : null}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	tableHeadCell : PropTypes.array.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: 1,
	},
}));

function EnhancedTableToolbar(props){
	const classes = useToolbarStyles();
	const { numSelected, tableTitle, moveCount, onUpdateSequence, onUpdateStatus,onSearch } = props;
	const [open, setOpen] = useState(false);
	const [status, setStatus] = useState(1);
	const onChange = (e) => {
		setStatus(e.target.value);
	}

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} row(s) selected
				</Typography>
			) : (
				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
					{tableTitle}
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton aria-label="delete"  onClick={()=>onUpdateStatus()}>
						{
							status == 1 ? <DeleteIcon/> : <UndoIcon/>
						}
					</IconButton>
				</Tooltip>
			) : (
				<div>
					<FormControl>
						<div style={{marginTop:8,marginRight:20}}>
							<SearchBox onChangeText={onSearch} text={"Search template"} small={true}/>
						</div>
					</FormControl>
					<Tooltip title="Update">
						<IconButton disabled={moveCount === 0} aria-label="update" onClick={()=>onUpdateSequence()}>
							<CloudUploadIcon/>
						</IconButton>
					</Tooltip>
					{/* <Tooltip title="Add">
                    <IconButton aria-label="add" onClick={()=>onAddTemplate()}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
                <FormControl>
                    <Select
                        style={{marginLeft:3}}
                        margin={'dense'}
                        variant={'outlined'}
                        labelId="active_status-label"
                        id="active_status"
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        value={status}
                        onChange={e => onChange(e)}>
                        <MenuItem value={1}>Active Templates</MenuItem>
                        <MenuItem value={0}>Inactive Templates</MenuItem>
                    </Select>
                </FormControl> */}
				</div>
			)}
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	tableTitle : PropTypes.string.isRequired,
	moveCount : PropTypes.number.isRequired,
	onUpdateSequence: PropTypes.func.isRequired,
	onUpdateStatus: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	container:{
		maxHeight: '70vh',
	}
}));

export default function ItemTemplateEnhancedTable({headCell, title, data, changeSequence, updateStatus}) {
	const classes = useStyles();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('sequence_no');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [moveCount, setMoveCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [localItems, setLocalItems] = useState([]);
	const [templateStatus, onChangeTemplateStatus] = useState(1);
	const [searchRes, setSearchRes] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(()=>{
		setLocalItems(data);
	},[data])

	const handleDragEnd = (result, provided) => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		setLocalItems((prev) => {
			const temp = [...prev];
			const d = temp[result.destination.index];
			temp[result.destination.index] = temp[result.source.index];
			temp[result.source.index] = d;
			return temp;
		});

		setMoveCount(moveCount+1);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = localItems.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};


	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const onSearch = (_query) =>{
		setSearchRes([]);
		setSearchQuery(_query);
		const query = jstring(_query);
		if (query.isValid()){
			const _searchRes = [];
			const tokens = query.toString().split(" ");
			for (let i = 0; i < (localItems || []).length; i++){
				const {dtl_name, dtl_code ,created_by} = localItems[i];
				let resCount = 0;
				for (let j = 0; j < tokens.length; j++){
					if (jstring(dtl_name).searchDeep(tokens[j])){
						resCount++;
					}
					if (jstring(dtl_code).searchDeep(tokens[j])){
						resCount++;
					}
				}
				if (resCount >= tokens.length){
					_searchRes.push(localItems[i]);
				}
			}
			setSearchRes(_searchRes);
		}
	}
	const onUpdateSequence = () =>{
		setSearchQuery("");
		setSearchRes([]);
		changeSequence(dataToShowInTable);
		setMoveCount(0);
	}
	const onUpdateStatus = () =>{
		setSearchQuery("");
		setSearchRes([]);
		updateStatus(selected, templateStatus);
		setSelected([]);
	}
	const isSelected = (id) => selected.indexOf(id) !== -1;
	const dataToShowInTable = (!isEmpty(searchQuery) ? searchRes : localItems) || [];
	const dataLength = dataToShowInTable.length;
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength  - page * rowsPerPage);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar
					numSelected={selected.length}
					tableTitle={title}
					moveCount={moveCount}
					onUpdateSequence={onUpdateSequence}
					onUpdateStatus={onUpdateStatus}
					onSearch={onSearch}
				/>
				<TableContainer className={classes.container}>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={'small'}
						aria-label="enhanced table"
						stickyHeader
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={dataLength}
							tableHeadCell={headCell}
						/>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable" direction="vertical">
								{(droppableProvided) => (
									<TableBody
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
									>
										{dataToShowInTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((item, index) => {
												const isItemSelected = isSelected(item.id);
												return (
													<Draggable
														key={item.id}
														draggableId={item.id}
														index={index}
													>
														{(
															draggableProvided,
															snapshot
														) => {
															return (
																<TableRow
																	ref={draggableProvided.innerRef}
																	{...draggableProvided.draggableProps}
																	style={{
																		...draggableProvided.draggableProps.style,
																		background: snapshot.isDragging
																			? "rgba(245,245,245, 0.75)"
																			: "none"
																	}}
																>
																	<TableCell align="center">
																		{
																			jstring(searchQuery).isValid() ?
																				<div {...draggableProvided.dragHandleProps}/>:
																				<div {...draggableProvided.dragHandleProps}>
																					<ReorderIcon />
																				</div>
																		}
																	</TableCell>
																	<TableCell padding="checkbox">
																		<Checkbox
																			checked={isItemSelected}
																			inputProps={{ 'aria-labelledby': item.id }}
																			onClick={(event) => handleClick(event, item.id)}
																			selected={isItemSelected}
																		/>
																	</TableCell>
																	{
																		item.sequence_no == 0 ?
																			<TableCell>-</TableCell>
																			: <TableCell>{(rowsPerPage * page) +  (index + 1)}</TableCell>
																	}
																	<TableCell component="th" id={item.id} scope="row" padding="none" >{item.dtl_code}</TableCell>
																	<TableCell>{item.dtl_name}</TableCell>
																	<TableCell><Chip label={item.dtl_type}/></TableCell>
																	<TableCell>{item.updated_by}</TableCell>
																	<TableCell>{moment(item.updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')}</TableCell>
																</TableRow>
															);
														}}
													</Draggable>
												);
											})}
										{droppableProvided.placeholder}
										{emptyRows > 0 && (
											<TableRow style={{ height: 33 * 5 }}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								)}
							</Droppable>
						</DragDropContext>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[
						{label: 100, value: 100},
						{label: 140, value: 140},
						{label: 165, value: 165},
						{label: 200, value: 200},
						{label: "All", value: data.length}
					]}
					component="div"
					count={dataToShowInTable.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={(event)=>handleChangeRowsPerPage(event)}
				/>
			</Paper>
		</div>
	);
}
