import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import JString from '@easytech-international-sdn-bhd/jstring';
import { de } from '../../Helper/Helper';
import API_Service, { axiosDefaultConfig } from '../../Services/API_Service';
import { empty, req_token } from '../../Helper/Constants';
import { useSnackbar } from 'notistack';

export default function OutletVisitedInsight() {
    const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	const singleton = PersistantData.getInstance();
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const {enqueueSnackbar} = useSnackbar();
	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const [token, setToken] = useState(empty);
	const [expTime, setExpTime] = useState(empty);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getToken(gState);
		}
	}, [gState.client, language]);

	const getToken = (state) => {
		let formData = new FormData();
		formData.append('username', state.username);
		formData.append('password', state.password);
		API_Service.post(req_token, formData, axiosDefaultConfig(state.client)).then(({data}) => {
			if (data) {
				var {extraData, result, message} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {expiry_timestamp, token} = extraData;
					setToken(token);
					setExpTime(expiry_timestamp);
					snack(message);
				} else {
					snack(message, 'warning');
				}
			}
		}).catch(de);
	}

    const iframe = `https://team.easym.com.my/outlet_visited_insight/${token}`;

	return (
		<div style={{height: '85vh'}}>
			<iframe width="100%" height="100%" src={iframe}></iframe>
		</div>
	)
}
