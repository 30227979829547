import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { empty, req_compt, req_compt_details } from '../../../Helper/Constants';
import API_Service, { axiosURIencode } from '../../../Services/API_Service';
import { makeStyles } from '@material-ui/styles';
import { de } from '../../../Helper/Helper';
import AppContext from '../../../Services/AppContext';

const useStyles = makeStyles((theme) => ({
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	}
}));

export default function AddCompetitor({open, axiosConfig, axiosPut, snack, language, onClose, onReload, edit = null, branchesList = []}) {
    const [gState] = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cmptID, setCmptID] = useState(empty);
    const [oriCmptCode, setOriCmptCode] = useState(empty);
    const [cmptCode, setCmptCode] = useState(empty);
	const [cmptName, setCmptName] = useState(empty);
	const [cmptRemark, setCmptRemark] = useState(empty);
	const [cpmtDescription, setCmptDescription] = useState(empty);
    const [selectedBranch, setSelectedBranch] = useState([]);

    useEffect(() => {
        if(open){
            if (edit && Object.keys(edit).length !== 0) {
                setCmptID(edit.id);
                setCmptCode(edit.cmpt_code);
                setCmptName(edit.cmpt_name);
                setCmptRemark(edit.cmpt_remark);
                setCmptDescription(edit.cmpt_desc);
                setOriCmptCode(edit.cmpt_code);
                if (edit.company) {
                    setSelectedBranch(JSON.parse(edit.company));
                }
            } else {
                if (gState.company) {
                    setSelectedBranch(JSON.parse(gState.company));
                }
            }
        }
    }, [open]);

    const addNewCompetitor = (axiosRequestConfig = axiosConfig) => {
		let formData = new FormData();
		formData.append('cmpt_code', cmptCode);
		formData.append('cmpt_name', cmptName);
		formData.append('cmpt_remark', cmptRemark);
		formData.append('cmpt_desc', cpmtDescription);
        formData.append('company', selectedBranch);

		if(cmptName === empty){
			snack('Please insert competitor name.', 'warning');
		}else{
			API_Service.post(req_compt, formData, axiosRequestConfig).then(({data}) => {
				const {result, message, extraData} = data;
				if (result) {
					snack(language.insert_success);
                    onReload(cmptCode);
					createOnClose();
				} else {
					snack(message, 'warning');
				}
			});
		}
	}

    const editCompetitor = (axiosPutConfig = axiosPut) => {
        if (cmptCode.trim() === empty || oriCmptCode.trim() === empty) {
            snack('Competitor code can not leave blank when editing.', 'warning');
        } else {
            var params = {
                id: cmptID,
                cmpt_code: cmptCode,
                cmpt_name: cmptName,
                cmpt_remark: cmptRemark,
                cmpt_desc: cpmtDescription,
                ori_cmpt_code: oriCmptCode,
                company: selectedBranch,
            };
            params = axiosURIencode(params);
            API_Service.put(req_compt_details, params, axiosPutConfig).then(({data}) => {
                var {result, message, extraData} = data;
                if (result == 1) {
                    snack(message);
                    createOnClose();
                    onReload(cmptCode);
                } else {
                    snack(message, 'error');
                }
            }).catch((err) => de(err.message));
        }
        
    }

    const createOnClose = () => {
        setCmptID(empty);
		setCmptCode(empty);
		setCmptName(empty);
		setCmptRemark(empty);
		setCmptDescription(empty);
        setOriCmptCode(empty);

        onClose();
	}

	return (
        <Dialog fullScreen={fullScreen} aria-labelledby="simple-dialog-title" onClose={() => createOnClose()} open={open}>
            <DialogTitle id="simple-dialog-title">
                {cmptID !== empty ? language.edit_competitor : language.add_competitor}
            </DialogTitle>
            <div style={{width: 600, padding: theme.spacing(2)}}>
                <div className={classes.viewInRowSpace}>
                    <TextField
                        id="input-cmpt-code"
                        style={{width: '48%', height: 50}}
                        inputProps={{maxLength: 20}}
                        label={language.competitor_code}
                        value={cmptCode}
                        // disabled={currentCustomer != null ? true : false}
                        onChange={(e) => setCmptCode(e.target.value)}
                    />
                    <TextField
                        id="input-cmpt-name"
                        style={{width: '48%', height: 50}}
                        label={language.competitor_name}
                        value={cmptName}
                        onChange={(e) => setCmptName(e.target.value)}
                    />
                    {
						branchesList.length > 0 && (
							<FormControl style={{width: '48%', height: 50}}>
								<InputLabel id="demo-simple-select-label">Branch</InputLabel>
								<Select
									disabled={gState.company}
									value={selectedBranch}
									onChange={(e) => {
										setSelectedBranch(e.target.value);
										// setEdited(true);
									}}
									multiple
								>
									{branchesList.map((data) => (
										<MenuItem
											value={data.value}
											key={data.value}
										>
											{data.name.toUpperCase()}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)
					}
                    <TextField
                        id="input-cmpt-remark"
                        style={{width: '100%', height: 50}}
                        label={language.remark}
                        value={cmptRemark}
                        onChange={(e) => setCmptRemark(e.target.value)}
                    />
                    <TextField
                        id="input-cmpt-description"
                        style={{width: '100%', height: 100}}
                        multiline
                        rows={4}
                        label={language.description}
                        value={cpmtDescription}
                        onChange={(e) => setCmptDescription(e.target.value)}
                    />
                </div>
                <div style={{height: 20}}/>
                <div style={{display: "flex"}}>
                    <Button
                        style={{marginLeft: 'auto'}}
                        variant="outlined"
                        onClick={() => {
                            if(cmptID !== empty){
                                editCompetitor();
                            }else{
                                addNewCompetitor();
                            }
                        }}
                        color={'primary'}>
                        {cmptID !== empty ? language.update : language.add}
                    </Button>
                </div>
                <div style={{height: 20}}/>
            </div>
        </Dialog>
	)
}