import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import 'mapbox-gl/dist/mapbox-gl.css';
import IconButton from "@material-ui/core/IconButton";
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import {Button, Chip, DialogContent, Grid, Paper} from '@material-ui/core';
import {req_stock_brand_item, req_stock_def, req_stock_no_cat} from '../../Helper/Constants';
import MUIDataTable from 'mui-datatables';
import VirtualListCB from '../VirtualListCB';
import EditBrand from '../EditBrand';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from "@material-ui/core/Tooltip";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	appBar: {
		position: 'relative',
	},
	root: {
		flexGrow: 1
	},
	grid: {
		marginTop: 10,
		paddingLeft: 20,
		paddingRight: 20
	},
	dlgContent: {margin: -20, marginTop: -10},
	paper: {
		padding: 10
	},
	paperMaxHeight: {
		padding: 10,
		maxHeight: 120
	},
	row: {
		flexDirection: 'row'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function BrandDetails({
										 item: currentItem,
										 open,
										 setClose,
										 language,
										 onUpdateBrand,
										 onSnack,
										 integration
									 }) {
	const [gState] = useContext(AppContext);
	const classes = useStyles();
	const [axiosConfig, setAxiosConfig] = useState({});
	const [item, setItem] = useState(currentItem);
	const [openBrandEdit, setOpenBrandEdit] = useState(false);
	const [brandItemData, setBrandItemData] = useState([]);
	const [newItemListOpen, setNewItemListOpen] = useState(false);
	const [newItemList, setNewItemList] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
		}
	}, [gState?.client]);

	useEffect(() => {
		if (!_.isEmpty(currentItem) && !_.isEmpty(axiosConfig)) {
			loadBrandItemData();
			loadNewBrandItemList();
		}
	}, [currentItem, axiosConfig]);

	const onClose = () => {

		setClose();
	}

	const editBrand = (data) => {
		setOpenBrandEdit(false);
		if (data) {
			setItem(data[0]);
			onUpdateBrand(data[0]);
		}
	}

	const loadBrandItemData = (config = axiosConfig) => {
		API_Service.get(`${req_stock_no_cat}?brand_id=${item.brand_id}`, _.isEmpty(config) ? axiosConfig : config).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				onSnack(language.no_data, 'warning');
			} else {
				var {brand, stock} = extraData;
				setBrandItemData(() => (stock));
			}
		});
	}

	const loadNewBrandItemList = (config = axiosConfig) => {
		API_Service.get(req_stock_def, config).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				const {stock} = extraData;
				var _arr = [];
				for (let i = 0; i < stock.length; i++) {
					const {product_code, product_name, product_status, brand_id} = stock[i];
					if (product_status === '1' && brand_id === '0') {
						_arr.push({
							name: product_name,
							secondary: product_code,
							value: product_code,
							status: parseInt(product_status)
						});
					}else{
						_arr.push({
							name: product_name,
							secondary: product_code,
							value: product_code,
							hide: true,
							status: parseInt(product_status)
						});
					}
				}

				if (_arr.length > 0) {
					setNewItemList(newItemList => (_arr));
				} else {
					onSnack(language.no_data, 'warning');
				}
			}
		});
	}

	const brandViewClose = (newValue) => {
		setNewItemListOpen(false);
		if (newValue) {
			if (newValue['name'] != 'reset') {
				var formdata = new FormData();
				formdata.append('brand_id', item.brand_id);
				formdata.append('product', JSON.stringify(newValue));

				API_Service.post(req_stock_brand_item, formdata, axiosConfig).then(({data}) => {
					var {result, extraData} = data;
					result = JString(result).toNumber();
					if (result === 1) {
						var {stock} = extraData;
						setBrandItemData(() => (stock ? stock : []));
						loadNewBrandItemList();
						onSnack && onSnack(language.record_updated, 'success');
					} else {
						onSnack && onSnack(language.operation_fail, 'error');
					}
				});
			}
		} else {

		}
	}

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.brand_details}
						</Typography>
					</Toolbar>
				</AppBar>
				<DialogContent className={classes.dlgContent}>
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={4}>
							<Paper className={classes.paper}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg direction="column" container>
										<Typography variant="subtitle1" className={classes.textleft}>
											{item.brand_code}
										</Typography>
										<Typography variant="inherit" component="h4" className={classes.textleft}>
											{item.brand_name}
										</Typography>
									</Grid>
									{
										integration === '0' && (
											<Button
												onClick={() => setOpenBrandEdit(true)}
												size="small">
												<EditIcon/>
											</Button>
										)
									}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={8}>
							<BrandItemList
								data={brandItemData}
								language={language}
								gState={gState}
								onEvent={() => loadBrandItemData()}
								onSnack={onSnack}
								setNewItemListOpen={setNewItemListOpen}
								item={item}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<EditBrand
				open={openBrandEdit}
				onClose={(data) => editBrand(data)}
				language={language}
				brand_code={item.brand_code}
				brand_name={item.brand_name}
			/>
			{
				newItemListOpen && (
					<VirtualListCB
						setClose={(d) => brandViewClose(d)}
						open={newItemListOpen}
						title={'New Brand Items'}
						data={newItemList}
						language={language}
						filteration={0}
					/>
				)
			}
		</div>
	)
}


function BrandItemList({data, language, gState, onEvent, onSnack, setNewItemListOpen, item}) {
	const onRowDelete = (row) => {
		const _arr = [];
		for (let i = 0; i < row.length; i++) {
			var {dataIndex} = row[i];
			_arr.push(data[dataIndex]['product_code']);
		}
		var axiosPut = axiosPutConfig(gState.client);
		var params = axiosURIencode({
			product: _arr.join(","),//JSON.stringify(_arr),
			ori_code: item.brand_id
		});
		API_Service.put(req_stock_brand_item, params, axiosPut).then(({data}) => {
			var {result} = data;
			result = JString(result).toNumber();
			if (parseInt(result) === 1) {
				onSnack(language.record_updated, 'success');
			} else {
				onSnack(language.operation_fail, 'error');
			}
			onEvent();
		});
	}
	return (
		<MUIDataTable
			title={language.brand_list}
			data={data || []}
			columns={itemDataCell}
			options={{
				print: false,
				filter: false,
				rowsPerPage: 125,
				rowsPerPageOptions: [125, 140, 165, 200],
				responsive: 'scrollMaxHeight',
				selectableRows: data.length > 0 ? 'multiple' : 'none',
				download: false,
				viewColumns: false,
				onRowsDelete: ({data: row}) => onRowDelete(row),
				setTableProps: () => ({size: 'small'}),
				customToolbar: () =>
					<TemplateTableCustomToolbar
						addBrandItem={() => {
							setNewItemListOpen(true)
						}}
					/>
			}}
		/>
	)
}

const itemDataCell = [
	{
		name: "product_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "product_desc",
		label: "Description",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return <p>{JString(value).trimHtml().str || '-'}</p>;
			}
		}
	},
	{
		name: "product_status",
		label: "Status",
		options: {
			display: true,
			sort: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				const {rowIndex: dataIndex} = tableMeta;
				switch (value) {
					case "1" :
						return (
							<Chip size="small" icon={<DoneIcon/>} label="Active"/>
						);
						break;
					case "0" :
						return (
							<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
						);
						break;
						break;
					default :
						return (null);
						break;
				}
			}
		}
	}
];


function TemplateTableCustomToolbar({addBrandItem}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add Into Brand'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => addBrandItem()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
