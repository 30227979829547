import React, { useContext, useEffect, useState } from 'react';
import { Chip, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { red } from '@material-ui/core/colors';
import JString from '@easytech-international-sdn-bhd/jstring';
import AppContext from '../../Services/AppContext';
import API_Service, { axiosDefaultConfig, axiosPutConfig, axiosURIencode } from '../../Services/API_Service';
import { de, getSetting } from '../../Helper/Helper';
import { empty, req_compt, req_cust_branches, req_module_setting } from '../../Helper/Constants';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';
import PersistantData from '../../Services/PersistantData';
import CompetitorDetails from './CompetitorDetails';
import AddCompetitor from './AddCompetitor';

export default function Competitor() {
	const [gState] = useContext(AppContext);

	const headCells = [
		{
			name: "cmpt_code",
			label: "Competitor Code",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "cmpt_name",
			label: "Competitor Name",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "company",
			label: "Branch",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					if (value) {
						const branch = JSON.parse(value);
						return branch.map((b)=><Chip size="small" label={b.toUpperCase()}/>);
					}
					return '';
				}
			}
		},
		{
			name: "created_at",
			label: "Created Date",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
					)
				}
			}
		},
		{
			name: "created_by",
			label: "Created By",
			options: {
				display: false,
				filter: false,
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowData} = tableMeta;
					var customerStatus = parseInt(value);
					const onChangeStatus = () => {
						// changeCustomerStatus(rowData[0]);
						customerStatus = customerStatus === 1 ? 0 : 1;
					}
					if (customerStatus === 1) {
						return (
							<IconButton
								onClick={() => {
									changeCompetitorStatus(rowData);
								}}>
								<LockOpenIcon/>
							</IconButton>
						);
					} else if (customerStatus === 0) {
						return (
							<IconButton
								onClick={() => {
									changeCompetitorStatus(rowData);
								}}>
								<LockIcon htmlColor={red[500]}/>
							</IconButton>
						);
					}
				}
			}
		},
	];

	const [axiosConfig, setAxiosConfig] = useState({});
	const [axiosPut, setAxiosPutConfig] = useState({});
	const [activeComptList, setActiveComptList] = useState([]);
	const [inactiveComptList, setInactiveComptList] = useState([]);
	const [integration, setIntegration] = useState(1);
	const {enqueueSnackbar} = useSnackbar();
	const [showActive, setShowActive] = useState(true);
	const singleton = PersistantData.getInstance();
	var {translation: language} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang();
	}
	// create dialog
	const [createDialog, setCreateDialog] = useState(false);
	//details dialog
	const [detailsDialog, setDetailsDialog] = useState(false);
	const [competitorCode, setCompetitorCode] = useState(empty);
	const [branchesList, setBranchesList] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			var axiosPut = axiosPutConfig(gState.client);
			setAxiosPutConfig(() => axiosPut);
			setAxiosConfig(() => (axiosGetConfig));
			loadConfig(axiosGetConfig);
			loadCompetitorList(axiosGetConfig);
			loadBranchList(axiosGetConfig);
		}
	}, [gState.client]);

	const loadConfig = (axiosRequestConfig) => {
		API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _integration = getSetting(extraData, 'integration', {});
				setIntegration(_integration);
			}
		});
	}

	const loadCompetitorList = (axiosRequestConfig) => {
		var parameters = '';
			if (gState.company) {
				const param = axiosURIencode({
					company: gState.company,
				});
				parameters = `?${param}`;
			}
		API_Service.get(`${req_compt}${parameters}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {active_competitor, inactive_competitor} = extraData;
					setActiveComptList(() => active_competitor);
					setInactiveComptList(() => inactive_competitor);
				}
			}
		}).catch(err => de(err.message, 'customer list'));
	}

	const loadBranchList = (axiosRequestConfig) => {
        API_Service.get(req_cust_branches, axiosRequestConfig).then(({data}) => {
            if (data) {
                var {result, extraData } = data;
                result = JString(result).toNumber();
				var _data = [];
                if (result === 1) {
					for (let i = 0; i < extraData.length; i++) {
						if (gState.company) {
							const company = JSON.parse(gState.company);
							if (company.includes(extraData[i]['value'])) {
								_data.push(extraData[i]);	
							}
						} else {
							_data.push(extraData[i]);
						}
					}
					setBranchesList(_data);
                }
            }
        }).catch(err => de(err.message, 'customer branch list'));
    }

	const changeCompetitorStatus = (rowData) => {
		var params = {
			compt_code: rowData[0],
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt, params, axiosPut).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
				loadCompetitorList(axiosConfig);
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const openDetailDialog = ($cmptCode) => {
		setCompetitorCode($cmptCode);
		setDetailsDialog(true);
	}

	const closeDetailDialog = () => {
		setCompetitorCode(empty);
		setDetailsDialog(false);
	}

	return (
		<div>
			<MUIDataTable
				title={language.competitor}
				data={showActive ? activeComptList : inactiveComptList}
				columns={headCells}
				options={{
					selectableRows: 'none',
					print: false,
					filter: false,
					download: false,
					rowsPerPage: 150,
					rowsPerPageOptions: [150, 300, 600, 900],
					responsive: 'simple',
					setTableProps: () => ({size: 'small'}),
					onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
						if (colIndex !== (headCells.length - 1)) {
							openDetailDialog(showActive ? activeComptList[dataIndex]?.cmpt_code : inactiveComptList[dataIndex]?.cmpt_code);
						}
					},
					customToolbar: () => {
						return (
							<React.Fragment>
								{
									showActive ?
										<Tooltip title={'Show Active'}>
											<IconButton
												onClick={() => {
													setShowActive(false);
												}}>
												<VisibilityOffIcon/>
											</IconButton>
										</Tooltip>
										:
										<Tooltip title={'Show Inactive Only'}>
											<IconButton
												onClick={() => {
													setShowActive(true);
												}}>
												<VisibilityIcon/>
											</IconButton>
										</Tooltip>
								}
								{
									integration === '0' ?
										<Tooltip title={'Add New'}>
											<IconButton
												edge={'end'}
												color={'primary'}
												onClick={() => setCreateDialog(true)} aria-label="addnew">
												<AddIcon/>
											</IconButton>
										</Tooltip>
										:
										''
								}
							</React.Fragment>
						);
					},
				}}
			/>

			<AddCompetitor
				open={createDialog}
				axiosConfig={axiosConfig}
				axiosPut={axiosPut}
				snack={snack}
				language={language}
				onClose={() => setCreateDialog(false)}
				onReload={() => loadCompetitorList(axiosConfig)}
				branchesList={branchesList}
			/>

			<CompetitorDetails
				competitor_code={competitorCode}
				open={detailsDialog}
				setClose={() => closeDetailDialog()}
				axiosConfig={axiosConfig}
				axiosPut={axiosPut}
				integration={integration}
				snack={snack}
				language={language}
				onReload={() => loadCompetitorList(axiosConfig)}
				branchesList={branchesList}
			/>
		</div>
	)
}