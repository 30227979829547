import React from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {skeletonBack, skeletonDone} from '../../Helper/Constants';

export default function ShimmerLoading({blocks}) {
	return (
		<SkeletonTheme color={skeletonBack} highlightColor={skeletonDone}>
			{
				blocks && blocks.map((item, index) => {
					return (
						<Skeleton key={String(index)} count={parseInt(item.count)} height={parseInt(item.height)}/>
					)
				})
			}
		</SkeletonTheme>
	)
}
