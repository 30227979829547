import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularProgressWithLabel({value, targetValue}) {
	const label = `${value}/${targetValue}`;
	const completion = value === 0 ? 0 : (value / targetValue) * 100;

	return (
		<Box position="relative" display="inline-flex" style={{marginBottom: 5}}>
			<CircularProgress size={35} variant="determinate" value={completion}/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center">
				<p style={{fontSize: 10}}>{label}</p>
			</Box>
		</Box>
	);
}
