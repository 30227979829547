import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import { de } from '../../Helper/Helper';
import { Popup } from 'react-mapbox-gl';
import { Typography, makeStyles } from '@material-ui/core';

const styles = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
    },
    {
        elementType: 'geometry',
        stylers: [{ color: '#242f3e' }],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#242f3e' }],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#746855' }],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
    },
    {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
    }
];
const yellow = "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";

const useStyles = makeStyles((theme) => ({
    div: {
        '& > div': {
            position: 'relative !important',
        },
    },
}));

const GoogleMap = ({
    latlongList,
    height = '100%',
    width = '100%',
    mapHeight = '80vh',
    mapWidth = '100%',
    showCheckinData,
    ...others
}) => {
    const classes = useStyles();
    const [currentLocation, setCurrentLocation] = useState({ lat: 3.142540, lng: 101.621420 });
    const [activeMarker, setActiveMarker] = useState(null);
    const [infoWindowVisible, setInfoWindowVisible] = useState(false);
    const [markerLocation, setMarkerLocation] = useState({lat: 3.142540, lng: 101.621420});
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);

    // useEffect(() => {
    //     console.log('Map and markers initialized:', window.google);
    // }, [window.google]);

    const onMarkerMouseover = (props, marker, e) => {
        setActiveMarker(props);
        setMarkerLocation({lat: props.position.lat, lng: props.position.lng});
        setLatitude(props.position.lat);
        setLongitude(props.position.lng);
        setInfoWindowVisible(true);
    };

    const GoogleMapComponent = () => (
        <Map
            google={window.google}
            zoom={14}
            style={{ height: mapHeight, width: mapWidth }}
            initialCenter={currentLocation}
            styles={styles}
        >
            <Marker 
                position={currentLocation} 
                icon={{url: yellow}}
                name={'Current location'}
            />
            {/* {latlongList.map((item, index) => (
                <Marker
                    key={index}
                    position={{ lat: item.loc[1], lng: item.loc[0] }}
                    icon={{url: yellow}}
                    onClick={onMarkerMouseover}
                    // onClick={() => showCheckinData(item.cust_code)}
                />
            ))}
            
            <InfoWindow
                // marker={activeMarker}
                // position={currentLocation}
                onOpen={(e)=>de("betty-999", e)}
                lng={longitude}
                lat={latitude}
                visible={infoWindowVisible}
                onClose={() => setInfoWindowVisible(false)}
            >
                <div>
                    <h4>Info Window Content</h4>
                </div>
            </InfoWindow> */}
        </Map>
    );

    useEffect(() => {
        position();
    }, [latlongList]);

    const position = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude }),
            (err) => console.error(err)
        );
    };

    return (
        <div className={classes.div} style={{ height: height, width: width }}>
            <GoogleMapComponent />
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyD3Glah-NDcJ0z7D4HtnO6QRMo23SSAyW8', // Replace with your API key
})(GoogleMap);
