import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

export const usePaperStyles = makeStyles(theme => ({
	lottieStyle: {
		flex: 1,
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '10%'
	},
	viewInRowPad: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	viewInRowSpaceWOpadding: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	viewInRowWOpadding: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(1)
	},
	viewInRowDialog: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		justifyContent: 'flex-start'
	},
	viewInRowDialogEnd: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		justifyContent: 'flex-end'
	},
	viewInRowEqualPad: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1),
		width: '100%'
	},
	paperView: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		'& > *': {
			width: '100%'
		},
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
	},
	dialogPaper: {
		minWidth: 600,
		padding: theme.spacing(1),
		paddingRight: theme.spacing(2),
		zIndex: theme.zIndex + 1,
		alignItems: 'center'
	},
	paperViewWOspace: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			width: '100%'
		},
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
	},
	viewPadding: {
		padding: theme.spacing(2)
	},
	paperViewMin: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		'& > *': {
			width: '30%'
		},
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		marginBottom: 10
	}
}));
