import React, {useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import AppContext from '../../Services/AppContext';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import PersistantData from '../../Services/PersistantData';
import {
	mapbox_key,
    req_cust,
    req_cust_category,
    req_cust_category_status,
    req_cust_chain,
    req_cust_chain_status,
    req_cust_status,
    req_mobile_module_setting,
    req_module_setting,
    req_salesman,
    req_cust_type,
    req_cust_type_status,
	req_cust_branches,
	req_cust_area,
	req_cust_area_status
} from '../../Helper/Constants';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Chip, Typography} from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {grey, red} from '@material-ui/core/colors';
import MUIDataTable from 'mui-datatables';
import _ from 'lodash';
import useStateCallback from '../../Services/StateCallback';
import ShimmerLoading from '../../Components/ShimmerLoading';
import moment from 'moment';
import CustomerDetails from '../../Components/CustomerDetails';
import {de, getSetting} from '../../Helper/Helper';
import Tooltip from "@material-ui/core/Tooltip";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddIcon from '@material-ui/icons/Add';
import AddCustomer from '../../Components/AddCustomer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {BottomNavigation, BottomNavigationAction, IconButton, Tab, Tabs} from '@material-ui/core';
import {useCommonStyles} from '../../Styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Button from '@material-ui/core/Button';
import AddCustCategory from '../../Components/AddCustCategory';
import AddCustChain from '../../Components/AddCustChain';
import CustCategoryDetails from '../../Components/CustCategoryDetails';
import CustChainDetails from '../../Components/CustChainDetails';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/Map';
import { pure } from 'recompose';
import ReactMapboxGl, {Marker, Popup} from 'react-mapbox-gl';
import RoomIcon from '@material-ui/icons/Room';
import AddCustType from '../../Components/AddCustType';
import CustTypeDetails from '../../Components/CustTypeDetails';
import GoogleMap from '../../Components/GoogleMap';
import AddAreaType from '../../Components/AddAreaType';
import CustAreaDetails from '../../Components/CustAreaDetails';

const useStyles = makeStyles((theme) => ({
	sectionTitle: {
		color: grey[500],
		marginLeft: theme.spacing(1),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	left: {
		marginLeft: theme.spacing(1)
	},
	fontGrey: {
		color: 'grey'
	},
	marginTop: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3)
	}
}));

export default function Customer() {
	const classes = useStyles();
	const commonStyles = useCommonStyles();
	const [gState] = useContext(AppContext);

	const [config, setConfig] = useState({
		orderType: []
	});
	const [choosenCustType, setChoosenCustType] = useState({});
	const [choosenCustArea, setChoosenCustArea] = useState({});
	const [modules,setModules] = useState({});
	const [customerGroups, setCustomerGroups] = useState({});
	const [customerList, setCustomerList] = useState([]);
	const [inactiveCustList, setInactiveCustList] = useState([]);
	const [loginList, setLoginList] = useState([]);
	// const [customerIndexedList, setCustomerIndexedList] = useStateCallback({}, () => {
	// });
	// const [inactiveCustomerIndexedList, setInactiveCustomerIndexedList] = useStateCallback({}, () => {
	// });
	const [chosenCustomer, setChosenCustomer] = useState({});
	const [showCustomerDetails, toggleCustomerDetails] = useState(false);
	const [integration, setIntegration] = useState(1);

	const [axiosConfig, setAxiosConfig] = useState({});
	const [axiosPut, setAxiosPutConfig] = useState({});

	const {enqueueSnackbar} = useSnackbar();
	const [createDialog, showCreateDialog] = useState(false);

	const [showInactive, setShowInactive] = useState(false);

	const [currentPage,setCurrentPage] = useState(0);
	const [tabPosition, setTabPosition] = useState(0);
	const [customerChainList, setCustomerChainList] = useState([]);
	const [customerCategoryList, setCustomerCategoryList] = useState([]);
    const [customerTypeList, setCustomerTypeList] = useState([]);
	const [customerAreaList, setCustomerAreaList] = useState([]);
	const [newCustChainView, setNewCustChainView] = useState(false);
	const [newCustCategoryView, setNewCustCategoryView] = useState(false);
    const [newCustTypeView, setNewCustTypeView] = useState(false);
	const [newCustAreaView, setNewCustAreaView] = useState(false);
	const [showCustCategoryDetails, toggleCustCategoryDetails] = useState(false);
	const [chosenCustCategory, setChosenCustCategory] = useState(-1);
	const [showCustChainDetails, toggleCustChainDetails] = useState(false);
    const [showCustTypeDetails, toggleCustTypeDetails] = useState(false);
	const [showCustAreaDetails, toggleCustAreaDetails] = useState(false);
	const [chosenCustChain, setChosenCustChain] = useState(-1);
	const [activeCustCategoryList, setActiveCustCategoryList] = useState([]);
	// const [inactiveCustCategoryList, setInactiveCustCategoryList] = useState([]);
	const [activeCustChainList, setActiveCustChainList] = useState([]);
    const [activeCustTypeList, setActiveCustTypeList] = useState([]);
	const [activeCustAreaList, setActiveCustAreaList] = useState([]);
	// const [inactiveCustChainList, setInactiveCustChainList] = useState([]);
	const [salespersonList, setSalespersonList] = useState([]);
	const [mobileCustomization, setMobileCustomization] = useState({});
	const [branchesList, setBranchesList] = useState([]);

    const [customerTable, setCustomerTable] = useState({
        customerIndexedList: {},
        inactiveCustomerIndexedList: {},
        customerList: [],
        inactiveCustList: [],
        salespersonList: [],
        mapLatlongList: []
    });

	const singleton = PersistantData.getInstance();
	var {translation: language} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang();
	}

	const theme = useTheme();
	const lightTheme = theme.palette.type === 'light';
	const [latlongList, setLatlongList] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			var axiosPut = axiosPutConfig(gState.client);
			setAxiosPutConfig(axiosPut);
			setAxiosConfig(() => (axiosGetConfig));
			loadCustomerList(axiosGetConfig);
			loadLoginList(axiosGetConfig);
			loadConfig(axiosGetConfig);
			loadCustCategoryList(axiosGetConfig);
			loadCustChainList(axiosGetConfig);
            loadCustTypeList(axiosGetConfig);
			loadBranchList(axiosGetConfig);
			loadMobileModule(axiosGetConfig);
			loadCustAreaList(axiosGetConfig);
		}
	}, [gState.client]);

	const loadMobileModule = (config = axiosConfig) => {
		API_Service.get(req_mobile_module_setting, config).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const {module} = extraData;
				const _customization = module.find((content) => content.module === 'app_customization');
				if (_customization) {
					const {status} = _customization;
					setMobileCustomization(JSON.parse(status));
				}
			}
		});
	}

	const loadConfig = (axiosRequestConfig) => {
		API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customer', {});
				const _customerGroups = getSetting(extraData, 'customer_groups', {});
				const _integration = getSetting(extraData, 'integration', {});
                const _modules = getSetting(extraData, 'modules', {});
				// de(_customerGroups, '--grps--', extraData)
				setConfig(_config);
				setCustomerGroups(_customerGroups);
				setIntegration(_integration);
                setModules(_modules);
			}
		});
	}

	const loadLoginList = (axiosRequestConfig) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData: api_Sp} = data;
				if (result) {
					var _data = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const {staff_code, name, login_id, company} = api_Sp[idx];
						_data.push({staff_code, name, login_id, company: company ? company : null});
					}
					setLoginList(loginList => (_data));
				}
			}
		}).catch(err => de(err.message, 'login list'));
	}

    const loadCustomerList = (axiosRequestConfig) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_cust}${parameters}`, axiosRequestConfig).then(({data}) => {
            if (data) {
                var {result, extraData: apiCust} = data;
                var {inactive_customer, customer, salesperson_list} = apiCust;
                // de(apiCust,'CUST');
                result = JString(result).toNumber();
                if (result === 1) {
                    var _data = [];
                    var _inact_data = [];
                    var indexed = {};
                    var inact_indexed = {};
                    const list = [];
                    for (let idx = 0; idx < customer.length; idx++) {
                        var obj = customer[idx];
                        const {
                            billing_address1,
                            billing_address2,
                            billing_address3,
                            billing_address4,
                            billing_city,
                            billing_state,
                            billing_zipcode,
                            billing_country,
                            shipping_address1,
                            shipping_address2,
                            shipping_address3,
                            shipping_address4,
                            shipping_city,
                            shipping_state,
                            shipping_zipcode,
                            shipping_country,
                            cust_code
                        } = obj;
                        var address = JString(billing_address1).toStr() +
                            JString(billing_address2).toStr() +
                            JString(billing_address3).toStr() +
                            JString(billing_address4).toStr() + ' ' +
                            JString(billing_city).toStr() + ' ' +
                            JString(billing_state).toStr() + ' ' +
                            JString(billing_zipcode).toStr() + ' ' +
                            JString(billing_country).toStr();
                        var shipping = JString(shipping_address1).toStr() +
                            JString(shipping_address2).toStr() +
                            JString(shipping_address3).toStr() +
                            JString(shipping_address4).toStr() + ' ' +
                            JString(shipping_city).toStr() + ' ' +
                            JString(shipping_state).toStr() + ' ' +
                            JString(shipping_zipcode).toStr() + ' ' +
                            JString(shipping_country).toStr();

                        obj.billing_address = address;
                        obj.shipping_address = shipping;

                        _data.push(obj);
                        indexed[`${cust_code}`] = obj;
                        list.push({cust_code: cust_code, cust_company_name: obj.cust_company_name, loc: [obj.longitude, obj.latitude], marker_color: obj.marker_color});
                    }
                    for (let idx = 0; idx < inactive_customer.length; idx++) {
                        var inact_obj = inactive_customer[idx];
                        const {
                            billing_address1,
                            billing_address2,
                            billing_address3,
                            billing_address4,
                            billing_city,
                            billing_state,
                            billing_zipcode,
                            billing_country,
                            shipping_address1,
                            shipping_address2,
                            shipping_address3,
                            shipping_address4,
                            shipping_city,
                            shipping_state,
                            shipping_zipcode,
                            shipping_country,
                            cust_code
                        } = inact_obj;
                        var inact_address = JString(billing_address1).toStr() +
                            JString(billing_address2).toStr() +
                            JString(billing_address3).toStr() +
                            JString(billing_address4).toStr() + ' ' +
                            JString(billing_city).toStr() + ' ' +
                            JString(billing_state).toStr() + ' ' +
                            JString(billing_zipcode).toStr() + ' ' +
                            JString(billing_country).toStr();
                        var inact_shipping = JString(shipping_address1).toStr() +
                            JString(shipping_address2).toStr() +
                            JString(shipping_address3).toStr() +
                            JString(shipping_address4).toStr() + ' ' +
                            JString(shipping_city).toStr() + ' ' +
                            JString(shipping_state).toStr() + ' ' +
                            JString(shipping_zipcode).toStr() + ' ' +
                            JString(shipping_country).toStr();

                        inact_obj.billing_address = inact_address;
                        inact_obj.shipping_address = inact_shipping;

                        _inact_data.push(inact_obj);
                        inact_indexed[`${cust_code}`] = inact_obj;
                        list.push({cust_code: cust_code, cust_company_name: inact_obj.cust_company_name, loc: [inact_obj.longitude, inact_obj.latitude], marker_color: inact_obj.marker_color});
                    }
                    // de("loadCustomerList: I FINISHED");
                    /* setCustomerIndexedList(() => (indexed));
                    setInactiveCustomerIndexedList(() => (inact_indexed));
                    setCustomerList(() => (_data));
                    setInactiveCustList(() => (_inact_data));
                    setSalespersonList(()=>(salesperson_list));
                    setLatlongList(list); */
					// de("betty-customerList", _data.length);
                    setCustomerTable(() => ({
                        customerIndexedList: indexed,
                        inactiveCustomerIndexedList: inact_indexed,
                        customerList: _data,
                        inactiveCustList: _inact_data,
                        salespersonList: salesperson_list,
                        mapLatlongList: list
                    }));
					// setCustomerTable(prevState=>({...prevState, customerList: _data}));
					// de("betty-customerList2", customerTable);
                }
            }
        }).catch(err => de(err.message, 'customer list'));
    }

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

    const changeCustomerStatus = (cust_code) => {
        var params = {
            cust_code: cust_code,
        };
        params = axiosURIencode(params);
        API_Service.put(req_cust_status, params, axiosPut).then(({data}) => {
            var {result, message, extraData} = data;
            if (result == 1) {
                for (let ii = 0; ii < customerTable.customerList.length; ii++) {
                    const {cust_code: toFindCode} = customerTable.customerList[ii];
                    if (JString(toFindCode).isEqualDeep(cust_code)) {
                        customerTable.customerList[ii].customer_status = customerTable.customerList[ii].customer_status == 1 ? 0 : 1;
                    }
                }
                onUpdateCustomer(extraData);
                // setCustomerList(customerList);
                setCustomerTable(prevState=>({...prevState, customerList: customerTable.customerList}));
                snack(message);
            } else {
                snack(message, 'error');
            }
        }).catch((err) => de(err.message));
    }

	const onUpdateCustomer = (newItem) => {
		var newcust = 1;
		if (newItem.length > 0) {
            var data = customerTable.customerList;
			var address = JString(newItem[0]['billing_address1']).toStr() +
				JString(newItem[0]['billing_address2']).toStr() +
				JString(newItem[0]['billing_address3']).toStr() +
				JString(newItem[0]['billing_address4']).toStr() +
				JString(newItem[0]['billing_city']).toStr() +
				JString(newItem[0]['billing_state']).toStr() +
				JString(newItem[0]['billing_zipcode']).toStr() +
				JString(newItem[0]['billing_country']).toStr();
			newItem[0]['billing_address'] = address;
			for (var i = 0; i < data.length; i++) {
				if (data[i]['cust_id'] === newItem[0]['cust_id']) {
					data[i] = newItem[0];
					newcust = 0;
				}
			}
			if (newcust === 1) {
				data.push(newItem[0]);
			}
            setCustomerTable(prevState=>({...prevState, customerList: data}));
		}
		loadCustomerList(axiosDefaultConfig(gState.client));
	}

	const custClicked = (cust_code) => {
        var cust = showInactive ? _.get(customerTable.inactiveCustomerIndexedList, cust_code) : _.get(customerTable.customerIndexedList, cust_code);
		setChosenCustomer(() => (cust));
		toggleCustomerDetails(() => (true));
	}

	const headCells = [
		{
			name: "cust_code",
			label: "Code",
			options: {
				display: true,
				filterType: 'textField'
			}
		},
		{
			name: "cust_company_name",
			label: "Name",
			options: {
				display: true,
				filterType: 'textField'
			}
		},
		{
			name: "company",
			label: "Branch",
			options: {
				display: true,
				customBodyRender: (value) => {
					if (value) {
						const branch = JSON.parse(value);
						return branch.map((b, index)=><Chip key={index} size="small" label={b.toUpperCase()}/>);
					}
					return '';
				}
			}
		},
		{
			name: "created_date",
			label: "Created Date",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
					)
				}
			}
		},
		{
			name: "cust_tel",
			label: "Phone",
			options: {
				filter: false,
				display: false
			}
		},
		{
			name: "billing_address",
			label: "Address",
			options: {
				display: true,
				customBodyRender: (value) => {
					return _.truncate(value, {length: 40});
				},
				filterType: 'textField'
			}
		},
		// {
		// 	name: "shipping_address",
		// 	label: "Shipping",
		// 	options: {
		// 		display: false,
		// 		filter: false,
		// 		customBodyRender: (value) => {
		// 			return _.truncate(value, {length: 25});
		// 		}
		// 	}
		// },
		// {
		// 	name: "termcode",
		// 	label: "Term",
		// 	options: {
		// 		filter:false,
		// 		display: false
		// 	}
		// },
		{
			name: "cust_category_name",
			label: "Category",
			options: {
				display: false
			}
		},
		{
			name: "cust_chain_name",
			label: "Chain",
			options: {
				display: false
			}
		},
        {
            name: "cust_type_name",
            label: "Type",
            options: {
                display: true
            }
        },
		{
            name: "area_name",
            label: "Area",
            options: {
                display: true
            }
        },
		{
			name: "billing_city",
			label: "Region",
			options: {
				display: false
			}
		},
		{
			name: "agent",
			label: "Agent",
			options: {
				display: true,
				filterType: 'custom',
				customBodyRender: (value) => {
					return _.truncate(value, {length: 25});
				},
				filterOptions: {
					logic: (agent, filters) => {
						//if (filters.length) return !filters.includes(agent);
						if (filters.length){
							for (let i = 0; i < filters.length; i++) {
								const element = filters[i];
								if(agent.indexOf(element) == -1){
									return true;
								}
							}
						}
					  return false;
					},
					display: (filterList, onChange, index, column) => {

					  return (
						<FormControl>
						  <InputLabel htmlFor="select-multiple-chip">
							Agent
						  </InputLabel>
						  <Select
							multiple
							value={filterList[index]}
							renderValue={selected => selected.join(", ")}
							onChange={event => {
							  filterList[index] = event.target.value;
							  onChange(filterList[index], index, column);
							}}
						  >

							{
                                customerTable.salespersonList.length > 0 ?
                                    customerTable.salespersonList.map(item => (
								<MenuItem key={item} value={item}>
									<Checkbox
									color="primary"
									checked={filterList[index].indexOf(item) > -1}
									/>
									<ListItemText primary={item} />
								</MenuItem>
								)) : null
							}
						  </Select>
						</FormControl>
					  );
					}
				}
			}
		},
		// {
		// 	name: "current_balance",
		// 	label: "Outstanding",
		// 	options: {
		// 		display: false,
		// 		filter: false,
		// 		customBodyRender: (value) => {
		// 			return JString(value).inCurrency();
		// 		}
		// 	}
		// },
		{
			name: "updated_at",
			label: "Last Updated",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
					)
				}
			}
		},
		{
			name: "created_by",
			label: "Created By",
			options: {
				filter:false,
				display: false
			}
		},
		{
			name: "updated_by",
			label: "Last Updated By",
			options: {
				filter:false,
				display: true
			}
		},
		{
			name: "tmplt_data",
			label: "Template",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					if(value && value.length > 0){
                        return(
                            <>
                                {
                                    value.map((data) => {
                                        if(data.tmplt_id){
                                            return(<Chip key={String(data.tmplt_id).concat("---template--chip")} size="small" style={{backgroundColor: "grey"}} label={data.tmplt_name}/>)
                                        }
                                    })
                                }
                            </>
                        );
                    }else{
                        return ("-");
                    }
				}
			}
		},
		{
			name: "customer_status",
			label: "Status",
			options: {
				display: true,
				sort: false,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowData} = tableMeta;
					var customerStatus = parseInt(value);
					const onChangeStatus = () => {
						changeCustomerStatus(rowData[0]);
						customerStatus = customerStatus === 1 ? 0 : 1;
					}
					if (customerStatus === 1) {
						return (
							<IconButton
								onClick={() => {
									onChangeStatus();
								}}>
								<LockOpenIcon/>
							</IconButton>
						);
					} else if (customerStatus === 0) {
						return (
							<IconButton
								onClick={() => {
									onChangeStatus();
								}}>
								<LockIcon htmlColor={red[500]}/>
							</IconButton>
						);
					}
				}
			}
		}
	];

	const categoryHeadCell = [
		{
			name: "cust_category_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "cust_category_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "cust_category_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "cust_category_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeCustCatStatus = () => {
						changeCustCatStatus(customerCategoryList[dataIndex]['cust_category_code'], (isUpdated) => {
							if (isUpdated) {
								if (value == 0 || value == 2) {
									updateValue("1");
								} else {
									updateValue("0");
								}
							}
						});
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeCustCatStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeCustCatStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeCustCatStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const chainHeadCell = [
		{
			name: "cust_chain_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "cust_chain_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "cust_chain_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "cust_chain_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeCustChainStatus = () => {
						changeCustChainStatus(customerChainList[dataIndex]['cust_chain_code'], (isUpdated) => {
							if (isUpdated) {
								if (value == 0 || value == 2) {
									updateValue("1");
								} else {
									updateValue("0");
								}
							}
						});
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const customerAreaCell = [
		{
			name: "id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "area_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "area_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "area_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeCustChainStatus = () => {
						changeCustAreaStatus(customerAreaList[dataIndex]['area_code'], (isUpdated) => {
							if (isUpdated) {
								if (value == 0 || value == 2) {
									updateValue("1");
								} else {
									updateValue("0");
								}
							}
						});
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeCustChainStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

    const typeHeadCell = [
        {
            name: "id",
            label: "--",
            options: {
                display: false
            }
        },
        {
            name: "code",
            label: "Code",
            options: {
                display: true
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                display: true
            }
        },
        {
            name: "created_at",
            label: "Created Date",
            options: {
                display: true
            }
        },
        {
            name: "updated_at",
            label: "Updated Date",
            options: {
                display: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const {rowIndex: dataIndex} = tableMeta;
                    const onChangeCustTypeStatus = () => {
                        changeCustTypeStatus(customerTypeList[dataIndex]['code'], (isUpdated) => {
                            if (isUpdated) {
                                if (value == 0 || value == 2) {
                                    updateValue("1");
                                } else {
                                    updateValue("0");
                                }
                            }
                        });
                    }
                    switch (value) {
                        case "1" :
                            return (
                                <Button onClick={() => onChangeCustTypeStatus()}>
                                    <Chip size="small" icon={<DoneIcon/>} label="Active"/>
                                </Button>
                            );
                            break;
                        case "0" :
                            return (
                                <Button onClick={() => onChangeCustTypeStatus()}>
                                    <Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
                                </Button>
                            );
                            break;
                        case "2" :
                            return (
                                <Button onClick={() => onChangeCustTypeStatus()}>
                                    <Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
                                </Button>
                            );
                            break;
                        default :
                            return (null);
                            break;
                    }
                }
            }
        }
    ];

	const changeCustCatStatus = (cust_category_code, cb) => {
		var params = {
			cust_category_code: cust_category_code,
			client: gState.client,
			updated_by: gState.username
		};
		params = axiosURIencode(params);
		API_Service.put(req_cust_category_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result == 1) {
				onUpdateCustCategory();
				toggleCustChainDetails(() => (false));
				// for (let ii = 0; ii < customerCategoryList.length; ii++) {
				// 	const {cust_category_code} = customerCategoryList[ii];
				// 	if (JString(cust_category_code).isEqualDeep(cust_category_code)) {
				// 		customerCategoryList[ii].cust_category_status = customerCategoryList[ii].cust_category_status == 1 ? 0 : 1;
				// 	}
				// }
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

	const changeCustChainStatus = (cust_chain_code, cb) => {
		var params = {
			cust_chain_code: cust_chain_code,
			client: gState.client,
			updated_by: gState.username
		};
		params = axiosURIencode(params);
		API_Service.put(req_cust_chain_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result == 1) {
				onUpdateCustChain();
				// for (let ii = 0; ii < customerChainList.length; ii++) {
				// 	const {cust_chain_code} = customerChainList[ii];
				// 	if (JString(cust_chain_code).isEqualDeep(cust_chain_code)) {
				// 		customerChainList[ii].cust_chain_status = customerChainList[ii].cust_chain_status == 1 ? 0 : 1;
				// 	}
				// }
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

	const changeCustAreaStatus = (area_code, cb) => {
		var params = {
			area_code: area_code,
			client: gState.client,
			updated_by: gState.username
		};
		params = axiosURIencode(params);
		API_Service.put(req_cust_area_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result == 1) {
				onUpdateCustArea();
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

    const changeCustTypeStatus = (cust_type_code, cb) => {
        var params = {
            cust_type_code: cust_type_code,
            client: gState.client,
            updated_by: gState.username
        };
        params = axiosURIencode(params);
        API_Service.put(req_cust_type_status, params, axiosConfig).then(({data}) => {
            var {result} = data;
            if (result == 1) {
                onUpdateCustType();
            }
            cb(result == 1);
        }).catch((err) => de(err.message));
    }

	const onClickCustCategoryList = (item_code, arr) => {
		for (let index = 0; index < arr.length; index++) {
			const {cust_category_code} = arr[index];
			if (item_code == cust_category_code) {
				onClickCustCategory(index);
			}
		}
	}

	const onClickCustChainList = (item_code, arr) => {
		for (let index = 0; index < arr.length; index++) {
			const {cust_chain_code} = arr[index];
			if (item_code == cust_chain_code) {
				onClickCustChain(index);
			}
		}
	}

    const onClickCustTypeList = (item) => {
        toggleCustTypeDetails(true);
        setChoosenCustType(item);
        // de("betty", item);
        // for (let index = 0; index < arr.length; index++) {
        // 	const {code} = arr[index];
        // 	if (item_code == code) {
        // 		onClickCustType(index);
        // 	}
        // }
    }

	const onClickCustAreaList = (item) => {
		toggleCustAreaDetails(true);
        setChoosenCustArea(item);
    }

	const onClickCustCategory = (index) => {
		setChosenCustCategory(() => (index));
		toggleCustCategoryDetails(() => (true));
	}

	const onClickCustChain = (index) => {
		setChosenCustChain(() => (index));
		toggleCustChainDetails(() => (true));
	}

	const onUpdateCustCategory = () =>{
		var axiosGetConfig = axiosDefaultConfig(gState.client);
		loadCustCategoryList(axiosGetConfig);
	}

	const onUpdateCustChain = () => {
		var axiosGetConfig = axiosDefaultConfig(gState.client);
		loadCustChainList(axiosGetConfig);
	}

    const onUpdateCustType = () => {
        var axiosGetConfig = axiosDefaultConfig(gState.client);
        loadCustTypeList(axiosGetConfig);
    }

	const onUpdateCustArea = () => {
        var axiosGetConfig = axiosDefaultConfig(gState.client);
        loadCustAreaList(axiosGetConfig);
    }

	const loadCustCategoryList = (axiosRequestConfig) => {
		API_Service.get(`${req_cust_category}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;

				result = JString(result).toNumber();
				if (result === 1) {
					var _inact_data = [];
					var _act_data = [];
					for (let i = 0; i < extraData.length; i++) {
						const element = extraData[i];
						if(element['cust_category_status'] == 1){
							_act_data.push(element);
						}else{
							_inact_data.push(element);
						}
					}
					setCustomerCategoryList(() => (extraData));
					setActiveCustCategoryList(()=>(_act_data));
					// setInactiveCustCategoryList(() => (_inact_data));
				}
			}
		}).catch(err => de(err.message, 'customer category list'));
	}

	const loadCustChainList = (axiosRequestConfig) => {
		API_Service.get(`${req_cust_chain}`, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var _inact_data = [];
					var _act_data = [];
					for (let i = 0; i < extraData.length; i++) {
						const element = extraData[i];
						if(element['cust_chain_status'] == 1){
							_act_data.push(element);
						}else{
							_inact_data.push(element);
						}
					}
					setCustomerChainList(() => (extraData));
					setActiveCustChainList(()=>(_act_data));
					// setInactiveCustChainList(() => (_inact_data));
				}
			}
		}).catch(err => de(err.message, 'customer chain list'));
	}

    const loadCustTypeList = (axiosRequestConfig) => {
        API_Service.get(req_cust_type, axiosRequestConfig).then(({data}) => {
            if (data) {
                var {result, extraData } = data;
                result = JString(result).toNumber();
                if (result === 1) {
                    var _inact_data = [];
                    var _act_data = [];
                    for (let i = 0; i < extraData.length; i++) {
                        const element = extraData[i];
                        if(JString(element['status']).toNumber() === 1){
                            _act_data.push(element);
                        }else{
                            _inact_data.push(element);
                        }
                    }
                    setCustomerTypeList(() => (extraData));
                    setActiveCustTypeList(()=>(_act_data));
                }
            }
        }).catch(err => de(err.message, 'customer chain list'));
    }

	const loadCustAreaList = (axiosRequestConfig) => {
        API_Service.get(req_cust_area, axiosRequestConfig).then(({data}) => {
            if (data) {
                var {result, extraData } = data;
                result = JString(result).toNumber();
                if (result === 1) {
                    var _inact_data = [];
                    var _act_data = [];
                    for (let i = 0; i < extraData.length; i++) {
                        const element = extraData[i];
                        if(JString(element['area_status']).toNumber() === 1){
                            _act_data.push(element);
                        }
                    }
                    setCustomerAreaList(() => (extraData));
                    setActiveCustAreaList(()=>(_act_data));
                }
            }
        }).catch(err => de(err.message, 'customer area list'));
    }

	const loadBranchList = (axiosRequestConfig) => {
        API_Service.get(req_cust_branches, axiosRequestConfig).then(({data}) => {
            if (data) {
                var {result, extraData } = data;
                result = JString(result).toNumber();
				var _data = [];
                if (result === 1) {
					for (let i = 0; i < extraData.length; i++) {
						if (gState.company) {
							const company = JSON.parse(gState.company);
							if (company.includes(extraData[i]['value'])) {
								_data.push(extraData[i]);	
							}
						} else {
							_data.push(extraData[i]);
						}
					}
					setBranchesList(_data);
                }
            }
        }).catch(err => de(err.message, 'customer branch list'));
    }

	const chosenCustCategoryData = customerCategoryList[chosenCustCategory];
	const chosenCustChainData = customerChainList[chosenCustChain];

	return (
		<div>
			{
				currentPage === 0 && (
					<div className={classes.marginTop}>
					{
                        (customerTable.customerList?.length === 0 && customerTable.inactiveCustList?.length === 0) ?
							<ShimmerLoading
								blocks={[
									{count: 1, height: 50},
									{count: 1, height: 40},
									{count: 1, height: 30},
									{count: 1, height: 20},
									{count: 1, height: 40},
									{count: 1, height: 50}
								]}
							/> : null
					}
						<CustomerTable
                            inactiveCustList={customerTable.inactiveCustList}
                            customerList={customerTable.customerList}
							custClicked={(props) => custClicked(props)}
							showCreateDialog={showCreateDialog}
							headCells={headCells}
							setShowInactive={setShowInactive}
							showInactive={showInactive}
							integration={integration}
						/>
					</div>
				)
			}
			{
				currentPage === 1 && (
					<div className={classes.marginTop}>
						<Tabs
							indicatorColor={'primary'}
							textColor={'inherit'}
							variant={'fullWidth'}
							value={tabPosition}
							centered
							onChange={(e, v) => setTabPosition(v)}
							aria-label="simple tabs log">
							<Tab label={language.cust_category}/>
                            <Tab label={language.cust_type}/>
							<Tab label={language.cust_chain}/>
							<Tab label={language.cust_area}/>
						</Tabs>
						<div style={{height: 15}}/>
						{
							tabPosition === 0 && (
								<MUIDataTable
									title={language.cust_category_list}
									data={customerCategoryList}
									columns={categoryHeadCell}
									options={{
										print: false,
										filter: true,
										download: false,
										selectableRows: 'none',
										rowsPerPage: 99,
										rowsPerPageOptions: [125, 140, 165, 200],
										responsive: 'scroll',
										onRowClick: (rowData, {dataIndex}) => {
											onClickCustCategoryList(customerCategoryList[dataIndex]['cust_category_code'], customerCategoryList)
										},
										customToolbar: () =>
											<TemplateTableToolbar
												addNewItem={() => {
													setNewCustCategoryView(true)
												}}
											/>
									}}
								/>
							)
						}
						{
							tabPosition === 1 && (
								<MUIDataTable
									title={language.cust_type_list}
									data={customerTypeList}
									columns={typeHeadCell}
									options={{
										print: false,
										filter: true,
										download: false,
										selectableRows: 'none',
										rowsPerPage: 99,
										rowsPerPageOptions: [125, 140, 165, 200],
										responsive: 'scroll',
										onRowClick: (rowData, {dataIndex}) => {
											onClickCustTypeList(customerTypeList[dataIndex])
										},
										customToolbar: () =>
											<TemplateTableToolbar
												addNewItem={() => {
													setNewCustTypeView(true)
												}}
											/>
									}}
								/>
							)
						}
						{
							tabPosition === 2 && (
								<MUIDataTable
									title={language.cust_chain_list}
									data={customerChainList}
									columns={chainHeadCell}
									options={{
										print: false,
										filter: true,
										download: false,
										selectableRows: 'none',
										rowsPerPage: 99,
										rowsPerPageOptions: [125, 140, 165, 200],
										responsive: 'scroll',
										onRowClick: (rowData, {dataIndex}) => {
											onClickCustChainList(customerChainList[dataIndex]['cust_chain_code'], customerChainList)
										},
										customToolbar: () =>
											<TemplateTableToolbar
												addNewItem={() => {
													setNewCustChainView(true)
												}}
											/>
									}}
								/>
							)
						}
						{
							tabPosition === 3 && (
								<MUIDataTable
									title={language.area}
									data={customerAreaList}
									columns={customerAreaCell}
									options={{
										print: false,
										filter: true,
										download: false,
										selectableRows: 'none',
										rowsPerPage: 99,
										rowsPerPageOptions: [125, 140, 165, 200],
										responsive: 'scroll',
										onRowClick: (rowData, {dataIndex}) => {
											onClickCustAreaList(customerAreaList[dataIndex])
										},
										customToolbar: () =>
											<TemplateTableToolbar
												addNewItem={() => {
													setNewCustAreaView(true)
												}}
											/>
									}}
								/>
							)
						}
					</div>
				)
			}
            {
                currentPage === 2 && (
                    <>
                        <GoogleMap
                            // lightTheme={lightTheme}
                            // latlongList={latlongList}
                            // height={'100%'}
                            // width={'100%'}
                            // mapHeight={'80vh'}
                            // pointerEvents={"none"}
                        />
                    </>
                )
            }
			<BottomNavigation
				value={currentPage}
				onChange={(event, newValue) => {
					setCurrentPage(newValue);
				}}
				styles={{
					width: '100%',
					position: 'fixed',
					bottom: 0
				}}
				showLabels
				className={commonStyles.bottomNav}>
				<BottomNavigationAction label="Outlet List" icon={<CreateNewFolderIcon/>}/>
				<BottomNavigationAction label="Management" icon={<AccountTreeIcon/>}/>
				<BottomNavigationAction label="Map" icon={<MapIcon/>}/>
				{/* <BottomNavigationAction label="Promo / Bundle" icon={<RedeemIcon />} /> */}
			</BottomNavigation>
			<CustomerDetails
				open={showCustomerDetails}
				setClose={() => toggleCustomerDetails(() => (false))}
				customer={chosenCustomer}
				language={language}
				axiosConfig={axiosConfig}
				axiosPut={axiosPut}
				onSnack={({msg, type}) => {
					snack(msg, type);
				}}
				availableAgents={loginList}
				config={config}
				groups={customerGroups}
				onUpdateCustomer={onUpdateCustomer}
				integration={integration}
				custCategoryList={activeCustCategoryList}
				custChainList={activeCustChainList}
                custTypeList={activeCustTypeList}
				custAreaList={activeCustAreaList}
				branchesList={branchesList}
			/>
            {
                createDialog && (
                    <AddCustomer
                        open={createDialog}
                        onClose={(data) => {
                            showCreateDialog(false);
                            onUpdateCustomer(data);
                        }}
                        language={language}
                        custCategoryList={activeCustCategoryList}
                        custChainList={activeCustChainList}
                        custTypeList={activeCustTypeList}
						custAreaList={activeCustAreaList}
						branchesList={branchesList}
                    />
                )
            }
            {
                newCustCategoryView && (
                    <AddCustCategory
                        open={newCustCategoryView}
                        onClose={(data) => {
                            setNewCustCategoryView(false);
                            onUpdateCustCategory(data);
                        }}
                        language={language}
                        onSnack={(msg, type) => snack(msg, type)}
                        axiosConfig={axiosConfig}
                        customization={mobileCustomization}
                    />
                )
            }

            { newCustChainView && (
                <AddCustChain
                    open={newCustChainView}
                    onClose={(data) => {
                        setNewCustChainView(false);
                        onUpdateCustChain(data);
                    }}
                    language={language}
                    onSnack={(msg, type) => snack(msg, type)}
                    axiosConfig={axiosConfig}
                />
            )}

            {
                newCustTypeView && (
                    <AddCustType
                        open={newCustTypeView}
                        onClose={(data) => {
                            setNewCustTypeView(false);
                            onUpdateCustType(data);
                        }}
                        language={language}
                        onSnack={(msg, type) => snack(msg, type)}
                        axiosConfig={axiosConfig}
                    />
                )
            }
			{
                newCustAreaView && (
                    <AddAreaType
                        open={newCustAreaView}
                        onClose={(data) => {
                            setNewCustAreaView(false);
                            onUpdateCustArea(data);
                        }}
                        language={language}
                        onSnack={(msg, type) => snack(msg, type)}
                        axiosConfig={axiosConfig}
                    />
                )
            }
			{
				showCustCategoryDetails ?
					<CustCategoryDetails
						open={showCustCategoryDetails}
						setClose={() => {
							setChosenCustCategory(() => (-1));
							toggleCustCategoryDetails(() => (false));
						}}
						item={showCustCategoryDetails ? chosenCustCategoryData : null}
						language={language}
						onUpdateCustCategory={() => {
							onUpdateCustCategory()
						}}
						onSnack={(msg, type) => snack(msg, type)}
						integration={integration}
						mobileCustomization={mobileCustomization}
						modules={modules}
					/> : null
			}
			{
				showCustChainDetails ?
					<CustChainDetails
						open={showCustChainDetails}
						setClose={() => {
							setChosenCustChain(() => (-1));
							toggleCustChainDetails(() => (false));
						}}
						item={showCustChainDetails ? chosenCustChainData : null}
						language={language}
						onUpdateCustChain={() => {
							onUpdateCustChain()
						}}
						onSnack={(msg, type) => snack(msg, type)}
						integration={integration}
					/> : null
			}
            {
                showCustTypeDetails ?
                    <CustTypeDetails
                        open={showCustTypeDetails}
                        setClose={() => {
                            setChoosenCustType(() => ({}));
                            toggleCustTypeDetails(() => (false));
                        }}
                        item={showCustTypeDetails ? choosenCustType : null}
                        language={language}
                        onUpdateCustType={() => {
                            onUpdateCustType()
                        }}
                        onSnack={(msg, type) => snack(msg, type)}
                        integration={integration}
                    /> : null
            }
			{
                showCustAreaDetails ?
                    <CustAreaDetails
                        open={showCustAreaDetails}
                        setClose={() => {
                            setChoosenCustArea(() => ({}));
                            toggleCustAreaDetails(() => (false));
                        }}
                        item={showCustAreaDetails ? choosenCustArea : null}
                        language={language}
                        onUpdateCustArea={() => {
                            onUpdateCustArea()
                        }}
                        onSnack={(msg, type) => snack(msg, type)}
                        integration={integration}
                    /> : null
            }
		</div>
	)
}

// const CustomerTable = React.memo(({
function CustomerTable({
   inactiveCustList,
   customerList,
   custClicked,
   showCreateDialog,
   headCells,
   setShowInactive,
   showInactive,
   integration
}) {
	const [queryChanged, queryChanging] = useState(false);
	const [gState] = useContext(AppContext);
	const singleton = PersistantData.getInstance();
	var {translation: language} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	useEffect(() => {
	}, [customerList]);

	if (customerList.length == 0 && inactiveCustList.length == 0) {
		return null;
	}
	return (
		<>
			<MUIDataTable
				title={language.customer_low}
				data={showInactive ? inactiveCustList : customerList}
				columns={headCells}
				options={{
					selectableRows: 'none',
					print: false,
					filter: true,
					rowsPerPage: 150,
					rowsPerPageOptions: [150, 300, 600, 900],
					responsive: 'simple',
					setTableProps: () => ({size: 'small'}),
					// customSearch: (searchQuery, currentRow, columns)=>{
					// 	de("Betty-customSearch", searchQuery, currentRow, columns);
					// 	for (let i = 0; i < columns.length; i++)
					// 	{
					// 		const { name } = columns[i];
					// 		if (name === "cust_code" || name === "cust_company_name" || name === "billing_address")
					// 		{
					// 			const column_value = currentRow[i];
					// 			de("Betty-customSearch", String(searchQuery).trim());
					// 			// if (jstring(column_value).searchDeep(String(searchQuery).trim()))
					// 			// {
					// 			// 	return true;
					// 			// }
					// 		}
					// 	}
					// },
					onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
						if (colIndex !== (headCells.length - 1)) {
							custClicked(showInactive ? inactiveCustList[dataIndex]?.cust_code : customerList[dataIndex]?.cust_code);
						}
					},
					customToolbar: () => {
						return (
							<TemplateTableCustomToolbar
								showActive={showInactive}
								onNewTemplate={() => {
									showCreateDialog(true);
								}}
								onShowInactive={setShowInactive}
								integration={integration}
							/>
						);
					},
				}}
			/>
		</>
	)
}

function areEqual(prevProps, nextProps) {
	return prevProps.customerList.length === nextProps.customerList.length && _.isEqual(prevProps.groups, nextProps.groups);
}

function TemplateTableCustomToolbar({showActive, onNewTemplate, onShowInactive, integration}) {
	return (
		<React.Fragment>
			{
				showActive ?
					<Tooltip title={'Show Active'}>
						<IconButton
							onClick={() => {
								onShowInactive(false);
							}}>
							<VisibilityOffIcon/>
						</IconButton>
					</Tooltip>
					:
					<Tooltip title={'Show Inactive Only'}>
						<IconButton
							onClick={() => {
								onShowInactive(true);
							}}>
							<VisibilityIcon/>
						</IconButton>
					</Tooltip>
			}
			{
				integration === '0' ?
					<Tooltip title={'Add New'}>
						<IconButton
							edge={'end'}
							color={'primary'}
							onClick={() => onNewTemplate()} aria-label="addnew">
							<AddIcon/>
						</IconButton>
					</Tooltip>
					:
					''
			}

		</React.Fragment>
	)
}

function TemplateTableToolbar({addNewItem}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add New Item'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => addNewItem()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

