import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import JString from '@easytech-international-sdn-bhd/jstring';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import {usePaperStyles} from '../../Styles/StylePaper';
import {de} from "../../Helper/Helper";
import API_Service, {axiosURIencode} from '../../Services/API_Service';
import {req_stock_adjustment_doc_dtl_cancel, req_stock_transfer_doc_dtl_cancel} from '../../Helper/Constants';

const useStyles = makeStyles((theme) => ({
	image: {
		maxWidth: 150,
		marginLeft: 10
	},
	large_image: {
		maxWidth: 450,
		maxHeight: 450
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	attachment_title: {
		marginTop: 20,
		marginLeft: 10
	},
	image_title: {
		textAlign: 'center',
		fontSize: 16,
		color: "black"
	},
	image_desc: {
		textAlign: 'center',
		fontSize: 14,
		color: "grey"
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
	{
		name: "id",
		label: "ID",
		options: {
			display: false,
			filter: false,
			print: false,
			search: false
		}
	},
	{
		name: "product_code",
		label: "Code",
	},
	{
		name: "product_name",
		label: "Name",
	},
	{
		name: "from_location",
		label: "From Location",
	},
	{
		name: "to_location",
		label: "To Location",
	},
	{
		name: "unit_price",
		label: "Sold At",
		options: {
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					JString(value).inCurrency()
				)
			}
		}
	},
	{
		name: "quantity",
		label: "QTY",
	},
	{
		name: "sub_total",
		label: "SubTotal",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					JString(value).inCurrency()
				)
			}
		}
	},
	{
		name: "cancel_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Cancelled"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	},
	{
		name: "acknowledged",
		label: "Acknowledged",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case 0 :
						return (<Chip size="small" icon={<DoneIcon/>} label="PENDING"/>);
						break;
					case 1 :
						return (<Chip size="small" icon={<CloseIcon/>} label="YES"/>);
						break;
					default :
						return (<Chip size="small" icon={<CloseIcon/>} label="PENDING"/>);
						break;
				}
			}
		}
	}
]
const adjheadCells = [
	{
		name: "id",
		label: "ID",
		options: {
			display: false,
			filter: false,
			print: false,
			search: false
		}
	},
	{
		name: "product_code",
		label: "Code",
	},
	{
		name: "product_name",
		label: "Name",
	},
	{
		name: "quantity",
		label: "QTY",
	},
	{
		name: "cancel_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Cancelled"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	}
]

function createData(id, product_code, product_name, from_location, to_location, unit_price, quantity, unit_uom, sub_total, cancel_status, acknowledged) {
	return {
		id,
		product_code,
		product_name,
		from_location,
		to_location,
		unit_price: JString(unit_price).toNumber(),
		quantity: String(quantity) + ' ' + String(unit_uom),
		sub_total: JString(sub_total).toNumber(),
		cancel_status,
		acknowledged: parseInt(acknowledged || 0)
	};
}

export default function StockTransferDetails({
												 open,
												 setClose,
												 language,
												 itemList = [],
												 docDtl = [],
												 axiosConfig,
												 doctype,
												 snack
											 }) {
	const classes = useStyles();
	const paperStyle = usePaperStyles();
	const [items, setItems] = useState(itemList);

	useEffect(() => {
		createRows(itemList?.items);
	}, [itemList]);

	const createRows = (__itemList) => {
		if (__itemList) {
			var _data = [];
			for (let i = 0; i < __itemList.length; i++) {
				const {
					id,
					product_code,
					product_name,
					from_location,
					to_location,
					unit_uom,
					unit_price,
					quantity,
					sub_total,
					cancel_status,
					acknowledged
				} = __itemList[i];
				_data.push(
					createData(
						id,
						product_code,
						product_name,
						from_location,
						to_location,
						unit_price,
						quantity,
						unit_uom,
						sub_total,
						cancel_status,
						acknowledged
					)
				);
			}
			de(_data);
			setItems(items => (_data));
		}
	}

	const resetAndClose = () => {
		setClose();
	}

	const getSelectedDocIds = (data) => {
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {id} = items[dataIndex];
			ids += JString(id).toString();
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		return ids;
	}

	const toggleDocDtlCancel = (data) => {
		var params = {
			doc_id: getSelectedDocIds(data)
		};
		params = axiosURIencode(params);
		let req = '';
		if (doctype === 'transfer') {
			req = `${req_stock_transfer_doc_dtl_cancel}`;
		} else {
			req = `${req_stock_adjustment_doc_dtl_cancel}`;
		}
		API_Service.put(req, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result === 1) {
				snack(language.deleted);
			} else {
				snack(language.delete_fail);
			}
		});
	}

	return (
		<div>
			<Dialog fullScreen open={open} onClose={() => resetAndClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => resetAndClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.doc_details}
						</Typography>
					</Toolbar>
				</AppBar>
				<div>
					<MUIDataTable
						title={docDtl.length > 0 ? `# ${docDtl[0]?.st_code}` : ''}
						data={items}
						columns={doctype === 'transfer' ? headCells : adjheadCells}
						options={{
							filter: false,
							print: false,
							rowsPerPage: 5,
							rowsPerPageOptions: [5, 10, 25],
							responsive: 'scrollMaxHeight',
							setTableProps: () => ({size: 'small'}),
							onRowsDelete: ({data, lookup}) => {
								toggleDocDtlCancel(data);
							}
						}}
					/>
				</div>
			</Dialog>
		</div>
	);
}
