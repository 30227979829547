import React, {useCallback, useContext, useEffect, useState} from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Lottie from 'lottie-react-web';
import LottieAnim from '../../Assets/dashboard.json';
import {Offline, Online} from 'react-detect-offline';
import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import CheckBox from '../../Components/CheckBox';
import API_Service, {axiosDefaultConfig} from '../../Services/API_Service';
import {
    ck_session_id,
    ck_u_client,
    ck_u_device_no,
    ck_u_login_id,
    ck_u_password,
    ck_u_remember_auth,
    ck_u_role_id,
    ck_u_salesperson,
    ck_u_selforder,
    ck_u_username,
    empty,
    no,
    req_login,
    yes
} from "../../Helper/Constants";
import JString from '@easytech-international-sdn-bhd/jstring';
import packageJSON from '../../../package.json';
import TextInput from '../../Components/TextInput';
import History from '../../Services/History';
import grey from '@material-ui/core/colors/grey';
import _ from 'lodash';
import CryptData from "../../Helper/CryptData";
import AppContext from "../../Services/AppContext";
import LinearLoading from "../../Components/LinearLoading";
import * as firebase from '../../Firebase';
import {de} from "../../Helper/Helper";
import useCookieHook from "../../Services/useCookieHook";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import {ops, tables} from "../../Services/indexedDB";

const useStyles = makeStyles(theme => ({
	root: {
		background: '#FEF200'
		// background: 'linear-gradient(45deg, #F9FF95 30%, #F0FF12 90%)',
	},
	container_div: {
		height: '100vh',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: 10,
		alignItems: 'center'
	},
	sign_in: {
		height: 400,
		width: 400,
		padding: 10
	},
	advert: {
		height: 410,
		width: 600
	},
	loginBtn: {
		margin: theme.spacing(1),
		width: '100vh',
		backgroundColor: '#FEF200'
	},
	es_title: {
		margin: theme.spacing(1),
		color: '#FEF200'
	},
	no_internet: {
		textAlign: 'center',
		fontSize: 12,
		color: red[500]
	},
	version: {
		textAlign: 'center',
		fontSize: 12,
		color: 'grey'
	},
	checkbox: {
		marginTop: theme.spacing(1)
	}
}));
export default function SignIn() {
	const styles = useStyles();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: LottieAnim,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const [axiosConfig, setAxiosConfig] = useState({});
	const [sessionid, setSessionId] = useState(empty);
	const [gState, setGState] = useContext(AppContext);
	const [theme, setTheme] = useState({
		palette: {
			type: "light",
			primary: {
				main: '#FEF200'
			},
			secondary: {
				main: grey[500]
			}
		}
	});
	const [notificationError, setNotificationError] = useState('');
	const muiTheme = createTheme(theme);

	const [state, setState] = useState({
		username: empty,
		password: empty,
		remember_me: false,
		isWrongPassword: false,
		loginInProgress: false
	});

	const [cookies, setCookie, removeCookie, cookieInstance] = useCookieHook([
		ck_u_client, ck_u_login_id, ck_u_password, ck_u_role_id, ck_u_username, ck_u_remember_auth, ck_u_salesperson, ck_u_selforder
	]);
	var u_client = cookies(ck_u_client);
	var u_username = cookies(ck_u_username);
	var u_password = cookies(ck_u_password);
	var u_remember_auth = cookies(ck_u_remember_auth);

	if (JString(u_username).isValid()) {
		u_client = JString(u_client).isValid() ?
			new CryptData(u_client, u_username)
				.decryptAES()
				.getValue()
			: empty;
		u_password = JString(u_password).isValid() ?
			new CryptData(u_password, u_username)
				.decryptAES()
				.getValue()
			: empty;
	}

	useEffect(() => {
		initFingerPrint();
		if (u_remember_auth === yes && !_.isEmpty(u_client) && !_.isEmpty(u_password) && !_.isEmpty(u_username)) {
			gotoDashboard();
		} else {
			de('cookie remember me?', u_remember_auth, u_client, u_password, u_username);
		}
		var axiosGetConfig = axiosDefaultConfig(gState.client);
		setAxiosConfig(axiosGetConfig);
	}, [u_username, u_client, cookieInstance]);

	const initFingerPrint = async () => {
		const existingFingerPrints = await ops.get(tables.uniques);
		de(existingFingerPrints, '--existing ids--');
		if (existingFingerPrints?.fingerprint) {
			setSessionId(existingFingerPrints.fingerprint);
		} else {
			const fpPromise = FingerprintJS.load();
			await (async () => {
				const fp = await fpPromise;
				const result = await fp.get();
				const fingerprint = result.visitorId;
				ops.insert(tables.uniques, {fingerprint, date_time: new Date().toISOString()});
				setSessionId(fingerprint);
			})();
		}
	}

	const onTextChange = prop => event => {
		setState({...state, [prop]: event.target.value});
	}

	const onCheckboxChange = event => {
		const remember_me_cb = event.target.checked;
		setState({...state, [event.target.name]: remember_me_cb});
	};

	var vUsername = JString(state.username).str;
	var vPassword = JString(state.password).str;

	const checkTokenAndLogin = (svUsername = empty, svPassword = empty) => {
		firebase.initNotification((token) => {
			performLogin(svUsername, svPassword, token);
		}, null);
	}

	const performLogin = (svUsername = empty, svPassword = empty, token = empty) => {
		setState({...state, loginInProgress: true});

		var formdata = new FormData();
		formdata.append('username', svUsername || vUsername);
		formdata.append('password', svPassword || vPassword);
		formdata.append('device_token', token || '');
		formdata.append('fingerprint_id', sessionid);
		API_Service.post(`${req_login}`, formdata, axiosConfig)
			.then(({data}) => {
				if (data) {
					var {result, extraData: e} = data;

					result = JString(result).toNumber();

					if (result === 0) {
						setState({...state, isWrongPassword: result === 0, loginInProgress: false});
					}
					if (e && result === 1) {
						const lGstate = {
							...gState,
							username: e.username,
							password: e.password,
							client: e.client_company,
							device_no: e.device_no,
							company: e.company
						};
						setGState(lGstate);
						var _clientId = new CryptData(e.client_company, e.username)
							.isString()
							.encryptAES()
							.getValue();
						var _login_id = new CryptData(e.id, e.username)
							.isString()
							.encryptAES()
							.getValue();
						var _password = new CryptData(e.password, e.username)
							.isString()
							.encryptAES()
							.getValue();
						var _session_id = sessionid;
						var _role_id = new CryptData(e.role, e.username)
							.isString()
							.encryptAES()
							.getValue();
						var _is_selfOrder = e.is_selforder;
						var _is_salesperson = e.is_salesperson;

						cookieKeyValue(ck_u_client, _clientId, lGstate);
						cookieKeyValue(ck_u_login_id, _login_id, lGstate);
						cookieKeyValue(ck_u_username, e.username, lGstate);
						cookieKeyValue(ck_u_password, _password, lGstate);
						cookieKeyValue(ck_u_role_id, _role_id, lGstate);
						cookieKeyValue(ck_u_salesperson, _is_salesperson, lGstate);
						cookieKeyValue(ck_u_selforder, _is_selfOrder, lGstate);
						cookieKeyValue(ck_session_id, _session_id, lGstate);
						cookieKeyValue(ck_u_remember_auth, state.remember_me ? yes : no, lGstate);
						cookieKeyValue(ck_u_device_no, e.device_no, lGstate);
						throttleRedirect();
					}
				}
			});
	}

	const cookieKeyValue = (key, value, extra = gState) => {
		setCookie(key, value, {path: '/'}, extra);
	}
	const gotoDashboard = () => {
		setState({...state, loginInProgress: false});
		History.push('/main');
	}
	const throttleRedirect = useCallback(_.debounce(gotoDashboard, 1000), []);
	const disabledLoginBtn = (vUsername.length < 3 || vPassword.length < 4) || state.loginInProgress;

	return (
		<MuiThemeProvider theme={muiTheme}>
			<CssBaseline/>
			<div className={styles.root}>
				<Container>
					<Typography component="div" className={styles.container_div}>
						<div className={styles.advert}>
							<Lottie
								options={defaultOptions}
								forceSegment={true}
								width={'90%'}
								height={'90%'}
							/>
						</div>
						<Card className={styles.sign_in} elevation={5}>
							<CardHeader
								title={'EASYM'}
								subheader={'Back Office Management'}/>
							<CardContent>
								{state.loginInProgress && <LinearLoading/>}
								<TextInput
									isError={state.isWrongPassword}
									helperText={state.isWrongPassword ? 'Check your username' : ''}
									onChange={onTextChange('username')}
									placeholder={'username'}
								/>
								<TextInput
									onSubmit={() => checkTokenAndLogin()}
									isError={state.isWrongPassword}
									helperText={state.isWrongPassword ? 'Check your password' : ''}
									onChange={onTextChange('password')}
									placeholder={'password'}
									isPassword={true}
									autoComplete="current-password"
								/>
								<CheckBox
									checked={state.checkedB}
									onChange={onCheckboxChange}
									name="remember_me"
									color="primary"
									text={"Remember me"}
								/>
							</CardContent>
							<CardActions>
								<Button
									onClick={() => checkTokenAndLogin()}
									className={styles.loginBtn}
									disabled={disabledLoginBtn}
									variant="contained"
									color="primary">
									Login
								</Button>
							</CardActions>
							<Offline>
								<Typography className={styles.no_internet} component="p">
									You are not connected to internet
								</Typography>
							</Offline>
							<Online>
								<Typography className={styles.version} component="p">
									Version {packageJSON.version}
								</Typography>
							</Online>
						</Card>
					</Typography>
				</Container>
			</div>
		</MuiThemeProvider>
	);
}
