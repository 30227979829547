import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {fade, makeStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const useSearchStyle = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.10),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.15),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '35ch',
			'&:focus': {
				width: '50ch',
			},
		},
	},
	inputInputSmall: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '6ch',
			'&:focus': {
				width: '50ch',
			},
		},
	}
}));

export default function SearchBox({onChangeText, text, small, value}) {
	const searchClass = useSearchStyle();
	return (
		<div className={searchClass.search}>
			<div className={searchClass.searchIcon}>
				<SearchIcon/>
			</div>
			<InputBase
				placeholder={text || ''}
				classes={{
					root: searchClass.inputRoot,
					input: small ? searchClass.inputInputSmall : searchClass.inputInput,
				}}
				inputProps={{'aria-label': 'search'}}
				onChange={(e) => onChangeText(e.target.value)}
				value={value}
			/>
		</div>
	)
}
