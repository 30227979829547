import {Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import {useTheme} from '@material-ui/styles';
import React from 'react';


export default function AnyDialog({open, title, onDismiss, children, footer = false, maxWidth = "sm", ...others}) {
	const theme = useTheme();
	const fullScreen = false;//useMediaQuery(theme.breakpoints.down('md'));
	return (
		<Dialog
			maxWidth={maxWidth}
			fullScreen={fullScreen}
			onClose={onDismiss}
			open={open} {...others}>
			{
				title && (
					<DialogTitle>
						{title}
					</DialogTitle>
				)
			}
			{children}
			{
				footer && (
					<DialogActions>
						{footer()}
					</DialogActions>
				)
			}
		</Dialog>
	)
}
