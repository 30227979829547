import React, {useContext, useEffect, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Button from '@material-ui/core/Button';
import MuiPhoneNumber from "material-ui-phone-number";
import {no_image, place_by_id, req_checkin_sched, req_last_sched, req_module_setting} from '../../Helper/Constants';
import {grey} from '@material-ui/core/colors';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import moment from 'moment';
import AppContext from '../../Services/AppContext';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {
    Box,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import _ from 'lodash';
import VirtualList from '../../Components/VirtualList';
import AnyDialog from '../../Components/AnyDialog';
import {Autocomplete} from '@material-ui/lab';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputTags from '../../Components/InputTags';
import BlinkContainer from '../../Components/BlinkContainer';
import LocationSearch from '../../Components/LocationSearch';
import {LinkPreview} from '@dhaiwat10/react-link-preview';
import {Done} from '@material-ui/icons';
import {VariableSizeList} from 'react-window';
import {useTheme} from '@material-ui/styles';
import {DropzoneArea} from "material-ui-dropzone";
import {arrayBufferToBase64, de, makeRandomString} from "../../Helper/Helper";
import MediaBox from "../../Components/MediaBox";

const makebranchaddress = (obj) => {
	var baddress = '', saddress = '';
	for (const column_name in obj) {
		if (Object.hasOwnProperty.call(obj, column_name)) {
			const column_value = obj[column_name];
			if (JString(column_name).searchTokens(['billing'], true)) {
				baddress += column_value;
			}
			if (JString(column_name).searchTokens(['shipping'], true)) {
				saddress += column_value;
			}
		}
	}
	return {baddress, saddress};
}
const makebranchname = (obj) => {
	return JString(obj.branch_name)
			.replaceInfinity('[\(\)]', '')
			.replaceInfinity(obj.branch_code, '')
			.str.trim()
		||
		'-';
}

export default function AddVisitSched({
										  open,
										  onDismiss,
										  projectData,
										  language,
										  technicianList,
										  branchList,
										  customerList,
										  customModules = {},
										  addVisitSchedRules = {allow_tag_delete: 1},
										  outstandingBalance = {},
										  requestReload = function () {
										  },
										  onSnack,
										  isEditing = false,
										  editData = {
											  id: "",
											  cust_code: "",
											  branch_code: "",
											  salesperson_id: "",
											  sched_datetime: "",
											  sched_note: "",
											  sched_status: "",
											  tech_assignee: "",
											  tech_assigned: "",
											  created_by: "",
											  updated_by: "",
											  site_in_charge: "",
											  site_in_charge_contact: "",
											  site_location: "",
											  site_location_desc: '',
											  active_status: "",
											  updated_at: "",
											  in_progress: "",
											  tags: {},
											  project_no: [],
											  image_list: []
										  }
									  }) {

	const commonStyle = useCommonStyles();
	const paperStyle = usePaperStyles();
	const [customer, setCustomer] = useState({});
	const [branches, setBranches] = useState([]);
	const [gState, setGState] = useContext(AppContext);
	const [customerView, showCustomerView] = useState(false);
	const [chosenBranch, setChosenBranch] = useState({});
	const [chosenProjects, setChosenProjects] = useState([]);
	const [technician, setTechnician] = useState({});
	const [chosenDate, setChosenDate] = useState(new Date());
	const [chosenTime, setChosenTime] = useState(new Date());
	const [chosenNote, setChosenNote] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [tags, setTags] = useState({});
	const [isSavingTag, setIsSavingTag] = useState(false);
	const [currentTags, setCurrentTags] = useState({});
	const [siteLocation, foundLocation] = useState('');
	const [locationMoreInfo, setLocationMoreInfo] = useState({});
	const [lastSchedData, setLastSchedData] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [imageList, setImageList] = useState([]);
	const [isLocationSearch, enableLocationSearch] = useState(false);
	const [contactPerson, setContactPerson] = useState('');
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [existingImages, setExistingImages] = useState([]);

	useEffect(() => {
		if (_.isObject(customModules) && !_.isNull(customModules) && Object.keys(customModules).length !== 0) {
			setTags(customModules);
		}
	}, [customModules]);

	useEffect(() => {
		return () => {
			reset();
		};
	}, []);

	useEffect(() => {
		if (isEditing) {
			for (let i = 0; i < customerList.length; i++) {
				const obj = customerList[i];
				if (obj.value == editData.cust_code) {
					setCustomer(obj);
				}
			}
			for (const cust_code in branchList) {
				if (Object.hasOwnProperty.call(branchList, cust_code)) {
					const br_arr = branchList[cust_code];
					for (let i = 0; i < br_arr.length; i++) {
						const obj = br_arr[i];
						if (obj.branch_code == editData.branch_code) {
							setChosenBranch(obj.branch_code);
						}
					}
				}
			}
			for (let i = 0; i < technicianList.length; i++) {
				const obj = technicianList[i];
				if (obj.key == editData.login_id) {
					setTechnician(obj.key);
				}
			}
			const appmnt_date = moment(editData.sched_datetime, 'YYYY-MM-DD HH:mm:ss');
			setChosenDate(appmnt_date);
			setChosenTime(appmnt_date);
			setChosenNote(editData.sched_note || '');
			if (JString(editData.tags).isJson()) {
				setCurrentTags(JString(editData.tags).toJson());
			}
			if (JString(editData.site_location_desc).isJson()) {
				setLocationMoreInfo(JString(editData.site_location_desc).toJson());
			}
			if (editData.site_location && JString(editData.site_location).searchDeep('query_place_id')) {
				var link = new URL(editData.site_location);
				var place_id = link.searchParams.get('query_place_id');
				var description = link.searchParams.get('query');
				foundLocation({place_id, description});
				enableLocationSearch(true);
			} else {
				foundLocation(editData.site_location);
			}
			setPhoneNumber(editData.site_in_charge_contact);
			setContactPerson(editData.site_in_charge);
			if (JString(editData.project_no).isJson()) {
				setChosenProjects(JString(editData.project_no).toJson() || []);
			}
			setExistingImages(editData?.image_list || []);
		}
	}, [isEditing]);

	const customerplaceholder = () => {
		var label = language.customer;
		if (customer?.name) {
			label = customer?.name;
		}
		return label;
	}
	const onCustomerBtnClick = () => {
		showCustomerView(true);
	}
	const onSelectBranch = event => {
		setChosenBranch(event.target.value || '');
	}

	const getbranch = (id) => {
		for (let i = 0; i < branches.length; i++) {
			const {branch_code} = branches[i];
			if (branch_code == id) {
				return branches[i];
			}
		}
		return {};
	}

	const onTechnicianOptionSelect = (option, value) => {
		return option.key == value.key;
	}

	const onCustomerViewClose = (newValue) => {
		setFilteredProjects([]);
		showCustomerView(false);
		if (newValue) {
			const {value} = newValue;
			if (branchList[value]) {
				setBranches(branchList[value]);
			}
			if (projectData instanceof Array && projectData.length > 0) {
				var newProject = [];
				for (let i = 0; i < projectData.length; i++) {
					const {UDF_CUSTCODE} = projectData[i];
					if (UDF_CUSTCODE === value) {
						newProject.push(projectData[i]);
					}
				}
				setFilteredProjects(newProject);
			}
			const params = axiosURIencode({
				cust_code: value,
				active_status: 1,
				limit: 1
			});
			API_Service.get(`${req_last_sched}?${params}`, axiosDefaultConfig(gState.client)).then(({data}) => {
				const {extraData, result} = data;
				if (parseInt(result) === 1) {
					setLastSchedData(extraData[0]);
				}
			});
			setCustomer(newValue);
		}
	}

	const onAddNewTag = (_newtags, key, isDelete = false) => {
		const existing = tags[key];
		var matchCount = 0;
		for (let i = 0; i < existing.length; i++) {
			const item = existing[i];
			if (_newtags.includes(item)) {
				matchCount++;
			}
		}
		if (matchCount != _newtags.length || isDelete) {
			setIsSavingTag(true);
			const newArr = isDelete ? _newtags : _.uniq([...existing, ..._newtags]);
			const others = {};
			for (const existingKey in tags) {
				if (Object.hasOwnProperty.call(tags, key)) {
					if (existingKey != key) {
						others[existingKey] = tags[existingKey];
					}
				}
			}
			const params = axiosURIencode({
				config: 'sched_tags',
				data: JSON.stringify({[key]: newArr, ...others})
			});
			API_Service.put(req_module_setting, params, axiosPutConfig(gState.client)).then(({data}) => {
				const {result} = data;
				if (parseInt(result) === 1) {
					tags[key] = newArr;
					setTags(tags);
					if (isDelete === false) {
						onChangeTag(_newtags, key);
					}
				}
				setIsSavingTag(false);
			});
		}
	}

	const onRemoveTag = (_tag, key) => {
		const existing = tags[key];
		const newArr = [];
		for (let i = 0; i < existing.length; i++) {
			const item = existing[i];
			if (!JString(_tag).isEqualDeep(item)) {
				newArr.push(item);
			}
		}
		onAddNewTag(newArr, key, true);
	}

	const onChangeTag = (e, key) => {
		currentTags[key] = e;
		setCurrentTags(currentTags);
	}

	const createCustomModule = () => {
		if (_.isObject(tags) && !_.isNull(tags) && Object.keys(tags).length != 0) {
			return (
				<div>
					{
						Object.keys(tags).map((key, index) =>
							<div className={commonStyle.wrapper} key={String(index)}>
								<InputTags
									addVisitSchedRules={addVisitSchedRules}
									showLoading={isSavingTag}
									onAdd={(items) => onAddNewTag(items, key)}
									onRemove={(item) => onRemoveTag(item, key)}
									selectedTags={e => {
										onChangeTag(e, key)
									}}
									fullWidth
									variant="outlined"
									id={"tags" + index}
									name={"tags" + index}
									placeholder="Tags"
									label={String(key).toUpperCase()}
									tags={tags[key]}
									preset={currentTags[key] || []}
								/>
							</div>
						)
					}
				</div>
			)
		}
		return null;
	}

	const onDelete = () => {
		var params = axiosURIencode({
			ids: editData.id,
			delete: 1
		});
		API_Service.put(req_checkin_sched, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				requestReload();
				onDismiss();
			} else {
				onSnack(language.operation_fail, 'error');
			}
			setIsLoading(false);
		});
	}

	const getFormData = () => {
		var _date = moment(chosenDate).format('YYYY-MM-DD');
		var _time = moment(chosenTime).format('HH:mm:ss');
		const formdata = new FormData();
		formdata.append('cust_code', customer.value);
		formdata.append('tech_assignee', technician);
		if (typeof chosenBranch === 'string') {
			formdata.append('branch_code', chosenBranch || '');
		}
		var _siteLocation = JString(siteLocation?.place_id).isValid() ?
			place_by_id({query: siteLocation.description, place_id: siteLocation.place_id}) :
			siteLocation;
		formdata.append('site_location', _siteLocation);
		formdata.append('tags', JSON.stringify(currentTags));
		formdata.append('sched_datetime', `${_date} ${_time}`);
		formdata.append('site_in_charge_contact', phoneNumber);
		formdata.append('site_in_charge', contactPerson);
		formdata.append('sched_note', chosenNote);
		formdata.append('project_no', JSON.stringify(chosenProjects));
		formdata.append('site_location_desc', JSON.stringify(locationMoreInfo));
		if (imageList.length > 0) {
			var final_images = [];
			for (let i = 0; i < imageList.length; i++) {
				imageList[i].salesperson_id = technician;
				final_images.push(imageList[i]);
			}
			formdata.append('image_list', JSON.stringify(final_images));
		}
		return formdata;
	}

	const onUpdate = () => {
		setIsLoading(true);

		const formdata = getFormData();
		var params = {id: editData.id};
		formdata.forEach((value, key) => {
			params[key] = value;
		});
		const unlink_image_list = [];
		if (editData?.image_list) {
			for (let u = 0; u < editData?.image_list.length; u++) {
				const editImage = editData?.image_list[u];
				let not_found = true;
				for (let e = 0; e < existingImages.length; e++) {
					const existsImage = existingImages[e];
					if (existsImage.upload_image === editImage.upload_image) {
						not_found = false;
					}
				}
				if (not_found) {
					unlink_image_list.push(editImage);
				}
			}
			if (unlink_image_list.length > 0) {
				params.unlink_image_list = JSON.stringify(unlink_image_list);
			}
		}
		params = axiosURIencode(params);

		API_Service.put(req_checkin_sched, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				requestReload();
				onDismiss();
			} else {
				onSnack(language.operation_fail, 'error');
			}
			setIsLoading(false);
		});
	}

	const onSave = () => {
		setIsLoading(true);
		const formdata = getFormData();
		API_Service.post(req_checkin_sched, formdata, axiosDefaultConfig(gState.client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				requestReload();
				onDismiss(extraData);
			} else {
				onSnack(language.operation_fail, 'error');
			}
			setIsLoading(false);
		});
	}
	const onSchedImageUpload = (files) => {
		function makeImageObject(content) {
			return {
				salesperson_id: 0,
				filename: makeRandomString() + '.png',
				base64data: 'data:image/png;base64,' + arrayBufferToBase64(content),
				taken_time: moment().format('YYYY-MM-DD hh:mm:ss'),
				location: '',
				remark: '',
				type_name: '-',
				bind_type: 'SREQ',
				bind_id: ''
			}
		}

		if (files.length > 0) {
			const images = [];
			for (let i = 0; i < files.length; i++) {
				const reader = new FileReader()
				reader.onabort = () => de('file reading was aborted')
				reader.onerror = () => de('file reading has failed')
				reader.onload = () => {
					images.push(makeImageObject(reader.result));
					console.log(images);
					setImageList(images);
				}
				reader.readAsArrayBuffer(files[i])
			}
		}
	}

	const reset = () => {
		setCustomer({});
		setBranches([]);
		showCustomerView(false);
		setChosenBranch({});
		setTechnician({});
		setChosenDate(new Date());
		setChosenTime(new Date());
		setChosenNote('');
		setIsLoading(false);
		setTags({});
		setIsSavingTag(false);
		setCurrentTags({});
		foundLocation('');
		setLastSchedData([]);
		setPhoneNumber('');
		enableLocationSearch(false);
		setContactPerson('');
	}

	const getLastVisitCustomerBranch = (_branch_code) => {
		if (branches?.length > 0) {
			for (let i = 0; i < branches.length; i++) {
				const {branch_code, branch_name} = branches[i];
				if (JString(branch_code).isEqualDeep(_branch_code)) {
					return branch_name;
				}
			}
		}
	}

	const get_formatted_tags = (saved_tags) => {
		return JString(saved_tags).toJson();
	}

	const cutomer_outstanding =
		(customer?.value) && outstandingBalance[customer.value] && parseFloat(outstandingBalance[customer.value]) > 0 ?
			JString(outstandingBalance[customer.value]).inCurrency()
			:
			'';

	const getPreviewLink = () => {
		if (locationMoreInfo && (locationMoreInfo?.website || locationMoreInfo?.url)) {
			return (locationMoreInfo?.website || locationMoreInfo?.url);
		}
		if (JString(siteLocation?.place_id).isValid()) {
			return place_by_id({query: siteLocation.description, place_id: siteLocation.place_id});
		}
		return '';
	}

	const getLastSchedDiff = (_date) => {
		const past = moment(_date, 'YYYY-MM-DD HH:mm:ss');
		return past.fromNow();
	}

	const onClearLocation = () => {
		foundLocation({});
		setLocationMoreInfo({});
	}

	const getProjects = filteredProjects.length > 0 ? filteredProjects : projectData;

	const filterOptions = createFilterOptions({
		stringify: (option) => option.project_code + option.project_desc,
	});

	const disbaleSave =
		!JString(customer?.name).isValid() || !JString(technician).isValid() ||
		isLoading;
	const getLocationPreset = () => {
		let _branch_code = chosenBranch;
		let _customer = customer;
		let result = {name: _customer.name, value: _customer.value, address: _customer.address};
		if (JString(_branch_code).isValid()) {
			for (let i = 0; i < branches.length; i++) {
				const {branch_code} = branches[i];
				if (JString(branch_code).isEqualDeep(_branch_code)) {
					result.address = makebranchaddress(branches[i]).baddress;
				}
			}
		}
		return result;
	}

	const deleteTempImage = (image) => {
		setExistingImages(existingImages.filter(v => v.upload_image !== image));
	}

	return (
		<AnyDialog open={open} onDismiss={() => {
			onDismiss();
			reset();
		}}>
			<div className={paperStyle.dialogPaper}>
				<Button
					startIcon={
						customerList?.length === 0 ?
							<CircularProgress color={'inherit'} size={20}/> :
							undefined
					}
					onClick={() => onCustomerBtnClick()}
					variant={'contained'}
					fullWidth
					color={'primary'}
					className={commonStyle.button}>
					<Typography component={'p'} className={commonStyle.searchBtnFont}>
						{customerplaceholder()}
					</Typography>
				</Button>
				{
					cutomer_outstanding ?
						<div style={{marginLeft: 0}} className={commonStyle.wrapper}>
							<BlinkContainer>
								<Typography component={'p'} color={'error'}>
									{language.outstanding}: {cutomer_outstanding}
								</Typography>
							</BlinkContainer>
						</div> :
						null
				}
				{
					lastSchedData?.cust_code && (
						<div className={commonStyle.wrapper}>
							<Box
								padding={1}
								style={{borderStyle: 'dashed', width: 600, borderWidth: 1, borderColor: grey[500]}}
								borderRadius={2}>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<Typography component={'p'} className={commonStyle.label}>
										{String(language.last_visit).toUpperCase()}
									</Typography>
									<Chip label={getLastSchedDiff(lastSchedData.sched_datetime)} variant="outlined"
										  color="primary" size="small"/>
								</div>
								<Typography component={'p'} className={commonStyle.value}>
									{moment(lastSchedData.sched_datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')}
								</Typography>
								{
									lastSchedData.branch_code && (
										<>
											<Typography component={'p'} className={commonStyle.label}>
												{String(language.branch).toUpperCase()}
											</Typography>
											<Typography component={'p'} className={commonStyle.value}>
												{getLastVisitCustomerBranch(lastSchedData.branch_code)}
											</Typography>
										</>
									)
								}
								{
									lastSchedData.sched_note && (
										<>
											<Typography component={'p'} className={commonStyle.label}>
												{String(language.note).toUpperCase()}
											</Typography>
											<Typography component={'p'} className={commonStyle.value}>
												{lastSchedData.sched_note}
											</Typography>
										</>
									)
								}
								{
									get_formatted_tags(lastSchedData.tags) && (
										<div>
											{
												Object.keys(get_formatted_tags(lastSchedData.tags)).map((key, index) =>
													<>
														<Typography component={'p'} className={commonStyle.label}>
															{String(key).toUpperCase()}
														</Typography>
														<div style={{display: 'flex', flexDirection: 'row'}}>
															{
																get_formatted_tags(lastSchedData.tags)[key].map((item) =>
																	<Chip
																		size={'small'}
																		key={item}
																		label={item}
																		style={{marginRight: 2}}
																	/>
																)
															}
														</div>
													</>
												)
											}
										</div>
									)
								}
							</Box>
						</div>
					)
				}
				{
					branches?.length > 0 ?
						<FormControl fullWidth style={{padding: 8, paddingRight: 0, margin: 2}}>
							<InputLabel id="branch-id-label" style={{marginLeft: 7}}>Site/Branch</InputLabel>
							<Select
								fullWidth
								labelId="branch-id-label"
								id="branch-id"
								value={chosenBranch || branches[0].branch_code}
								renderValue={(branch_code) => {
									const item = getbranch(branch_code);
									if (_.isEmpty(item)) {
										return (undefined)
									}
									const branch_name = makebranchname(item);
									const {baddress, saddress} = makebranchaddress(item);
									return (
										<Grid container direction={'column'} spacing={1} value={item.branch_code}>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													BRANCH NAME
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{branch_name}
												</Typography>
											</Grid>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													ADDRESS1
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{baddress}
												</Typography>
											</Grid>
											<Grid item>
												<Typography component={'p'} className={commonStyle.label}>
													ADDRESS2
												</Typography>
												<Typography component={'p'} className={commonStyle.value}>
													{saddress}
												</Typography>
											</Grid>
										</Grid>
									)
								}}
								onChange={onSelectBranch}>
								{
									branches.map((item, index) => {
										const {saddress} = makebranchaddress(item);
										const branch_name = makebranchname(item);
										return (
											<MenuItem value={item.branch_code} key={String(index)}>
												<Typography component={'div'} className={commonStyle.value}>
													{branch_name} | {saddress}
												</Typography>
											</MenuItem>
										)
									})
								}
							</Select>
						</FormControl> :
						null
				}
				<div className={commonStyle.wrapper}>
					<Autocomplete
						fullWidth
						className={commonStyle.input}
						options={technicianList}
						getOptionSelected={(option, value) => onTechnicianOptionSelect(option, value)}
						getOptionLabel={(option) => option.value}
						onChange={(e, v, r) => v && setTechnician(v.key)}
						id="merchandiser"
						renderInput={(params) =>
							<TextField
								variant={'outlined'}
								placeholder={String(language.person_in_charge).toUpperCase()}
								{...params}
								margin={'dense'}/>
						}
					/>
				</div>
				<div className={commonStyle.wrapper} style={{marginTop: -15}}>
					<Autocomplete
						fullWidth
						className={commonStyle.input}
						multiple
						limitTags={3}
						id="multiple-limit-tags"
						options={getProjects}
						filterOptions={filterOptions}
						ListboxComponent={ListboxComponent}
						onChange={(e, v, r) => v && setChosenProjects(v)}
						getOptionLabel={(option) => option.project_code}
						getOptionSelected={(option, value) => option.project_code === value.project_code}
						renderOption={(option, {selected}) => {
							return (
								<React.Fragment>
									<Done
										fontSize={'small'}
										color={'primary'}
										style={{visibility: selected ? 'visible' : 'hidden', marginRight: 10}}/>
									<div>
										<Typography component={'p'} className={commonStyle.label}>
											{option.project_code}
										</Typography>
										<Typography component={'p'} className={commonStyle.value}>
											{option.project_desc}
										</Typography>
									</div>
								</React.Fragment>
							)
						}}
						defaultValue={chosenProjects}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								margin={'dense'}
								variant="outlined"
								label={String(language.choose_project).toUpperCase()}
								placeholder={String(language.choose_project).toUpperCase()}/>
						)}
					/>
				</div>
				<div className={commonStyle.wrapper} style={{marginRight: 10}}>
					<TextField
						value={contactPerson}
						onChange={e => setContactPerson(e.target.value)}
						margin={'dense'}
						id="filled-basic"
						label={String(language.contact_person).toUpperCase()}
						variant={'outlined'} fullWidth/>
					<MuiPhoneNumber
						variant={'outlined'}
						name="phone"
						label={String(language.phone_number).toUpperCase()}
						InputLabelProps={{
							style: {color: grey[500]},
						}}
						data-cy="user-phone"
						defaultCountry={"my"}
						onlyCountries={['my']}
						value={phoneNumber}
						onChange={prop => setPhoneNumber(prop)}
						countryCodeEditable={false}
						fullWidth
						margin={'dense'}
						regions={['asia']}
					/>
				</div>
				<div
					className={commonStyle.wrapper}
					style={{
						borderStyle: 'dashed',
						borderRadius: 3,
						borderWidth: 1,
						borderColor: grey[500],
						padding: 5,
						paddingLeft: 10,
						paddingRight: 10
					}}>
					<FormControlLabel
						control={
							<Switch
								color={'primary'}
								checked={isLocationSearch}
								onChange={e => enableLocationSearch(e.target.checked)}
								name={language.search_location}/>}
						label={
							<Typography
								component={'p'}
								className={commonStyle.searchBtnFont}>
								{String(language.search_location).toUpperCase()}
							</Typography>
						}
					/>
					{
						isLocationSearch ?
							<LocationSearch
								label={String(language.site_loc).toUpperCase()}
								onFound={d => {
									foundLocation(d);
								}}
								onLocationInfo={(info) => {
									setLocationMoreInfo(info);
								}}
								onClear={() => onClearLocation()}
								preset={getLocationPreset()}
							/>
							:
							<TextField
								value={typeof siteLocation === 'string' ? siteLocation : ''}
								onChange={e => foundLocation(e.target.value)}
								margin={'dense'}
								id="filled-basic"
								label={String(language.site_loc).toUpperCase()}
								variant="filled" fullWidth/>
					}
					{
						getPreviewLink() && (
							<LinkPreview url={getPreviewLink()} width={600}/>
						)
					}
				</div>
				<div className={commonStyle.wrapper}>
					<div className={commonStyle.inputWrapper}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								disablePast
								variant="inline"
								label={String(language.choose_date).toUpperCase()}
								value={chosenDate}
								onChange={setChosenDate}
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div className={commonStyle.radioWrapper} style={{marginTop: -7}}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<TimePicker
								variant="inline"
								label={language.time}
								value={chosenTime}
								onChange={setChosenTime}
							/>
						</MuiPickersUtilsProvider>
					</div>
				</div>
				{createCustomModule()}
				<TextField
					value={chosenNote}
					onChange={e => setChosenNote(e.target.value)}
					className={commonStyle.textAreaWrapper}
					id="filled-basic"
					label={language.instructions}
					variant="filled" fullWidth/>
				<div className={commonStyle.dropZoneWrapper}>
					<div className={commonStyle.wrapperNoSpace}>
						{
							existingImages.map(v =>
								<MediaBox
									secondaryBtn={'DELETE'}
									secondaryBtnAction={() => {
										deleteTempImage(v.upload_image)
									}}
									image={v.upload_image || no_image}
									description={v.upload_remark}
									key={v.upload_image}
									Component={() => <img className={commonStyle.mediaBoxImage}
														  src={v.upload_image || no_image}/>}
								/>
							)
						}
					</div>
					<DropzoneArea
						onChange={(files) => {
							onSchedImageUpload(files)
						}}
						dropzoneParagraphClass={commonStyle.dropzoneText}
						acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
						filesLimit={10}
						clearOnUnmount={true}
					/>
				</div>
				{
					isEditing ?
						<div style={{display: 'flex', flexDirection: 'row', paddingLeft: 10}}>
							<Button
								fullWidth
								startIcon={isLoading ? <CircularProgress color={'inherit'} size={20}/> : undefined}
								onClick={() => {
									onDelete()
								}}
								variant={'contained'}
								color={'primary'}
								className={commonStyle.deleteBtn}>
								<Typography component={'p'} className={commonStyle.searchBtnFont}>
									{language.delete}
								</Typography>
							</Button>
							<Button
								fullWidth
								startIcon={isLoading ? <CircularProgress color={'inherit'} size={20}/> : undefined}
								onClick={() => {
									onUpdate()
								}}
								variant={'contained'}
								color={'primary'}
								className={commonStyle.button}>
								<Typography component={'p'} className={commonStyle.searchBtnFont}>
									{language.update}
								</Typography>
							</Button>
						</div> :
						<Button
							startIcon={isLoading ? <CircularProgress color={'inherit'} size={20}/> : undefined}
							disabled={disbaleSave}
							onClick={() => {
								onSave()
							}}
							variant={'contained'}
							fullWidth
							color={'primary'}
							className={commonStyle.button}>
							<Typography component={'p'} className={commonStyle.searchBtnFont}>
								{language.save}
							</Typography>
						</Button>
				}
			</div>
			<VirtualList
				open={customerView}
				setClose={data => onCustomerViewClose(data)}
				title={language.cust_list}
				data={customerList}
				language={language}
			/>
		</AnyDialog>
	)
}


const LISTBOX_PADDING = 8;

function renderRow(props) {
	const {data, index, style} = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const {children, ...other} = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {noSsr: true});
	const itemCount = itemData.length;
	const itemSize = smUp ? 48 : 65;

	const getChildSize = (child) => {
		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

