import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route} from "react-router-dom";
import JString from "@easytech-international-sdn-bhd/jstring";

export default function RouteWrapper({
										 component: Component,
										 isPrivate,
										 ...rest
									 }) {
	const session = JString(sessionStorage.getItem('session'));
	const signed = session.isValid();

	if (!signed) {
		return <Redirect to="/"/>;
	}

	/*if (!isPrivate && signed) {
		return <Redirect to="/main" />;
	}*/

	return <Route {...rest} component={Component}/>;
}

RouteWrapper.propTypes = {
	isPrivate: PropTypes.bool,
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
	isPrivate: false
};
