import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service from '../../Services/API_Service';
import {empty, req_cust_chain, req_cust_type} from '../../Helper/Constants';
import {useMediaQuery, useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	}
}));

export default function AddCustType({open, onClose, language, onSnack, axiosConfig}) {
	const classes = useStyles();

	const [custTypeCode, setCustTypeCode] = useState(empty);
	const [custTypeName, setCustTypeName] = useState(empty);

	useEffect(() => {
	}, [open]);

	const closeRemove = (data) => {
		setCustTypeCode(empty);
		setCustTypeName(empty);
		if (data) {
			onClose(data[0]);
		}
	}

	const addCustType = () => {
		let formData = new FormData();
		formData.append('cust_type_code', custTypeCode);
		formData.append('cust_type_name', custTypeName);

		if (custTypeName === empty || custTypeCode === empty) {
			onSnack('Insert Type Code and Type Name', 'warning');
		} else {
			API_Service.post(req_cust_type, formData, axiosConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					closeRemove(extraData);
					onSnack(language.insert_success);
				} else {
					onSnack(language.insert_fail, 'warning');
				}
			});
		}
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog fullScreen={fullScreen} onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{language.new_cust_type}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						id="input-type-code"
						style={{width: '48%', height: 50}}
						inputProps={{maxLength: 10, pattern: "[a-z]{1,15}"}}
						label={language.cust_type_code}
						value={custTypeCode}
						onChange={(e) => setCustTypeCode(e.target.value)}
					/>
					<TextField
						id="input-type-name"
						style={{width: '48%', height: 50}}
						label={language.cust_type_name}
						value={custTypeName}
						onChange={(e) => setCustTypeName(e.target.value)}
					/>
				</div>
				<div style={{height: 20}}/>
				<div style={{display: "flex"}}>
					<Button
						style={{marginLeft: 'auto'}}
						variant="outlined"
						disabled={custTypeCode === empty || custTypeName === empty}
						onClick={() => {
							addCustType()
						}}
						color={'primary'}>
						{language.add}
					</Button>
				</div>
				<div style={{height: 20}}/>
			</div>
		</Dialog>
	)
}

