import { AppBar, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import API_Service, { axiosURIencode } from '../../../Services/API_Service';
import { empty, no_image, req_compt_productdetails, req_compt_productimage } from '../../../Helper/Constants';
import { arrayBufferToBase64, de } from '../../../Helper/Helper';
import { usePaperStyles } from '../../../Styles/StylePaper';
import MediaBox from '../../../Components/MediaBox';
import ReactImageMagnify from 'react-image-magnify';
import { DropzoneArea } from 'material-ui-dropzone';
import { grey } from '@material-ui/core/colors';

const labels = {
	product_code: "Code",
	product_name: "Name",
    product_barcode: "Barcode",
	product_remark: "Remark",
    product_desc: "Description",
	created_at: "Created At"
};

const useStyles = makeStyles((theme) => ({
    appBar: {
		position: 'relative',
	},
    title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
    grid: {
		padding: theme.spacing(1),
		maxWidth: '100%'
	},
    paper: {
		padding: theme.spacing(1),
		textAlign: 'flex-start',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
        flex: 1
	},
    paper2: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 300,
	},
    viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
    large_image: {
		minWidth: 800,
		maxHeight: 450
	},
	large_image2: {
		maxWidth: 400,
		maxHeight: 450
	},
    dropzoneText: {
		color: grey[600]
	},
}));

export default function CompetitorProductDetails({open, onDialogClose, details, axiosConfig, axiosPut, language, snack, onReload}) {
    const classes = useStyles();
    const paperStyle = usePaperStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [editDialog, setEditDialog] = useState(false);

    const [cmptProductID, setCmptProductID] = useState(empty);
    const [cmptProductCode, setCmptProductCode] = useState(empty);
    const [cmptProductName, setCmptProductName] = useState(empty);
    const [cmptProductBarcode, setCmptProductBarcode] = useState(empty);
    const [cmptProductRemark, setCmptProductRemark] = useState(empty);
    const [cmptProductDesc, setCmptProductDesc] = useState(empty);
    const [oriCmptProductCode, setOriCmptProductCode] = useState(empty);
    const [imageList, setImageList] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [itemImage, setItemImage] = useState(empty);

    const [showDropzone, setShowDropzone] = useState(true);

    useEffect(() => {
        if(Object.keys(details).length !== 0){
            setCmptProductID(details['id']);
            setCmptProductCode(details['product_code']);
            setCmptProductName(details['product_name']);
            setCmptProductBarcode(details['product_barcode']);
            setCmptProductRemark(details['product_remark']);
            setCmptProductDesc(details['product_desc']);
            setOriCmptProductCode(details['product_code']);

            if(details['product_image']){
                const array = JSON.parse(details['product_image']);
                setImageList(array);
            }
        }
    }, [details]);

    const editCompetitorProduct = (config = axiosPut) => {
        if (cmptProductCode.trim() === empty || oriCmptProductCode.trim() === empty) {
            snack('Product code can not leave blank when editing.', 'warning');
        } else {
            var params = {
                product_code: cmptProductCode,
                product_name: cmptProductName,
                product_barcode: cmptProductBarcode,
                product_desc: cmptProductDesc,
                product_remark: cmptProductRemark,
                id: cmptProductID,
                ori_product_code: oriCmptProductCode,
            };
            params = axiosURIencode(params);
            API_Service.put(req_compt_productdetails, params, config).then(({data}) => {
                var {result, message, extraData} = data;
                if (result == 1) {
                    details['product_code'] = cmptProductCode;
                    details['product_name'] = cmptProductName;
                    details['product_barcode'] = cmptProductBarcode;
                    details['product_remark'] = cmptProductDesc;
                    details['product_desc'] = cmptProductRemark;
                    onReload();
                    onEditDialogClose();
                    snack(message);
                } else {
                    snack(message, 'error');
                }
            }).catch((err) => de(err.message));
        }
    }

    const onEditDialogOpen = () => {
        if(Object.keys(details).length !== 0){
            setCmptProductID(details['id']);
            setCmptProductCode(details['product_code']);
            setCmptProductName(details['product_name']);
            setCmptProductBarcode(details['product_barcode']);
            setCmptProductRemark(details['product_remark']);
            setCmptProductDesc(details['product_desc']);
            setOriCmptProductCode(details['product_code']);
        }

        setEditDialog(true)
    }

    const closeDialog = () => {
        setCmptProductID(empty);
        setCmptProductCode(empty);
        setCmptProductName(empty);
        setCmptProductBarcode(empty);
        setCmptProductRemark(empty);
        setCmptProductDesc(empty);
        setItemImage(empty);
        setImageList([]);
        setOriCmptProductCode(empty);

        onDialogClose();
    }

    const onEditDialogClose = () => {
        setCmptProductID(empty);
        setCmptProductCode(empty);
        setCmptProductName(empty);
        setCmptProductBarcode(empty);
        setCmptProductRemark(empty);
        setCmptProductDesc(empty);
        setOriCmptProductCode(empty);

        setEditDialog(false);
    }

    const deleteImage = (url, config = axiosPut) => {
        var params = {
			image_url: url,
            id: cmptProductID
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt_productimage, params, config).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
                onReload();
                // onEditDialogClose();
                loadProductDetail();
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
    }

    const loadProductDetail = (config = axiosConfig) => {
        API_Service.get(`${req_compt_productdetails}?id=${cmptProductID}`, config).then(({data}) => {
            const {result, extraData} = data;
            if(result){
                const array = JSON.parse(extraData[0]['product_image']);
                setImageList(array);
            }
        }).catch(de);
    }

    const onItemImageUpload = (files) => {
        var imageArrayBuffer = [];
        if (files.length > 0) {
            Array.from(files).forEach(file => {
                const reader = new FileReader();
            
                reader.onabort = () => de('file reading was aborted');
                reader.onerror = () => de('file reading has failed');
                reader.onload = () => {
                    const binaryStr = reader.result;
                    imageArrayBuffer.push(arrayBufferToBase64(binaryStr));
                    setItemImage(itemImage => imageArrayBuffer);
                };
            
                reader.readAsArrayBuffer(file);
            });
		}else{
            setItemImage(empty);
        }
    }

    const uploadItemImage = (config = axiosPut) => {
        if(itemImage === empty){
            snack('Please insert image.', 'warning');
        }else{
            var params = {
                update: true,
                image: itemImage,
                id: cmptProductID
            };
            params = axiosURIencode(params);
            API_Service.put(req_compt_productimage, params, config).then(({data}) => {
                var {result, message, extraData} = data;
                if (result == 1) {
                    // onEditDialogClose();
                    setItemImage(empty);
                    loadProductDetail();
                    snack(message);
                    setShowDropzone(false);
					setTimeout(() => {
						setShowDropzone(true);
					}, 100);
                    onReload();
                } else {
                    snack(message, 'error');
                }
            }).catch((err) => de(err.message));
        }
    }

	return (
        <>
            <Dialog fullScreen open={open} onClose={() => closeDialog()} /*TransitionComponent={Transition}*/>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {language.competitor_product_details}
                        </Typography>
                        {/* {
                            integration === '0' && currentPage === 0 && ( */}
                                <Button
                                    onClick={() => onEditDialogOpen()}
                                    size="small">
                                    <EditIcon style={{color: 'black'}}/>
                                </Button>
                            {/* )
                        } */}
                    </Toolbar>
                </AppBar>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={6} style={{display: 'flex'}}>
                        <Paper className={classes.paper}>
                            {
                                Object.keys(labels).map((key, idx) => {
                                    var subHeader = labels[key];
                                    var listValue = (details[key] == null || details[key] == '') ? '-' : details[key];
                                    if (key === 'created_at') {
                                        listValue = details[key] == null ? '-' : moment(details[key], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                                    }
                                    return (
                                        <div
                                            style={{marginTop: 10}}
                                            key={String(idx)}>
                                            <Typography variant={'inherit'} component={'p'}>
                                                {subHeader}
                                            </Typography>
                                            <Typography variant={'inherit'} component={'h4'}>
                                                {listValue}
                                            </Typography>
                                        </div>
                                    )
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper2}>
                            {
                                showDropzone ? 
                                    <DropzoneArea
                                        onChange={(files) => {
                                            onItemImageUpload(files)
                                        }}
                                        dropzoneParagraphClass={classes.dropzoneText}
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        filesLimit={10}
                                        clearOnUnmount={true}
                                    /> : <div style={{height: 250}}/>
                            }
                            <Button
                                style={{zIndex: 9999}}
                                disabled={itemImage === empty}
                                onClick={() => uploadItemImage()}
                                disableElevation={true}
                                size="small"
                                color={'primary'}>
                                {language.save || ''}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={12}>
                        {
                            imageList.length > 0 ?
                                <div className={paperStyle.paperViewWOspace}>
                                    {
                                        imageList.map((item, index) => {
                                            return (
                                                <MediaBox
                                                    key={String(index)}
                                                    image={item || no_image}
                                                    title={null}
                                                    description={null}
                                                    // primaryBtn={language.set_default}
                                                    secondaryBtn={language.delete}
                                                    viewBtn={'View'}
                                                    // primaryBtnAction={() => setDefaultImage(item.product_image_id)}
                                                    secondaryBtnAction={() => deleteImage(item)}
                                                    viewBtnAction={() => setSelectedImage(item)}
                                                    // default={item.product_default_image == 1 ? language.default : null}
                                                />
                                            )
                                        })
                                    }
                                </div> : null
                        }
                    </Grid>
                </Grid>
            </Dialog>

            <Dialog fullScreen={fullScreen} aria-labelledby="simple-dialog-title" onClose={() => onEditDialogClose()} open={editDialog}>
                <DialogTitle id="simple-dialog-title">
                    {language.edit_product}
                </DialogTitle>
                <div style={{width: 600, padding: theme.spacing(2)}}>
                    <div className={classes.viewInRowSpace}>
                        <TextField
                            id="input-cmpt-product-code"
                            style={{width: '48%', height: 50}}
                            inputProps={{maxLength: 20}}
                            label={language.product_code}
                            value={cmptProductCode}
                            onChange={(e) => setCmptProductCode(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-product-name"
                            style={{width: '48%', height: 50}}
                            label={language.product_name}
                            value={cmptProductName}
                            onChange={(e) => setCmptProductName(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-product-barcode"
                            style={{width: '48%', height: 50}}
                            label={language.barcode}
                            value={cmptProductBarcode}
                            onChange={(e) => setCmptProductBarcode(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-product-remark"
                            style={{width: '100%', height: 50}}
                            label={language.remark}
                            value={cmptProductRemark}
                            onChange={(e) => setCmptProductRemark(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-product-description"
                            style={{width: '100%', height: 100}}
                            multiline
                            rows={4}
                            label={language.description}
                            value={cmptProductDesc}
                            onChange={(e) => setCmptProductDesc(e.target.value)}
                        />
                    </div>
                    <div style={{height: 20}}/>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{marginLeft: 'auto'}}
                            variant="outlined"
                            onClick={() => {
                                editCompetitorProduct();
                            }}
                            color={'primary'}>
                            {language.update}
                        </Button>
                    </div>
                    <div style={{height: 20}}/>
                </div>
            </Dialog>

            {
                selectedImage && (
                    <Dialog
                        fullWidth={false}
                        maxWidth={'lg'}
                        open={selectedImage != null}
                        aria-labelledby="form-dialog-title"
                        onClose={() => {
                            setSelectedImage(null)
                        }}>
                        <DialogContent style={{height: 600}}>
                            <div className={classes.large_image}>
                                <ReactImageMagnify
                                    className={classes.large_image2}
                                    {...{
                                        smallImage: {
                                            alt: 'Neckwatch by ChengXi Malaysia',
                                            isFluidWidth: true,
                                            src: selectedImage,
                                        },
                                        largeImage: {
                                            src: selectedImage,
                                            width: 1200,
                                            height: 1800
                                        }
                                    }}
                                />
                            </div>
                        </DialogContent>
                    </Dialog>
                )
            }
        </>
	)
}