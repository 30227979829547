import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import JString from '@easytech-international-sdn-bhd/jstring';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {grey} from '@material-ui/core/colors';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {
    empty,
    no_image,
    no_image_file,
    pdf_image,
    req_cust,
    req_mobile_module_setting,
    req_module_setting,
    req_stock_atch,
    req_stock_batch,
    req_stock_commission,
    req_stock_def,
    req_stock_image,
    req_stock_info,
    req_stock_minqty,
    req_stock_update_qty,
    req_stock_vipqty
} from '../../Helper/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ExposureIcon from '@material-ui/icons/Exposure';
import Button from '@material-ui/core/Button';
import {DropzoneArea} from 'material-ui-dropzone';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import {usePaperStyles} from '../../Styles/StylePaper';
import moment from 'moment';
import _ from 'lodash';
import MediaBox from '../MediaBox';
import {arrayBufferToBase64, de, getProductInfoByCode, getSetting} from '../../Helper/Helper';
import MUIDataTable from 'mui-datatables';
import {NodataLottie} from '../LottieLoading/NodataLottie';
import {useCommonStyles} from '../../Styles';
import VirtualList from '../VirtualList';
import InputDialog from '../InputDialog';
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Tab, Tabs} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import Alert from '../Alert';
import EditBarcode from '../EditBarcode';
import EditIcon from '@material-ui/icons/Edit';
import EditProductName from '../EditProductName';
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from '@material-ui/icons/Add';
import EditBatch from '../EditBatch';

const vipHeadCell = [
	{
		name: "id",
		label: "ID",
		options: {
			display: false
		}
	},
	{
		name: "cust_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "vip_qty",
		label: "QTY",
		options: {
			display: true,
			filter: false
		}
	},
];

const minHeadCell = [
	{
		name: "id",
		label: "ID",
		options: {
			display: false
		}
	},
	{
		name: "cust_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "product_min_qty",
		label: "QTY",
		options: {
			display: true,
			filter: false
		}
	},
];

const useStyles = makeStyles((theme) => ({
	card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	dropzoneText: {
		color: grey[600]
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	vipQtyTitle: {
		margin: theme.spacing(2),
		flex: 1,
	},
	atchTitle: {
		margin: theme.spacing(1),
		flex: 1,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	root: {
		flexGrow: 1,
	},
	pdfRoot: {
		maxWidth: 110,
		maxHeight: 230,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	pdfMedia: {
		height: 120,
		width: 100,
		margin: 'auto',
		marginTop: theme.spacing(1)
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 300,
	},
	imgPaper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 400
	},
	vareaFair: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 470,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	varea: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 370,
	},
	vemptyarea: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 100
	},
	vplayer: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 250
	},
	vview: {
		padding: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 320,
		width: 400,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		justifyContent: 'center'
	},
	center: {
		justifyContent: 'center'
	},
	fairspace: {
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	grid: {
		padding: theme.spacing(1),
	},
	textleft: {
		textAlign: "left"
	},
	maxImage: {
		maxWidth: 250,
		maxHeight: 280
	},
	marginTop: {
		marginTop: theme.spacing(1)
	},
	chip: {
		margin: theme.spacing(0.5),
		marginLeft: 0,
		marginBottom: 0
	},
	chipInRow: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		margin: 0,
		marginLeft: -5
	},
	pdfPadding: {
		padding: theme.spacing(1),
		width: '50%'
	},
	padding: {
		padding: theme.spacing(1),
		width: '100%'
	},
	viplayer: {

		width: '80%',
		margin: 'auto'
	},
	height: {
		height: 250,
	},
	bottomNav: {
		position: 'fixed',
		bottom: 0,
		top: 'auto',
		width: '100%'
	},
	textfield: {
		width: '80%'
	},
	paperViewWOspace: {
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			width: '25%'
		},
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		textAlign: 'center',
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	font: {
		textAlign: 'center'
	},
	fontGrey: {
		color: 'grey',
		textAlign: 'left'
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			format={'##%'}
			isNumericString
			suffix="%"
			min={0}
			max={99}
		/>
	);
}

function NumberFormatCustomVIPQty(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			format={'#######'}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}

function NumberFormatCustomMINQty(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			format={'#######'}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}

function NumberFormatCustomCommission(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={true}
			isNumericString
			format={'######'}
			min={0}
			max={999999}
		/>
	);
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default function ItemDetails({
										item: currentItem,
										open,
										setClose,
										language,
										onUpdateStock,
										onSnack,
										integration,
										categories = [],
										brands = []
									}) {
	const classes = useStyles();
	const paperStyle = usePaperStyles();
	const commonStyle = useCommonStyles();
	const [gState] = useContext(AppContext);

	const [currentPage, setCurrentPage] = useState(0);
	const [qtyCurrentPage, setQtyCurrentPage] = useState(0);
	const [pdfCurrentPage, setPdfCurrentPage] = useState(0);

	const [virtualQTY, setVirtualQTY] = useState(empty);
	const [vipQty, setVipQty] = useState([]);
	const [CommissionMargin, setCommissionMargin] = useState(empty);
	const [item, setItem] = useState(currentItem);
	const [itemImage, setItemImage] = useState(null);
	const [activeImageList, setActiveImageList] = useState([]);
	const [isUpdatingVirtual, setUpdatingVirtual] = useState(false);
	const [isUpdatingAvail, setUpdatingAvail] = useState(false);
	const [isUpdatingCommission, setUpdatingCommission] = useState(false);
	const [showDropzone, toggleDropZone] = useState(true);
	const [vipCustomer, setVipCustomer] = useState({
		name: undefined,
		value: undefined
	});
	const [editAvailableQty, setEditAvailableQty] = useState(empty);
	const [vipCustomerQty, setVipCustomerQty] = useState(empty);
	const [customerList, setCustomerList] = useState([]);
	const [showCustomerList, toggleCustomerList] = useState(false);
	const [showEditVip, setEditVip] = useState(false);
	const [editVipQtyInfo, setEditVipQtyInfo] = useState({
		cust_code: undefined,
		vip_qty: undefined
	});
	const [isVipQtyLoading, setVipQtyLoading] = useState(false);

	//product min qty
	const [minQty, setMinQty] = useState([]);
	const [minCustomer, setMinCustomer] = useState({
		name: undefined,
		value: undefined
	});
	const [minCustomerQty, setMinCustomerQty] = useState(empty);
	const [minCustomerList, setMinCustomerList] = useState([]);
	const [showMinCustomerList, toggleMinCustomerList] = useState(false);
	const [isMinQtyLoading, setMinQtyLoading] = useState(false);
	const [showEditMin, setEditMin] = useState(false);
	const [editMinQtyInfo, setEditMinQtyInfo] = useState({
		cust_code: undefined,
		product_min_qty: undefined
	});

	const [itemPdf, setItemPdf] = useState(null);
	const [videoLink, setVideoLink] = useState(null);
	const [videoId, setVideoId] = useState(null);

	const [activePdfList, setActivePdfList] = useState([]);
	const [activeVideoList, setActiveVideoList] = useState([]);

	const [idToDelete, setIdToDelete] = useState(null);

	const [deleteAtchAlert, showDeleteAtchAlert] = useState(false);

	const [itemUom, setItemUom] = useState([]);
	const [openBarcode, setOpenBarcode] = useState(false);
	const [uomSelected, setUomSelected] = useState(null);
	const [barcode, setBarcode] = useState(empty);
	const [price, setPrice] = useState(0);
	const [price2, setPrice2] = useState(0);
	const [rate, setRate] = useState(empty);
	const [openProductEdit, setOpenProductEdit] = useState(false);
	const [categoryName, setCategoryName] = useState('');
	const [brandName, setBrandName] = useState('');
	const [categoryList, setCategoryList] = useState([]);
	const [brandList, setBrandList] = useState([]);
	const [customization, setcustomization] = useState(false);
	const [stdTitle, setStdTitle] = useState(empty);
	const [minTitle, setMinTitle] = useState(empty);
	const [batchModule, setBatchModule] = useState(false);
	const [openBatchDialog, setOpenBatch] = useState(false);
	const [batchList, setBatchList] = useState([]);
	const [selectedBatchNo, setSelectedBatchNo] = useState(empty);

	const invalidateAll = () => {
		setItemUom([]);
		setVipCustomerQty(empty);
		setVipQty([]);
		setCommissionMargin(empty);
		setItem(null);
		setItemImage(null);
		setActiveImageList([]);
		setUpdatingVirtual(false);
		setUpdatingCommission(false);
		toggleDropZone(true);
		setVipCustomer({
			name: undefined,
			value: undefined
		});
		setVipCustomerQty(empty);
		setCustomerList([]);
		toggleCustomerList(false);
		setEditVip(false);
		setEditVipQtyInfo({
			cust_code: undefined,
			vip_qty: undefined
		});
		setVipQtyLoading(false);
		setUpdatingAvail(false);
		setMinQty([]);
		setEditMinQtyInfo({
			cust_code: undefined,
			product_min_qty: undefined
		});
		setMinQtyLoading(false);
		setMinCustomerQty(empty);
		setMinCustomer({
			name: undefined,
			value: undefined
		});
		setMinCustomerQty(empty);
		setMinCustomerList([]);
		toggleMinCustomerList(false);

		setVideoId(null);
		setVideoLink(null);
		setActivePdfList([]);
		setActiveVideoList([]);
		setItemPdf(null);
		setBatchList([]);
	}

	useEffect(() => {
		if (!_.isEmpty(currentItem)) {
			de("deleteUom::useEffect");
			loadProdUom();
			setItem(item => (currentItem));
			loadProductImages(currentItem);
			loadCustomerList();
			loadModuleSettings();
			loadConfig();
		}
	}, [currentItem]);

	useEffect(() => {
		if (!_.isEmpty(item)) {
			var category_id = item.category_id ? JString(item.category_id).toNumber() : 0;
			var brand_id = item.brand_id ? JString(item.brand_id).toNumber() : 0;
			var cat_list = [];
			var bra_list = [];
			if (categories.length > 0) {
				if (category_id !== 0) {
					for (let i = 0; i < categories.length; i++) {
						const element = categories[i];
						if (element['category_id'] == category_id) {
							setCategoryName(element['category_name']);
							break;
						}
					}
				}
				for (let j = 0; j < categories.length; j++) {
					const {category_status} = categories[j];
					if (category_status == 1) {
						cat_list.push(categories[j]);
					}
				}
				setCategoryList(() => (cat_list));
			}

			if (brands.length > 0) {
				if (brand_id !== 0) {
					for (let i = 0; i < brands.length; i++) {
						const element = brands[i];
						if (element['brand_id'] == brand_id) {
							setBrandName(element['brand_name']);
							break;
						}
					}
				}
				for (let j = 0; j < brands.length; j++) {
					const {brand_status} = brands[j];
					if (brand_status == 1) {
						bra_list.push(brands[j]);
					}
				}
				setBrandList(() => (bra_list));
			}
		}
	}, [categories, brands, item]);

	const loadProdBatch = () => {
		API_Service.get(`${req_stock_batch}?product_code=${currentItem?.product_code}`, axiosPutConfig(gState.client)).then(({data}) => {
			var {result, extraData} = data;
			if (extraData) {
				setBatchList(extraData);
			}
		});
	}

	const loadProdUom = () => {
		getProductInfoByCode(currentItem?.product_code, axiosPutConfig(gState.client), (extraData) => {
			de("loadProdUom::why?",currentItem?.product_code,extraData);
			if (extraData) {
				var {uom_price} = extraData;
				setItemUom(itemUom => (uom_price));
			}
		});
	}

	const loadCustomerList = () => {
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.get(req_cust, axiosConfig).then(({data}) => {
			if (data) {
				var {result, extraData: apiCust} = data;
				var {customer} = apiCust;
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let idx = 0; idx < customer.length; idx++) {
						const {cust_company_name, cust_code} = customer[idx];
						_data.push({name: cust_company_name, value: cust_code});
					}
					_data.push({name: gState.translation.customer, value: 0});
					setCustomerList(customerList => (_data));
					setMinCustomerList(minCustomerList => (_data));
				}
			}
		});
	}

	const loadVipQty = (newItem) => {
		if (newItem) {
			var axiosConfig = axiosPutConfig(gState.client);
			API_Service.get(`${req_stock_vipqty}?product_code=${newItem.product_code}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result == 1) {
					setVipQty(vipQty => (extraData));
				}
			});
		}
	}

	const loadMinQty = (newItem) => {
		if (newItem) {
			var axiosConfig = axiosPutConfig(gState.client);
			API_Service.get(`${req_stock_minqty}?product_code=${newItem.product_code}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result == 1) {
					setMinQty(minQty => (extraData));
				}
			});
		}
	}

	const loadProductImages = (newItem) => {
		if (newItem) {
			var axiosConfig = axiosPutConfig(gState.client);
			API_Service.get(`${req_stock_image}?product_id=${newItem.product_id}`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				if (result == 1) {
					setActiveImageList(activeImageList => (extraData));
				} else {
					setActiveImageList([]);
				}
			});
		}
	}

	const updateAtchState = (id) => {
		setIdToDelete(id);
		showDeleteAtchAlert(true);
	}

	const loadProductPdf = (newItem) => {
		de('loadProductPdf newItem ==> ', newItem);
		if (newItem) {
			var axiosConfig = axiosPutConfig(gState.client);
			API_Service.get(`${req_stock_atch}?product_code=${newItem.product_code}&content_type=pdf`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				de('loadProductPdf result ==> ', result);
				de('loadProductPdf extraData ==> ', extraData);
				if (result == 1) {
					de('loadProductPdf ==> ', loadProductPdf);
					var _data = [];
					for (let idx = 0; idx < extraData.length; idx++) {
						const {id, product_code, link, created_date} = extraData[idx];
						var date = moment(created_date).format('DD-MM-YYYY');
						_data.push({id: id, name: product_code, value: link, created_date: date});
					}
					setActivePdfList(activePdfList => (_data));
				} else {
					setActivePdfList([]);
				}
			});
		}
	}

	const loadProductVideo = (newItem) => {
		if (newItem) {
			var axiosConfig = axiosPutConfig(gState.client);
			API_Service.get(`${req_stock_atch}?product_code=${newItem.product_code}&content_type=video`, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				result = JString(result).toNumber();
				//de('loadProductVideo result ==> ', result);
				//de('loadProductVideo extraData ==> ', extraData);
				if (result == 1) {
					//de('loadProductVideo ==> ', loadProductVideo);
					var _data = [];
					for (let idx = 0; idx < extraData.length; idx++) {
						const {id, product_code, link} = extraData[idx];
						var str = initialGetVideoId(link);
						_data.push({id: id, name: product_code, value: str});
					}
					setActiveVideoList(activeVideoList => (_data));
				}
			});
		}
	}

	const onChangeVirtualQTY = (props) => {
		setVirtualQTY(() => (props.target.value));
	}

	const onChangeAvailQTY = (props) => {
		setEditAvailableQty(props.target.value);
	}

	const onChangeCommission = (props) => {
		setCommissionMargin(props.target.value);
	}

	const onChangeVipQty = (props) => {
		setVipCustomerQty(vipCustomerQty => (props.target.value));
	}

	const onChangeMinQty = (props) => {
		setMinCustomerQty(minCustomerQty => (props.target.value));
	}

	const onVirtualQtyUpdate = () => {
		setUpdatingVirtual(true);
		var params = {
			product_code: item.product_code,
			virtual_qty: virtualQTY
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);

		API_Service.put(req_stock_def, params, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				extraData = extraData[0];
				setItem(() => (extraData));
				onUpdateStock(extraData);
			}
			setUpdatingVirtual(false);
		});
	}

	const deleteBatchNo = (data) => {
		var batches = '';
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {batch_code} = batchList[dataIndex];
			batches += batch_code;
			if (index != (size - 1) && size != 1) {
				batches += ',';
			}
		}
		const params = axiosURIencode({
			product_code: currentItem?.product_code,
			batch_no: batches,
			active_status: 0
		});

		API_Service.put(req_stock_batch, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result, message} = data;
			if (parseInt(result) === 1) {
				onSnack(language.update_success);
				loadProdBatch();
			} else {
				onSnack(language.update_fail, 'error');
			}
		});
	}

	const deleteUom = (data) => {
		var uoms = '';
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {product_uom} = itemUom[dataIndex];
			uoms += JString(product_uom).toString();
			if (index != (size - 1) && size != 1) {
				uoms += ',';
			}
		}
		var params = {
			product_code: currentItem?.product_code,
			uom: uoms,
			active_status: 0
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);

		API_Service.put(`${req_stock_info}`, params, axiosConfig).then(({data}) => {
			var {result} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				onSnack && onSnack(language.update_success, 'success');
				de("deleteUom::loadProdUom");
				loadProdUom();
			} else {
				onSnack && onSnack(language.update_fail, 'error');
			}
		});
	}

	const calcVirtual = (referred_qty = -1) => {
		return Math.round(
			parseInt(item?.product_current_quantity) *
			(referred_qty === -1 ? virtualQTY : referred_qty) /
			100
		);
	}

	const onClose = () => {
		setVirtualQTY(() => (empty));
		setBrandName(empty);
		setCategoryName(empty);
		setClose();
	}

	const onItemImageUpload = (files) => {
		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setItemImage(itemImage => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const uploadItemImage = () => {
		de('bufferbased64 ==>', arrayBufferToBase64(itemImage));
		var axiosConfig = axiosPutConfig(gState.client);
		var formdata = new FormData();
		formdata.append('product_id', item.product_id);
		formdata.append('product_image', arrayBufferToBase64(itemImage));
		formdata.append('default_image', 0);
		formdata.append('active_status', 1);
		formdata.append('created_date', moment().format('YYYY-MM-DD'));
		API_Service.post(req_stock_image, formdata, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				onSnack && onSnack(language.record_updated, 'success');
				loadProductImages(currentItem);
				setItemImage(null);
				toggleDropZone(false);
				setTimeout(() => {
					toggleDropZone(true);
				}, 100);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	const inactiveItemImage = (image_id) => {
		var donotAllowToSave = false;
		if (activeImageList.length > 1) {
			for (let index = 0; index < activeImageList.length; index++) {
				const {product_image_id, product_default_image} = activeImageList[index];
				if (image_id == product_image_id && product_default_image == 1) {
					donotAllowToSave = true;
					break;
				}
			}
		}
		if (donotAllowToSave) {
			onSnack && onSnack(language.def_del_image, 'error');
			return;
		}
		var params = {
			image_id: image_id
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_image, params, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				onSnack && onSnack(language.record_updated, 'success');
				loadProductImages(currentItem);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	const setDefaultImage = (image_id) => {
		var params = {
			product_id: currentItem.product_id,
			image_id: image_id,
			default: 1
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_image, params, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				onSnack && onSnack(language.record_updated, 'success');
				onUpdateStock(extraData[0]);
				setItem(extraData[0]);
				loadProductImages(currentItem);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	/* PDF FX HERE */
	const onItemPdfUpload = (files) => {
		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setItemPdf(itemPdf => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const uploadItemPdf = () => {
		var axiosConfig = axiosPutConfig(gState.client);
		var formdata = new FormData();
		formdata.append('product_code', item.product_code);
		formdata.append('link', arrayBufferToBase64(itemPdf));
		formdata.append('content_type', 'pdf');
		formdata.append('active_status', 1);
		formdata.append('created_date', moment().format('YYYY-MM-DD'));
		API_Service.post(req_stock_atch, formdata, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			de('result uploadItemPdf ===> ', result);
			de('result extraData ===> ', extraData);
			if (result === 1) {
				onSnack && onSnack(language.pdf_uploaded, 'success');
				loadProductPdf(currentItem);
				toggleDropZone(false);
				setTimeout(() => {
					toggleDropZone(true);
				}, 100);
			} else {
				onSnack && onSnack(language.pdf_failed, 'error');
			}
		});
	}

	const uploadItemVideo = () => {
		var axiosConfig = axiosPutConfig(gState.client);
		var formdata = new FormData();
		formdata.append('product_code', item.product_code);
		formdata.append('link', videoLink);
		formdata.append('content_type', 'video');
		formdata.append('active_status', 1);
		formdata.append('created_date', moment().format('YYYY-MM-DD'));
		API_Service.post(req_stock_atch, formdata, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			// de('result uploadItemVideo ===> ', result);
			// de('result extraData ===> ', extraData);
			if (result === 1) {
				onSnack && onSnack(language.video_uploaded, 'success');
				setVideoLink(null);
				setVideoId(null);
				loadProductVideo(currentItem);
			} else {
				onSnack && onSnack(language.video_failed, 'error');
			}
		});
	}


	const deleteBtnAction = (id) => {
		showDeleteAtchAlert(false);
		de('deleteBtnAction id ===> ', id);
		var params = {
			id: id
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_atch, params, axiosConfig).then(({data}) => {
			var {result, message} = data;
			result = JString(result).toNumber();
			de('deleteBtnAction result ===> ', result);
			de('deleteBtnAction message ===> ', message);
			if (result === 1) {
				onSnack && onSnack(language.record_updated, 'success');
				de('currentItem =====> ', currentItem);
				loadProductVideo(currentItem);
				loadProductPdf(currentItem);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	const barcodeEdit = (index) => {
		setOpenBarcode(true);
		setUomSelected(itemUom[index]['product_uom']);
		setBarcode(itemUom[index]['QR_Code']);
		setPrice(parseFloat(itemUom[index]['product_std_price']).toFixed(2));
		setPrice2(parseFloat(itemUom[index]['product_min_price']).toFixed(2));
		setRate(itemUom[index]['product_uom_rate']);
	}

	const batchEdit = (index) => {
		const data = {
			batch_no: batchList[index]['batch_code'],
			expiry_date: batchList[index]['exp_date'],
			manufactured_date: batchList[index]['mfg_date']
		};
		setSelectedBatchNo(data);
		setOpenBatch(true);
	}

	const loadConfig = () => {
		API_Service.get(req_module_setting, axiosDefaultConfig(gState.client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _availableModules = getSetting(extraData, 'modules', {});
				// de("betty-module", _availableModules);
				if(_availableModules.productBatchNo){
					setBatchModule(_availableModules.productBatchNo);
					loadProdBatch();
				}
			}
		});
	}

	const loadModuleSettings = () => {
		var config = axiosDefaultConfig(gState.client)
		API_Service.get(req_mobile_module_setting, config).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const {module, pdf_images} = extraData;
				for (let m = 0; m < module.length; m++) {
					if (module[m]['module'] === 'app_customer_price_title') {
						let setting = JSON.parse(module[m]['status']);
						setStdTitle(setting.std);
						setMinTitle(setting.min);
						setcustomization(true);
					}
				}
			} else {
				onSnack(language.not_found_salesperson, 'warning');
			}
		});
	}

	const uomHeadCell = [
		// {
		//     name:"product_code",
		//     label:"Product Code"
		// },
		{
			name: "product_uom",
			label: "Product UOM"
		},
		{
			name: "product_std_price",
			label: customization ? stdTitle : "Product Std Price",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return parseFloat(value).toFixed(2);
				}
			}
		},
		{
			name: "product_min_price",
			label: customization ? minTitle : "Product Min Price",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return parseFloat(value).toFixed(2);
				}
			}
		},
		{
			name: "QR_Code",
			label: "Barcode"
		},
		{
			name: "product_uom_rate",
			label: "UOM Rate"
		},
		{
			name: "",
			label: "",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					if (integration === '0') {
						return (

							<div>
								<Button
									onClick={() => barcodeEdit(dataIndex)}
									size="small"><EditIcon/>
								</Button>
							</div>
						);
					}

				}
			}
		}
	];

	const batchHeadCell = [
		{
			name: "batch_code",
			label: "Batch No",
			options: {
				display: true
			}
		},
		{
			name: "exp_date",
			label: "Expiry Date",
			options: {
				display: true
			}
		},
		{
			name: "mfg_date",
			label: "Manufactured Date",
			options: {
				display: true
			}
		},
		{
			name: "",
			label: "",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					if (integration === '0') {
						return (

							<div>
								<Button
									onClick={() => batchEdit(dataIndex)}
									size="small"><EditIcon/>
								</Button>
							</div>
						);
					}

				}
			}
		}
	];

	const onDeleteVipQTY = (data) => {
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {id} = vipQty[dataIndex];
			ids += JString(id).toString();
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		var params = {
			running_id: ids,
			delete: 1
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_vipqty, params, axiosConfig).then(({data}) => {
			setTimeout(() => {
				loadVipQty(currentItem);
			}, 500);
		});
	}

	const onDeleteMinQTY = (data) => {
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {id} = minQty[dataIndex];
			ids += JString(id).toString();
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		var params = {
			running_id: ids,
			delete: 1
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_minqty, params, axiosConfig).then(({data}) => {
			setTimeout(() => {
				loadMinQty(currentItem);
				onSnack && onSnack(language.min_qty_deleted, 'warning');
			}, 500);
		});
	}

	const onDeletePdf = (data) => {
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {id} = activePdfList[dataIndex];
			ids += JString(id).toString();
			if (index != (size - 1) && size != 1) {
				ids += "','";
			}
		}

		de('pdf id to be deleted ===> ', ids);
		deleteBtnAction(ids);
	}

	const onViewCustomerList = (data = null) => {
		if (data) {
			setVipCustomer(data);
		}
		toggleCustomerList(showCustomerList => (!showCustomerList));
	}

	const onViewMinCustomerList = (data = null) => {
		if (data) {
			setMinCustomer(data);
		}
		toggleMinCustomerList(showMinCustomerList => (!showMinCustomerList));
	}

	const onSaveCustomerVipQty = (quantity = -1) => {
		var params = {
			cust_code: vipCustomer.value,
			product_code: currentItem.product_code,
			vip_qty: quantity == -1 ? vipCustomerQty : quantity
		}
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_vipqty, params, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				setVipCustomerQty(empty);
				setVipCustomer(vipCustomer => ({name: undefined, value: undefined}));
				setTimeout(() => {
					loadVipQty(currentItem);
				}, 500);
			}
			setVipQtyLoading(isVipQtyLoading => (false));
		});
	}

	const onSaveCustomerMinQty = (quantity = -1) => {
		var custCode = minCustomer.value == undefined ? 0 : minCustomer.value;
		var params = {
			cust_code: minCustomer.value == undefined ? 0 : minCustomer.value,
			product_code: currentItem.product_code,
			product_min_qty: quantity == -1 ? minCustomerQty : quantity
		}
		var __MinQty = quantity == -1 ? minCustomerQty : quantity;
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);

		API_Service.put(req_stock_minqty, params, axiosConfig).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				onSnack && onSnack(language.min_qty_failed_update);
			}
			if (result === 1) {
				if (custCode == 0) {
					setMinCustomer(minCustomer => ({name: undefined, value: undefined}));
					setTimeout(() => {
						loadMinQty(currentItem);
						onSnack && onSnack(language.min_qty_updated_product);
					}, 500);
				} else {
					setMinCustomer(minCustomer => ({name: undefined, value: undefined}));
					setTimeout(() => {
						loadMinQty(currentItem);
						onSnack && onSnack(language.min_qty_updated);
					}, 500);
				}
			}
			setMinQtyLoading(isMinQtyLoading => (false));
		});
	}

	const onSaveAvailQty = () => {
		setUpdatingAvail(true);
		var params = {
			product_code: currentItem.product_code,
			quantity: editAvailableQty
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);
		API_Service.put(req_stock_update_qty, params, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const _newData = extraData[0];
				setItem(() => (_newData));
				onUpdateStock(_newData);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
			setUpdatingAvail(false);
		});
	}

	const onCustomerVipQtyClick = ({cust_code, vip_qty}) => {
		for (let index = 0; index < customerList.length; index++) {
			const obj = customerList[index];
			const {value} = obj;
			if (JString(value).isEqual(cust_code)) {
				setVipCustomer(vipCustomer => (obj));
				break;
			}
		}
		setEditVipQtyInfo(editVipQtyInfo => ({
			vip_qty,
			cust_code
		}));
		setEditVip(true);
	}

	const onChangeVipQtyEdit = (props) => {
		if (props == false) {
			setEditVip(false);
			return;
		}
		setVipQtyLoading(isVipQtyLoading => (true));
		var newQty = props['targetVipQty'];
		setVipCustomerQty(vipCustomerQty => (JString(newQty).toString()));
		onSaveCustomerVipQty(newQty);
		setEditVip(false);
	}

	const changeEditVipQtyView = {
		contentChildren: [
			{
				name: language.vip_qty,
				code: "targetVipQty",
				component: "textinput",
				type: "number",
				default_value: ''
			}
		],
		actionChildren: [
			{
				name: language.cancel,
				code: "cancel",
				action: false
			},
			{
				name: language.update,
				code: "update",
				action: true,
				condition: [{targetVipQty: 1}],
				onCondition: "disabled"
			}
		]
	};

	const onCustomerMinQtyClick = ({cust_code, product_min_qty}) => {
		for (let index = 0; index < minCustomerList.length; index++) {
			const obj = minCustomerList[index];
			const {value} = obj;
			if (JString(value).isEqual(cust_code)) {
				setMinCustomer(minCustomer => (obj));
				break;
			}
		}
		setEditMinQtyInfo(editMinQtyInfo => ({
			product_min_qty,
			cust_code
		}));
		setEditMin(true);
	}

	const onChangeMinQtyEdit = (props) => {
		if (props == false) {
			setEditMin(false);
			return;
		}
		setMinQtyLoading(isMinQtyLoading => (true));
		var newQty = props['targetMinQty'];
		setMinCustomerQty(minCustomerQty => (JString(newQty).toString()));
		onSaveCustomerMinQty(newQty);
		setEditMin(false);
	}

	const onCommissionUpdate = () => {
		setUpdatingCommission(true);
		var params = {
			product_code: currentItem.product_code,
			commission_margin: CommissionMargin
		};
		params = axiosURIencode(params);
		var axiosConfig = axiosPutConfig(gState.client);

		API_Service.put(req_stock_commission, params, axiosConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				const _newData = extraData[0];
				setItem(() => (_newData));
				onUpdateStock(_newData);
			} else {
				onSnack && onSnack(language.operation_fail, 'error');
			}
		});
	}

	const changeEditMinQtyView = {
		contentChildren: [
			{
				name: language.min_order_qty,
				code: "targetMinQty",
				component: "textinput",
				type: "number",
				default_value: ''
			}
		],
		actionChildren: [
			{
				name: language.cancel,
				code: "cancel",
				action: false
			},
			{
				name: language.update,
				code: "update",
				action: true,
				condition: [{targetMinQty: 1}],
				onCondition: "disabled"
			}
		]
	};

	const onCloseCurrentView = () => {
		invalidateAll();
		onClose();
	}

	const initialGetVideoId = (prop) => {
		var str = prop.split('=');
		if (str[1] != null) {
			const link = 'https://www.youtube.com/embed/' + str[1];
			return link;
		}
	}

	const onGetVideoId = (prop) => {
		if (prop.includes("youtube")) {
			setVideoLink(prop);
			var str = prop.split('=');
			//console.log('str ==>' , str);
			//console.log('str[1] ==>' , str[1]);
			if (str[1] != null) {
				const link = 'https://www.youtube.com/embed/' + str[1];
				//console.log('link ==>' , link);
				setVideoId(link);
				//console.log('videoId ==>' , videoId);
			}
		} else {
			onSnack && onSnack(language.not_youtube_link, 'error');
			setVideoId(null);
			setVideoLink(null);
		}
	}

	const availQtyThrottle = useCallback(_.debounce(onChangeAvailQTY, 300), []);
	const virtualQtyThrottle = useCallback(_.debounce(onChangeVirtualQTY, 500), []);
	const vipQtyThrottle = useCallback(_.debounce(onChangeVipQty, 500), []);
	const CommissionThrottle = useCallback(_.debounce(onChangeCommission, 500), []);

	const minQtyThrottle = useCallback(_.debounce(onChangeMinQty, 500), []);
	var virtual_label = language.virtual_qty + '(' + calcVirtual() + ')';
	var item_desc = JString(item?.product_desc).trimHtml().str;

	if (item == undefined || open == false) return null;

	function LinkTab(props) {
		return (
			<Tab
				component="a"
				onClick={(event) => {
					event.preventDefault();
				}}
				{...props}
			/>
		);
	}

	const editBarcode = () => {
		setOpenBarcode(false);
		setUomSelected(empty);
		setBarcode(empty);
		setPrice(0);
		setPrice2(0);
		setRate(empty);
		loadProdUom();
	}

	const editProductName = (data) => {
		setOpenProductEdit(false);
		if (data) {
			setItem(data[0]);
			onUpdateStock(data[0]);
		}
	}

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => onCloseCurrentView()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => onCloseCurrentView()}
									aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.item_dtl}
						</Typography>
					</Toolbar>
				</AppBar>
				<div style={{marginBottom: 150}}>
					{
						currentPage == 0 && (
							<>
								<Grid container spacing={3} className={classes.grid}>
									<Grid item xs={6}>
										<Paper className={classes.paper}>
											<Grid container spacing={2}>
												<Grid item>
													<img className={classes.maxImage} alt={item.product_code}
														 src={item.image_url || no_image_file}/>
												</Grid>
												<Grid item xs={12} lg direction="column" container>
													<div component="ul" className={classes.chipInRow}
														 style={{textAlign: 'left'}}>
														<li>
															<Chip
																avatar={<Avatar>C</Avatar>}
																label={categoryName ? categoryName : ' - '}
																className={classes.chip}
																sx={{textAlign: 'left'}}
															/>
															<Chip
																avatar={<Avatar>B</Avatar>}
																label={brandName ? brandName : ' - '}
																className={classes.chip}
																sx={{textAlign: 'left'}}
															/>
														</li>
													</div>
													<Grid item>
														<Typography variant="subtitle1" className={classes.textleft}>
															{item.product_code}
														</Typography>
														<Typography variant="inherit" component="h4"
																	className={classes.textleft}>
															{item.product_name}
														</Typography>
														{
															item_desc ?
																<Typography variant="subtitle1"
																			className={classes.textleft}>
																	{item_desc}
																</Typography>
																: null
														}
													</Grid>
													<div component="ul" className={classes.chipInRow}>
														<li>
															<Chip
																avatar={<Avatar>A</Avatar>}
																label={item.product_current_quantity ? 'QTY ' + item.product_current_quantity : 'QTY ' + 0}
																className={classes.chip}
															/>
														</li>
													</div>
												</Grid>
												{
													integration === '0' && (
														<Button
															onClick={() => setOpenProductEdit(true)}
															size="small">
															<EditIcon/>
														</Button>
													)
												}
											</Grid>
										</Paper>
									</Grid>
									<Grid item xs={6}>
										<Paper className={classes.paper}>
											{
												showDropzone ?
													<DropzoneArea
														onChange={(files) => {
															onItemImageUpload(files)
														}}
														dropzoneParagraphClass={classes.dropzoneText}
														acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
														filesLimit={1}
														clearOnUnmount={true}
													/> : <div style={{height: 250}}/>
											}
											<Button
												disabled={itemImage === null}
												onClick={() => uploadItemImage()}
												disableElevation={true}
												size="small"
												color={'primary'}>
												{language.save || ''}
											</Button>
										</Paper>
									</Grid>
								</Grid>
								<Grid container spacing={3} className={classes.grid}>
									<Grid item xs={6}>
												<MUIDataTable
													title={language.product_uom}
													data={itemUom}
													columns={uomHeadCell}
													options={{
														print: false,
														filter: false,
														download: false,
														search: false,
														viewColumns: false,
														selectableRows: 'multiple',
														onRowsDelete: ({data, lookup}) => {
															deleteUom(data);
														},
														rowsPerPage: 40,
														rowsPerPageOptions: [25, 40, 65, 100],
														responsive: 'scrollMaxHeight',
														setTableProps: () => ({size: 'small'}),
														customToolbar: () => {
															return (
																<TemplateTableCustomToolbar
																	onNewTemplate={() => {
																		setOpenBarcode(true)
																	}}
																	integration={integration}
																/>
															);
														},
													}}
												/>
									</Grid>
									{
										batchModule && (
											<Grid item xs={6}>
												<MUIDataTable
													title={language.batch_no}
													data={batchList}
													columns={batchHeadCell}
													options={{
														print: false,
														filter: false,
														download: false,
														search: false,
														viewColumns: false,
														selectableRows: batchList.length > 0 ? 'multiple' : 'none',
														onRowsDelete: ({data, lookup}) => {
															deleteBatchNo(data);
														},
														rowsPerPage: 40,
														rowsPerPageOptions: [25, 40, 65, 100],
														responsive: 'scrollMaxHeight',
														setTableProps: () => ({size: 'small'}),
														customToolbar: () => {
															return (
																<Tooltip title={'Add New'}>
																	<IconButton
																		edge={'end'}
																		color={'primary'}
																		onClick={() => setOpenBatch(true)} aria-label="addnew">
																		<AddIcon/>
																	</IconButton>
																</Tooltip>
															);
														},
													}}
												/>
											</Grid>
										)
									}
									<Grid item xs={12}>
										{
											activeImageList.length > 0 ?
												<div className={paperStyle.paperViewWOspace}>
													{
														activeImageList.map((item, index) => {
															return (
																<MediaBox
																	key={String(index)}
																	image={item.image_url || no_image}
																	title={null}
																	description={null}
																	primaryBtn={language.set_default}
																	secondaryBtn={language.delete}
																	primaryBtnAction={() => setDefaultImage(item.product_image_id)}
																	secondaryBtnAction={() => inactiveItemImage(item.product_image_id)}
																	default={item.product_default_image == 1 ? language.default : null}
																/>
															)
														})
													}
												</div> : null
										}
									</Grid>
								</Grid>
							</>
						)
					}
					{
						currentPage == 1 && (
							<div>
								<Tabs
									variant="fullWidth"
									value={qtyCurrentPage}
									indicatorColor="primary"
									textColor="primary"
									onChange={(event, newValue) => {
										setQtyCurrentPage(newValue);
									}}
									aria-label="nav tabs example">
									<LinkTab label={"VIP QTY"}/>
									<LinkTab label={"Min Order QTY"}/>
								</Tabs>
								{
									qtyCurrentPage == 0 && (
										<div className={classes.padding}>
											<Paper className={paperStyle.viewInRowEqualPad}>
												<Button
													disabled={customerList.length === 0}
													className={commonStyle.searchBtnSize}
													color="inherit"
													onClick={() => onViewCustomerList()}
													size="small"
													endIcon={customerList.length === 0 ? <CircularProgress size={20}/> :
														<KeyboardArrowDownRoundedIcon/>}
													startIcon={<BusinessIcon/>}>
													<Typography component="p" className={commonStyle.searchBtnFont}>
														{vipCustomer?.name ? _.truncate(vipCustomer?.name, {length: 15}) : language.customer}
													</Typography>
												</Button>
												<TextField
													id="input-vip-qty-id"
													name="input-vip-qty"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<ExposureIcon/>
															</InputAdornment>
														),
														inputComponent: NumberFormatCustomVIPQty,
													}}
													onChange={vipQtyThrottle}
												/>
												<Button
													onClick={() => onSaveCustomerVipQty()}
													disabled={(vipCustomerQty === empty || JString(vipCustomer?.name).length == 0)}
													disableElevation={true}
													size="small"
													color={'primary'}>
													{language.save || ''}
												</Button>
											</Paper>
											{
												vipQty.length > 0 ?
													<div className={classes.padding}>
														<div className={classes.marginTop}/>
														<MUIDataTable
															title={language.vip_qty_list}
															data={vipQty}
															columns={vipHeadCell}
															options={{
																print: false,
																filter: false,
																download: false,
																search: true,
																viewColumns: false,
																selectableRows: 'multiple',
																onRowsDelete: ({data, lookup}) => {
																	onDeleteVipQTY(data);
																},
																rowsPerPage: 40,
																rowsPerPageOptions: [25, 40, 65, 100],
																responsive: 'scrollMaxHeight',
																onRowClick: (rowData, rowMeta) => {
																	onCustomerVipQtyClick({
																		cust_code: rowData[1],
																		product_code: rowData[3]
																	});
																},
																setTableProps: () => ({size: 'small'})
															}}
														/>
													</div>
													:
													<div style={{height: 300, width: '100%'}}>
														<NodataLottie width={300} height={300}/>
													</div>
											}
										</div>
									)
								}
								{
									qtyCurrentPage == 1 && (
										<div className={classes.padding}>
											<Paper className={paperStyle.viewInRowEqualPad}>
												<Button
													disabled={minCustomerList.length === 0}
													className={commonStyle.searchBtnSize}
													color="inherit"
													onClick={() => onViewMinCustomerList()}
													size="small"
													endIcon={minCustomerList.length === 0 ? <CircularProgress size={20}/> :
														<KeyboardArrowDownRoundedIcon/>}
													startIcon={<BusinessIcon/>}>
													<Typography component="p" className={commonStyle.searchBtnFont}>
														{minCustomer?.name ? _.truncate(minCustomer?.name, {length: 15}) : language.customer}
													</Typography>
												</Button>
												<TextField
													id="input-min-qty-id"
													name="input-min-qty"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<ExposureIcon/>
															</InputAdornment>
														),
														inputComponent: NumberFormatCustomMINQty,
													}}
													onChange={minQtyThrottle}
												/>
												<Button
													onClick={() => onSaveCustomerMinQty()}
													disableElevation={true}
													size="small"
													color={'primary'}>
													{language.save || ''}
												</Button>
											</Paper>
											{
												minQty.length > 0 ?
													<div className={classes.padding}>
														<div className={classes.marginTop}/>
														<MUIDataTable
															title={language.min_qty_list}
															data={minQty}
															columns={minHeadCell}
															options={{
																print: false,
																filter: false,
																download: false,
																search: true,
																viewColumns: false,
																selectableRows: 'multiple',
																onRowsDelete: ({data, lookup}) => {
																	onDeleteMinQTY(data);
																},
																rowsPerPage: 40,
																rowsPerPageOptions: [25, 40, 65, 100],
																responsive: 'scrollMaxHeight',
																onRowClick: (rowData, rowMeta) => {
																	onCustomerMinQtyClick({
																		cust_code: rowData[1],
																		product_code: rowData[3]
																	});
																},
																setTableProps: () => ({size: 'small'})
															}}
														/>
													</div>
													:
													<div style={{height: 300, width: '100%'}}>
														<NodataLottie width={300} height={300}/>
													</div>
											}
										</div>
									)
								}
							</div>
						)
					}
					{   /* upload pdf file here */
						currentPage == 2 && (
							<div>
								<Tabs
									variant="fullWidth"
									value={pdfCurrentPage}
									indicatorColor="primary"
									textColor="primary"
									onChange={(event, newValue) => {
										setPdfCurrentPage(newValue);
									}}
									aria-label="nav tabs example">
									<LinkTab label={"PDF"}/>
									<LinkTab label={"Video"}/>
								</Tabs>
								{
									pdfCurrentPage == 0 && (
										<div className={classes.padding}>
											<Grid container spacing={3} className={classes.grid}>
												<Grid item xs={6}>
													<Paper className={classes.paper}>
														{
															showDropzone ?
																<DropzoneArea
																	onChange={(files) => {
																		onItemPdfUpload(files)
																	}}
																	dropzoneParagraphClass={classes.dropzoneText}
																	acceptedFiles={['.pdf']} //application/*
																	filesLimit={1}
																	clearOnUnmount={true}
																	dropzoneText={"Drag and drop a PDF file here or click"}
																/> : <div style={{height: 250}}/>
														}
														<Button
															disabled={itemPdf === null}
															onClick={() => uploadItemPdf()}
															disableElevation={true}
															size="small"
															color={'primary'}>
															{language.save || ''}
														</Button>
													</Paper>
												</Grid>
											</Grid>
											<div className={classes.padding}>
												{
													activePdfList.length > 0 ?
														<div className={paperStyle.paperViewWOspace}>
															{
																activePdfList.map((item, index) => {
																	return (
																		<Card className={classes.pdfRoot} key={String(index)}>
																			<CardActionArea>
																				<CardMedia
																					className={classes.pdfMedia}
																					image={pdf_image}
																					title={activePdfList[index].value}
																					onClick={() => {
																						if (_.isArray(activePdfList)) {
																							const {
																								name,
																								value
																							} = activePdfList[index];
																							const link = value;
																							link && window.open(link, '_blank');
																						}
																					}}
																				/>
																				<CardContent>
																					<Typography variant="inherit" component="p"
																								className={classes.fontGrey}>
																						{activePdfList[index].created_date}
																					</Typography>
																				</CardContent>
																			</CardActionArea>
																			<CardActions className={classes.center}>
																				<Button
																					style={{color: red[400]}}
																					onClick={() => {
																						updateAtchState(item.id)
																					}}
																					size="small"
																					color="inherit"
																				>
																					DELETE
																				</Button>
																			</CardActions>
																		</Card>
																	)
																})
															}
														</div>
														: null
												}
											</div>
										</div>
									)
								}
								{
									pdfCurrentPage == 1 && (
										<div className={classes.padding}>
											<Grid container spacing={3} className={classes.grid}>
												<Grid item xs={6}>
													<Paper className={videoId != null ? classes.varea : classes.vemptyarea}>
														<div style={{height: 10}}/>
														<TextField
															id="outlined-multiline-static"
															label={language.paste_link_here}
															variant="outlined"
															className={classes.textfield}
															value={videoLink}
															onChange={(event) => onGetVideoId(event.target.value)}
														/>
														<div style={{height: 10}}/>
														{
															videoId != null ?
																<div className={classes.viplayer}>
																	<CardMedia
																		className={classes.height}
																		component='iframe'
																		title='viplayer'
																		src={videoId}   //'https://www.youtube.com/embed/wV2qUaUHZaE'
																	/>
																	<Button
																		onClick={() => uploadItemVideo()}
																		disableElevation={true}
																		size="small"
																		color={'primary'}>
																		{language.save || ''}
																	</Button>
																</div>
																: null
														}
														<div style={{height: 10}}/>
													</Paper>
												</Grid>
											</Grid>
											{
												activeVideoList.length > 0 ?
													<div className={paperStyle.paperViewWOspace}>
														{
															activeVideoList.map((item, index) => {
																return (
																	<Card className={classes.vview} key={String(index)}>
																		<CardActionArea>
																			<CardMedia
																				className={classes.vplayer}
																				component='iframe'
																				title='test'
																				src={item.value}
																			/>
																		</CardActionArea>
																		<CardActions className={classes.center}>
																			<Button
																				style={{color: red[400]}}
																				onClick={() => {
																					updateAtchState(item.id)
																				}}
																				size="small"
																				color="inherit"
																			>
																				DELETE
																			</Button>
																		</CardActions>
																	</Card>
																)
															})
														}
													</div>
													: null
											}
										</div>
									)
								}
							</div>
						)
					}
				</div>
				<VirtualList
					open={showCustomerList}
					setClose={data => onViewCustomerList(data)}
					title={language.cust_list}
					data={customerList}
					language={language}
				/>
				{
					showEditVip && (
						<InputDialog
							open={showEditVip}
							title={language.edit_vip}
							children={changeEditVipQtyView}
							onChange={props => {
							}}
							onPress={props => onChangeVipQtyEdit(props)}
							onClose={() => setEditVip(showEditVip => (false))}
							showLoading={false}
						/>
					)
				}
				<VirtualList
					open={showMinCustomerList}
					setClose={data => onViewMinCustomerList(data)}
					title={language.cust_list}
					data={minCustomerList}
					language={language}
				/>
				{
					showEditMin && (
						<InputDialog
							open={showEditMin}
							title={language.edit_min_qty}
							children={changeEditMinQtyView}
							onChange={props => {
							}}
							onPress={props => onChangeMinQtyEdit(props)}
							onClose={() => setEditMin(showEditMin => (false))}
							showLoading={false}
						/>
					)
				}
				{/* <BottomNavigation
                value={currentPage}
                onChange={(event, newValue) => {
                    setCurrentPage(newValue);
                }}
                showLabels
                className={classes.bottomNav}>
                <BottomNavigationAction label="Info" icon={<InfoIcon />} />
                <BottomNavigationAction label="VIP & Min Qty" icon={<PlaylistAddIcon />} />
                <BottomNavigationAction label="Attachments" icon={<AttachmentIcon />} />
            </BottomNavigation> */}
				<Alert
					setPositive={() => {
						showDeleteAtchAlert(false)
					}}
					setNegative={() => {
						deleteBtnAction(idToDelete)
					}}
					setClose={() => {
						showDeleteAtchAlert(false)
					}}
					open={deleteAtchAlert}
					title={language.es_backend}
					message={language.atch_delete}
					positive={language.cancel}
					negative={language.proceed_caps}
					autoDispossal={true}
					unmount={true}
				/>
			</Dialog>
			<EditBarcode
				open={openBarcode}
				onClose={() => editBarcode()}
				language={language}
				product_code={item.product_code}
				uom={uomSelected}
				barcode={barcode}
				uomrate={rate}
				price={price}
				price2={price2}
				stdTitle={stdTitle}
				minTitle={minTitle}
			/>
			<EditProductName
				open={openProductEdit}
				onClose={(data) => editProductName(data)}
				language={language}
				product_code={item.product_code}
				product_name={item.product_name}
				category_id={item.category_id}
				brand_id={item.brand_id}
				category_list={categoryList}
				brand_list={brandList}
				product_desc={item_desc}
				qr_code={item.QR_code}
			/>
			<EditBatch
				open={openBatchDialog}
				onClose={() => {setOpenBatch(false);setSelectedBatchNo(empty);}}
				language={language}
				data={selectedBatchNo}
				product_code={item.product_code}
				onUpdate={() => loadProdBatch()}
			/>
		</div>
	)
}

function TemplateTableCustomToolbar({onNewTemplate, integration}) {
	return (
		<React.Fragment>
			{
				integration === '0' && (
					<Tooltip title={'Add New'}>
						<IconButton
							edge={'end'}
							color={'primary'}
							onClick={() => onNewTemplate()} aria-label="addnew">
							<AddIcon/>
						</IconButton>
					</Tooltip>
				)
			}
		</React.Fragment>
	)
}
