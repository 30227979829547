import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import JString from '@easytech-international-sdn-bhd/jstring';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';
import {ck_st_dtl_columns, no_image, req_stock_take,} from '../../Helper/Constants';
import Image from 'material-ui-image';
import {useCookies} from 'react-cookie';
import _ from 'lodash';
import API_Service, { axiosURIencode } from '../../Services/API_Service';
import { de } from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	image: {
		maxWidth: 150,
		marginLeft: 10
	},
	large_image: {
		maxWidth: 450,
		maxHeight: 450
	},
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	attachment_title: {
		marginTop: 20,
		marginLeft: 10
	},
	image_title: {
		textAlign: 'center',
		fontSize: 16,
		color: "black"
	},
	image_desc: {
		textAlign: 'center',
		fontSize: 14,
		color: "grey"
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
	{
		name: "id",
		label: "ID",
		options: {
			display: false
		}
	},
	{
		name: "product_code",
		label: "Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "image_url",
		label: "Image",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				if (value) {
					return (
						<div style={{width: 100, height: 100}}>
							<Image alt={value} src={value || no_image}/>
						</div>
					)
				}
			}
		}
	},
	{
		name: "sp_remark",
		label: "Remark",
		options: {
			display: true
		}
	},
	{
		name: "current_quantity",
		label: "Current Quantity",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch(value) {
					case '1888888':
						return 'High';
					case '1555555':
						return 'Medium';
					case '1111111':
						return 'Low';
					case '-111111':
						return 'Delist';
				}
				value = parseInt(value);
				if (value < 3 && value !== 0) {
					return (<Chip size="small" label={value} style={{backgroundColor: "orange"}}/>);
				} else if (value === 0) {
					return (<Chip size="small" label={value} style={{backgroundColor: "red"}}/>);
				} else {
					return value;
				}
			}
		}
	},
	{
		name: "suggested_quantity",
		label: "Suggested Quantity",
		options: {
			display: true
		}
	},
	{
		name: "unit_uom",
		label: "UOM",
		options: {
			display: true
		}
	},
	{
		name: "shelf_location",
		label: "Shelf",
		options: {
			display: true
		}
	},
	{
		name: "suggest_return",
		label: "Return",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="YES"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="NO"/>);
						break;
					default :
						return (<Chip size="small" icon={<CloseIcon/>} label="NO"/>);
						break;
				}
			}
		}
	},
	{
		name: "suggest_transfer",
		label: "Transfer",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="YES"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="NO"/>);
						break;
					default :
						return (<Chip size="small" icon={<CloseIcon/>} label="NO"/>);
						break;
				}
			}
		}
	},
	{
		name: "expiry_date",
		label: "Expiry Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta) => {
				let diffy = new Date(value).getFullYear() - new Date().getFullYear();
				let timenow = new Date().getTime() + (30 * 24 * 60 * 60 * 1000);
				let timevalue = new Date(value).getTime();

				if (diffy === 0 && timenow > timevalue) {
					return (<Chip size="small"
								  label={moment(value, 'YYYY-MM-DD').format('YYYY') === '1970' ? '' : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')}
								  style={{backgroundColor: "red"}}/>);
				} else {
					return moment(value, 'YYYY-MM-DD').format('YYYY') === '1970' ? '' : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
				}
			}
		}
	},
	{
		name: "foc_qty",
		label: "FOC",
		options: {
			display: true
		}
	},
	{
		name: "markdown_price",
		label: "Markdown Price",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return JString(value).inCurrency()
			}
		}
	},
	{
		name: "active_status",
		label: "Status",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Cancelled"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	}
];

export default function StockTakeDetails({open, setClose, language, axiosConfig, stockTakeID}) {
	const classes = useStyles();
	const [items, setItems] = useState([]);
	const [cookies, setCookie] = useCookies([ck_st_dtl_columns]);

	useEffect(() => {
		// createRows(itemList?.items);
		if (open) {
			const param = axiosURIencode({
				doc_id: stockTakeID
			});
			API_Service.get(`${req_stock_take}?${param}`, axiosConfig).then(({data}) => {
				const {result, extraData} = data;
				if (parseInt(result) === 1) {
					setItems(items => (extraData));
				}
			});
		}
	}, [open]);

	// const createRows = (__itemList) => {
	// 	if (__itemList) {
	// 		var _data = [];
	// 		for (let i = 0; i < __itemList.length; i++) {
	// 			_data.push(
	// 				__itemList[i]
	// 			);
	// 		}
	// 		setItems(items => (_data));
	// 	}
	// }

	const resetAndClose = () => {
		setItems([]);
		setClose();
	}

	const redefineHeaders = (columns) => {
		const lastSelection = cookies[ck_st_dtl_columns];
		if (_.isEmpty(lastSelection)) {
			return columns;
		}
		columns = columns.map((item) => {
			const key = item.name;
			var objCopy = _.merge({}, item);
			objCopy.options.display = lastSelection[`${key}`];
			return objCopy;
		});
		return columns;
	}

	const tableHead = redefineHeaders(headCells);

	const docTableColumnsChanged = (changedColumns, action) => {
		const lastSelection = cookies[ck_st_dtl_columns];
		var obj = {};
		var a = headCells.map((item) => {
			if (item.name == changedColumns) {
				obj[`${item.name}`] = action == 'add';
				return {[`${item.name}`]: action == 'add'};
			}
			obj[`${item.name}`] = (lastSelection && _.has(lastSelection, `${item.name}`)) ? lastSelection[`${item.name}`] : item.options.display;
			return {[`${item.name}`]: item.options.display};
		});
		setCookie(ck_st_dtl_columns, JSON.stringify(obj), {path: '/', sameSite: true, maxAge: 36000 * 3600});
	}

	return (
		<div>
			<Dialog fullScreen open={open} onClose={() => resetAndClose()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => resetAndClose()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{language.stock_take_details}
						</Typography>
					</Toolbar>
				</AppBar>
				<div>
					<MUIDataTable
						title={items.length > 0 ? `# ${stockTakeID}` : ''}
						data={items}
						columns={tableHead}
						options={{
							filter: false,
							print: false,
							selectableRows: 'none',
							rowsPerPage: 50,
							rowsPerPageOptions: [50, 100, 150],
							responsive: 'scrollMaxHeight',
							setTableProps: () => ({size: 'small'}),
							onColumnViewChange: (changedColumns, direction) => {
								docTableColumnsChanged(changedColumns, direction);
							}
						}}
					/>
				</div>
			</Dialog>
		</div>
	);
}
