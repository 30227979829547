import React, {useContext, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosDefaultConfig} from '../../Services/API_Service';
import {req_send_sms} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import MuiPhoneNumber from "material-ui-phone-number";
import {grey} from '@material-ui/core/colors';

const useStyles = makeStyles({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	}
});

export default function SendSMS({open, setClose, language, data}) {
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [phoneNo, setPhoneNo] = useState(data?.site_in_charge_contact);
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const sendSMS = () => {
		const formdata = new FormData();
		formdata.append('sms_from', 'EasyM');
		formdata.append('sms_content', ':+Your+Schedule+Code+=+' + data?.sched_code + '.+Customer+=+' + data?.cust_company_name);
		formdata.append('sms_contact', phoneNo ? phoneNo : data?.site_in_charge_contact);
		API_Service.post(req_send_sms, formdata, axiosDefaultConfig(gState.client)).then(({data}) => {
			const {result, extraData} = data;
			if (extraData?.desc === 'OK') {
				setClose();
				snack(language.operation_success);
			} else {
				snack(language.operation_fail, 'error');
			}
		});
	}
	return (
		<Dialog onClose={() => setClose()} aria-labelledby="simple-dialog-title" open={open}>
			<div>
				<DialogTitle id="simple-dialog-title">
					{'Send SMS to technician'}
				</DialogTitle>
				<MuiPhoneNumber
					className={classes.dlg}
					variant={'outlined'}
					name="phone"
					label={String(language.phone_number).toUpperCase()}
					InputLabelProps={{
						style: {color: grey[500]},
					}}
					data-cy="user-phone"
					defaultCountry={"my"}
					onlyCountries={['my']}
					value={data ? data?.site_in_charge_contact : phoneNo}
					onChange={prop => setPhoneNo(prop)}
					countryCodeEditable={false}
					margin={'dense'}
					regions={['asia']}
				/>
				<DialogActions>
					<Button
						onClick={() => sendSMS()}
						variant={'text'}
						color={'primary'}>
						{language.yes}
					</Button>
					<Button
						onClick={() => setClose()}
						variant={'text'}
						color={'primary'}>
						{language.no}
					</Button>
				</DialogActions>
			</div>
		</Dialog>

	)
}
