import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Box = withStyles({
	root: {
		color: '#FEF200',
		'&$checked': {
			color: '#FEF200',
		},
	},
	checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
	checkbox: {
		marginTop: theme.spacing(1)
	}
}));

export default function CheckBox({text, ...rest}) {
	const styles = useStyles();
	return (
		<FormControlLabel
			control={
				<Box
					{...rest}
					color="primary"
				/>
			}
			label={text}
			className={styles.checkbox}
		/>
	)
}
