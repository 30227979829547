import React from 'react';
import styled, {keyframes} from 'styled-components';
import {flash} from 'react-animations';

const flashAnim = keyframes`${flash}`;

const FlashContainer = styled.div`
  animation: 1 2s ${flashAnim};
`;

export default function BlinkContainer({children}) {
	return (
		<FlashContainer>
			{children}
		</FlashContainer>
	)
}
