import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography} from '@material-ui/core';
import red from '@material-ui/core/colors/red';

export default function ListView(props) {

	const {onClose, onSelect, value: valueProp, open, title, language, options, ...other} = props;
	const [value, setValue] = React.useState(valueProp);
	const radioGroupRef = React.useRef(null);

	React.useEffect(() => {
		if (!open) {
			setValue(valueProp);
		}
	}, [valueProp, open]);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = () => {
		onSelect(value);
	};

	const handleChange = (event) => {
		if (event.target) {
			setValue(value => (event.target.value));
		}
		event.persist();
	};

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			onEntering={handleEntering}
			aria-labelledby="confirmation-dialog-title"
			open={open}
			{...other}>
			<DialogTitle id="confirmation-dialog-title">
				{title || ''}
			</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					value={value}
					onChange={handleChange}>
					{options.map((option, idx) => {
						if (idx !== options.length - 1) {
							return (
								<FormControlLabel
									value={option.value}
									key={String(idx)}
									control={<Radio/>}
									label={option.name}/>
							)
						} else {
							return (
								null
							)
						}
					})}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onSelect(options[options.length - 1]?.value)}>
					<Typography component="p" style={{color: red[400]}}>
						{language.reset_selection}
					</Typography>
				</Button>
				<Button autoFocus onClick={handleCancel} color="primary">
					<Typography component="p">
						{language.cancel}
					</Typography>
				</Button>
				<Button onClick={handleOk} color="primary">
					<Typography component="p">
						{language.ok}
					</Typography>
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ListView.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	language: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired
};
