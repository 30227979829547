import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles, useTheme} from '@material-ui/core/styles';
// import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DateRangePicker from '../_DateRange';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {de} from '../../Helper/Helper';
import {empty} from '../../Helper/Constants';
import HyperServerDateRange from "../_DateRange2"; // theme css file

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		background: theme.palette.primary.light,
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1)
	},
	root: {
		background: theme.palette.background.paper,
	}
}));
export default function DateRangePickerModal({
												 setPositive,
												 setNegative,
												 setClose,
												 open,
												 positive,
												 negative,
												 reset,
												 input,
												 minDate,
												 maxDate,
												 direction = 'horizontal',
												 hideDefinedRanges = false
											 }) {
	const classes = useStyles();
	const [dateRange, setDateRange] = useState({});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(()=>{
		if(input !== undefined && Object.keys(input).length !== 0){
			setDateRange(input);
		}
	},[setPositive, setNegative, setClose]);


	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Dialog
				fullScreen={fullScreen}
				maxWidth={'lg'}
				open={open}
				onClose={() => setClose()}
				aria-labelledby="responsive-dialog-title">
				<DialogTitle
					id="responsive-dialog-title">
					EasyM
				</DialogTitle>
				<DialogContent>
					<div style={{flexDirection: 'column'}}>
						{/* <DateRangePicker
                            open={true}
                            onChange={range => setDateRange(range)}
                        /> */}
						{/*<DateRangePicker*/}
						{/*	onChange={(data) => {*/}
						{/*		setDateRange({startDate: data[0], endDate: data[1]})*/}
						{/*	}}*/}
						{/*	onlyCalendar*/}
						{/*	autoOk={true}*/}
						{/*/>*/}
						<HyperServerDateRange
							onChange={item => setDateRange(item)}
						  	months={2}
						  	direction={direction}
							input={dateRange}
							minDate={minDate}
							maxDate={maxDate}
							hideDefinedRanges={hideDefinedRanges}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					{
						reset && (
							<Button autoFocus onClick={() => setPositive(empty)} color="inherit">
								{reset}
							</Button>
						)
					}
					<Button autoFocus onClick={() => setNegative()} color="inherit">
						{negative}
					</Button>
					<Button
						onClick={() => {
							de(dateRange);
							setPositive(dateRange)
						}}
						color="inherit"
						autoFocus>
						{positive}
					</Button>
				</DialogActions>
			</Dialog>
		</MuiPickersUtilsProvider>
	)
	return (
		<Modal
			open={open}
			onClose={() => setClose()}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description">
			<div className={classes.root}>
				<DateRangePicker
					open={open}
					onChange={range => setDateRange(range)}
				/>
				<div className={classes.row}>
					<Button autoFocus onClick={() => setNegative()} color="inherit">
						{negative}
					</Button>
					<Button
						onClick={() => setPositive(dateRange)}
						color="inherit"
						autoFocus>
						{positive}
					</Button>
				</div>
			</div>
		</Modal>
	)
}
