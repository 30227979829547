import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service from '../../Services/API_Service';
import {empty, req_cust_category} from '../../Helper/Constants';
import {useMediaQuery, useTheme} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { SketchPicker } from 'react-color';

const useStyles = makeStyles((theme) => ({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	fullWIdth: {
		minWidth: '96%'
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	}
}));

export default function AddCustCategory({open, onClose, language, onSnack, axiosConfig, mobileCustomization}) {
	const classes = useStyles();

	const [custCategoryCode, setCustCategoryCode] = useState(empty);
	const [custCategoryName, setCustCategoryName] = useState(empty);
	// const [standardSKUQty, setStandardSKUQty] = useState(empty);
	const [color, setColor] = useState('');

	useEffect(() => {
	}, [open]);

	const closeRemove = (data) => {
		setCustCategoryCode(empty);
		setCustCategoryName(empty);
		if (data) {
			onClose(data[0]);
		}
	}

	const addCustCategory = () => {
		let formData = new FormData();
		formData.append('cust_category_code', custCategoryCode);
		formData.append('cust_category_name', custCategoryName);
		formData.append('marker_color', color);
		// if(standardSKUQty !== empty){
		// 	formData.append('standard_sku_qty', standardSKUQty);
		// }

		if (custCategoryName === empty || custCategoryCode === empty) {
			onSnack('Insert Category Code and Category Name', 'warning');
		} else {
			API_Service.post(req_cust_category, formData, axiosConfig).then(({data}) => {
				const {result, extraData, message} = data;
				if (result) {
					closeRemove(extraData);
					onSnack(language.insert_success);
				} else {
					onSnack(message, 'warning');
				}
			});
		}
	}

	const handleColorChange = (input) => {
		setColor(input.hex);
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog fullScreen={fullScreen} onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{language.new_cust_category}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						id="input-item-code"
						style={{width: '48%', height: 50, marginBottom: 10}}
						inputProps={{maxLength: 10, pattern: "[a-z]{1,15}"}}
						label={language.cust_category_code}
						value={custCategoryCode}
						onChange={(e) => setCustCategoryCode(e.target.value)}
					/>
					<TextField
						id="input-item-name"
						style={{width: '48%', height: 50, marginBottom: 10}}
						label={language.cust_category_name}
						value={custCategoryName}
						onChange={(e) => setCustCategoryName(e.target.value)}
					/>
					<TextField
						id="input-color"
						style={{width: '100%', height: 50}}
						label={'Marker Color'}
						name="input-color"
						value={color}
						disabled={true}
					/>
					<SketchPicker
						className={classes.fullWIdth}
						color={color}
						onChange={handleColorChange}
					/>
					{/* {
						mobileCustomization.hasOwnProperty('skustandard') && mobileCustomization.skustandard.enabled ? (
							<TextField
								id="input-item-skustandard"
								style={{width: '48%', height: 50, marginBottom: 10}}
								InputProps={{
									inputComponent: NumberFormatCustom,
								}}
								label={mobileCustomization.skustandard.title}
								value={standardSKUQty}
								onChange={(e) => setStandardSKUQty(e.target.value)}
							/>
						) : <></>
					} */}
				</div>
				<div style={{height: 20}}/>
				<div style={{display: "flex"}}>
					<Button
						style={{marginLeft: 'auto'}}
						variant="outlined"
						disabled={custCategoryCode === empty || custCategoryName === empty}
						onClick={() => {
							addCustCategory()
						}}
						color={'primary'}>
						{language.add}
					</Button>
				</div>
				<div style={{height: 20}}/>
			</div>
		</Dialog>
	)
}


NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			allowLeadingZeros={true}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}
