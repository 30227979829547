import React, { useEffect, useState } from 'react';
import { empty, no_image, req_compt_categorydetails, req_compt_categoryimage, req_compt_categoryproduct, req_compt_product, req_compt_unassigncategoryproduct } from '../../../Helper/Constants';
import { AppBar, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import { usePaperStyles } from '../../../Styles/StylePaper';
import MediaBox from '../../../Components/MediaBox';
import ReactImageMagnify from 'react-image-magnify';
import { grey } from '@material-ui/core/colors';
import API_Service, { axiosURIencode } from '../../../Services/API_Service';
import { arrayBufferToBase64, de } from '../../../Helper/Helper';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import JString from '@easytech-international-sdn-bhd/jstring';
import VirtualListCB from '../../../Components/VirtualListCB';

const labels = {
	categoryIdenitifierId: "Code",
	category_name: "Name",
	created_at: "Created At",
    created_by: "Created By"
};

const useStyles = makeStyles((theme) => ({
    appBar: {
		position: 'relative',
	},
    title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
    grid: {
		padding: theme.spacing(1),
		maxWidth: '100%'
	},
    paper: {
		padding: theme.spacing(1),
		textAlign: 'flex-start',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
        flex: 1
	},
    paper2: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1,
		height: 300,
	},
    large_image: {
		minWidth: 800,
		maxHeight: 450
	},
	large_image2: {
		maxWidth: 400,
		maxHeight: 450
	},
    dropzoneText: {
		color: grey[600]
	},
}));

export default function CompetitorCategoryDetails({open, onDialogClose, details, axiosConfig, axiosPut, language, snack, onReload}) {
    const headCells = [
		{
			name: "product_code",
			label: "Code",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "product_name",
			label: "Name",
			options: {
				display: true,
				filter: false,
			}
		}
	];

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const paperStyle = usePaperStyles();
    const [cmptCategoryID, setCmptCategoryID] = useState(empty);
    const [cmptCategoryCode, setCmptCategoryCode] = useState(empty);
    const [cmptCategoryName, setCmptCategoryName] = useState(empty);
    const [itemImage, setItemImage] = useState(empty);
    const [imageList, setImageList] = useState([]);
    const [editDialog, setEditDialog] = useState(false);
    const [productList, setProductList] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [competitorProductDialog, setCompetitorProductDialog] = useState(false);

    const [categoryProductList, setCategoryProductList] = useState([]);

    const [showDropzone, setShowDropzone] = useState(true);

    useEffect(() => {
        if(Object.keys(details).length !== 0){
            // de("betty-details", details);
            setCmptCategoryID(details['id']);
            setCmptCategoryCode(details['categoryIdenitifierId']);
            setCmptCategoryName(details['category_name']);

            if(details['category_image']){
                const array = JSON.parse(details['category_image']);
                setImageList(array);
            }
        }
    }, [details]);

    useEffect(() => {
        // de("betty-open", Object.keys(details).length !== 0, details);
        if(Object.keys(details).length !== 0){
            loadCategoryDetail(details['id']);
            loadCompetitorProducts(details['cmpt_code']);
        }
    }, [open]);

    const closeDialog = () => {
        setCmptCategoryID(empty);
        setCmptCategoryCode(empty);
        setCmptCategoryName(empty);
        setItemImage(empty);
        setImageList([]);
        setProductList([]);

        onDialogClose();
    }

    const onEditDialogOpen = () => {
        if(Object.keys(details).length !== 0){
            setCmptCategoryID(details['id']);
            setCmptCategoryCode(details['categoryIdenitifierId']);
            setCmptCategoryName(details['category_name']);
        }

        setEditDialog(true);
    }

    const onEditDialogClose = () => {
        setCmptCategoryID(empty);
        setCmptCategoryCode(empty);
        setCmptCategoryName(empty);

        setEditDialog(false);
    }

    const loadCategoryDetail = (id = cmptCategoryID, config = axiosConfig) => {
        API_Service.get(`${req_compt_categorydetails}?id=${id}`, config).then(({data}) => {
            const {result, extraData} = data;
            if(result){
                setCategoryProductList(extraData.product);
                // setCmptCategoryCode(extraData[0]['categoryIdenitifierId']);
                // setCmptCategoryName(extraData[0]['category_name']);
                // de("betty-array", extraData);
                if(extraData.category_image){
                    const array = JSON.parse(extraData.category_image);
                    setImageList(array);
                }
            }
        }).catch(de);
    }

    const loadCompetitorProducts = (code = details['cmpt_code'], config = axiosConfig) => {
        API_Service.get(`${req_compt_product}?cmpt_code=${code}`, config).then(({data}) => {
            const {result, extraData} = data;
            if(result === 1){
                var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {product_name, product_code, categoryIdenitifierId, status} = extraData[idx];
                    if(categoryIdenitifierId === null){
                        _data.push({name: product_name, value: product_code, hide: false, status: parseInt(status)});
                    }else{
                        _data.push({name: product_name, value: product_code, hide: true, status: parseInt(status)});
                    }
				}
                setProductList(_data);
            }
        }).catch(de);
    }

    const onItemImageUpload = (files) => {
        var imageArrayBuffer = [];
        if (files.length > 0) {
            Array.from(files).forEach(file => {
                const reader = new FileReader();
            
                reader.onabort = () => de('file reading was aborted');
                reader.onerror = () => de('file reading has failed');
                reader.onload = () => {
                    const binaryStr = reader.result;
                    imageArrayBuffer.push(arrayBufferToBase64(binaryStr));
                    setItemImage(itemImage => imageArrayBuffer);
                };
            
                reader.readAsArrayBuffer(file);
            });
		}else{
            setItemImage(empty);
        }
    }

    const uploadItemImage = (config = axiosPut) => {
        if(itemImage === empty){
            snack('Please insert image.', 'warning');
        }else{
            var params = {
                'update': true,
                image: itemImage,
                id: cmptCategoryID,
                category_code: cmptCategoryCode
            };
            params = axiosURIencode(params);
            API_Service.put(req_compt_categoryimage, params, config).then(({data}) => {
                var {result, message, extraData} = data;
                if (result == 1) {
                    // onEditDialogClose();
                    setItemImage(empty);
                    loadCategoryDetail();
                    snack(message);
                    setTimeout(() => {
						setShowDropzone(true);
					}, 100);
                    onReload();
                } else {
                    snack(message, 'error');
                }
            }).catch((err) => de(err.message));
        }
    }

    const deleteImage = (url, config = axiosPut) => {
        var params = {
			image_url: url,
            id: cmptCategoryID,
            category_code: cmptCategoryCode
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt_categoryimage, params, config).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
                // onEditDialogClose();
                loadCategoryDetail();
				snack(message);
                onReload();
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
    }

    const editCompetitorCategory = (config = axiosPut) => {
        var params = {
			category_code: cmptCategoryCode,
            category_name: cmptCategoryName,
            id: cmptCategoryID
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt_categorydetails, params, config).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
                details['category_name'] = cmptCategoryName;
				// loadCategoryDetail();
                onReload();
                onEditDialogClose();
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
    }

    const assignProductToCategory = (data, config = axiosPut) => {
        var value = '';
        if(data){
            // de("betty-assignProductToCategory", data);
            for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					value += data[i].value;
				} else {
					value += data[i].value + ",";
				}
			}
        }else{
            setCompetitorProductDialog(false);
        }

        if(value !== ''){
            // de("betty-spvalue",  value);
            var params = {
                category_code: cmptCategoryCode,
                product_code: value
            };
            params = axiosURIencode(params);
            API_Service.put(req_compt_categoryproduct, params, config).then(({data}) => {
                var {result, message, extraData} = data;
                if(result){
                    loadCategoryDetail();
                    loadCompetitorProducts();
                    setCompetitorProductDialog(false);
                }
            }).catch((err) => de(err.message));
        }else{
            setCompetitorProductDialog(false);
        }
    }

    const onRowDelete = (row, config = axiosPut) => {
		const _arr = [];
		for (let i = 0; i < row.length; i++) {
			var {dataIndex} = row[i];
			_arr.push(categoryProductList[dataIndex]['product_code']);
		}
		var params = axiosURIencode({
			product_code: _arr.join(","),
			cmpt_code: details['cmpt_code']
		});

		API_Service.put(req_compt_unassigncategoryproduct, params, config).then(({data}) => {
			var {result, message} = data;
			result = JString(result).toNumber();
			if (parseInt(result) === 1) {
				snack(message, 'success');
                loadCategoryDetail();
                loadCompetitorProducts();
			} else {
				snack(message, 'error');
			}
		});
	}

	return (
        <>
            <Dialog fullScreen open={open} onClose={() => closeDialog()} /*TransitionComponent={Transition}*/>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {language.competitor_category_details}
                        </Typography>
                        {/* {
                            integration === '0' && currentPage === 0 && ( */}
                                <Button
                                    onClick={() => onEditDialogOpen()}
                                    size="small">
                                    <EditIcon style={{color: 'black'}}/>
                                </Button>
                            {/* )
                        } */}
                    </Toolbar>
                </AppBar>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={6} style={{display: 'flex'}}>
                        <Paper className={classes.paper}>
                            {
                                Object.keys(labels).map((key, idx) => {
                                    var subHeader = labels[key];
                                    var listValue = (details[key] == null || details[key] == '') ? '-' : details[key];
                                    if (key === 'created_at') {
                                        listValue = details[key] == null ? '-' : moment(details[key], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                                    }
                                    return (
                                        <div
                                            style={{marginTop: 10}}
                                            key={String(idx)}>
                                            <Typography variant={'inherit'} component={'p'}>
                                                {subHeader}
                                            </Typography>
                                            <Typography variant={'inherit'} component={'h4'}>
                                                {listValue}
                                            </Typography>
                                        </div>
                                    )
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.paper2}>
                            {
                                showDropzone ? 
                                    <DropzoneArea
                                        onChange={(files) => {
                                            onItemImageUpload(files)
                                        }}
                                        dropzoneParagraphClass={classes.dropzoneText}
                                        acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                        filesLimit={10}
                                        clearOnUnmount={true}
                                    /> : <div style={{height: 250}}/>
                            }
                            <Button
                                style={{zIndex: 9999}}
                                disabled={itemImage === empty}
                                onClick={() => uploadItemImage()}
                                disableElevation={true}
                                size="small"
                                color={'primary'}>
                                {language.save || ''}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={language.category_products}
                            data={categoryProductList}
                            columns={headCells}
                            options={{
                                selectableRows: categoryProductList.length > 0 ? 'multiple' : 'none',
                                print: false,
                                filter: false,
                                download: false,
                                rowsPerPage: 150,
                                rowsPerPageOptions: [150, 300, 600, 900],
                                responsive: 'simple',
                                setTableProps: () => ({size: 'small'}),
                                onRowsDelete: ({data: row}) => onRowDelete(row),
                                onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
                                    if (colIndex !== (headCells.length - 1)) {
                                        // openCategoryDialog(showCatActive ? activeCategoryList[dataIndex] : inactiveCategoryList[dataIndex]);
                                    }
                                },
                                customToolbar: () => {
                                    return (
                                        <React.Fragment>
                                            {/* {
                                                showCatActive ?
                                                    <Tooltip title={'Show Active'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                setShowCatActive(false);
                                                            }}>
                                                            <VisibilityOffIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title={'Show Inactive Only'}>
                                                        <IconButton
                                                            onClick={() => {
                                                                setShowCatActive(true);
                                                            }}>
                                                            <VisibilityIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                            } */}
                                            <Tooltip title={language.assign_product}>
                                                <IconButton
                                                    edge={'end'}
                                                    color={'primary'}
                                                    onClick={() => {
                                                        if(productList.length > 0){
                                                            setCompetitorProductDialog(true)    
                                                        }else{
                                                            snack('No product is created on this competitor or is fully assigned.','warning');
                                                        }
                                                    }} 
                                                    aria-label="assignproduct">
                                                    <AddIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </React.Fragment>
                                    );
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={12}>
                        {
                            imageList.length > 0 ?
                                <div className={paperStyle.paperViewWOspace}>
                                    {
                                        imageList.map((item, index) => {
                                            return (
                                                <MediaBox
                                                    key={String(index)}
                                                    image={item || no_image}
                                                    title={null}
                                                    description={null}
                                                    secondaryBtn={language.delete}
                                                    viewBtn={'View'}
                                                    secondaryBtnAction={() => deleteImage(item)}
                                                    viewBtnAction={() => setSelectedImage(item)}
                                                />
                                            )
                                        })
                                    }
                                </div> : null
                        }
                    </Grid>
                </Grid>
            </Dialog>

            <Dialog fullScreen={fullScreen} aria-labelledby="simple-dialog-title" onClose={() => onEditDialogClose()} open={editDialog}>
                <DialogTitle id="simple-dialog-title">
                    {language.edit_category}
                </DialogTitle>
                <div style={{width: 600, padding: theme.spacing(2)}}>
                    <div className={classes.viewInRowSpace}>
                        <TextField
                            id="input-cmpt-product-name"
                            style={{width: '100%', height: 50}}
                            label={language.category_name}
                            value={cmptCategoryName}
                            onChange={(e) => setCmptCategoryName(e.target.value)}
                        />
                    </div>
                    <div style={{height: 20}}/>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{marginLeft: 'auto'}}
                            variant="outlined"
                            onClick={() => {
                                editCompetitorCategory();
                            }}
                            color={'primary'}>
                            {language.update}
                        </Button>
                    </div>
                    <div style={{height: 20}}/>
                </div>
            </Dialog>

            {
                selectedImage && (
                    <Dialog
                        fullWidth={false}
                        maxWidth={'lg'}
                        open={selectedImage != null}
                        aria-labelledby="form-dialog-title"
                        onClose={() => {
                            setSelectedImage(null)
                        }}>
                        <DialogContent style={{height: 600}}>
                            <div className={classes.large_image}>
                                <ReactImageMagnify
                                    className={classes.large_image2}
                                    {...{
                                        smallImage: {
                                            alt: 'Neckwatch by ChenXi Malaysia',
                                            isFluidWidth: true,
                                            src: selectedImage,
                                        },
                                        largeImage: {
                                            src: selectedImage,
                                            width: 1200,
                                            height: 1800
                                        }
                                    }}
                                />
                            </div>
                        </DialogContent>
                    </Dialog>
                )
            }

            <VirtualListCB
				open={competitorProductDialog}
				setClose={data => assignProductToCategory(data)}
				title={language.competitor_products}
				data={productList}
				language={language}
                filteration={0}
			/>
        </>
	)
}