import { AppBar, BottomNavigation, BottomNavigationAction, Button, Dialog, DialogTitle, Grid, IconButton, Paper, TextField, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { arrayBufferToBase64, de } from '../../../Helper/Helper';
import { usePaperStyles } from '../../../Styles/StylePaper';
import API_Service, { axiosURIencode } from '../../../Services/API_Service';
import { databridge_emit, empty, req_compt_category, req_compt_details, req_compt_product, req_compt_productdetails } from '../../../Helper/Constants';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import AddCompetitor from '../AddCompetitor';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import MUIDataTable from 'mui-datatables';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddIcon from '@material-ui/icons/Add';
import { grey, red } from '@material-ui/core/colors';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { DropzoneArea } from 'material-ui-dropzone';
import CompetitorProductDetails from '../CompetitorProductDetails';
import CompetitorCategoryDetails from '../CompetitorCategoryDetails';

const useStyles = makeStyles((theme) => ({
    appBar: {
		position: 'relative',
	},
    title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
    bottomNav: {
		position: 'fixed',
		bottom: 0,
		top: 'auto',
		width: '100%'
	},
    grid: {
		padding: theme.spacing(1),
		maxWidth: '100%'
	},
    paper: {
		padding: theme.spacing(1),
		textAlign: 'flex-start',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1
	},
    viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
    dropzoneText: {
		color: grey[600],
	},
    dropZone: {
        marginTop: 16,
        width: '100%'
    }
}));

const labels = {
	cmpt_code: "Code",
	cmpt_name: "Name",
	cmpt_remark: "Remark",
    cmpt_desc: "Description",
	created_at: "Created At",
	created_by: "Created By",
    company: "Branch",
};

export default function CompetitorDetails({competitor_code, open, setClose, axiosConfig, axiosPut, integration, snack, language, onReload, branchesList = []}) {
    const headCells = [
		{
			name: "product_code",
			label: "Code",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "product_name",
			label: "Name",
			options: {
				display: true,
				filter: false,
			}
		},
        {
			name: "product_barcode",
			label: "Barcode",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "created_at",
			label: "Created Date",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
					)
				}
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowData} = tableMeta;
					var customerStatus = parseInt(value);
					if (customerStatus === 1) {
						return (
							<IconButton
								onClick={() => {
									changeProductStatus(rowData);
								}}>
								<LockOpenIcon/>
							</IconButton>
						);
					} else if (customerStatus === 0) {
						return (
							<IconButton
								onClick={() => {
									changeProductStatus(rowData);
								}}>
								<LockIcon htmlColor={red[500]}/>
							</IconButton>
						);
					}
				}
			}
		},
	];

    const headCells2 = [
		{
			name: "categoryIdenitifierId",
			label: "Code",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "category_name",
			label: "Name",
			options: {
				display: true,
				filter: false,
			}
		},
		{
			name: "created_at",
			label: "Created Date",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value) => {
					return (
						moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
					)
				}
			}
		},
		{
			name: "category_status",
			label: "Status",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowData} = tableMeta;
					var customerStatus = parseInt(value);
					if (customerStatus === 1) {
						return (
							<IconButton
								onClick={() => {
									changeCategoryStatus(rowData);
								}}>
								<LockOpenIcon/>
							</IconButton>
						);
					} else if (customerStatus === 0) {
						return (
							<IconButton
								onClick={() => {
									changeCategoryStatus(rowData);
								}}>
								<LockIcon htmlColor={red[500]}/>
							</IconButton>
						);
					}
				}
			}
		},
	];

    const classes = useStyles();
    const rowClass = usePaperStyles();
    const [currentPage, setCurrentPage] = useState(0);
    const [competitorDetail, setCompetitorDetail] = useState({});
    const [editDialog, setEditDialog] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // create product dialog
    const [showActive, setShowActive] = useState(true);
    const [activeProductList, setActiveProductList] = useState([]);
    const [inactiveProductList, setInactiveProductList] = useState([]);
    const [showCatActive, setShowCatActive] = useState(true);
    const [activeCategoryList, setActiveCategoryList] = useState([]);
    const [inactiveCategoryList, setInactiveCategoryList] = useState([]);
    const [createDialog, setCreateDialog] = useState(false);
    const [createCategoryDialog, setCreateCategoryDialog] = useState(false);
    const [cmptProductCode, setCmptProductCode] = useState(empty);
    const [cmptProductName, setCmptProductName] = useState(empty);
    const [cmptProductBarcode, setCmptProductBarcode] = useState(empty);
    const [cmptProductRemark, setCmptProductRemark] = useState(empty);
    const [cmptProductDesc, setCmptProductDesc] = useState(empty);
    const [itemImage, setItemImage] = useState(empty);
    const [cmptCategoryName, setCmptCategoryName] = useState(empty);
    const [catItemImage, setCatItemImage] = useState(empty);

    const [cmptProductDialog, setCmptProductDialog] = useState(false);
    const [selectedCmptProduct, setSelectedCmptProduct] = useState({});

    const [cmptCategoryDialog, setCmptCategoryDialog] = useState(false);
    const [selectedCmptCategory, setSelectedCmptCategory] = useState({});

    useEffect(() => {
        if(competitor_code !== empty){
            loadCmptDetails(competitor_code);
        }
    }, [open]);

    const onDialogClose = () => {
        setCompetitorDetail({});
        setActiveProductList([]);
        setInactiveProductList([]);
        setActiveCategoryList([]);
        setInactiveCategoryList([]);
        setClose();
    }

    const loadCmptDetails = (cmpt_code, config = axiosConfig) => {
        API_Service.get(`${req_compt_details}?cmpt_code=${cmpt_code}`, config).then(({data}) => {
            const {result, extraData} = data;
            if(result){
                setCompetitorDetail(extraData);
                setActiveProductList(extraData.active_product);
                setInactiveProductList(extraData.inactive_product);
                setActiveCategoryList(extraData.active_category);
                setInactiveCategoryList(extraData.inactive_category);
            }
        }).catch(de);
    }

    const addCompetitorProduct = (config = axiosConfig) => {
        let formData = new FormData();
		formData.append('cmpt_code', competitor_code);
        formData.append('product_code', cmptProductCode);
        formData.append('product_name', cmptProductName);
        formData.append('product_barcode', cmptProductBarcode);
        formData.append('product_desc', cmptProductDesc);
        formData.append('product_remark', cmptProductRemark);
        formData.append('product_image', itemImage);

        if(cmptProductName === empty){
			snack('Please insert product name.', 'warning');
		}else{
			API_Service.post(req_compt_product, formData, config).then(({data}) => {
				const {result, message, extraData} = data;
				if (result) {
					snack(language.insert_success);
                    loadCmptDetails(competitor_code);
                    onCreateDialogClose();
				} else {
					snack(message, 'warning');
				}
			});
		}
    }

    const changeProductStatus = (rowData, config = axiosPut) => {
		var params = {
			product_code: rowData[0],
            cmpt_code: competitor_code
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt_product, params, config).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
				loadCmptDetails(competitor_code);
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
	}

    const onCreateDialogClose = () => {
        setCmptProductCode(empty);
        setCmptProductName(empty);
        setCmptProductBarcode(empty);
        setCmptProductRemark(empty);
        setCmptProductDesc(empty);
        setItemImage(empty);

        setCreateDialog(false);
    }

    const openEditDialog = (data) => {
        if(data){
            setCmptProductDialog(true);
            setSelectedCmptProduct(data);
        }
    }

    const onItemImageUpload = (files) => {
        var imageArrayBuffer = [];
        if (files.length > 0) {
            Array.from(files).forEach(file => {
                const reader = new FileReader();
            
                reader.onabort = () => de('file reading was aborted');
                reader.onerror = () => de('file reading has failed');
                reader.onload = () => {
                    const binaryStr = reader.result;
                    imageArrayBuffer.push(arrayBufferToBase64(binaryStr));
                    setItemImage(itemImage => imageArrayBuffer);
                };
            
                reader.readAsArrayBuffer(file);
            });
		}else{
            setItemImage(empty);
        }
    }

    const onCreateCatDialogClose = () => {
        setCmptCategoryName(empty);
        setCatItemImage(empty);

        setCreateCategoryDialog(false);
    }

    const onCatImageUpload = (files) => {
        var imageArrayBuffer = [];
        if (files.length > 0) {
            Array.from(files).forEach(file => {
                const reader = new FileReader();
            
                reader.onabort = () => de('file reading was aborted');
                reader.onerror = () => de('file reading has failed');
                reader.onload = () => {
                    const binaryStr = reader.result;
                    imageArrayBuffer.push(arrayBufferToBase64(binaryStr));
                    setCatItemImage(catItemImage => imageArrayBuffer);
                };
            
                reader.readAsArrayBuffer(file);
            });
		}else{
            setCatItemImage(empty);
        }
    }

    const addCompetitorCategory = (config = axiosConfig) => {
        let formData = new FormData();
		formData.append('cmpt_code', competitor_code);
        formData.append('category_name', cmptCategoryName);
        formData.append('category_image', catItemImage);

        if(cmptCategoryName === empty){
			snack('Please insert category name.', 'warning');
		}else{
			API_Service.post(req_compt_category, formData, config).then(({data}) => {
				const {result, message, extraData} = data;
				if (result) {
					snack(language.insert_success);
                    loadCmptDetails(competitor_code);
                    onCreateCatDialogClose();
				} else {
					snack(message, 'warning');
				}
			});
		}
    }

    const changeCategoryStatus = (rowData, config = axiosPut) => {
		var params = {
			category_code: rowData[0],
            cmpt_code: competitor_code
		};
		params = axiosURIencode(params);
		API_Service.put(req_compt_category, params, config).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
				loadCmptDetails(competitor_code);
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
	}

    const openCategoryDialog = (data) => {
        if(data){
            setCmptCategoryDialog(true);
            setSelectedCmptCategory(data);
        }
    }

	return (
        <>
            <Dialog fullScreen open={open} onClose={() => onDialogClose()} /*TransitionComponent={Transition}*/>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onDialogClose()} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {language.competitor_details}
                        </Typography>
                        {
                            integration === '0' && currentPage === 0 && (
                                <Button
                                    onClick={() => setEditDialog(true)}
                                    size="small">
                                    <EditIcon style={{color: 'black'}}/>
                                </Button>
                            )
                        }
                    </Toolbar>
                </AppBar>
                {
                    currentPage == 0 && (
                        <Grid container spacing={3} className={classes.grid}>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}>
                                    {
                                        Object.keys(labels).map((key, idx) => {
                                            var subHeader = labels[key];
                                            var listValue = competitorDetail[key] == null ? '-' : competitorDetail[key];
                                            if (key === 'created_at') {
                                                listValue = competitorDetail[key] == null ? '-' : moment(competitorDetail[key], 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                                            }
                                            if (key === 'company' && listValue !== '-') {
                                                const branch = JSON.parse(listValue.toUpperCase());
                                                listValue = branch.join(", ");
                                            }
                                            return (
                                                <div
                                                    style={{marginTop: 10}}
                                                    key={String(idx)}>
                                                    <Typography variant={'inherit'} component={'p'}>
                                                        {subHeader}
                                                    </Typography>
                                                    <Typography variant={'inherit'} component={'h4'}>
                                                        {listValue}
                                                    </Typography>
                                                </div>
                                            )
                                        })
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    )
                }
                {
                    currentPage == 1 && (
                        <>
                            <div style={{height: 20}}/>
                            <MUIDataTable
                                title={language.competitor_products}
                                data={showActive ? activeProductList : inactiveProductList}
                                columns={headCells}
                                options={{
                                    selectableRows: 'none',
                                    print: false,
                                    filter: false,
                                    download: false,
                                    rowsPerPage: 150,
                                    rowsPerPageOptions: [150, 300, 600, 900],
                                    responsive: 'simple',
                                    setTableProps: () => ({size: 'small'}),
                                    onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
                                        if (colIndex !== (headCells.length - 1)) {
                                            openEditDialog(showActive ? activeProductList[dataIndex] : inactiveProductList[dataIndex]);
                                        }
                                    },
                                    customToolbar: () => {
                                        return (
                                            <React.Fragment>
                                                {
                                                    showActive ?
                                                        <Tooltip title={'Show Active'}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowActive(false);
                                                                }}>
                                                                <VisibilityOffIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={'Show Inactive Only'}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowActive(true);
                                                                }}>
                                                                <VisibilityIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                <Tooltip title={'Add New'}>
                                                    <IconButton
                                                        edge={'end'}
                                                        color={'primary'}
                                                        onClick={() => setCreateDialog(true)} 
                                                        aria-label="addnew">
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        );
                                    },
                                }}
                            />
                        </>
                    )
                }
                {
                    currentPage == 2 && (
                        <>
                            <div style={{height: 20}}/>
                            <MUIDataTable
                                title={language.competitor_product_category}
                                data={showCatActive ? activeCategoryList : inactiveCategoryList}
                                columns={headCells2}
                                options={{
                                    selectableRows: 'none',
                                    print: false,
                                    filter: false,
                                    download: false,
                                    rowsPerPage: 150,
                                    rowsPerPageOptions: [150, 300, 600, 900],
                                    responsive: 'simple',
                                    setTableProps: () => ({size: 'small'}),
                                    onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
                                        if (colIndex !== (headCells.length - 1)) {
                                            openCategoryDialog(showCatActive ? activeCategoryList[dataIndex] : inactiveCategoryList[dataIndex]);
                                        }
                                    },
                                    customToolbar: () => {
                                        return (
                                            <React.Fragment>
                                                {
                                                    showCatActive ?
                                                        <Tooltip title={'Show Active'}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowCatActive(false);
                                                                }}>
                                                                <VisibilityOffIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={'Show Inactive Only'}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowCatActive(true);
                                                                }}>
                                                                <VisibilityIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                <Tooltip title={'Add New'}>
                                                    <IconButton
                                                        edge={'end'}
                                                        color={'primary'}
                                                        onClick={() => setCreateCategoryDialog(true)} 
                                                        aria-label="addnew">
                                                        <AddIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </React.Fragment>
                                        );
                                    },
                                }}
                            />
                        </>
                    )
                }
                <div style={{height: 250}}/>
                <BottomNavigation
                    value={currentPage}
                    onChange={(event, newValue) => {
                        setCurrentPage(newValue);
                    }}
                    showLabels
                    className={classes.bottomNav}>
                    <BottomNavigationAction label="Info" icon={<InfoIcon/>}/>
                    <BottomNavigationAction label="Products" icon={<CreateNewFolderIcon/>}/>
                    <BottomNavigationAction label="Templates" icon={<NoteAddIcon/>}/>
                </BottomNavigation>
            </Dialog>

            <AddCompetitor
                open={editDialog}
                axiosConfig={axiosConfig}
                axiosPut={axiosPut}
                snack={snack}
                language={language}
                onClose={() => {
                    setEditDialog(false)
                }}
                onReload={(data) => {
                    loadCmptDetails(data);
                    onReload();
                }}
                edit={competitorDetail}
                branchesList={branchesList}
            />

            <Dialog fullScreen={fullScreen} aria-labelledby="simple-dialog-title" onClose={() => onCreateDialogClose()} open={createDialog}>
                <DialogTitle id="simple-dialog-title">
                    {language.add_product}
                </DialogTitle>
                <div style={{width: 600, padding: theme.spacing(2)}}>
                    <div className={classes.viewInRowSpace}>
                        <TextField
                            id="input-cmpt-code"
                            style={{width: '48%', height: 50}}
                            inputProps={{maxLength: 20}}
                            label={language.product_code}
                            value={cmptProductCode}
                            onChange={(e) => setCmptProductCode(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-name"
                            style={{width: '48%', height: 50}}
                            label={language.product_name}
                            value={cmptProductName}
                            onChange={(e) => setCmptProductName(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-product-barcode"
                            style={{width: '48%', height: 50}}
                            label={language.barcode}
                            value={cmptProductBarcode}
                            onChange={(e) => setCmptProductBarcode(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-remark"
                            style={{width: '100%', height: 50}}
                            label={language.remark}
                            value={cmptProductRemark}
                            onChange={(e) => setCmptProductRemark(e.target.value)}
                        />
                        <TextField
                            id="input-cmpt-description"
                            style={{width: '100%', height: 100}}
                            multiline
                            rows={4}
                            label={language.description}
                            value={cmptProductDesc}
                            onChange={(e) => setCmptProductDesc(e.target.value)}
                        />
                        <div className={classes.dropZone}>
                            <DropzoneArea
                                onChange={(files) => {
                                    onItemImageUpload(files)
                                }}
                                dropzoneParagraphClass={classes.dropzoneText}
                                acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                filesLimit={10}
                                clearOnUnmount={true}
                            />
                        </div>
                    </div>
                    <div style={{height: 20}}/>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{marginLeft: 'auto'}}
                            variant="outlined"
                            onClick={() => {
                                addCompetitorProduct();
                            }}
                            color={'primary'}>
                            {language.add}
                        </Button>
                    </div>
                    <div style={{height: 20}}/>
                </div>
            </Dialog>

            <CompetitorProductDetails
                open={cmptProductDialog}
                onDialogClose={() => setCmptProductDialog(false)}
                details={selectedCmptProduct}
                axiosConfig={axiosConfig}
                axiosPut={axiosPut}
                language={language}
                snack={snack}
                onReload={() => loadCmptDetails(competitor_code)}
            />

            <Dialog fullScreen={fullScreen} aria-labelledby="simple-dialog-title" onClose={() => onCreateCatDialogClose()} open={createCategoryDialog}>
                <DialogTitle id="simple-dialog-title">
                    {language.add_category}
                </DialogTitle>
                <div style={{width: 600, padding: theme.spacing(2)}}>
                    <div className={classes.viewInRowSpace}>
                        {/* <TextField
                            id="input-cmpt-code"
                            style={{width: '48%', height: 50}}
                            inputProps={{maxLength: 20}}
                            label={'Category Code'}
                            value={cmptProductCode}
                            onChange={(e) => setCmptProductCode(e.target.value)}
                        /> */}
                        <TextField
                            id="input-cmpt-name"
                            style={{width: '100%', height: 50}}
                            label={language.category_name}
                            value={cmptCategoryName}
                            onChange={(e) => setCmptCategoryName(e.target.value)}
                        />
                        <div className={classes.dropZone}>
                            <DropzoneArea
                                onChange={(files) => {
                                    onCatImageUpload(files)
                                }}
                                dropzoneParagraphClass={classes.dropzoneText}
                                acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                filesLimit={10}
                                clearOnUnmount={true}
                            />
                        </div>
                    </div>
                    <div style={{height: 20}}/>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{marginLeft: 'auto'}}
                            variant="outlined"
                            onClick={() => {
                                addCompetitorCategory();
                            }}
                            color={'primary'}>
                            {language.add}
                        </Button>
                    </div>
                    <div style={{height: 20}}/>
                </div>
            </Dialog>

            <CompetitorCategoryDetails
                open={cmptCategoryDialog}
                onDialogClose={() => {setCmptCategoryDialog(false);setSelectedCmptCategory({});}}
                details={selectedCmptCategory}
                axiosConfig={axiosConfig}
                axiosPut={axiosPut}
                language={language}
                snack={snack}
                onReload={() => loadCmptDetails(competitor_code)}
            />
        </>
	)
}