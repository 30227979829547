import React, {useEffect, useState} from 'react';
import {useCommonStyles} from '../../Styles';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import RoomIcon from '@material-ui/icons/Room';
import {BottomNavigation, BottomNavigationAction, Chip, DialogContent, Tab, Tabs} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ReactMapboxGl, {Layer, Marker, Popup, Source, ZoomControl} from 'react-mapbox-gl';
import {pure} from 'recompose';
import 'mapbox-gl/dist/mapbox-gl.css';
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {
    mapbox_key,
    no_image,
    req_cust_approve,
    req_module_setting,
    req_salesman_location,
    req_checkin_stocktake,
	req_checkin_stocktransfer,
	req_checkin_images,
    req_stock_take_status,
    req_stock_transfer_status
} from '../../Helper/Constants';
import moment from 'moment';
import JString from '@easytech-international-sdn-bhd/jstring';
import {usePaperStyles} from '../../Styles/StylePaper';
import MediaBox from '../MediaBox';
import Tooltip from "@material-ui/core/Tooltip";
import API_Service, {axiosURIencode} from '../../Services/API_Service';
import {lineString} from '@turf/turf';
import MUIDataTable from 'mui-datatables';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import StockTakeDetails from '../../Components/StockTakeDetails';
import {de, getSetting} from '../../Helper/Helper';
import _ from 'lodash';
import StockTransferDetails from '../StockTransferDetails';
import ReactImageMagnify from 'react-image-magnify';
import VirtualList from '../VirtualList';

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	large_image: {
		minWidth: 800,
		maxHeight: 450
	},
	large_image2: {
		maxWidth: 400,
		maxHeight: 450
	},
	image: {
		maxWidth: 150,
		marginLeft: 10
	},
	card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	cardChosen: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		backgroundColor: theme.palette.secondary.light
	},
	appBar: {
		position: 'relative',
	},
	saveBtn: {
		marginTop: theme.spacing(2),
	},
	input: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'flex-start',
		color: theme.palette.text.secondary,
		zIndex: theme.zIndex + 1
	},
	grid: {
		padding: theme.spacing(2)
	},
	textleft: {
		textAlign: "left"
	},
	maxImage: {
		maxWidth: 250,
		maxHeight: 280
	},
	marginTop: {
		marginTop: theme.spacing(1)
	},
	chooseButton: {
		width: '100%',
		height: theme.spacing(5),
		justifyContent: 'left',
		marginTop: theme.spacing(1)
	},
	image_title: {
		textAlign: 'center',
		fontSize: 16,
		color: "black"
	},
	image_desc: {
		textAlign: 'center',
		fontSize: 14,
		color: "grey"
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
	mobile_checkin_id: "Doc ID",
	st_id: "Stock Take ID",
	staff_code: "Agent",
	person_met: "Person Met",
	cust_company_name: "Outlet",
	create_date: "Date",
	checkin_time: "Checked-In",
	// checkin_location: "Checked-In At",
	checkout_time: "Checked-Out",
	// checkout_location: "Checked-Out At",
	remark1: "Remark 1",
	remark2: "Remark 2",
	remark3: "Remark 3"
};
const schedLabels = {
	sched_datetime: "Appointment Date",
	sched_note: "Note",
	site_in_charge: "Site In-Charge",
	site_in_charge_contact: 'Contact'
}

const headCells = [
	{
		name: "st_id",
		label: "Stock Take ID",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true,
		}
	},
	{
		name: "name",
		label: "Agent",
		options: {
			display: true
		}
	},
	{
		name: "created_date",
		label: "Created Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "doc_ref_id",
		label: "Doc Ref",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					_.truncate(value)
				)
			}
		}
	},
	{
		name: "active_status",
		label: "Active Status",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "1" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "0" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	},
	{
		name: "st_fault_message",
		label: "Doc Fault Message",
		options: {
			display: false
		}
	}
]

const transferheadCells = [
	{
		name: "st_code",
		label: "Stock Take ID",
		options: {
			display: true
		}
	},
	{
		name: "st_date",
		label: "Doc Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "from_location",
		label: "From Location",
		options: {
			display: true
		}
	},
	{
		name: "to_location",
		label: "To Location",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: false
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: false
		}
	},
	{
		name: "name",
		label: "Agent",
		options: {
			display: true
		}
	},
	{
		name: "note",
		label: "Note",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					_.truncate(value)
				)
			}
		}
	},
	{
		name: "total",
		label: "Grand Total",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					JString(value).inCurrency()
				)
			}
		}
	},
	{
		name: "cancel_status",
		label: "Cancel Status",
		options: {
			display: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Cancelled"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	},
	{
		name: "st_fault_message",
		label: "Doc Fault Message",
		options: {
			display: false
		}
	}
]
export default function CheckinDetails({
										   checkinData,
										   open,
										   setClose,
										   language,
										   axiosApiConfig,
										   current_username,
										   snack,
										   customerList
									   }) {
	const classes = useStyles();
	const commonStyles = useCommonStyles();
	const paperStyle = usePaperStyles();
	const [userLocations, setUserLocations] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentST, setCurrentST] = useState(0);
	const [showDocDetails, toggleDocDetails] = useState(false);
	const [appSTList, setAppSTList] = useState([]);
	const [conSTList, setConSTList] = useState([]);
	const [transSTList, setTransSTList] = useState([]);
	const [postSTList, setPostSTList] = useState([]);
	const [currentSTrans, setCurrentSTrans] = useState(0);
	const [showTransDocDetails, toggleTransDocDetails] = useState(false);
	const [appSTransList, setAppSTransList] = useState([]);
	const [conSTransList, setConSTransList] = useState([]);
	const [transSTransList, setTransSTransList] = useState([]);
	const [postSTransList, setPostSTransList] = useState([]);
	const [sTransInclude, setSTransInclude] = useState(0);
	const [approveCheckinID, setApproveCheckinID] = useState(null);
	const [approveSalespersonID, setApproveSalespersonID] = useState(null);
	const [approveOutletName, setApproveOutletName] = useState(null);
	const [approveOutletAddr, setApproveOutletAddr] = useState(null);
	const [cView, setCView] = useState(false);
	const [stToView, setSTToView] = useState(null);
	const [attachments, setAttachments] = useState([]);

	useEffect(() => {
		if(open){
			const {checkin_time, checkout_time, salesperson_id, mobile_checkin_id} = checkinData;
			const param = axiosURIencode({
				mobile_checkin_id: mobile_checkin_id,
				from_time: checkin_time,
				to_time: checkout_time,
				salesperson_id
			});

			API_Service.get(`${req_checkin_stocktake}?${param}`, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (parseInt(result) === 1) {
					var apparr		= extraData.filter((item) => item.st_status === '0');
					var conarr		= extraData.filter((item) => item.st_status === '1');
					var transarr	= extraData.filter((item) => item.st_status === '2');
					var postarr		= extraData.filter((item) => item.st_status === '3');
					// de("betty-req_checkin_stocktake", extraData, apparr, conarr, transarr, postarr);
					setAppSTList(apparr);
					setConSTList(conarr);
					setTransSTList(transarr);
					setPostSTList(postarr);
				}
			});

			API_Service.get(`${req_checkin_images}?${param}`, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (parseInt(result) === 1) {
					setAttachments(extraData);
				}
			});

			API_Service.get(`${req_checkin_stocktransfer}?${param}`, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (parseInt(result) === 1) {
					var apptransarr 	= extraData.filter((item) => item.st_status === '0');
					var contransarr 	= extraData.filter((item) => item.st_status === '1');
					var trantranssarr	= extraData.filter((item) => item.st_status === '2');
					var posttransarr	= extraData.filter((item) => item.st_status === '3');
					// de("betty-req_checkin_stocktransfer", extraData, apptransarr, contransarr, trantranssarr, posttransarr);
					setAppSTransList(apptransarr);
					setConSTransList(contransarr);
					setTransSTransList(trantranssarr);
					setPostSTransList(posttransarr);
				}
			});

			API_Service.get(`${req_salesman_location}?${param}`, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (parseInt(result) === 1) {
					setUserLocations(extraData);
				}
			});

			API_Service.get(req_module_setting, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result == 1) {
					setSTransInclude(getSetting(extraData, 'StockTransfer')?.include);
				} else {
					setSTransInclude([]);
				}
			});
		}
	}, [open]);

	const closeDialog = () => {
		setAppSTList([]);
		setConSTList([]);
		setTransSTList([]);
		setPostSTList([]);
		setAttachments([]);
		setAppSTransList([]);
		setConSTransList([]);
		setTransSTransList([]);
		setPostSTransList([]);
		setUserLocations([]);
		setSTransInclude([]);
		setSTToView(null);
		onClose();
	}

	const getSelectedDocIds = (data, type) => {
		var original_data = [];
		if (currentPage === 2) {
			original_data = conSTransList;
			if (type == 'transferred') {
				original_data = transSTransList;
			}
			if (type == 'posted') {
				original_data = postSTransList;
			}
			if (type == 'active') {
				original_data = appSTransList;
			}
		} else {
			original_data = conSTList;
			if (type == 'transferred') {
				original_data = transSTList;
			}
			if (type == 'posted') {
				original_data = postSTList;
			}
			if (type == 'active') {
				original_data = appSTList;
			}
		}

		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			if (currentPage === 2) {
				const {st_code} = original_data[dataIndex];
				ids += JString(st_code).toString();
			} else {
				const {st_id} = original_data[dataIndex];
				ids += JString(st_id).toString();
			}
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		return ids;
	}

	const toggleDocStatus = (d, type = 'confirmed', status) => {
		var params = {
			doc_id: getSelectedDocIds(d, type),
			st_status: status,
			update_by: current_username
		};
		params = axiosURIencode(params);
		API_Service.put(req_stock_take_status, params, axiosApiConfig).then(({data}) => {
			const {result} = data;
			if (result == 1) {
				var original_data = conSTList;
				if (type == 'transferred') {
					original_data = transSTList;
				}
				if (type == 'posted') {
					original_data = postSTList;
				}
				if (type == 'active') {
					original_data = appSTList;
				}
				for (let index = 0, size = d.length; index < size; index++) {
					const {dataIndex} = d[index];
					original_data[dataIndex]['st_status'] = status;
					if (status === 2) {
						transSTList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setConSTList(original_data);
					} else if (status === 3) {
						postSTList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setTransSTList(original_data);
					} else {
						conSTList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setAppSTList(original_data);
					}
					setCurrentST(status);
				}
				snack(language.update_success);
			} else {
				snack(language.update_fail, 'warning');
			}
		})
	}

	const toggleTransferDocStatus = (d, type = 'confirmed', status) => {
		var params = {
			doc_id: getSelectedDocIds(d, type),
			st_status: status,
			update_by: current_username
		};
		params = axiosURIencode(params);
		API_Service.put(req_stock_transfer_status, params, axiosApiConfig).then(({data}) => {
			const {result} = data;
			if (result == 1) {
				var original_data = conSTransList;
				if (type == 'transferred') {
					original_data = transSTransList;
				}
				if (type == 'posted') {
					original_data = postSTransList;
				}
				if (type == 'active') {
					original_data = appSTransList;
				}
				for (let index = 0, size = d.length; index < size; index++) {
					const {dataIndex} = d[index];
					original_data[dataIndex]['st_status'] = status;
					if (status === 2) {
						transSTransList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setConSTransList(original_data);
					} else if (status === 3) {
						postSTransList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setTransSTransList(original_data);
					} else {
						conSTransList.push(original_data[dataIndex]);
						original_data.splice(dataIndex, 1);
						setAppSTransList(original_data);
					}
					setCurrentSTrans(status);
				}
				snack(language.update_success);
			} else {
				snack(language.update_fail, 'warning');
			}
		})
	}

	const toggleDocCancel = (d, type = 'confirmed') => {
		var params = {
			doc_id: getSelectedDocIds(d, type),
			update_by: current_username,
			delete: 1
		};
		params = axiosURIencode(params);
		API_Service.put(req_stock_transfer_status, params, axiosApiConfig).then(({data}) => {
			const {result} = data;
			if (result == 1) {
				snack(language.update_success);
			} else {
				snack(language.update_fail, 'warning');
			}
		})
	}

	const toggleTranserDocCancel = (d, type = 'confirmed') => {
		var params = {
			doc_id: getSelectedDocIds(d, type),
			update_by: current_username,
			delete: 1
		};
		params = axiosURIencode(params);
		API_Service.put(req_stock_take_status, params, axiosApiConfig).then(({data}) => {
			const {result} = data;
			if (result == 1) {
				snack(language.update_success);
			} else {
				snack(language.update_fail, 'warning');
			}
		})
	}

	const theme = useTheme();
	const lightTheme = theme.palette.type === 'light';

	const onClose = () => {
		setClose();
	}

	const [selectedImage, setSelectedImage] = useState(null);

	const latlongList = [{
		title: 'CHECK-IN',
		loc: [checkinData?.location_lng || 0, checkinData?.location_lat || 0]
	}, {title: 'CHECK-OUT', loc: [checkinData?.checkout_lng || 0, checkinData?.checkout_lat || 0]}];
	// const attachements = checkinData?.uploads || [];

	const hasSched = checkinData && 'schedule' in checkinData && Object.keys(checkinData?.schedule || {})?.length > 0;

	const approve = (checkinID, salespersonID, outletName, outletAddr) => {
		setApproveCheckinID(approveCheckinID => (checkinID));
		setApproveSalespersonID(approveSalespersonID => (salespersonID));
		setApproveOutletName(approveOutletName => (outletName));
		setApproveOutletAddr(approveOutletAddr => (outletAddr));
		let formData = new FormData();
		formData.append('checkin_id', checkinID);
		formData.append('salesperson', salespersonID);
		formData.append('cust_company_name', outletName);
		formData.append('billing_address1', outletAddr);
		API_Service.post(req_cust_approve, formData, axiosApiConfig).then(({data}) => {
			const {result, message, extraData} = data;
			if (result) {
				setClose();
				snack(language.insert_success);
			} else {
				snack(message, 'warning');
			}
		});
	}

	const reject = (checkinID, salespersonID) => {
		setApproveCheckinID(approveCheckinID => (checkinID));
		setApproveSalespersonID(approveSalespersonID => (salespersonID));
		setCView(true);
	}

	const cViewClose = (newValue) => {
		setCView(false);
		if (newValue.value != 'RESET') {
			var params = {
				checkin_id: approveCheckinID,
				salesperson_id: approveSalespersonID,
				cust_code: newValue.value
			};
			params = axiosURIencode(params);
			API_Service.put(req_cust_approve, params, axiosApiConfig).then(({data}) => {
				const {result, message} = data;
				if (result == 1) {
					setClose();
					snack(language.update_success);
				} else {
					snack(message, 'warning');
				}
			});
		}
	}

	return (
		<div className={classes.root}>
			<Dialog fullScreen open={open} onClose={() => closeDialog()} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
							<CloseIcon/>
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							{currentPage === 0 ? language.checkin_dtl : currentPage === 1 ? language.stock_take : language.stock_transfer}
						</Typography>
					</Toolbar>
				</AppBar>
				{
					currentPage === 0 && (
						<div>
							<Grid container spacing={3} className={classes.grid}>
								<Grid item xs={6}>
									<Paper className={classes.paper}>
										<Grid container spacing={3}>
											{
												Object.keys(labels).map((key, idx) => {
													var subHeader = labels[key];
													var listValue = '-';
													if (checkinData === undefined) {
														return null;
													}
													Object.keys(checkinData).map((nKey, nIdx) => {
														if (nKey === 'schedule') return null;
														if (nKey === key) {
															listValue = checkinData[nKey] || '-';
															var st_id = '';
															if (nKey === 'st_id') {
																for (let i = 0; i < listValue.length; i++) {
																	st_id += listValue[i]['st_id'];
																	if (i !== listValue.length - 1) {
																		st_id += ',';
																	}
																}
																listValue = st_id;
															}
															if (key === 'remark3') {
																var remark = '';
																for (let i = 0; i < checkinData['remark3'].length; i++) {
																	if (checkinData['remark3'][i]['code'] === 'collection') {
																		remark += checkinData['remark3'][i]['value'];
																		if (checkinData['remark3'][i]['selected'] !== '') {
																			remark += '. Yes\n';
																		}
																	}
																}
																if (remark === '') {
																	remark = '-';
																}
																listValue = remark;
															}
														}
													});
													if (
														key === 'create_date' ||
														key === 'checkin_time' ||
														key === 'checkout_time'
													) {
														listValue = moment(listValue, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
														if (key === 'checkin_time') {
															listValue += '\n' + checkinData['checkin_location'];
														} else if (key === 'checkout_time') {
															listValue += '\n' + checkinData['checkout_location'];
														}
													}
													{/* if(key == 'cust_company_name' && checkinData['customer_id'] == 999999999){
														if(checkinData['pending_customer'] && checkinData['pending_customer'].length > 0){
															listValue = (
																<>
																{checkinData['pending_customer'][0].outlet_name}&nbsp;
																<Chip size="small" style={{backgroundColor: "yellowgreen"}} onClick={()=>approve(checkinData.mobile_checkin_id, checkinData.salesperson_id, checkinData.pending_customer[0].outlet_name, checkinData.pending_customer[0].outlet_address)} label={'Approve'}/>&nbsp;
																<Chip size="small" style={{backgroundColor: "orangered"}} onClick={()=>reject(checkinData.mobile_checkin_id, checkinData.salesperson_id)} label={'Reject'}/>
																</>
															);
														}
													} */}
													return (
														<Grid key={idx} item xs={6}>
															<div
																style={{marginTop: 10}}
																key={String(idx)}>
																<Typography variant={'inherit'} component={'p'}>
																	{subHeader}
																</Typography>
																<Typography variant={'inherit'} component={'h4'}
																			style={{whiteSpace: 'pre-line'}}>
																	{listValue}
																</Typography>
															</div>
														</Grid>
													)
												})
											}
										</Grid>
									</Paper>
								</Grid>
								{
									hasSched && (
										<Grid item xs={6}>
											<Paper className={classes.paper}>
												{
													Object.keys(checkinData?.schedule || {})?.length > 0 &&
													Object.keys(checkinData?.schedule || {}).map
													((field, index) => {
														if (['sched_datetime', 'sched_note', 'site_in_charge', 'site_in_charge_contact'].includes(field)) {
															var listValue = checkinData.schedule[field];
															if (field == 'sched_datetime') {
																listValue = moment(listValue, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A');
															}
															return (
																<div
																	style={{marginTop: 10}}
																	key={String(index)}>
																	<Typography variant={'inherit'} component={'p'}>
																		{schedLabels[field]}
																	</Typography>
																	<Typography variant={'inherit'} component={'h4'}>
																		{listValue || '-'}
																	</Typography>
																</div>
															)
														}
														if (['tags', 'project_no'].includes(field)) {
															listValue = checkinData.schedule[field];
															if (!listValue) return null;
															if (field == 'tags') {
																return (
																	<div>
																		{
																			Object.keys(listValue).map((item, inx) => {
																				return (
																					<div
																						style={{marginTop: 10}}
																						key={String(inx)}>
																						<Typography variant={'inherit'}
																									component={'p'}>
																							{String(item).toUpperCase()}
																						</Typography>
																						<div className={classes.row}>
																							{
																								listValue[item] && listValue[item].map((tag, idx) =>
																									<Chip
																										key={String(idx)}
																										variant={'outlined'}
																										label={tag}
																									/>
																								)
																							}
																						</div>
																					</div>
																				)
																			})
																		}
																	</div>
																)
															}
															if (field == 'project_no') {
																return (
																	<div>
																		{
																			listValue.map((item, index) =>
																				<div
																					style={{marginTop: 10}}
																					key={String(index)}>
																					<Typography variant={'inherit'}
																								component={'p'}>
																						Project Code
																					</Typography>
																					<Chip
																						variant={'outlined'}
																						label={item.project_code}
																					/>
																				</div>
																			)
																		}
																	</div>
																)
															}
														}
													})
												}
											</Paper>
										</Grid>
									)
								}
							</Grid>
							{
								(attachments && attachments.length > 0) && (
									<div className={paperStyle.paperViewWOspace}>
										{
											attachments.map((item, index) => {
												return (
													<MediaBox
														onClick={() => setSelectedImage(item)}
														style={classes.image}
														key={String(index)}
														image={item.upload_image || no_image}
														title={item.upload_type_name}
														description={item.upload_remark}
													/>
												)
											})
										}
									</div>
								)
							}
							{
								selectedImage && (
									<Dialog
										fullWidth={false}
										maxWidth={'lg'}
										open={selectedImage != null}
										aria-labelledby="form-dialog-title"
										onClose={() => {
											setSelectedImage(null)
										}}>
										<DialogContent style={{height: 600}}>
											<div className={classes.large_image}>
												<ReactImageMagnify
													className={classes.large_image2}
													{...{
														smallImage: {
															alt: 'Wristwatch by Ted Baker London',
															isFluidWidth: true,
															src: selectedImage.upload_image,
														},
														largeImage: {
															src: selectedImage.upload_image,
															width: 1200,
															height: 1800
														}
													}}
												/>
											</div>
										</DialogContent>
									</Dialog>
								)
							}
							{
								checkinData && (
									<RenderMap
										lightTheme={lightTheme}
										latlongList={latlongList}
										userLocations={userLocations}
									/>
								)
							}
						</div>
					)
				}
				{
					currentPage === 1 && (
						<div>
							<Tabs
								variant="fullWidth"
								value={currentST}
								indicatorColor="primary"
								textColor="primary"
								onChange={(event, newValue) => {
									setCurrentST(newValue);
								}}
								aria-label="nav tabs example">
								<LinkTab label={language.inapp_doc}/>
								<LinkTab label={language.confirmed_doc}/>
								<LinkTab label={language.trans_doc}/>
								<LinkTab label={language.posted_doc}/>
							</Tabs>
							{
								currentST === 0 && (
									<MUIDataTable
										title={checkinData?.mobile_checkin_id ? checkinData.mobile_checkin_id : ''}
										data={appSTList}
										columns={headCells}
										options={{
											print: false,
											filter: true,
											selectableRows: appSTList?.length > 0 ? 'multiple' : 'none',
											rowsSelected: appSTList?.length > 0 ? undefined : [],
											rowsPerPage: 10,
											rowsPerPageOptions: [10],
											responsive: 'simple',
											onRowClick: (rowData) => {
												// de("betty-rowData", rowData);
												setSTToView(rowData[0]);
												toggleDocDetails(true);
											},
											customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
												const data = selectedRows?.data;
												return (
													<div style={{marginRight: 25}}>
														<ToolbarCustomSelect
															title={'Transfer'}
															onClick={() => {
																toggleDocStatus(data, 'active', 1)
															}}
														>
															<SendIcon/>
														</ToolbarCustomSelect>
														<ToolbarCustomSelect
															title={'Delete'}
															onClick={() => {
																toggleDocCancel(data, 'active')
															}}
														>
															<DeleteIcon/>
														</ToolbarCustomSelect>
													</div>
												)
											},
										}}
									/>
								)
							}
							{
								currentST === 1 && (
									<MUIDataTable
										title={language.stock_take}
										data={conSTList}
										columns={headCells}
										options={{
											print: false,
											filter: true,
											selectableRows: conSTList?.length > 0 ? 'multiple' : 'none',
											rowsSelected: conSTList?.length > 0 ? undefined : [],
											rowsPerPage: 10,
											rowsPerPageOptions: [10],
											responsive: 'simple',
											onRowClick: (rowData) => {
												setSTToView(rowData[0]);
												toggleDocDetails(true)
											},
											customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
												const data = selectedRows?.data;
												return (
													<div style={{marginRight: 25}}>
														<ToolbarCustomSelect
															title={'Transfer'}
															onClick={() => {
																toggleDocStatus(data, 'confirmed', 2)
															}}
														>
															<SendIcon/>
														</ToolbarCustomSelect>
														<ToolbarCustomSelect
															title={'Delete'}
															onClick={() => {
																toggleDocCancel(data, 'confirmed')
															}}
														>
															<DeleteIcon/>
														</ToolbarCustomSelect>
													</div>
												)
											},
										}}
									/>
								)
							}
							{
								currentST === 2 && (
									<MUIDataTable
										title={language.stock_take}
										data={transSTList}
										columns={headCells}
										options={{
											print: false,
											filter: true,
											selectableRows: transSTList?.length > 0 ? 'multiple' : 'none',
											rowsSelected: transSTList?.length > 0 ? undefined : [],
											rowsPerPage: 10,
											rowsPerPageOptions: [10],
											responsive: 'simple',
											onRowClick: (rowData) => {
												setSTToView(rowData[0]);
												toggleDocDetails(true)
											},
											customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
												const data = selectedRows?.data;
												return (
													<div style={{marginRight: 25}}>
														<ToolbarCustomSelect
															title={'Transfer'}
															onClick={() => {
																toggleDocStatus(data, 'transferred', 3)
															}}
														>
															<SendIcon/>
														</ToolbarCustomSelect>
													</div>
												)
											},
										}}
									/>
								)
							}
							{
								currentST === 3 && (
									<MUIDataTable
										title={language.stock_take}
										data={postSTList}
										columns={headCells}
										options={{
											print: false,
											filter: true,
											selectableRows: 'none',
											rowsPerPage: 10,
											rowsPerPageOptions: [10],
											responsive: 'simple',
											onRowClick: (rowData) => {
												setSTToView(rowData[0]);
												toggleDocDetails(true)
											}
										}}
									/>
								)
							}
						</div>
					)
				}
				{
					sTransInclude === '1' && (
						currentPage === 2 && (
							<div>
								<Tabs
									variant="fullWidth"
									value={currentSTrans}
									indicatorColor="primary"
									textColor="primary"
									onChange={(event, newValue) => {
										setCurrentSTrans(newValue);
									}}
									aria-label="nav tabs example">
									<LinkTab label={language.inapp_doc}/>
									<LinkTab label={language.confirmed_doc}/>
									<LinkTab label={language.trans_doc}/>
									<LinkTab label={language.posted_doc}/>
								</Tabs>
								{
									currentSTrans === 0 && (
										<MUIDataTable
											title={language.stock_transfer}
											data={appSTransList}
											columns={transferheadCells}
											options={{
												print: false,
												filter: true,
												selectableRows: appSTransList?.length > 0 ? 'multiple' : 'none',
												rowsSelected: appSTransList?.length > 0 ? undefined : [],
												rowsPerPage: 10,
												rowsPerPageOptions: [10],
												responsive: 'simple',
												onRowClick: () => {
													toggleTransDocDetails(true)
												},
												customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
													const data = selectedRows?.data;
													return (
														<div style={{marginRight: 25}}>
															<ToolbarCustomSelect
																title={'Transfer'}
																onClick={() => {
																	toggleTransferDocStatus(data, 'active', 1)
																}}
															>
																<SendIcon/>
															</ToolbarCustomSelect>
															<ToolbarCustomSelect
																title={'Delete'}
																onClick={() => {
																	toggleTranserDocCancel(data, 'active')
																}}
															>
																<DeleteIcon/>
															</ToolbarCustomSelect>
														</div>
													)
												},
											}}
										/>
									)
								}
								{
									currentSTrans === 1 && (
										<MUIDataTable
											title={language.stock_transfer}
											data={conSTransList}
											columns={transferheadCells}
											options={{
												print: false,
												filter: true,
												selectableRows: conSTransList?.length > 0 ? 'multiple' : 'none',
												rowsSelected: conSTransList?.length > 0 ? undefined : [],
												rowsPerPage: 10,
												rowsPerPageOptions: [10],
												responsive: 'simple',
												onRowClick: () => {
													toggleTransDocDetails(true)
												},
												customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
													const data = selectedRows?.data;
													return (
														<div style={{marginRight: 25}}>
															<ToolbarCustomSelect
																title={'Transfer'}
																onClick={() => {
																	toggleTransferDocStatus(data, 'confirmed', 2)
																}}
															>
																<SendIcon/>
															</ToolbarCustomSelect>
															<ToolbarCustomSelect
																title={'Delete'}
																onClick={() => {
																	toggleTranserDocCancel(data, 'confirmed')
																}}
															>
																<DeleteIcon/>
															</ToolbarCustomSelect>
														</div>
													)
												},
											}}
										/>
									)
								}
								{
									currentSTrans === 2 && (
										<MUIDataTable
											title={language.stock_transfer}
											data={transSTransList}
											columns={transferheadCells}
											options={{
												print: false,
												filter: true,
												selectableRows: transSTransList?.length > 0 ? 'multiple' : 'none',
												rowsSelected: transSTransList?.length > 0 ? undefined : [],
												rowsPerPage: 10,
												rowsPerPageOptions: [10],
												responsive: 'simple',
												onRowClick: () => {
													toggleTransDocDetails(true)
												},
												customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
													const data = selectedRows?.data;
													return (
														<div style={{marginRight: 25}}>
															<ToolbarCustomSelect
																title={'Transfer'}
																onClick={() => {
																	toggleTransferDocStatus(data, 'transferred', 3)
																}}
															>
																<SendIcon/>
															</ToolbarCustomSelect>
														</div>
													)
												},
											}}
										/>
									)
								}
								{
									currentSTrans === 3 && (
										<MUIDataTable
											title={language.stock_transfer}
											data={postSTransList}
											columns={transferheadCells}
											options={{
												print: false,
												filter: true,
												selectableRows: 'none',
												rowsPerPage: 10,
												rowsPerPageOptions: [10],
												responsive: 'simple',
												onRowClick: () => {
													toggleTransDocDetails(true)
												}
											}}
										/>
									)
								}
							</div>
						)
					)
				}

				<StockTakeDetails
					open={showDocDetails}
					setClose={() => toggleDocDetails((false))}
					language={language}
					stockTakeID={stToView}
					axiosConfig={axiosApiConfig}
				/>
				{/* <StockTransferDetails
					open={showTransDocDetails}
					setClose={() => toggleTransDocDetails(showDocDetails => (false))}
					language={language}
					itemList={checkinData?.transfer_dtl}
					docDtl={checkinData?.transfer_id}
					axiosConfig={axiosApiConfig}
					doctype={'transfer'}
					snack={snack}
					ToolbarCustomSelect={ToolbarCustomSelect}
				/> */}
				<BottomNavigation
					value={currentPage}
					onChange={(event, newValue) => {
						setCurrentPage(newValue);
					}}
					showLabels
					className={commonStyles.bottomNav}>
					<BottomNavigationAction label="CheckIn Details"/>
					<BottomNavigationAction label="Stock Take"/>
					{
						sTransInclude === '1' && (
							<BottomNavigationAction label="Stock Transfer"/>
						)
					}
				</BottomNavigation>
			</Dialog>

			<VirtualList
				open={cView}
				setClose={data => cViewClose(data)}
				title={language.cust_list}
				data={customerList}
				language={language}
			/>
		</div>
	)
}

export function ToolbarCustomSelect({onClick, title, children, disabled = false}) {
	return (
		<React.Fragment>
			<Tooltip title={title}>
				<IconButton disabled={disabled} onClick={() => onClick()}>
					{children}
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

const RenderMap = pure(({lightTheme, latlongList, userLocations}) => {
	const Map = ReactMapboxGl({accessToken: mapbox_key});
	const currentLocation = latlongList && latlongList[0]?.loc ? latlongList[0]?.loc : [101.621420, 3.142540];
	const wrapperStyle = {
		height: '100%', width: '90%',
		alignSelf: 'center',
		marginTop: 10
	};
	const [linearString, setLinearString] = useState({});
	useEffect(() => {
		if (userLocations) {
			var newArr = [];
			for (let i = 0; i < userLocations.length; i++) {
				const {latitude, longitude} = userLocations[i];
				const lat = parseFloat(latitude);
				const lng = parseFloat(longitude);
				if (lat && lng) {
					newArr.push([lng, lat]);
				}
			}
			if (newArr.length > 1) {
				const _ls = lineString(newArr);
				setLinearString(_ls);
			}
		}
	}, [userLocations]);
	return (
		<div style={wrapperStyle}>
			<Map
				zoom={[50]}
				center={currentLocation}
				style={lightTheme ? "mapbox://styles/mapbox/light-v10" : "mapbox://styles/mapbox/dark-v10"}
				containerStyle={{
					height: '80vh',
					width: '100%'
				}}>
				<ZoomControl/>
				{
					linearString && (
						<Source
							id="linestr"
							geoJsonSource={{
								type: "geojson",
								data: linearString
							}}
						/>
					)
				}
				<Layer
					id="linestr"
					sourceId="linestr"
					type="line"
					paint={{
						"line-color": "#f00"
					}}
				/>
				<RenderMarkerPopup
					latlongList={latlongList}
				/>
			</Map>
		</div>
	)
});
const RenderMarkerPopup = pure(({latlongList}) => {
	const [popupData, setPopupData] = useState({show: false, loc: [], text: ''});
	return (
		<div>
			{
				latlongList && latlongList.map((item, index) =>
					<Marker
						onMouseEnter={() => setPopupData({show: true, loc: item.loc, text: item.title})}
						onMouseLeave={() => setPopupData({show: false, loc: [], text: ''})}
						key={String(index)}
						coordinates={item.loc}
						anchor="bottom">
						<RoomIcon color={'primary'}/>
					</Marker>
				)
			}
			{
				popupData.show && (
					<Popup
						coordinates={popupData.loc}
						onClick={() => setPopupData({show: false, loc: [], text: ''})}
						anchor={'top'}>
						<p>{popupData.text}</p>
					</Popup>
				)
			}
		</div>
	)
});

function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

