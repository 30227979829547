import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service from '../../Services/API_Service';
import {empty, req_stock_category} from '../../Helper/Constants';
import {useMediaQuery, useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	},
	viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	}
}));

export default function AddCategory({open, onClose, language, onSnack, axiosConfig}) {
	const classes = useStyles();

	const [rows, setRows] = useState([{uom: "", price: 0, barcode: "", rate: ""}]);
	const [categoryCode, setCategoryCode] = useState(empty);
	const [categoryName, setCategoryName] = useState(empty);

	useEffect(() => {
	}, [open]);

	const closeRemove = (data) => {
		setCategoryCode(empty);
		setCategoryName(empty);
		if (data) {
			onClose(data[0]);
		}
	}

	const addCategory = () => {
		let formData = new FormData();
		formData.append('category_code', categoryCode);
		formData.append('category_name', categoryName);

		if (categoryName === empty || categoryCode === empty) {
			onSnack('Insert Category Code and Category Name', 'warning');
		} else {
			API_Service.post(req_stock_category, formData, axiosConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					closeRemove(extraData);
					onSnack(language.insert_success);
				} else {
					onSnack(language.insert_fail, 'warning');
				}
			});
		}
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog fullScreen={fullScreen} onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open}>
			<DialogTitle id="simple-dialog-title">
				{language.new_category}
			</DialogTitle>
			<div style={{width: 600, padding: theme.spacing(2)}}>
				<div className={classes.viewInRowSpace}>
					<TextField
						id="input-item-code"
						style={{width: '48%', height: 50}}
						inputProps={{maxLength: 10, pattern: "[a-z]{1,15}"}}
						label={language.category_code}
						value={categoryCode}
						onChange={(e) => setCategoryCode(e.target.value)}
					/>
					<TextField
						id="input-item-name"
						style={{width: '48%', height: 50}}
						label={language.category_name}
						value={categoryName}
						onChange={(e) => setCategoryName(e.target.value)}
					/>
				</div>
				<div style={{height: 20}}/>
				<div style={{display: "flex"}}>
					<Button
						style={{marginLeft: 'auto'}}
						variant="outlined"
						disabled={categoryCode === empty || categoryName === empty}
						onClick={() => {
							addCategory()
						}}
						color={'primary'}>
						{language.add}
					</Button>
				</div>
				<div style={{height: 20}}/>
			</div>
		</Dialog>
	)
}

