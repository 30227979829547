import React, {useContext, useEffect, useState} from 'react';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import JString from '@easytech-international-sdn-bhd/jstring';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    ck_st_columns,
    defDateFormat,
    empty,
    req_cust,
    req_custby_salesman,
    req_salesman,
    req_stock_take,
    req_stock_take_dtl,
    reset
} from '../../Helper/Constants';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import EventNoteIcon from '@material-ui/icons/EventNote';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import API_Service, {axiosDefaultConfig} from '../../Services/API_Service';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import red from '@material-ui/core/colors/red';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import ListView from '../../Components/ListView';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import StockTakeDetails from '../../Components/StockTakeDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import BusinessIcon from '@material-ui/icons/Business';
import VirtualListCB from '../../Components/VirtualListCB';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import {KeyboardArrowRightRounded} from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import {useCookies} from 'react-cookie';

const ClearButton = withStyles(theme => ({
	root: {
		borderColor: red[500]
	}
}))(Button);
const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	spacingTop: {
		marginTop: theme.spacing(2),
		flex: 1,
		zIndex: 10
	},
	secRow: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(1)
	},
	viewPadding: {
		padding: theme.spacing(2)
	}
}));

const headCells = [
	{
		name: "st_id",
		label: "Doc ID",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Customer Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Customer",
		options: {
			display: true,
		}
	},
	{
		name: "name",
		label: "Agent",
		options: {
			display: true
		}
	},
	{
		name: "created_date",
		label: "Doc Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name: "doc_ref_id",
		label: "Doc Ref",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return (
					_.truncate(value)
				)
			}
		}
	},
	{
		name: "active_status",
		label: "Active Status",
		options: {
			display: false,
			customBodyRender: (value, tableMeta, updateValue) => {
				switch (value) {
					case "0" :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
					case "1" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Agent"/>);
						break;
					case "2" :
						return (<Chip size="small" icon={<CloseIcon/>} label="Admin"/>);
						break;
					default :
						return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
						break;
				}
			}
		}
	}
]

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function StockTake() {
	const classes = useStyles();
	const paperStyle = usePaperStyles();
	const commonStyle = useCommonStyles();
	const [cookies, setCookie] = useCookies([ck_st_columns]);

	const [gState, setGState] = useContext(AppContext);
	const singleton = PersistantData.getInstance();
	const [preLanguage, setLanguage] = useState({});
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const [preClient, setClient] = useState(empty);

	const [showDocDetails, toggleDocDetails] = useState(false);
	// const [docDetails, setDocDetails] = useState({
	// 	itemList: [],
	// 	docDtl: []
	// });
	const [docID, setDocID] = useState(null);

	const [docStatusMenu, setDocStatusMenu] = useState(null);
	const [docStatus, setDocStatus] = useState(empty);
	const [dateMenu, setDateMenu] = useState(null);
	const [docDate, setDocDate] = useState(empty);
	const [spView, setSpView] = useState(false);
	const [spValue, setSpValue] = useState(empty);
	const [spList, setSpList] = useState([]);
	const [csView, setCsView] = useState(false);
	const [csList, setCsList] = useState([]);
	const [csValue, setCsValue] = useState(empty);
	const [csSelected, setCsSelected] = useState();
	const [axiosConfig, setAxiosConfig] = useState({});
	const [openDateDoc, setOpenDateDoc] = useState(false);
	const [docDateRange, setDocDateRange] = useState({});

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [apiCounter, setApiCounter] = useState(0);

	const [backDrop, setBackDrop] = React.useState(false);

	const [activeDocs, setActiveDocs] = useState([]);
	const [selectedDocs, setSelectedDocs] = useState({});

	const dateMenuOptions = [
		{name: language.doc_date, value: reset},
		{name: language.today, value: format(new Date(), defDateFormat)},
		{name: language.yesterday, value: format(subDays(new Date(), 1), defDateFormat)},
		{name: language.last_week, value: format(subDays(new Date(), 7), defDateFormat)},
		{name: language.last_month, value: format(subMonths(new Date(), 1), defDateFormat)},
		{name: language.choose_date, value: reset}
	];
	const docStatusOptions = [
		{name: language.active_doc, value: '1'},
		{name: language.inactive_doc, value: '0'}
	];
	const [selectedDocDate, handleDocDateChange] = useState(new Date());
	const [socketCon, setSocketCon] = useState(undefined);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			setLanguage(language => (gState.translation || singleton.getlang()));
			setClient(client => (gState.client));
			var axiosRequestConfig = axiosDefaultConfig(gState.client);
			API_Service.get(`${req_salesman}`, axiosRequestConfig).then(({data}) => {
				if (data) {
					var {result, extraData: api_Sp} = data;
					result = JString(result).toNumber();
					if (result === 1) {
						var _data = [];
						for (let idx = 0; idx < api_Sp.length; idx++) {
							const {name, login_id} = api_Sp[idx];
							_data.push({name: name, value: login_id});
						}
						_data.push({name: gState.translation.salesman, value: reset});
						setSpList(spList => (_data));
					}
				}
			});
			setAxiosConfig(axiosConfig => (axiosRequestConfig));
			triggerAllDoc(axiosRequestConfig);
			loadCustomerList(axiosRequestConfig);
		}
	}, [gState.client, gState.translation]);

	const clearValues = () => {
		setDocStatusMenu(docStatusMenu => (null));
		setDocStatus(docStatus => (empty));
		setDateMenu(dateMenu => (null));
		setDocDate(docDate => (empty));
		setSpView(spView => (false));
		setSpValue(spValue => (empty));
		setActiveDocs(activeDocs => ([]));
		setDocDateRange({});
	}

	const getDocDetails = ({id: doc_id, obj: _docDtl}) => {
		if (doc_id) {
			toggleDocDetails(showDocDetails => (true));
			setDocID(doc_id);
			// setBackDrop(backDrop => (true));
			// API_Service.get(`${req_stock_take_dtl}${doc_id}`, axiosConfig).then(({data}) => {
			// 	setBackDrop(backDrop => (false));
			// 	if (data) {
			// 		var {result, extraData} = data;
			// 		result = JString(result).toNumber();
			// 		if (result === 1) {
			// 			setDocDetails(docDetails => ({
			// 				itemList: extraData,
			// 				docDtl: _docDtl
			// 			}));
			// 			toggleDocDetails(showDocDetails => (true));
			// 		} else {
			// 			snack(language.no_doc_dtl, 'error');
			// 		}
			// 	}
			// });
		}
	}

	const triggerAllDoc = (axiosRequestConfig) => {
		setSelectedDocs('');
		loadActiveDocuments(axiosRequestConfig);
	}

	const linkBuilder = (link) => {
		var st_date, st_date_to, salesman, cust_code, st_cancel_status;

		if (JString(spValue).isValid()) {
			salesman = spValue;
		} else {
			salesman = 0;
		}
		if (JString(docStatus?.value).isValid()) {
			st_cancel_status = docStatus?.value;
		} else {
			st_cancel_status = 1;
		}

		if (!_.isEmpty(docDateRange)) {
			st_date = format(docDateRange.startDate, defDateFormat);
			st_date_to = format(docDateRange.endDate, defDateFormat);
			var date = moment(st_date, 'DD-MM-YYYY');
			var today = moment(st_date_to, 'DD-MM-YYYY');
			var dayDiff = today.diff(date, 'days');
			if (dayDiff < 0) {
				st_date = format(docDateRange.endDate, defDateFormat);
				st_date_to = format(docDateRange.startDate, defDateFormat);
			}
		} else {
			st_date = format(new Date(), defDateFormat);
			st_date_to = format(new Date(), defDateFormat);
		}
		if (parseInt(st_cancel_status) === 2) {
			st_date = format(subMonths(new Date(), 12), defDateFormat);
			st_date_to = format(new Date(), defDateFormat);

			st_cancel_status = 1;
		}
		cust_code = '';
		for (let i = 0; i < csValue.length; i++) {
			if (i == csValue.length - 1) {
				cust_code += csValue[i]['value'];
			} else {
				cust_code += csValue[i]['value'] + ",";
			}
		}
		var tmp = link;

		tmp += `st_date=${st_date}&st_date_to=${st_date_to}&salesman=${salesman}&cust_code=${cust_code}&st_status=${st_cancel_status}`;

		return tmp;
	}

	const manipulateData = (data) => {
		var _final = [];
		if (data) {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 1) {
				for (let i = 0; i < extraData.length; i++) {
					_final.push(
						extraData[i]
					);
				}
			}
		}
		return _final;
	}

	const loadActiveDocuments = (axiosRequestConfig) => {
		setApiCounter(apiCounter => (apiCounter + 1));
		var link = `${req_stock_take}?`;
		link = linkBuilder(link);
		// console.log('link ===> ' + link);
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var tmp = manipulateData(data);
				if (tmp.length > 0) {
					snack(gState.translation.active_doc_loaded);
				} else {
					snack(gState.translation.no_active_document, 'warning');
				}
				setActiveDocs(activeDocs => (tmp));
				setApiCounter(apiCounter => (apiCounter - 1));
			}
		});
	}

	const loadCustomerList = (axiosRequestConfig, salesman = 0) => {
		var req = salesman ? `${req_custby_salesman}${salesman}` : `${req_cust}`;
		API_Service.get(req, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {result, extraData: apiCust} = data;
				var {customer, inactive_customer} = apiCust;
				var __data = apiCust;
				if (customer) {
					__data = customer
				}
				result = JString(result).toNumber();
				if (result === 1) {
					var _data = [];
					for (let idx = 0; idx < __data.length; idx++) {
						const {cust_company_name, cust_code} = __data[idx];
						_data.push({name: cust_company_name, secondary: cust_code, value: cust_code, status: 1});
					}
					for (let i = 0; i < inactive_customer.length; i++) {
						const {cust_code, cust_company_name} = inactive_customer[i];
						_data.push({
							name: cust_company_name,
							secondary: cust_code,
							value: cust_code,
							status: 0
						});
					}
					_data.push({name: gState.translation.customer, value: reset});
					setCsList(csList => (_data));
				}
			}
		});
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const redefineHeaders = (columns) => {
		const lastSelection = cookies[ck_st_columns];
		if (_.isEmpty(lastSelection)) {
			return columns;
		}
		columns = columns.map((item) => {
			const key = item.name;
			var objCopy = _.merge({}, item);
			objCopy.options.sortDirection = lastSelection[`${key}`];
			return objCopy;
		});
		return columns;
	}

	const docTableColumnsSortChanged = (changedColumns, action) => {
		var obj = {};
		if (action === 'descending') {
			action = 'desc';
		} else if (action === 'ascending') {
			action = 'asc';
		}
		var a = headCells.map((item) => {
			if (`${item.name}` === changedColumns) {
				obj[`${item.name}`] = action;
				return {[`${item.name}`]: action};
			}
		});
		setCookie(ck_st_columns, JSON.stringify(obj), {path: '/', sameSite: true, maxAge: 36000 * 3600});
	}

	const dateMenuClick = (event) => {
		setDateMenu(dateMenu => (event.currentTarget));
	};

	const closeDateMenu = (props) => {
		const {value} = props;
		setDateMenu(dateMenu => (null));
		setDocDate(docDate => (props));
	};

	const docStatusMenuClick = (event) => {
		setDocStatusMenu(docStatus => (event.currentTarget));
	};

	const closeDocStatusMenu = (props) => {
		setDocStatus(docStatus => (props));
		setDocStatusMenu(docStatus => (null));
	};

	const spViewClick = () => {
		setSpView(true);
	};

	const spViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			setSpValue(newValue);
			loadCustomerList(axiosConfig, newValue == reset ? 0 : newValue);
		}
	};

	const getSpName = props => {
		for (let i = 0; i < spList.length; i++) {
			const {name, value} = spList[i];
			if (value == props) {
				return name;
			}
		}
		return empty;
	}

	const csViewClick = () => {
		setCsView(true);
	};

	const csViewClose = (newValue) => {
		setCsView(false);
		if (newValue) {
			setCsValue(newValue);
			var csvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == csValue.length - 1) {
					csvalue += newValue[i].name;
				} else {
					csvalue += newValue[i].name + ",";
				}
			}
			setCsSelected(csvalue);
		}
	};

	const getSelectedDocIds = (data, type) => {
		var original_data = activeDocs;
		var ids = "";
		for (let index = 0, size = data.length; index < size; index++) {
			const {dataIndex} = data[index];
			const {st_id} = original_data[dataIndex];
			ids += JString(st_id).toString();
			if (index != (size - 1) && size != 1) {
				ids += ',';
			}
		}
		return ids;
	}

	const toggleDocPrint = (data, type = 'active') => {
		const doc_ids = getSelectedDocIds(data, type);
		setSelectedDocs(doc_ids);
		// var link = "http://easysales.asia/";
		// link += `${client}`;
		// link += "/easysales/cms/generateStockTakeReport.php?st_id=";
		// link += doc_ids;
		// console.log(doc_ids);
		// console.log("printlink:" + link);
		// window.open(link, "");
	}

	const loadGenerateReportPage = () => {
		var link = "http://easysales.asia/";
		link += `${client}`;
		//link += `maxion`;
		link += "/easysales/cms/ST_Report.php?";
		link = reportParams(link);
		window.open(link, "")
	}

	const reportParams = (link) => {
		var tmp = link;
		var st_date = format(docDateRange.startDate, defDateFormat);
		var st_date_to = format(docDateRange.endDate, defDateFormat);
		const docs = [];
		for (let i = 0; i < activeDocs.length; i++) {
			docs[i] = activeDocs[i]['st_id'];
		}
		tmp += `date_from=${st_date}&date_to=${st_date_to}`;
		tmp += `&st_id=${docs}`;
		// for(let index = 0, size = activeDocs.length; index < size; index++){
		//     tmp += activeDocs[index]['st_id'];
		//     if(index != (size - 1) && size != 1){
		//         tmp += ',';
		//     }
		// }
		return tmp;
	}

	const tableHead = redefineHeaders(headCells);

	var activeDocOptions = {
		print: false,
		download: false,
		filter: true,
		rowsPerPage: 45,
		rowsPerPageOptions: [45, 65, 85],
		responsive: 'scrollMaxHeight',
		customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
			const data = selectedRows?.data;
			const lookup = selectedRows?.lookup;
			const showDelete = docStatus?.value == empty || docStatus?.value == 0 || docStatus?.value == undefined;
			// toggleDocPrint(data,'active');
			return (
				<div style={{marginRight: 25}}>
					{
						// <ToolbarCustomSelect
						//     title={'Print'}
						//     onClick={()=>{toggleDocPrint(data,'active')}}>
						//     <PrintIcon />
						// </ToolbarCustomSelect>
					}
				</div>
			)
		},
		onRowClick: (rowData, rowMeta) => {
			getDocDetails({
				id: rowData[0],
				obj: {
					st_id: rowData[0],
					cust_code: rowData[1],
					cust_name: rowData[2],
					salesman: rowData[3],
					created_date: rowData[4],
					doc_ref_id: rowData[5],
					active_status: rowData[6]
				}
			})
		},
		onColumnSortChange: (changedColumns, direction) => {
			docTableColumnsSortChanged(changedColumns, direction);
		}
	};
	const datefromto = (range) => {
		const {startDate, endDate} = range;
		return moment(startDate).format('DD MMMM YY') + ' / ' + moment(endDate).format('DD MMMM YY');
	}
	return (
		<div>
			<div className={paperStyle.paperView}>
				<Paper className={paperStyle.viewInRowPad}>
					<div className={paperStyle.viewInRowSpace}>
						<div className={paperStyle.viewInRow}>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick=/*{dateMenuClick}*/{() => setOpenDateDoc(true)}
								size="small"
								endIcon={<KeyboardArrowRightRounded/>}
								startIcon={<EventNoteIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{!_.isEmpty(docDateRange) ? datefromto(docDateRange) : dateMenuOptions[0]?.name}
								</Typography>
							</Button>
							<Menu
								id="simple-menu"
								anchorEl={dateMenu}
								keepMounted
								open={Boolean(dateMenu)}
								onClose={() => closeDateMenu(docDate)}>
								{
									dateMenuOptions.map((item, idx) =>
										<MenuItem key={String(idx)} onClick={() => closeDateMenu(item)}>
											{item.name}
										</MenuItem>
									)
								}
							</Menu>
							{
								docDate?.name === dateMenuOptions[dateMenuOptions.length - 1]?.name
								&& selectedDocDate && (
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<DatePicker
											inputProps={{
												style: {fontSize: 12}
											}}
											margin="dense"
											inputVariant={'standard'}
											value={selectedDocDate}
											onChange={handleDocDateChange}/>
									</MuiPickersUtilsProvider>
								)
							}
							<div style={{width: 20}}/>
							<Button
								disabled={csList.length === 0}
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={() => csViewClick()}
								size="small"
								endIcon={csList.length === 0 ? <CircularProgress size={20}/> :
									<KeyboardArrowDownRoundedIcon/>}
								startIcon={<BusinessIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{csSelected ? _.truncate(csSelected, {length: 30}) : language.customer}
								</Typography>
							</Button>
							<div style={{width: 20}}/>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={() => spViewClick()}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<PersonIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{getSpName(spValue) || language.salesman}
								</Typography>
							</Button>
							<div style={{width: 20}}/>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={docStatusMenuClick}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<AssignmentLateIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{docStatus ? docStatus.name : docStatusOptions[0]?.name}
								</Typography>
							</Button>
							<Menu
								id="simple-menu"
								anchorEl={docStatusMenu}
								keepMounted
								open={Boolean(docStatusMenu)}
								onClose={() => closeDocStatusMenu(docStatus)}>
								{
									docStatusOptions.map((item, idx) =>
										<MenuItem key={String(idx)} onClick={() => closeDocStatusMenu(item)}>
											{item.name}
										</MenuItem>
									)
								}
							</Menu>
						</div>
					</div>
					<div className={paperStyle.viewInRowSpace}>
						<div className={classes.secRow}>
						</div>
						<div className={classes.secRow}>
							<ClearButton
								color="inherit"
								onClick={() => clearValues()}
								size="small"
								startIcon={<ClearAllIcon style={{color: red[400]}}/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}
											style={{color: red[400]}}>
									{language.clear}
								</Typography>
							</ClearButton>
							<div style={{width: 10}}/>
							<Button
								color="inherit"
								onClick={() => loadGenerateReportPage()}
								size="small"
								startIcon={<DescriptionIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{"Generate"}
								</Typography>
							</Button>
							<div style={{width: 10}}/>
							<Button
								color="inherit"
								onClick={() => triggerAllDoc(axiosConfig)}
								size="small"
								startIcon={<FindInPageIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{language.search_cap}
								</Typography>
							</Button>
						</div>
					</div>
					{
						apiCounter !== 0 && (
							<div className={classes.viewPadding}>
								<LinearProgress variant="buffer" value={(3 - apiCounter) * 33.3} valueBuffer={apiCounter}/>
							</div>
						)
					}
				</Paper>
			</div>
			{
				<div className={classes.spacingTop}>
					<MUIDataTable
						title={language.stock_take_list}
						data={activeDocs}
						columns={tableHead}
						options={activeDocOptions}
					/>
				</div>
			}
			{
				openDateDoc && (
					<DateRangePickerModal
						setPositive={range => {
							setDocDateRange(range);
							setOpenDateDoc(false)
						}}
						setNegative={() => {
							setOpenDateDoc(false)
						}}
						setClose={() => {
							setOpenDateDoc(false)
						}}
						open={openDateDoc}
						message={language.date_range}
						positive={language.ok}
						negative={language.cancel}
					/>
				)
			}
			<StockTakeDetails
				open={showDocDetails}
				setClose={() => toggleDocDetails(showDocDetails => (false))}
				language={language}
				axiosConfig={axiosConfig}
				stockTakeID={docID}
			/>
			<ListView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={spView}
				onClose={() => setSpView(false)}
				onSelect={spViewClose}
				value={spValue}
				title={language.salesman || ''}
				language={language}
				options={spList}
			/>
			<VirtualListCB
				open={csView}
				setClose={data => {
					csViewClose(data);
				}}
				title={language.cust_list}
				data={csList}
				language={language}
				filteration={0}
			/>
			<Backdrop className={classes.backdrop} open={backDrop} onClick={() => {
			}}>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</div>
	)
}

export function ToolbarCustomSelect({onClick, title, children}) {
	return (
		<React.Fragment>
			<Tooltip title={title}>
				<IconButton onClick={() => onClick()}>
					{children}
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
