import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    req_cust,
    req_cust_area,
    req_module_setting,
    req_report_schedulevisit,
    req_salesman,
    scheduled_visit_report_url
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import VirtualList from '../../Components/VirtualList';
import format from 'date-fns/format';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import {getSetting} from '../../Helper/Helper';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "schedule_date",
		label: "Schedule Date",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Outlet Area",
		options: {
			display: true
		}
	},
	{
		name: "status",
		label: "Status",
		options: {
			display: true
		}
	}
]

export default function RScheduleVisit() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const scheduleStatusList = [
		{
			"name": language.all,
			"value": "-1"
		},
		{
			"name": language.pending,
			"value": "1"
		},
		{
			"name": language.attended,
			"value": "2"
		},
		{
			"name": language.missed,
			"value": "0"
		}
	]

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const [splist, setSPlist] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [scheduleVisitList, setScheduleVisitList] = useState([]);

	const [scheduleVisitDateRange, setScheduleVisitDateRange] = useState({});
	const [openScheduleVisitDateDoc, setOpenScheduleVisitDateDoc] = useState(false);
	const [scheduleSpView, setScheduleSpView] = useState(false);
	const [scheduleOutletView, setScheduleOutletView] = useState(false);
	const [scheduleStatusMenu, setScheduleStatusMenu] = useState(false);
	const [scheduleStatus, setScheduleStatus] = useState(scheduleStatusList[0]);
	const [scheduleOutlet, setScheduleOutlet] = useState(empty);
	const [scheduleOutletSelect, setScheduleOutletSelected] = useState(empty);
	const [scheduleSpSelect, setScheduleSpSelected] = useState(empty);
	const [scheduleSalesperson, setScheduleSalesperson] = useState(empty);
	const [tableHeader, setTableHeader] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadConfig(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: area_status});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getScheduleVisit = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(scheduleVisitDateRange)) {
			st_date = format(scheduleVisitDateRange.startDate, defDateFormat);
			st_date_to = format(scheduleVisitDateRange.endDate, defDateFormat);
		}
		const param = axiosURIencode({
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: scheduleSpSelect,
			cust_code: scheduleOutletSelect,
			status: scheduleStatus.value,
			area: selectedArea,
			username: cookies(ck_u_username)
		});
		API_Service.get(`${req_report_schedulevisit}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					let date = moment(_arr['schedule_date'], 'DD/MM/YYYY').format('YYYY-MM-DD');
					let today = moment(new Date()).format('YYYY-MM-DD');
					var status = "PENDING"
					if (_arr['appmnt_status'] === '1') {
						status = "ATTENDED";
					} else if (_arr['appmnt_status'] === '3') {
						status = "MISSED W/REASON";
				 	} else if (_arr['appmnt_status'] === '0' && moment(date).isBefore(today)) {
						status = "MISSED";
					}
					_arr['status'] = status;
					_data.push(_arr);
				}
				setScheduleVisitList(scheduleVisitList => (_data));
			} else {
				setScheduleVisitList(scheduleVisitList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = nClient) => {
		API_Service.get(req_salesman, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		API_Service.get(req_cust, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const ScheduleVisitOutletViewClick = () => {
		setScheduleOutletView(true);
	}

	const ScheduleVisitOutletViewClose = (newValue) => {
		setScheduleOutletView(false);
		if (newValue) {
			var outletvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletvalue += newValue[i].value;
				} else {
					outletvalue += newValue[i].value + ",";
				}
			}
			setScheduleOutletSelected(outletvalue);

			var outletname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletname += newValue[i].name;
				} else {
					outletname += newValue[i].name + ",";
				}
			}
			setScheduleOutlet(outletname);
		}
	}

	const ScheduleVisitSPViewClick = () => {
		setScheduleSpView(true);
	}

	const ScheduleVisitSPViewClose = (newValue) => {
		setScheduleSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setScheduleSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setScheduleSalesperson(spname);
		}
	}

	const ScheduleStatusMenuClick = (event) => {
		setScheduleStatusMenu(true);
	}

	const ClosescheduleStatusMenu = (props) => {
		setScheduleStatus(props);
		setScheduleStatusMenu(false);
	}

	const getScheduleVisitDate = () => {
		return !_.isEmpty(scheduleVisitDateRange) ? moment(scheduleVisitDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(scheduleVisitDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const generateScheduleVisitReport = () => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(scheduleVisitDateRange)) {
			st_date = format(scheduleVisitDateRange.startDate, defDateFormat);
			st_date_to = format(scheduleVisitDateRange.endDate, defDateFormat);
		}
		const date_range = `${st_date} to ${st_date_to}`
		var link = JString(scheduled_visit_report_url)
			.replaceInfinity('@client', client)
			.replaceInfinity('@reportDate', encodeURIComponent(date_range))
			.replaceInfinity('@salespersonId', scheduleSpSelect)
			.replaceInfinity('@cust_code', scheduleOutletSelect)
			.replaceInfinity('@username', cookies(ck_u_username))
			.replaceInfinity('@status', scheduleStatus.value)
			.str;
		window.open(link, "_blank");
	}

	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_schedule_visit_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => ScheduleVisitSPViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{scheduleSalesperson ? _.truncate(scheduleSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => ScheduleVisitOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{scheduleOutlet ? _.truncate(scheduleOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={ScheduleStatusMenuClick}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography component="p" className={commonStyle.searchBtnFont}>
						{scheduleStatus ? scheduleStatus.name : language.schedule_status}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenScheduleVisitDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getScheduleVisitDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getScheduleVisit()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				scheduleVisitList.length > 0 && (
					<MUIDataTable
						title={language.schedule_visit}
						data={scheduleVisitList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={generateScheduleVisitReport}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={scheduleSpView}
				setClose={data => ScheduleVisitSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={scheduleOutletView}
				setClose={data => ScheduleVisitOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={0}
			/>

			<VirtualList
				open={scheduleStatusMenu}
				setClose={data => ClosescheduleStatusMenu(data)}
				title={"Status"}
				data={scheduleStatusList}
				language={language}
				hideValue={true}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setScheduleVisitDateRange(range);
					setOpenScheduleVisitDateDoc(false)
				}}
				setNegative={() => {
					setOpenScheduleVisitDateDoc(false)
				}}
				setClose={() => {
					setOpenScheduleVisitDateDoc(false)
				}}
				open={openScheduleVisitDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
