import {createContext} from 'react';
import {empty} from '../Helper/Constants';

const AppContext = createContext({
	translation: {},
	username: empty,
	password: empty,
	client: empty,
	tempState: {},
	emitter: {}
});

export default AppContext;
