import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Downshift from "downshift";
import {de} from "../../Helper/Helper";
import styled from 'styled-components';
import {Button, CircularProgress, IconButton} from "@material-ui/core";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import JString from "@easytech-international-sdn-bhd/jstring";

const useStyles = makeStyles(theme => ({
	chip: {
		margin: theme.spacing(0.5, 0.25)
	}
}));
const Listbox = styled('ul')`
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  & li {
    width:250px;
    padding:5px;
    padding-left:15px;
    padding-right:15px;
    display: flex;
    justify-content:space-between;
  }
  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;
  }
  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`;
export default function InputTags({
									  addVisitSchedRules,
									  selectedTags,
									  preset,
									  placeholder,
									  tags,
									  width = 600,
									  onAdd,
									  onRemove,
									  showLoading = false,
									  ...other
								  }) {
	const classes = useStyles();
	const [inputValue, setInputValue] = useState("");
	const [selectedItem, setSelectedItem] = useState([]);

	useEffect(() => {
		de(preset, 'preset');
		setSelectedItem(preset);
	}, [preset]);
	useEffect(() => {
		selectedTags(selectedItem);
	}, [selectedItem, selectedTags]);

	function handleKeyDown(event) {
		if (event.key === "Enter") {
			const newSelectedItem = [...selectedItem];
			const duplicatedValues = newSelectedItem.indexOf(
				event.target.value.trim()
			);

			if (duplicatedValues !== -1) {
				setInputValue("");
				return;
			}
			if (!event.target.value.replace(/\s/g, "").length) return;

			newSelectedItem.push(event.target.value.trim());

			onAdd(newSelectedItem);

			setSelectedItem(newSelectedItem);
			setInputValue("");
		}
		if (
			selectedItem.length &&
			!inputValue.length &&
			event.key === "Backspace"
		) {
			setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
		}
	}

	function handleChange(item) {
		let newSelectedItem = [...selectedItem];
		if (newSelectedItem.indexOf(item) === -1) {
			newSelectedItem = [...newSelectedItem, item];
		}
		setInputValue("");
		onAdd(newSelectedItem);
		setSelectedItem(newSelectedItem);
	}

	const handleDelete = item => () => {
		const newSelectedItem = [...selectedItem];
		newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
		setSelectedItem(newSelectedItem);
	};

	function handleInputChange(event) {
		setInputValue(String(event.target.value).toUpperCase());
	}

	return (
		<React.Fragment>
			<Downshift
				id={"downshift-multiple" + new Date().getMilliseconds()}
				inputValue={inputValue}
				onChange={handleChange}
				selectedItem={selectedItem}>
				{({getInputProps, isOpen, inputValue, getMenuProps, getItemProps, highlightedIndex}) => {
					const {onBlur, onChange, onFocus, ...inputProps} = getInputProps({
						onKeyDown: handleKeyDown,
						placeholder
					});
					return (
						<div style={{width}}>
							<TextField
								disabled={showLoading}
								margin={'dense'}
								fullWidth
								InputProps={{
									endAdornment: showLoading ?
										<CircularProgress size={15} color={'primary'}/> : undefined,
									startAdornment: selectedItem.map(item => (
										<Chip
											size={'small'}
											key={item}
											tabIndex={-1}
											label={item}
											className={classes.chip}
											onDelete={handleDelete(item)}
										/>
									)),
									onBlur,
									onChange: event => {
										handleInputChange(event);
										onChange(event);
									},
									onFocus
								}}
								{...other}
								{...inputProps}
							/>
							<Listbox {...getMenuProps()}>
								{isOpen && inputValue &&
									tags
										.filter(
											item => !inputValue || JString(item).searchDeep(inputValue),
										)
										.map((item, index) => (
											<li key={index + '--li'}>
												<Button
													{...getItemProps({
														key: `${item}${index}`,
														item,
														index,
														style: {
															flexGrow: 1,
															backgroundColor:
																highlightedIndex === index
																	? 'lightgray'
																	: 'white',
															fontWeight:
																selectedItem === item ? 'bold' : 'normal'
														},
														onMouseEnter: (e) => {
														}
													})}>
													{item}
												</Button>
												{
													addVisitSchedRules?.allow_tag_delete == 1 ?
														<IconButton edge="end" color="inherit" onClick={() => {
															onRemove(item)
														}} aria-label="close">
															<RemoveCircleIcon color={'error'} fontSize={'small'}/>
														</IconButton> :
														null
												}
											</li>
										))}
							</Listbox>
						</div>
					);
				}}
			</Downshift>
		</React.Fragment>
	);
}
InputTags.defaultProps = {
	tags: []
};
InputTags.propTypes = {
	selectedTags: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf(PropTypes.string)
};
