import { Button, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import { useCommonStyles } from '../../Styles';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import { defDateFormat, req_module_setting, req_report_skustandard, req_report_skustandardreport, req_delete_file } from '../../Helper/Constants';
import _ from 'lodash';
import moment from 'moment';
import format from 'date-fns/format';
import API_Service, { axiosDefaultConfig, axiosURIencode } from '../../Services/API_Service';
import JString from '@easytech-international-sdn-bhd/jstring';
import { de, getSetting } from '../../Helper/Helper';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import {Assessment as AssessmentIcon} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}
}));

const tableHead = [
	{
		name: "checkin_date",
		label: "Date",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				return moment(value).format('DD/MM/YYYY HH:mmA');
			}
		}
	},
	{
		name: "name",
		label: "Agent",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Area",
		options: {
			display: true
		}
	},
	{
		name: "cust_category_name",
		label: "Category",
		options: {
			display: true
		}
	},
	{
		name: "cust_chain_name",
		label: "Chain",
		options: {
			display: true
		}
	},
	{
		name: "bls",
		label: "BLS",
		options: {
			display: true,
			customBodyRender: (value, tableMeta, updateValue) => {
				if (parseInt(value) === 1) {
					return 'Achieved';
				} else {
					return 'Not Achieved';	
				}
			}
		}
	}
];

export default function RSKUStandard() {
    const classes = useStyles();
    const commonStyle = useCommonStyles();
    const [gState, setGState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			// getSalesmanList(gState.client);
			// getOutletList(gState.client);
			// loadStocksList(gState.client);
			loadConfig(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

    const [openDateDoc, setDateDoc] = useState(false);
    const [dateRange, setDateRange] = useState({});
    const getReportDate = () => {
		return !_.isEmpty(dateRange) ? moment(dateRange.startDate).format('DD MMMM YYYY') + " / " + moment(dateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}
	const [tableHeader, setTableHeader] = useState([]);
	const [reportList, setReportList] = useState([]);
	const [reportTitle, setReportTitle] = useState('');

	const loadConfig = (axiosRequestConfig) => {
		API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _modules = getSetting(extraData, 'modules', {});
				setReportTitle(_modules.skustandard.title);
			}
		});
	}

    const getReportData = (_client = client) => {
        var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			// salespersonId: reportSpSelect,
			// type: 'simple',
			// username: cookies(ck_u_username),
			// cust_code: reportOutletSelect,
			// area: selectedArea
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const params = axiosURIencode(parameters);
		API_Service.get(`${req_report_skustandard}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, message, extraData} = data;
			if (result) {
				const {sheet_a, sheet_b, sheet_c} = extraData;
				// for (let i = 0; i < header.length; i++) {
				// 	var head = header[i];
				// 	tableHead.splice(5, 0, {
				// 		name: head['name'],
				// 		label: head['label'],
				// 		options: {
				// 			display: true
				// 		}
				// 	});
				// }
				// setTableHeader(tableHead);
				setReportList(reportList => (sheet_a));
				snack(message, 'success');
			} else {
				setReportList(reportList => ([]));
				snack(message, 'warning');
			}
		});
    }

	const openPrintSelectView = () => {
		// setPrintView(true);
		generateReport(1);
	}

	const generateReport = (type, _client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			// salespersonId: reportSpSelect,
			// type: 'simple',
			// username: cookies(ck_u_username),
			// cust_code: reportOutletSelect,
			// area: selectedArea
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_skustandardreport}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				window.open(extraData.url, '_blank');
				const delay = setTimeout(() => {
					API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
					clearTimeout(delay);
				}, 5000);
			} else {
				snack(language.no_data, 'warning');
			}
		}).catch(err => {
			snack('The data is too large, please simplify the requirements', 'warning');
		});
	}

    return (
        <div>
            <div className={classes.viewInRow}>
                <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getReportDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getReportData()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
            </div>
			<div style={{height: 20}}/>
			{
				reportList.length > 0 && (
					<MUIDataTable
						title={reportTitle}
						data={reportList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
										break;
									case 'remove':
										tableHeader[index].options.display = false;
										break;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintSelectView}/>
								</>
						}}
					/>
				)
			}

            <DateRangePickerModal
				setPositive={range => {
					setDateRange(range);
					setDateDoc(false)
				}}
				setNegative={() => {
					setDateDoc(false)
				}}
				setClose={() => {
					setDateDoc(false)
				}}
				open={openDateDoc}
				message={language.date_range}
				positive={language.ok}
			/>
        </div>
    )
}
function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}