import React, {useContext, useEffect, useState} from "react";
import {createTheme, makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import {Router, useLocation} from 'react-router-dom';
import Routes from '../../RoutesInApp';
import History from '../../Services/History';
import AppContext from '../../Services/AppContext';
import {CookiesProvider} from 'react-cookie';
import {SnackbarProvider} from 'notistack';
import grey from '@material-ui/core/colors/grey';
import ExploreIcon from '@material-ui/icons/Explore';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AssessmentIcon from '@material-ui/icons/Assessment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';
import CategoryIcon from '@material-ui/icons/Category';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import GTranslateRoundedIcon from '@material-ui/icons/GTranslateRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersistantData from "../../Services/PersistantData";
import {Offline} from 'react-detect-offline';
import _ from 'lodash';
import {
	ck_lang,
	ck_theme,
	ck_u_client,
	ck_u_device_no,
	ck_u_role_id,
	ck_u_username,
	cookieClear,
	empty,
	req_mobile_module_setting,
	req_module_setting,
	req_setting
} from "../../Helper/Constants";
import JString from '@easytech-international-sdn-bhd/jstring';
import CryptData from "../../Helper/CryptData";
import Alert from '../../Components/Alert';
import MuiAlert from '@material-ui/lab/Alert';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsCellIcon from '@material-ui/icons/SettingsCell';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import LinkIcon from '@material-ui/icons/Link';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {de, getSetting} from "../../Helper/Helper";
import useCookieHook from "../../Services/useCookieHook";
import LiveUpdate from "../../Components/LiveUpdate";
import API_Service, {axiosDefaultConfig} from "../../Services/API_Service";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Dashboard from "../Dashboard";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import EngineeringIcon from '@material-ui/icons/AccessAlarm';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import HelpIcon from '@material-ui/icons/Help';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const drawerWidth = 275;

const useStyles = makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(0, 1),
		paddingLeft: theme.spacing(2),
		// ...theme.mixins.toolbar,
	},
	content_toolbar: {
		...theme.mixins.toolbar
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	title: {
		flexGrow: 1,
	},
	whiteText: {
		color: 'white'
	},
	logo: {
		maxWidth: '100%',
	},
	logo_img: {
		maxWidth: '100%',
	},
	header_noLogo: {
		fontSize: 'x-large'
	},
	header: {
		fontSize: '1.45em',
		textAlign: 'center',
		marginTop: 0,
		marginBottom: 0
	}
}));

function MAlert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MainView() {
	const classes = useStyles();

	const singleton = PersistantData.getInstance();

	const currentRoute = useLocation();

	const [gState, setGState] = useContext(AppContext);
	const [availableModules, setAvailableModules] = useState([]);
	const [availableTutorials, setAvailableTutorials] = useState([]);
	const [modules, setModules] = useState([]);
	const [logoUrl, setlogoUrl] = useState(null);
	const [displayReport, setDisplayReport] = useState(false);
	const [competitorModule, setCompetitorModule] = useState(false);

	const [cookies, setCookie, removeCookie] = useCookieHook([ck_theme, ck_lang, ck_u_role_id, ck_u_device_no]);
	/**
	 * SET COOKIES
	 */
	const defLang = cookies(ck_lang);
	const defTheme = cookies(ck_theme) || "dark";
	var isAdmin = cookies(ck_u_role_id);

	singleton.setlang(defLang);
	const deviceNo = cookies(ck_u_device_no);
	const defUsername = cookies(ck_u_username);
	var u_client = cookies(ck_u_client);
	if (JString(defUsername).isValid()) {
		u_client = JString(u_client).isValid() ?
			new CryptData(u_client, defUsername)
				.decryptAES()
				.getValue()
			: empty;
		isAdmin = JString(isAdmin).isValid() ?
			new CryptData(isAdmin, defUsername)
				.decryptAES()
				.getValue()
			: 5;
	}
	singleton.setUsername(defUsername);

	var sessionId = document.cookie.match(/sessionId=[^;]+/) + '';
	if (sessionId === null) {
		sessionId = Math.floor(Math.random() * 1000);
	}
	var res = sessionId.split("=");
	if (res.length > 0) {
		sessionId = res[1];
	}

	const defaultRoutes = {
		[`/main/dashboard`]: singleton.getlang()?.dashboard,
		[`/main`]: singleton.getlang()?.dashboard,
		[`/main/transactions`]: singleton.getlang()?.transaction,
		[`/main/useraccounts`]: singleton.getlang()?.user_accounts,
		[`/main/reconciliation`]: singleton.getlang()?.reconciliation,
		[`/main/stocklist`]: singleton.getlang()?.stock,
		[`/main/bundle`]: singleton.getlang()?.bundle,
		[`/main/customer`]: singleton.getlang()?.customer,
		[`/main/appcontent`]: singleton.getlang()?.app_content,
		[`/main/eventitems`]: singleton.getlang()?.event_items,
		[`/main/checkin`]: singleton.getlang()?.checkin,
		[`/main/dob`]: singleton.getlang()?.dob,
		[`/main/stocktransfer`]: singleton.getlang()?.stock_transaction,
		[`/main/stocktake`]: singleton.getlang()?.stock_take,
		[`/main/target`]: singleton.getlang()?.target,
		[`/main/cashrolling`]: singleton.getlang()?.cashrolling,
		[`/main/report`]: singleton.getlang()?.report,
		[`/main/backendsync`]: singleton.getlang()?.backend_sync,
		[`/main/modulelinkage`]: singleton.getlang()?.module_linkage,
		[`/main/B2BOrder`]: singleton.getlang()?.b2border,
		[`/main/stocktemplate`]: singleton.getlang()?.stock_template,
		[`/main/managesched`]: singleton.getlang()?.manage_sched,
		[`/main/ragentattend`]: singleton.getlang()?.agent_attend,
		[`/main/roos`]: singleton.getlang()?.oos,
		[`/main/routletvisit`]: singleton.getlang()?.outlet_visit,
		// [`/main/routletvisitfrequency`]: singleton.getlang()?.outlet_visit_frequency,
		[`/main/rstocktake`]: singleton.getlang()?.stock_take,
		// [`/main/rschedulevisit`]: singleton.getlang()?.schedule_visit,
		[`/main/rstocknearexpiry`]: singleton.getlang()?.stock_near_expiry,
		// [`/main/rmileage`]: singleton.getlang()?.mileage,
		[`/main/rposm`]: singleton.getlang()?.posm,
		[`/main/routletvisitanalysis`]: singleton.getlang()?.outlet_visit_analysis,
		[`/main/rphototakenanalysis`]: singleton.getlang()?.photo_taken_analysis,
		[`/main/usersettings`]: singleton.getlang()?.user_settings,
		[`/main/usersettings`]: singleton.getlang()?.system_settings,
		[`/main/gallery`]: singleton.getlang()?.photo_gallery,
		[`/main/competitorgallery`]: singleton.getlang()?.competitor + " " + singleton.getlang()?.photo_gallery,
		[`/main/rincentive`]: singleton.getlang()?.incentive,
		[`/main/competitor`]: singleton.getlang()?.competitor_price_checking,
		[`/main/aainsight`]: singleton.getlang()?.agent_attend_insight,
		[`/main/ovinsight`]: singleton.getlang()?.outlet_visit_insight,
		[`/main/rsku`]: singleton.getlang()?.rsku,
		[`/main/rdisplay`]: singleton.getlang()?.rdisplay,
		[`/main/rcompetitorpricecheck`]: singleton.getlang()?.rCompetitorPriceCheck
	};

	const [state, setState] = useState({
		theme: {
			palette: {
				type: defTheme,
				primary: {
					main: '#FEF200'
				},
				secondary: {
					main: grey[500]
				}
			}
		},
		language: singleton.getlang(),
		currentTitle: defaultRoutes[currentRoute.pathname] || 'Dashboard',
		menuLang: null
	});

	const [open, setOpen] = useState(false);
	const [liveUpdates, showLiveUpdates] = useState(false);
	const [logoutAlert, setLogoutAlert] = useState(false);

	let {theme, language, currentTitle, menuLang} = state;
	de("language",language);

	useEffect(() => {
		//gState.emitter = new EventEmitter();
		setGState({...gState, translation: language, username: defUsername, client: u_client});
	}, [language]);

	useEffect(() => {
		if (u_client) {
			const axiosGetConfig = axiosDefaultConfig(u_client);
			loadConfig(axiosGetConfig);
			loadCmsSettings(axiosGetConfig);
		}
	}, [u_client]);

	const loadConfig = (axiosRequestConfig) => {
		API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'MainView', {});
				showLiveUpdates(_config.live_updates == 1);
				const _availableModules = getSetting(extraData, 'access_rights', {})[`${isAdmin}`] || [];
				setAvailableModules(_availableModules);
				const _tutorial = getSetting(extraData, 'tutorial', {});
				setAvailableTutorials(_tutorial);
				const _modules = getSetting(extraData, 'modules', {});
				if(_modules.competitor){
					setCompetitorModule(true);
				}
				setModules(_modules);
			}
		});
		API_Service.get(req_mobile_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const {module, pdf_images} = extraData;
				const _customization = module.find((content) => content.module === 'app_calculation_percentage');
				if (_customization) {
					const {status} = _customization;
					if (status) {
						setDisplayReport(true);
					}
				}
			}
		});
	}

	const loadCmsSettings = (axiosRequestConfig) => {
		API_Service.get(req_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setlogoUrl(extraData[0].logo_Url);
			}
		});
	}

	const muiTheme = createTheme(theme);

	const drawerOpen = () => {
		setOpen(true);
	};

	const drawerClose = () => {
		setOpen(false);
	};

	const langMenuClick = (event) => {
		setState({
			...state,
			menuLang: event.currentTarget
		});
	};

	const closeLangMenu = (props) => {
		singleton.setlang(props);
		setState({
			...state,
			menuLang: null,
			language: singleton.getlang(),
			currentTitle: singleton.getlang()?.dashboard || 'Dashboard'
		});
		setGState({...gState, translation: singleton.getlang()});
		setCookie(ck_lang, props, {path: '/'});
	};

	// const toggleDarkTheme = () => {
	// 	let newPaletteType = theme.palette.type === "light" ? "dark" : "light";
	// 	setState({
	// 		...state,
	// 		theme:{
	// 			palette: {
	// 				type: newPaletteType,
	// 				primary:{
	// 					main: '#FEF200'
	// 				},
	// 				secondary:{
	// 					main: grey[500]
	// 				}
	// 			}
	// 		}
	// 	});
	// 	setCookie(ck_theme, newPaletteType, { path: '/' });
	// };
	const isDark = theme.palette.type === "dark";

	const redirection = (path, name) => {
		History.push(`/main/${String(path).toLowerCase()}`);
		setState({
			...state,
			currentTitle: name
		});
	}

	const dashboardScene = {
		name: language.dashboard,
		enabled: true,
		component: <DashboardRoundedIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('dashboard', language.dashboard);
		}
	};
	const transactionScene = {
		name: language.transaction,
		enabled: true,
		component: <InsertDriveFileRoundedIcon/>,
		action: () => {
			redirection('transactions', language.transaction);
		}
	};
	const userAccountsScene = {
		name: language.user_accounts,
		enabled: true,
		component: <AccountBoxIcon/>,
		action: () => {
			redirection('useraccounts', language.user_accounts);
		}
	};
	const reconciliationScene = {
		name: language.reconciliation,
		enabled: true,
		component: <AssignmentTurnedInIcon/>,
		action: () => {
			redirection('reconciliation', language.reconciliation);
		}
	};
	const stockList = {
		name: language.stock,
		enabled: true,
		component: <CreateNewFolderIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('stocklist', language.stock);
		}
	};

	const bundle = {
		name: language.bundle,
		enabled: true,
		component: <CategoryIcon/>,
		action: () => {
			redirection('bundle', language.bundle);
		}
	};

	const customer = {
		name: language.customer_low,
		enabled: true,
		component: <BusinessIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('customer', language.customer_low);
		}
	};
	const app_content = {
		name: language.app_content,
		enabled: true,
		component: <SettingsCellIcon/>,
		action: () => {
			redirection('appcontent', language.app_content);
		}
	};
	// const eventItemsScene = {
	// 	name: language.event_items,
	// 	enabled: true,
	// 	component: <CategoryIcon />,
	// 	action:()=>{
	// 		redirection('eventitems',language.dashboard);
	// 	}
	// };

	const checkinScene = {
		name: language.checkin,
		enabled: true,
		component: <ExploreIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('checkin', language.checkin)
		}
	}

	const AAInsightScene = {
		name: language.agent_attend_insight,
		enabled: true,
		component: <SupervisorAccountIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('aainsight', language.agent_attend_insight)
		}
	}

	const OVInsightScene = {
		name: language.outlet_visit_insight,
		enabled: true,
		component: <StorefrontIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('ovinsight', language.outlet_visit_insight)
		}
	}

	const dobScene = {
		name: language.dob,
		enabled: true,
		component: <LocalShippingIcon/>,
		action: () => {
			redirection('dob', language.dob)
		}
	}

	const stockTransactionScene = {
		name: language.stock_transaction,
		enabled: true,
		component: <SwapHorizontalCircleIcon/>,
		action: () => {
			redirection('stocktransfer', language.stock_transaction)
		}
	}

	const managesched = {
		name: language.manage_sched,
		enabled: true,
		component: <AccessAlarm style={{fontSize: 18}}/>,
		action: () => {
			redirection('managesched', language.manage_sched)
		}
	}

	const gallery = {
		name: language.photo_gallery,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('gallery', language.photo_gallery)
		}
	}

	const targetScene = {
		name: language.target,
		enabled: true,
		component: <TrackChangesIcon/>,
		action: () => {
			redirection('target', language.target)
		}
	}

	const stockTakeScene = {
		name: language.stock_take,
		enabled: true,
		component: <PostAddRoundedIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('stocktake', language.stock_take)
		}
	}

	const cashrollingScene = {
		name: language.cash_rolling,
		enabled: true,
		component: <MonetizationOnIcon/>,
		action: () => {
			redirection('cashrolling', language.cash_rolling)
		}
	}

	const reportScene = {
		name: language.report,
		enabled: true,
		component: <AssessmentIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('report', language.report)
		}
	}

	const stockTemplateScene = {
		name: language.stock_template,
		enabled: true,
		component: <NoteAddIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('stocktemplate', language.stock_template)
		}
	}

	const backendSyncScene = {
		name: language.backend_sync,
		enabled: true,
		component: <SyncRoundedIcon/>,
		action: () => {
			redirection('backendsync', language.backend_sync)
		}
	}

	const moduleLinkageScene = {
		name: language.module_linkage,
		enabled: true,
		component: <LinkIcon/>,
		action: () => {
			redirection('modulelinkage', language.module_linkage)
		}
	}

	const b2border = {
		name: language.b2border,
		enabled: true,
		component: <ListAltIcon/>,
		action: () => {
			redirection('b2border', language.b2border)
		}
	}

	const rAgentAttendScene = {
		name: language.agent_attend,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('ragentattend', language.agent_attend);
		}
	};
	const rOosScene = {
		name: language.oos,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('roos', language.oos);
		}
	};
	const rstocktakeScene = {
		name: language.stock_take,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rstocktake', language.stock_take);
		}
	};
	const rOutletVisitScene = {
		name: language.outlet_visit,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('routletvisit', language.outlet_visit);
		}
	};
	const rOutletVisitFrequencyScene = {
		name: language.outlet_visit_frequency,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('routletvisitfrequency', language.outlet_visit_frequency);
		}
	};
	const rStockNearExpiryScene = {
		name: language.stock_near_expiry,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rstocknearexpiry', language.stock_near_expiry);
		}
	};
	const rScheduleVisitScene = {
		name: language.schedule_visit,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rschedulevisit', language.schedule_visit);
		}
	};
	const rMileageScene = {
		name: language.mileage,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rmileage', language.mileage);
		}
	}

	const rIncentive = {
		name: language.incentive,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rincentive', language.incentive);
		}
	}

	const RPOSM = {
		name: language.posm,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rposm', language.posm);
		}
	}

	const ROutletVisitAnalysis = {
		name: language.outlet_visit,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('routletvisitanalysis', language.outlet_visit);
		}
	}

	const RPhotoTakenAnalysis = {
		name: language.photo_taken,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rphototakenanalysis', language.photo_taken);
		}
	}

	const sUser = {
		name: language.user_settings,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('usersettings', language.user_settings);
		}
	};

	const sSystem = {
		name: language.system_settings,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('systemsettings', language.system_settings);
		}
	};

	const competitor = {
		name: language.competitor_price_checking,
		enabled: true,
		component: <SportsKabaddiIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('competitor', language.competitor_price_checking)
		}
	}

	const RDisplay = {
		name: language.rdisplay,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rdisplay', language.rdisplay);
		}
	}

	const RSKU = {
		name: language.rsku,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rsku', language.rsku);
		}
	}

	const rCompetitorPriceCheck = {
		name: language.rCompetitorPriceCheck,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('rcompetitorpricecheck', language.rCompetitorPriceCheck);
		}
	}

	const sTutorials = {
		name: language.tutorials,
		enabled: true,
		component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
		action: () => {
			redirection('tutorials', language.tutorials);
		}
	}

	var operation = [
		dashboardScene,
		checkinScene,
		AAInsightScene,
		OVInsightScene
	];

	var controller = [
		customer,
		stockList,
		stockTemplateScene,
		managesched,
		// competitor
		// reportScene
		// stockTakeScene,
	];

	var report = [
		rAgentAttendScene,
		rstocktakeScene,
		rOosScene,
		rOutletVisitScene,
		// rScheduleVisitScene,
		rStockNearExpiryScene,
		// rOutletVisitFrequencyScene,
		// rMileageScene,
		gallery,
		rIncentive,
		RPOSM,
		// rCompetitorPriceCheck
	]

	var analysis = [
		ROutletVisitAnalysis,
		RPhotoTakenAnalysis,
	]

	var settings = [
		sUser,
		sSystem
	]

	var others = [
		sTutorials
	]

	// if(_.isArray(availableModules)&&availableModules.length > 0){
	// 	var newFeatureList = [];
	// 	for (let f = 0; f < featureList.length; f++) {
	// 		const { name } = featureList[f];
	// 		if(availableModules.includes(name)){
	// 			newFeatureList.push(featureList[f]);
	// 		}
	// 	}
	// 	featureList = newFeatureList;
	// }
	if (_.isArray(availableModules) && availableModules.length > 0) {
		var newController = [];
		for (let f = 0; f < controller.length; f++) {
			const {name} = controller[f];
			if (availableModules.includes(name)) {
				newController.push(controller[f]);
			}
		}
		controller = newController;
	}
	if (_.isArray(availableModules) && availableModules.length > 0) {
		var newOperation = [];
		for (let f = 0; f < operation.length; f++) {
			const {name} = operation[f];
			if (availableModules.includes(name)) {
				newOperation.push(operation[f]);
			}
		}
		operation = newOperation;
	}
	if (_.isArray(availableModules) && availableModules.length > 0) {
		var newReport = [];
		for (let f = 0; f < report.length; f++) {
			const {name} = report[f];
			if (availableModules.includes(name)) {
				newReport.push(report[f]);
			}
		}
		report = newReport;
	}
	if (_.isArray(availableModules) && availableModules.length > 0) {
		var newSettings = [];
		for (let f = 0; f < settings.length; f++) {
			const {name} = settings[f];
			if (availableModules.includes(name)) {
				newSettings.push(settings[f]);
			}
		}
		settings = newSettings;
	}

	if (displayReport) {
		report.push({
			name: 'Display %',
			enabled: true,
			component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('rdisplay', 'Display %');
			}
		});
		report.push({
			name: 'SKU %',
			enabled: true,
			component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('rsku', 'SKU %');
			}
		});
	}

	if (competitorModule) {
		controller.push({
			name: language.competitor_price_checking,
			enabled: true,
			component: <SportsKabaddiIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('competitor', language.competitor_price_checking)
			}
		});
		report.push({
			name: language.rCompetitorPriceCheck,
			enabled: true,
			component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('rcompetitorpricecheck', language.rCompetitorPriceCheck);
			}
		});
		report.push({
			name: language.competitor + " " + language.photo_gallery,
			enabled: true,
			component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('competitorgallery', language.competitor + " " + language.photo_gallery)
      }
		});
	}
	if (modules.hasOwnProperty('skustandard') && modules.skustandard.enabled) {
		report.push({
			name: modules.skustandard.title,
			enabled: true,
			component: <FiberManualRecordIcon style={{fontSize: 18}}/>,
			action: () => {
				redirection('rskustandard', modules.skustandard.title);
			}
		});
	}

	const logoutAction = {
		name: language.logout,
		component: <ExitToAppRoundedIcon/>,
		action: () => {
			setLogoutAlert(true);
		}
	}
	const settingList = [
		logoutAction
	];
	const clearCookiesAndRedirect = () => {
		cookieClear((name) => {
			setCookie(name, empty);
			removeCookie(name, {path: '/'});
			removeCookie(name, {path: '/admin'});
			removeCookie(name, {path: '/admin/main'});
			removeCookie(name, {path: '/admin#/main'});
			// removeCookie(name, { path: '/admin/#/main' });
		});
		sessionStorage.removeItem('session');
		var timer = setTimeout(() => {
			History.push('/admin');
			clearTimeout(timer);
		}, 1000);
	}
	const cLanguage = singleton.selectedLang();

	const [operationClick, setOperationClick] = useState(false);
	const [controllerClick, setControllerClick] = useState(false);
	const [reportClick, setReportClick] = useState(false);
	const [analysisClick, setAnalysisClick] = useState(false);
	const [settingClick, setSettingClick] = useState(false);


	const handleOperationClick = () => {
		setOperationClick(!operationClick);
	}
	const handleControllerClick = () => {
		setControllerClick(!controllerClick);
	}
	const handleReportClick = () => {
		setReportClick(!reportClick);
	}
	const handleAnalysisClick = () => {
		setAnalysisClick(!analysisClick);
	}
	const handleSettingClick = () => {
		setSettingClick(!settingClick);
	}
	const [tutorialDialog, setTutorialDialog] = useState(false);

	const toggleTutorials = (state) => {
		setTutorialDialog(true);
		// window.open('https://www.youtube.com/watch?v=V85iH8KR0d0&t=27s&ab_channel=EasySalesMobileOrderingAppLena', '_blank');
	}
	return (
		<MuiThemeProvider theme={muiTheme}>
			<CookiesProvider>
				<SnackbarProvider
					classes={{
						variantSuccess: classes.whiteText,
						variantError: classes.whiteText,
						variantInfo: classes.whiteText,
						variantWarning: classes.whiteText
					}}>
					<div className={classes.root}>
						<CssBaseline/>
						<AppBar
							position="fixed"
							className={clsx(classes.appBar, {
								[classes.appBarShift]: open,
							})}
							style={{background: '#FEF200', color: 'black'}}>
							<Toolbar>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									onClick={() => drawerOpen()}
									edge="start"
									className={clsx(classes.menuButton, {
										[classes.hide]: open,
									})}>
									<MenuIcon/>
								</IconButton>
								<Typography variant="h6" noWrap className={classes.title}>
									{cLanguage === 'en' ? currentTitle.toUpperCase() : currentTitle}
								</Typography>
								<Offline>
									<MAlert severity="error">
										{language.no_internet}
									</MAlert>
								</Offline>
								{
									liveUpdates && (
										<div style={{width: 20}}/>
									)
								}
								{
									liveUpdates && (
										<LiveUpdate
											username={defUsername}
											client={u_client}
											device_no={deviceNo}
										/>
									)
								}
								<div style={{width: 20}}/>
								<Button
									color="inherit"
									onClick={langMenuClick}
									size="small"
									endIcon={<KeyboardArrowDownRoundedIcon/>}
									startIcon={<GTranslateRoundedIcon/>}>
									<Typography component="p">
										{cLanguage === 'en' ? 'English' : '中文'}
									</Typography>
								</Button>
								<Menu
									id="simple-menu"
									anchorEl={menuLang}
									keepMounted
									open={Boolean(menuLang)}
									onClose={() => closeLangMenu(cLanguage)}>
									<MenuItem onClick={() => closeLangMenu('en')}>English</MenuItem>
									<MenuItem onClick={() => closeLangMenu('zh')}>中文</MenuItem>
								</Menu>
								{
									currentTitle in availableTutorials && (
										<Tooltip title="Tutorial Video">
											<IconButton
												color="inherit"
												aria-label="toggle tutorials"
												onClick={() => toggleTutorials(currentTitle)}
												edge="end">
												<HelpIcon/>
											</IconButton>
										</Tooltip>
									)
								}
								{/* <IconButton
									color="inherit"
									aria-label="toggle theme"
									onClick={toggleDarkTheme}
									edge="end">
									{
										isDark ?
										<Brightness7RoundedIcon/>
										:
										<Brightness4RoundedIcon/>
									}
								</IconButton> */}
							</Toolbar>
						</AppBar>
						<Drawer
							variant="permanent"
							className={clsx(classes.drawer, {
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							})}
							classes={{
								paper: clsx({
									[classes.drawerOpen]: open,
									[classes.drawerClose]: !open,
								}),
							}}>
							<div className={classes.toolbar}>
								{
									logoUrl ? (
										<div className={classes.logo}>
											<img className={classes.logo_img} src={logoUrl} />
											<h1 className={classes.header}>{JString(gState.client).toUpperCase()}
											{
												gState.company && (
													<> | {JSON.parse(gState.company.toUpperCase()).join(', ')}</>
												)
											}</h1>
										</div>
									) : <h1 className={classes.header_noLogo}>{JString(gState.client).toUpperCase()}</h1>

								}
								<IconButton onClick={() => drawerClose()}>
									{theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
								</IconButton>
							</div>
							<Divider/>
							<List>
								{
									operation.length > 0 && (
										<ListItem button onClick={handleOperationClick}>
											<ListItemIcon>
												<HomeWorkIcon style={{fontSize: 18}}/>
											</ListItemIcon>
											<ListItemText primary={
												<Typography component="p" style={{fontSize: 13}}>
													{cLanguage === 'en' ? language.operation.toUpperCase() : language.operation}
												</Typography>}/>
											{operationClick ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>
									)
								}
								{operation.map((obj, index) => {
									const disabled = obj.enabled === false;
									return (
										<Collapse key={`Main-operation-${String(index)}`} in={operationClick} timeout="auto" unmountOnExit>
											<List component="div" key={`Operation-${String(index)}`} disablePadding>
												<ListItem
													disabled={disabled}
													key={String(index)}
													onClick={() => obj.action()}
													button
													className={classes.nested}>
													<ListItemIcon
														style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
													<ListItemText primary={
														<Typography component="p" style={{fontSize: 13}}>
															{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
														</Typography>}/>
												</ListItem>
											</List>
										</Collapse>
									)
								})}
								{/* primaryTypographyProps={ cLanguage === 'en' ?  obj.name.toUpperCase() : obj.name } */}
								{
									controller.length > 0 && (
										<ListItem button onClick={handleControllerClick}>
											<ListItemIcon>
												<FormatListBulletedIcon style={{fontSize: 18}}/>
											</ListItemIcon>
											<ListItemText primary={
												<Typography component="p" style={{fontSize: 13}}>
													{cLanguage === 'en' ? language.controller.toUpperCase() : language.controller}
												</Typography>}/>
											{controllerClick ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>
									)
								}
								{controller.map((obj, index) => {
									const disabled = obj.enabled === false;
									return (
										<Collapse key={`Main-controller-${String(index)}`} in={controllerClick} timeout="auto" unmountOnExit>
											<List component="div" key={String(index)} disablePadding>
												<ListItem
													disabled={disabled}
													key={String(index)}
													onClick={() => obj.action()}
													button
													className={classes.nested}>
													<ListItemIcon
														style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
													<ListItemText primary={
														<Typography component="p" style={{fontSize: 13}}>
															{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
														</Typography>}/>
												</ListItem>
											</List>
										</Collapse>
									)
								})}
								{
									report.length > 0 && (
										<ListItem button onClick={handleReportClick}>
											<ListItemIcon>
												<AssessmentIcon style={{fontSize: 18}}/>
											</ListItemIcon>
											<ListItemText primary={
												<Typography component="p" style={{fontSize: 13}}>
													{cLanguage === 'en' ? language.report.toUpperCase() : language.report}
												</Typography>}/>
											{reportClick ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>
									)
								}
								{report.map((obj, index) => {
									const disabled = obj.enabled === false;
									return (
										<Collapse key={`Main-report-${String(index)}`} in={reportClick} timeout="auto" unmountOnExit>
											<List component="div" key={String(index)} disablePadding>
												<ListItem
													disabled={disabled}
													key={String(index)}
													onClick={() => obj.action()}
													button
													className={classes.nested}>
													<ListItemIcon
														style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
													<ListItemText primary={
														<Typography component="p" style={{fontSize: 13}}>
															{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
														</Typography>}/>
												</ListItem>
											</List>
										</Collapse>
									)
								})}
								{
									analysis.length > 0 && (
										<ListItem button onClick={handleAnalysisClick}>
											<ListItemIcon>
												<AssessmentIcon style={{fontSize: 18}}/>
											</ListItemIcon>
											<ListItemText primary={
												<Typography component="p" style={{fontSize: 13}}>
													{cLanguage === 'en' ? language.table_analysis_report.toUpperCase() : language.table_analysis_report}
												</Typography>}/>
											{analysisClick ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>
									)
								}
								{analysis.map((obj, index) => {
									const disabled = obj.enabled === false;
									return (
										<Collapse key={`Main-analysis-${String(index)}`} in={analysisClick} timeout="auto" unmountOnExit>
											<List component="div" key={String(index)} disablePadding>
												<ListItem
													disabled={disabled}
													key={String(index)}
													onClick={() => obj.action()}
													button
													className={classes.nested}>
													<ListItemIcon
														style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
													<ListItemText primary={
														<Typography component="p" style={{fontSize: 13}}>
															{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
														</Typography>}/>
												</ListItem>
											</List>
										</Collapse>
									)
								})}
								{
									settings.length > 0 && (
										<ListItem button onClick={handleSettingClick}>
											<ListItemIcon>
												<SettingsIcon style={{fontSize: 18}}/>
											</ListItemIcon>
											<ListItemText primary={
												<Typography component="p" style={{fontSize: 13}}>
													{cLanguage === 'en' ? language.settings.toUpperCase() : language.settings}
												</Typography>}/>
											{settingClick ? <ExpandLess/> : <ExpandMore/>}
										</ListItem>
									)
								}
								{settings.map((obj, index) => {
									const disabled = obj.enabled === false;
									return (
										<Collapse key={`Main-settings-${String(index)}`} in={settingClick} timeout="auto" unmountOnExit>
											<List component="div" key={String(index)} disablePadding>
												<ListItem
													disabled={disabled}
													key={String(index)}
													onClick={() => obj.action()}
													button
													className={classes.nested}>
													<ListItemIcon
														style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
													<ListItemText primary={
														<Typography component="p" style={{fontSize: 13}}>
															{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
														</Typography>}/>
												</ListItem>
											</List>
										</Collapse>
									)
								})}
								{
									others.map((obj, index) => {
										const disabled = obj.enabled === false;
										return (
											<ListItem
												disabled={disabled}
												key={String(index)}
												onClick={() => obj.action()}
												button>
												<ListItemIcon
													style={isDark ? {color: 'white'} : {color: 'black'}}>{obj.component}</ListItemIcon>
												<ListItemText primary={
													<Typography component="p" style={{fontSize: 13}}>
														{cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}
													</Typography>}/>
											</ListItem>
										)
									})
								}
							</List>
							<Divider/>
							<List>
								{settingList.map((obj, index) => (
									<ListItem
										onClick={() => obj.action()}
										button
										key={String(index)}>
										<ListItemIcon>{obj.component}</ListItemIcon>
										<ListItemText primary={cLanguage === 'en' ? obj.name?.toUpperCase() : obj.name}/>
									</ListItem>
								))}
							</List>
						</Drawer>
						<main className={classes.content}>
							<div className={classes.content_toolbar}/>
							<Router history={History}>
								<Routes/>
							</Router>
						</main>
					</div>
					<Alert
						setPositive={() => setLogoutAlert(false)}
						setNegative={() => clearCookiesAndRedirect()}
						setClose={() => setLogoutAlert(false)}
						open={logoutAlert}
						title={language.es_backend}
						message={language.proceed_logout}
						positive={language.cancel}
						negative={language.logout_cap}
						autoDispossal={true}
					/>
					<Dialog
						maxWidth={'lg'}
						open={tutorialDialog}
						onClose={() => setTutorialDialog(false)}
						aria-labelledby="responsive-dialog-title">
						<DialogActions>
							<IconButton edge="start" color="inherit" onClick={() => setTutorialDialog(false)}
										aria-label="close">
								<CloseIcon/>
							</IconButton>
						</DialogActions>
						<DialogContent>
							<iframe width="1173" height="650" src={availableTutorials[currentTitle]} title="EasyM Merchandising App" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
						</DialogContent>
					</Dialog>
				</SnackbarProvider>
			</CookiesProvider>
		</MuiThemeProvider>
	);
}
