import React, {useContext, useEffect, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Button from '@material-ui/core/Button';
import {req_checkin_changeappmnt, req_checkin_merchand_appmnt} from '../../Helper/Constants';
import {green, red, deepPurple, blue} from '@material-ui/core/colors';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import moment from 'moment';
import AppContext from '../../Services/AppContext';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Popover,
    Typography
} from '@material-ui/core';
import {
    Delete as DeleteIcon,
    QueryBuilder as QueryBuilderIcon,
    Repeat as RepeatIcon,
    Storefront as StorefrontIcon,
    Today as TodayIcon
} from '@material-ui/icons';
import VirtualList from '../../Components/VirtualList';
import {de} from '../../Helper/Helper';
import PersonIcon from '@material-ui/icons/Person';
import AnyDialog from '../../Components/AnyDialog';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Alert from '../../Components/Alert';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import '../../Styles/calendar.scss'
import HistoryIcon from '@material-ui/icons/History';

const frequencyLists = [
	{name: 'Every 1 Week', value: '1 Week'},
	{name: 'Every 2 Week', value: '2 Week'},
	{name: 'Every 3 Week', value: '3 Week'},
	{name: 'Every 4 Week', value: '4 Week'}
];

export default function AppointmentView({data, customerList, onSnack, merchandiserList, language, requestLoad, createSched, onNavigate}){
	const paperStyle = usePaperStyles();
	const commonStyle = useCommonStyles();

	const [ appointment, setAppointment ] = useState({});
	const [ isRequesting, setRequesting ] = useState(customerList?.length===0);
	const [ openCustomer,showCustomer ] = useState(false);
	const [ dateChanger, showDateChanger ] = useState(false);
	const [ timeChanger, showTimeChanger ] = useState(false);
	const [ openMchList, showMchList ] = useState(false);
	const [ calendarData, setCalendarData ] = useState([]);
	const [ changeableTime, setChangableTime ] = useState(new Date());
	const [ gState, setGState ] = useContext(AppContext);
	const [ openEditAlert, showEditAlert ] = useState(false);
	const [ openDeleteAlert, showDeleteAlert ] = useState(false);
	const [ dataChanging, setDataChanging ] = useState(undefined);
	const [ popUpSet, setPopUpSet ] = useState(false);
	const [ showFrequencyList, setFrequencyShow ] = useState(false);

	useEffect(()=>{
		if(customerList?.length > 0){
			setRequesting(false);
		}
	},[customerList]);

	useEffect(()=>{
		const convertedData = data && data.map(({startDate, endDate, ...rest})=>{
			return {
				startDate: moment(startDate).toDate(),
				endDate: moment(endDate).toDate(),
				...rest
			}
		})
		setCalendarData(convertedData);
		de('==appmnt data==',convertedData);
	},[data])

	// searched 1 month data but the cms did not show
	const updateAppointment = (data) =>{
		de('==requesting==',data);
		setRequesting(true);
		API_Service.put(req_checkin_changeappmnt,data,axiosPutConfig(gState.client)).then(({data})=>{
			const { result, message } = data;
			if(result == 1){
				requestLoad();
			}
			onSnack(message,result == 1?'success':'error');
			setRequesting(false);
			// showTooltip(false);
		}).catch(er=>setRequesting(false));
	}

	const getUpdateData = (type) =>{
		const { id, master, detail } = appointment;
		const { salesperson_id, id:master_id, appmnt_note, cust_code, branch_code,repetitive, appmnt_code } = master;
		const { appmnt_date, appmnt_time, appmnt_duration } = detail;
		return {
			dtl_id: id,
			salesperson_id,
			cust_code,
			updated_by: gState.username,
			appmnt_date,
			appmnt_time,
			appmnt_note,
			appmnt_duration,
			master_id,
			type,
			branch_code,
			repetitive,
			appmnt_code
		};
	}

	const onDeleteAppointment = (change_all = false) =>{
		setRequesting(true);
		const { dtl_id, appmnt_code, master_id, appmnt_date } = getUpdateData('');
		const data = axiosURIencode({ id: dtl_id, recur: +change_all, appmnt_code, master_id, appmnt_date });
		de('--delete--', { id: dtl_id, recur: +change_all, appmnt_code, master_id, appmnt_date });
		API_Service.put(req_checkin_merchand_appmnt,data,axiosPutConfig(gState.client)).then(({data})=>{
			const { result, message } = data;
			if(result == 1){
				requestLoad();
			}
			onSnack(message,result == 1?'success':'error');
			setRequesting(false);
			// showTooltip(false);
			setRequesting(false);
		}).catch(er=>setRequesting(false));
	}

	const onCustomerViewClose = (data, change_all = false) =>{
		if(data && !JString(data.value).isEqualDeep('reset') && appointment){
			const params = axiosURIencode({
				...getUpdateData('customer'),
				new_cust_code: data.value, recur: +change_all
			});
			updateAppointment(params);
		}
		showCustomer(false);
	}

	const onChangeDate = (date, change_all = false) =>{
		showDateChanger(false);
		const params = axiosURIencode({
			...getUpdateData('date'),
			new_appmnt_date: moment(date).format('YYYY-MM-DD'), recur: +change_all
		});
		updateAppointment(params);
		setSelectedEvent(null);
	}

	const onChangeTime = (date, change_all = false) =>{
		showTimeChanger(false);
		const params = axiosURIencode({
			...getUpdateData('time'),
			new_appmnt_time: moment(date).format('HH:mm'), recur: +change_all
		});
		updateAppointment(params);
		setSelectedEvent(null);
	}

	const onChangeMerchandiser = (data, change_all = false) =>{
		showMchList(false);
		const params = axiosURIencode({
			...getUpdateData('salesperson'),
			new_salesperson_id: data.key, recur: +change_all
		});
		updateAppointment(params);
	}

	const onChangeFrequency = (data, change_all = false) => {
		const params = axiosURIencode({
			...getUpdateData('frequency'),
			appmnt_interval: data.value,
			recur: +change_all
		});
		updateAppointment(params);
		setFrequencyShow(false);
	}

	const updateOrDelete = (data = [], type, isEdit) =>{
		const { master } = appointment;
		const { repetitive } = master;
		if(parseInt(repetitive) === 1){
			setDataChanging({
				type,
				data
			});
			if(isEdit){
				showEditAlert(true);
			}else{
				showDeleteAlert(true);
			}
		}else{
			if(type == 'customer'){
				onCustomerViewClose(data);
			}
			if(type == 'date'){
				onChangeDate(data);
			}
			if(type == 'time'){
				onChangeTime(data);
			}
			if(type == 'salesperson'){
				onChangeMerchandiser(data);
			}
			if(type == 'delete'){
				onDeleteAppointment();
			}
			if(type == 'frequency'){
				onSnack('This is not a repetitive schedule.', 'error');
				setFrequencyShow(false);
			}
		}
	}

	const decideAction = (change_all = false) =>{
		const {type,data} = dataChanging;
		if(type == 'customer'){
			onCustomerViewClose(data, change_all);
		}
		if(type == 'date'){
			onChangeDate(data, change_all);
		}
		if(type == 'time'){
			onChangeTime(data, change_all);
		}
		if(type == 'salesperson'){
			onChangeMerchandiser(data, change_all);
		}
		if(type == 'delete'){
			onDeleteAppointment(change_all);
		}
		if(type == 'frequency'){
			if(!change_all){
				onSnack('You can not change frequency for only 1 schedule.', 'error');
			}else{
				onChangeFrequency(data, change_all);
			}
		}
		showDeleteAlert(false);
		showEditAlert(false);
	}

	const [selectedEvent, setSelectedEvent] = useState(false);
	const [selectedEventPos, setSelectedEventPos] = useState({top: 0, left: 0});
	const localizer = momentLocalizer(moment)
	const handleSelectEvent = React.useCallback(
		(event) => {
			setSelectedEvent(event)
			setAppointment(event)

			const eventDiv = document.getElementById("event"+event.id).getElementsByClassName("rbc-event")[0];
			if (!eventDiv) return;
			const { top, left } = eventDiv.getBoundingClientRect();
			setSelectedEventPos({ top, left })
			// console.log(event)
		},
		[]
	)

	const handleSelectSlot = React.useCallback(({start, end}) => {
		createSched(start);
	},[])

	const popupEvent = React.useCallback(
		(events,date) => {
			if(new Date(date).getDate() < 6){
				if(events.length > 15){
					setPopUpSet({x: 0, y: -500})
				}else{
					setPopUpSet(false)
				}
			}else{
				if(events.length > 12){
					setPopUpSet({x: 0, y: -500})
				}else{
					setPopUpSet(false)
				}
			}
		},
		[]
	)

	const eventPropGetter = React.useCallback(
		(event, start, end, isSelected) => ({
			...{
				style: {
					backgroundColor: '#FF952B',
				},
			},
			...(parseInt(event?.detail?.appmnt_status) === 3 && {
				style: {
					backgroundColor: blue[500],
				},
			}),
			...(parseInt(event?.detail?.appmnt_status) === 2 && {
				style: {
					backgroundColor: deepPurple[500],
				},
			}),
			...(parseInt(event?.detail?.appmnt_status) === 1 && {
				style: {
					backgroundColor: green[500],
				},
			}),
			...(parseInt(event?.detail?.appmnt_status) === 0 && moment(event?.detail?.appmnt_date).endOf('day').isBefore() && {
				style: {
					backgroundColor: red[500],
				},
			}),
		}),
		[])

	return (
		<>
			<div
				style={{
					height: 600,
					marginTop: 5,
				}}
			>
				<Calendar
					components={{
						eventWrapper: (e) => {
							return(
								<div id={"event"+e.event.id}>
									{e.children}
								</div>
							)
						}
					}}
					localizer={localizer}
					events={calendarData}
					startAccessor="startDate"
					endAccessor="endDate"
					popup
					selectable
					onSelectEvent={(e)=>handleSelectEvent(e)}
					onSelectSlot={handleSelectSlot}
					eventPropGetter={eventPropGetter}
					views={['month', 'week', 'day']}
					defaultDate={moment().format('YYYY-MM-DD')}
					onNavigate={onNavigate}
					// popupOffset={popUpSet}
					// onShowMore={(events, date) => popupEvent(events, date)}
					// // showAllEvents={true}
				/>
			</div>

			<CustomPopover
				selectedEventPos={selectedEventPos}
				selectedEvent={selectedEvent}
				setSelectedEvent={setSelectedEvent}
				commonStyle={commonStyle}
				actions={
					isRequesting ? <CircularProgress size={20} style={{marginTop:10,marginLeft:10}}/>:
						<div className={paperStyle.viewInRow}>
							<IconButton
								onClick={()=>showCustomer(true)}>
								<StorefrontIcon/>
							</IconButton>
							<IconButton
								onClick={()=>showMchList(true)}>
								<PersonIcon/>
							</IconButton>
							<IconButton
								onClick={()=>{showDateChanger(true)}}>
								<TodayIcon/>
							</IconButton>
							<IconButton
								onClick={()=>{showTimeChanger(true)}}>
								<QueryBuilderIcon/>
							</IconButton>
							<IconButton
								disabled={parseInt(selectedEvent?.detail?.appmnt_status) !== 0 || !moment(selectedEvent?.startDate).isSameOrAfter(moment(), 'day')}
								onClick={()=>{setFrequencyShow(true)}}>
								<HistoryIcon/>
							</IconButton>
							<Divider variant={'middle'} orientation="vertical" flewidthItem/>
							<IconButton
								disabled={parseInt(selectedEvent?.detail?.appmnt_status) !== 0 || !moment(selectedEvent?.startDate).isSameOrAfter(moment(), 'day')}
								onClick={()=>updateOrDelete([],'delete',true)}>
								<DeleteIcon color={'error'}/>
							</IconButton>
						</div>
				}
				language={language}
			>
			</CustomPopover>

			<AnyDialog
				footer={()=>
					<>
						<Button variant={'text'} style={{alignSelf:'flex-end'}} onClick={()=>showTimeChanger(false)}>
							<Typography color={'error'} component={'p'} className={commonStyle.searchBtnFont}>
								{ language.cancel }
							</Typography>
						</Button>
						<Button
							variant={'text'}
							style={{alignSelf:'flex-end'}}
							onClick={()=>updateOrDelete(changeableTime,'time',true)}>
							<Typography color={'primary'} component={'p'} className={commonStyle.searchBtnFont}>
								{ language.update }
							</Typography>
						</Button>
					</>}
				open={timeChanger}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<TimePicker
						disablePast
						variant={'static'}
						label={language.time}
						value={changeableTime}
						onChange={setChangableTime}
					/>
				</MuiPickersUtilsProvider>
			</AnyDialog>
			<AnyDialog
				footer={()=>
					<Button variant={'text'} style={{alignSelf:'flex-end'}} onClick={()=>showDateChanger(false)}>
						<Typography color={'error'} component={'p'} className={commonStyle.searchBtnFont}>
							{ language.cancel }
						</Typography>
					</Button>}
				open={dateChanger}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						disablePast
						variant={'static'}
						label={String(language.choose_date).toUpperCase()}
						onChange={d=>updateOrDelete(d,'date',true)}
					/>
				</MuiPickersUtilsProvider>
			</AnyDialog>
			<AnyDialog
				footer={()=>
					<Button variant={'text'} style={{alignSelf:'flex-end'}} onClick={()=>showMchList(false)}>
						<Typography color={'error'} component={'p'} className={commonStyle.searchBtnFont}>
							{ language.cancel }
						</Typography>
					</Button>}
				open={openMchList}>
				<List
					subheader={
						<ListSubheader component="div" id="nested-list-subheader">
							{ language.pic_list }
						</ListSubheader>
					}
					component="nav"
					aria-label="merchandiser list">
					{
						merchandiserList?.length === 0 &&(
							<ListItem button>
								<ListItemIcon>
									<CircularProgress size={20}/>
								</ListItemIcon>
								<ListItemText primary="Loading..." />
							</ListItem>
						)
					}
					{
						merchandiserList?.map && merchandiserList.map((item,inx)=>{
							return(
								<ListItem
									onClick={()=>updateOrDelete(item,'salesperson',true)}
									button
									key={String(inx)}>
									<ListItemIcon>
										<PersonIcon />
									</ListItemIcon>
									<ListItemText
										primary={item.name} />
								</ListItem>
							)
						})
					}
				</List>
			</AnyDialog>
			<VirtualList
				open={openCustomer}
				setClose={data=>updateOrDelete(data,'customer',true)}
				title={language.cust_list}
				data={customerList}
				language={language}
			/>
			<Alert
				setPositive={()=>{ decideAction(false)  }}
				setNegative={()=>{ decideAction(true) }}
				setClose={()=>{ showEditAlert(false) }}
				open={openEditAlert}
				title={language.es_backend}
				message={language.recur_msg}
				positive={dataChanging && dataChanging.type != 'delete' ? language.edit_current : language.delete_current}
				negative={dataChanging && dataChanging.type != 'delete' ? language.edit_recur : language.delete_recur}
				autoDispossal={true}
				unmount={true}
			/>
			<Alert
				setPositive={()=>{ decideAction(false)  }}
				setNegative={()=>{ decideAction(true) }}
				setClose={()=>{ showDeleteAlert(false) }}
				open={openDeleteAlert}
				title={language.es_backend}
				message={language.recur_msg}
				positive={language.delete_current}
				negative={language.delete_recur}
				autoDispossal={true}
				unmount={true}
			/>
			<AnyDialog
				footer={()=>
					<Button variant={'text'} style={{alignSelf:'flex-end'}} onClick={()=>setFrequencyShow(false)}>
						<Typography color={'error'} component={'p'} className={commonStyle.searchBtnFont}>
							{ language.cancel }
						</Typography>
					</Button>}
				open={showFrequencyList}>
				<List
					subheader={
						<ListSubheader component="div" id="nested-list-subheader">
							{language.frequency_list}
						</ListSubheader>
					}
					component="nav"
					aria-label="merchandiser list">
					{
						frequencyLists && frequencyLists.map((item,inx)=>{
							return(
								<ListItem
									onClick={()=>updateOrDelete(item,'frequency',true)}
									button
									key={String(inx)}>
									<ListItemIcon>
										<HistoryIcon />
									</ListItemIcon>
									<ListItemText
										primary={item.name} />
								</ListItem>
							)
						})
					}
				</List>
			</AnyDialog>
			<div style={{height:60}}/>
		</>
	)
}

const CustomPopover = ({selectedEvent, setSelectedEvent, selectedEventPos, commonStyle, actions, language}) => {
	var backgroundColor = { backgroundColor: '#FF952B' };
	if(parseInt(selectedEvent?.detail?.appmnt_status) === 1){
		backgroundColor = { backgroundColor: green[500] };
	}
	if(parseInt(selectedEvent?.detail?.appmnt_status) === 2){
		backgroundColor = { backgroundColor: deepPurple[500] };
	}
	if(parseInt(selectedEvent?.detail?.appmnt_status) === 3){
		backgroundColor = { backgroundColor: blue[500] };
	}
	if(parseInt(selectedEvent?.detail?.appmnt_status) === 0 && moment(selectedEvent?.detail?.appmnt_date).endOf('day').isBefore()){
		backgroundColor = { backgroundColor: red[500] };
	}

	const id = selectedEvent ? 'simple-popover-jj' : undefined;

	const details = JString(selectedEvent?.detail?.miss_call_details).toJson() ? JString(selectedEvent.detail.miss_call_details).toJson().reason : undefined;

	const remarks = JString(selectedEvent?.detail?.miss_call_details).toJson() ? JString(selectedEvent.detail.miss_call_details).toJson().remark : undefined;

	return (
		<Popover
			PaperProps={{className:commonStyle.clPoper}}
			open={selectedEvent && selectedEventPos}
			id={id}
			anchorPosition={{ left: selectedEventPos.left, top: selectedEventPos.top + 25 }}
			anchorReference="anchorPosition"
			onClose={()=>setSelectedEvent(null)}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{
				parseInt(selectedEvent?.detail?.appmnt_status) === 2 ? 
				<Typography className={commonStyle.clPoperTxt} component={'p'}>
					{language.unsched_outlet_visit}
				</Typography> : actions
			}
			<div className={commonStyle.clTooltipContent}>
				<div className={commonStyle.circle} style={{...backgroundColor}}>
					{selectedEvent && selectedEvent.master.repetitive == 1 ?
						<RepeatIcon style={{margin:5}} fontSize={'small'} htmlColor={'#fff'}/>:null
					}
				</div>
				<Typography className={commonStyle.clPoperTxt} component={'p'} color={'textSecondary'}>
					{selectedEvent && selectedEvent.title }
				</Typography>
			</div>
			{
				details && (
					<div className={commonStyle.clTooltipContent}>
						<Typography className={commonStyle.clPoperTime} component={'p'} style={{width:'100%'}}>
							Reason: {details}
						</Typography>
						<Typography className={commonStyle.clPoperTime} component={'p'} style={{width:'100%'}}>
							{remarks}
						</Typography>
					</div>
				)
			}
			{
				selectedEvent?.master?.appmnt_note && selectedEvent.master.appmnt_note !== '' && (
					<div className={commonStyle.clTooltipContent}>
						<Typography className={commonStyle.clPoperTime} component={'p'} style={{width:'100%'}}>
							Note: {selectedEvent.master.appmnt_note}
						</Typography>
					</div>
				)
			}
			<div className={commonStyle.clTooltipContent}>
				<QueryBuilderIcon style={{marginLeft:3}} size={'small'} color={'textPrimary'}/>
				{selectedEvent && (
					<Typography className={commonStyle.clPoperTime} component={'p'}>
						{
							moment(selectedEvent.startDate).format("Hms") != "000" ?
							moment(selectedEvent.startDate).format('DD/MM/YYYY hh:mmA') : moment(selectedEvent.startDate).format('DD/MM/YYYY')
						} - {
							moment(selectedEvent.endDate).format("Hms") != "000" ?
							moment(selectedEvent.endDate).format('DD/MM/YYYY hh:mmA') : moment(selectedEvent.endDate).format('DD/MM/YYYY')
						}
					</Typography>
				)}
			</div>
		</Popover>
	)
}
