import JString from '@easytech-international-sdn-bhd/jstring';
import {
    Button,
    DialogContent,
    FormControl,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {req_stocktemplate, req_stocktemplate_seq} from '../../Helper/Constants';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import AnyDialog from '../../Components/AnyDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import StockTemplateItems from '../../Components/StockTemplateItems';
import {de} from "../../Helper/Helper";
import TemplateEnhancedTable from '../../Components/TemplateEnhancedTable';

const useStyles = makeStyles((theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row'
	},
	dlgContent: {
		width: 400,
		paddingBottom: 100
	}
}));

export default function StockTemplate() {
	const [createDialog, showCreateDialog] = useState(false);
	const [previewIndex, setPreviewIndex] = useState(-1);
	const [showingActiveData, setShowActiveData] = useState(true);

	const [templateData, setTemplateData] = useState([]);
	const [amendData, setAmendData] = useState(-1);

	const [gState] = useContext(AppContext);
	const [axiosConfig, setAxiosConfig] = useState({});

	const {enqueueSnackbar} = useSnackbar();
	const singleton = PersistantData.getInstance();
	var {translation: language} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
			loadStockTemplates(1, axiosGetConfig);
		}
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadStockTemplates = (active_status = 1, config = axiosConfig) => {
		setAmendData(-1);
		setTemplateData([]);
		showCreateDialog(false);
		var params = {
			active_status
		};
		params = axiosURIencode(params);
		API_Service.get(`${req_stocktemplate}?${params}`, config).then(({data}) => {
			var {extraData, result} = data;
			result = parseInt(result);
			if (result === 1) {
				setShowActiveData(active_status == 1);
				setTemplateData(extraData);
			} else {
				snack(language.no_data, 'warning');
			}
		});
	}

	const onEditTemplate = (index) => {
		setAmendData(index);
		showCreateDialog(true);
	}

	const onDeleteTemplate = (index) => {
		const data = getAmendData(index);
		var {id, active_status} = data;
		active_status ^= 1;
		var params = axiosURIencode({
			id,
			active_status
		});
		API_Service.put(req_stocktemplate, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				active_status ^= 1;
				loadStockTemplates(active_status);
			} else {
				snack(language.operation_fail, 'error');
			}
		});
	}

	const getAmendData = (index) => {
		if (index == -1) return {};
		if (index instanceof Array) {
			const active_status = templateData[index[0]].active_status;
			return {
				id: (index.map((__item) => templateData[__item].id)).join(","),
				active_status
			}
		}
		return templateData[index];
	}

	const changeSequence = (e)=>{
		let array = [];
		e.map((each,index)=>{
			array.push({
				id : each.id,
				sequence_no : index + 1
			})
		});
		const formdata = new FormData();
		formdata.append('array', JSON.stringify(array));
		API_Service.post(req_stocktemplate_seq, formdata, axiosConfig).then(({data}) => {
			de(data, 'tmeplate_seq')
			const {result} = data;
			if (parseInt(result) === 1) {
				snack(language.operation_success);
			} else {
				snack(language.operation_fail, 'error');
			}
		});
	}

	const updateStatus = (id, active_status) =>{
		active_status ^= 1;
		var params = axiosURIencode({
			id,
			active_status
		});
		API_Service.put(req_stocktemplate, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				active_status ^= 1;
				loadStockTemplates(active_status);
				snack(language.operation_success);
			} else {
				snack(language.operation_fail, 'error');
			}
		});
	}

	return (
		<div>
			{/* <MUIDataTable
				title={language.template_list}
				data={templateData}
				columns={templateCellData}
				options={{
					rowsSelected: templateData.length > 0 ? undefined : [],
					print: false,
					filter: false,
					selectableRows: templateData.length > 0 ? 'multiple' : 'none',
					rowsPerPage: 100,
					rowsPerPageOptions: [100, 140, 165, 200],
					responsive: 'scroll',
					onRowClick: (rowData, {dataIndex}) => {
						setPreviewIndex(dataIndex);
					},
					customToolbar: () =>
						<>
							{
								showingActiveData && (
									<TemplateTableCustomToolbar
										onNewTemplate={() => {
											showCreateDialog(true)
										}}
									/>
								)
							}
							<TemplateTableCustomStatus
								index={+showingActiveData}
								onActiveStatus={(status) => loadStockTemplates(status)}
							/>
						</>,
					customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
						<div style={{marginRight: 25}}>
							<TemplateTableCustomToolbarSelect
								onEditTemplate={(index) => {
									onEditTemplate(index)
								}}
								onDeleteTemplate={(index) => {
									onDeleteTemplate(index)
								}}
								data={selectedRows}
								showingActiveData={showingActiveData}
							/>
						</div>
				}}
			/> */}
			<CreateTemplate
				open={createDialog}
				axiosConfig={axiosConfig}
				onDismiss={() => showCreateDialog(false)}
				translated={language}
				gState={gState}
				onSnack={snack}
				onEvent={() => loadStockTemplates()}
				edit={amendData != -1}
				editData={getAmendData(amendData)}
			/>
			<StockTemplateItems
				open={previewIndex != -1}
				setClose={() => setPreviewIndex(-1)}
				data={getAmendData(previewIndex)}
				language={language}
				gState={gState}
				onSnack={snack}
			/>
			<TemplateEnhancedTable
				headCell={tableHeadCell}
				title={language.template_list}
				data={templateData}
				onChangeStatus={(e)=>loadStockTemplates(e,axiosConfig)}
				changeSequence={(e)=>changeSequence(e,axiosConfig)}
				updateStatus={(e,status)=>updateStatus(e,status)}
				addTemplate={()=>showCreateDialog(true)}
				setPreviewIndex={(index)=>setPreviewIndex(index)}
			/>
		</div>
	)
}

function CreateTemplate({
							open,
							onDismiss,
							axiosConfig,
							translated,
							gState,
							onEvent,
							onSnack,
							edit = false,
							editData = {}
						}) {
	const classes = useStyles();
	const [template, setTemplate] = useState('');

	useEffect(() => {
		if (edit) {
			const {tmplt_name} = editData;
			setTemplate(tmplt_name);
		}
	}, [edit]);

	const onConfirmPress = () => {
		setTemplate('');
		if (edit) {
			onUpdateTemplate();
		} else {
			onSaveTemplate();
		}
	}

	const onUpdateTemplate = () => {
		var params = axiosURIencode({
			id: editData.id,
			tmplt_name: template,
			updated_by: gState.username
		});
		API_Service.put(req_stocktemplate, params, axiosPutConfig(gState.client)).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				onEvent();
			} else {
				onSnack(translated.operation_fail, 'error');
			}
		});
	}

	const onSaveTemplate = () => {
		const formdata = new FormData();
		formdata.append('tmplt_name', template);
		formdata.append('created_by', gState.username);
		API_Service.post(req_stocktemplate, formdata, axiosConfig).then(({data}) => {
			de(data, 'tmeplate')
			const {result} = data;
			if (parseInt(result) === 1) {
				onEvent();
			} else {
				onSnack(translated.operation_fail, 'error');
			}
		});
	}

	return (
		<AnyDialog
			onDismiss={onDismiss}
			open={open}
			title={''}
			footer={() =>
				<Button
					onClick={() => onConfirmPress()}
					disabled={!JString(template).isValid()}
					fullWidth
					variant={'contained'}
					color={"primary"}>
					<Typography color={'inherit'}>
						{edit ? translated.update : translated.save}
					</Typography>
				</Button>

			}>
			<DialogContent className={classes.dlgContent}>
				<TextField
					value={template}
					placeholder={translated.new_tmplt}
					fullWidth
					rowsMax={2}
					variant={'filled'}
					onChange={e => setTemplate(String(e.target.value).toUpperCase())}
				/>
			</DialogContent>
		</AnyDialog>
	)
}

function TemplateTableCustomStatus({onActiveStatus, index}) {
	const [open, setOpen] = useState(false);
	const [status, setStatus] = useState(1);
	const onChange = (e) => {
		setStatus(e.target.value);
		onActiveStatus(e.target.value)
	}
	useEffect(() => {
		setStatus(index);
	}, [index]);
	return (
		<React.Fragment>
			<FormControl>
				<Select
					style={{marginTop: 3, marginLeft: 20}}
					margin={'dense'}
					variant={'outlined'}
					labelId="active_status-label"
					id="active_status"
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					value={status}
					onChange={e => onChange(e)}>
					<MenuItem value={1}>Active Templates</MenuItem>
					<MenuItem value={0}>Inactive Templates</MenuItem>
				</Select>
			</FormControl>
		</React.Fragment>
	)
}

function TemplateTableCustomToolbar({onNewTemplate}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add New'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onNewTemplate()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

function TemplateTableCustomToolbarSelect({onEditTemplate, onDeleteTemplate, data, showingActiveData}) {
	const getDataIndex = () => {
		if ((data.data || []).length === 1) {
			return data.data[0].dataIndex;
		}
		return data.data.map((item) => item.dataIndex);
	}
	return (
		<React.Fragment>
			<Row>
				{
					showingActiveData && (
						<Tooltip title={'Edit template'}>
							<IconButton
								edge={'end'}
								color={'primary'}
								onClick={() => onEditTemplate(getDataIndex())} aria-label="addnew">
								<EditIcon/>
							</IconButton>
						</Tooltip>
					)
				}
				<Tooltip title={showingActiveData ? 'Delete template' : 'Active template'}>
					<IconButton
						edge={'end'}
						onClick={() => onDeleteTemplate(getDataIndex())} aria-label="addnew">
						{showingActiveData ?
							<DeleteIcon color={'error'}/> :
							<UndoIcon/>}
					</IconButton>
				</Tooltip>
			</Row>
		</React.Fragment>
	)
}

function Row({children, width = 80}) {
	return (
		<div style={{width, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
			{children}
		</div>
	)
}

const templateCellData = [
	{
		name: "tmplt_name",
		label: "Name",
		options: {
			display: true
		}
	},
	{
		name: "created_at",
		label: "Created At",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
				)
			}
		}
	},
	{
		name: "created_by",
		label: "Created By",
		options: {
			display: true
		}
	},
	{
		name: "updated_by",
		label: "Updated By",
		options: {
			display: true
		}
	},
	{
		name: "updated_at",
		label: "Updated At",
		options: {
			display: true,
			filter: false,
			customBodyRender: (value) => {
				return (
					moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mmA')
				)
			}
		}
	}
]

const tableHeadCell = [
	{ id: 'tmplt_name', numeric: false, disablePadding: true, label: 'Name' },
	{ id: 'created_at', numeric: false, disablePadding: false, label: 'Created At' },
	{ id: 'created_by', numeric: false, disablePadding: false, label: 'Create By' },
	{ id: 'update_by', numeric: false, disablePadding: false, label: 'Updated By' },
	{ id: 'update_at', numeric: false, disablePadding: false, label: 'Updated At' },
	// { id: 'sequence_no', numeric: true, disablePadding: false, label: 'Sequence No' }
]
