// eslint-disable-next-line no-unused-vars
import React, {useContext, useEffect, useState} from "react";
import Cookies from 'universal-cookie';
import AppContext from "./AppContext";

export default function useCookieHook(dependencies = []) {
	const [gState, setGState] = useContext(AppContext);
	const [cookieInstance, setCookieInstance] = useState(new Cookies());
	useEffect(() => {

	}, [gState, dependencies]);

	function cookies(name) {
		return cookieInstance.get(`${gState.client}${name}`);
	}

	function setCookie(name, value, options = {}, extra = gState) {
		cookieInstance.set(`${extra.client}${name}`, value, options);
	}

	function removeCookie(name, options = {}, extra = gState) {
		cookieInstance.remove(`${extra.client}${name}`, options);
	}

	return [cookies, setCookie, removeCookie, cookieInstance];
}
