import React, {Fragment, useCallback, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import jstring from '@easytech-international-sdn-bhd/jstring';

export default function AutoCompleteSearch({onItemSelect, width, title, preData, onFocus, disabled, ...rest}) {
	const [open, setOpen] = useState(false);
	const [items, setItems] = useState(preData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!open) {
			setItems([]);
		} else {
			setItems(preData);
		}
	}, [open]);

	const onChangeSearch = (props) => {
		setLoading(true);
		const tmp = [];
		for (let i = 0; i < preData.length; i++) {
			const {value} = preData[i];
			if (jstring(value).searchDeep(props)) {
				tmp.push(preData[i]);
			}
		}
		setItems(tmp);
		setLoading(false);
	}

	const onOptionSelect = (option, value) => {
		return option.key == value.key;
	}

	const changeTextThrottle = useCallback(_.debounce(onChangeSearch, 300), []);

	return (
		<Autocomplete
			disabled={disabled}
			id="async-item-search"
			style={{width: width}}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			options={items}
			loading={loading}
			getOptionSelected={(option, value) => onOptionSelect(option, value)}
			getOptionLabel={(option) => option.value}
			autoHighlight={true}
			onChange={(e, v, r) => onItemSelect && v && onItemSelect(v)}
			renderOption={(option) => (
				<React.Fragment>
					<span role={'img'} arial-label={'emoji'}>👨🏻‍💼 {option.value}</span>
				</React.Fragment>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={title}
					variant="standard"
					onFocus={e => onFocus && onFocus(e)}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? <CircularProgress color="inherit" size={20}/> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						)
					}}
					autoFocus={true}
					onChange={e => changeTextThrottle(e.target.value)}
				/>
			)}
			{...rest}
		/>
	);
}
