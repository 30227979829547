import React from "react";
import PropTypes from "prop-types";
import {Route} from "react-router-dom";

export default function RouteWrapper({
										 component: Component,
										 isPrivate,
										 ...rest
									 }) {
	if (!isPrivate) {
		//return <Redirect to="/" />;
	}

	if (isPrivate) {
		//return <Redirect to="/main" />;
	}

	return <Route {...rest} component={Component}/>;
}

RouteWrapper.propTypes = {
	isPrivate: PropTypes.bool,
	component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
	isPrivate: false
};
