import React from 'react';
import jstring from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosURIencode} from '../Services/API_Service';
import {req_stock_def, req_stock_info} from './Constants';
import _ from 'lodash';
import moment from 'moment';

export const asJson = (content) => {
	return jstring(content).toJson();
}
export const arrayBufferToString = (buffer) => {
	var bufView = new Uint16Array(buffer);
	var length = bufView.length;
	var result = '';
	var addition = Math.pow(2, 16) - 1;

	for (var i = 0; i < length; i += addition) {

		if (i + addition > length) {
			addition = length - i;
		}
		result += String.fromCharCode.apply(null, bufView.subarray(i, i + addition));
	}

	return result;
};
export const de = (...props) => {
	if (isDebug()) {
		console.info(`%c [EasySales-CMS] ${moment().format('DD/MM/YYYY hh:mm:ss A')}`, 'background: red; color: white', ...props);
	}
}

export const isDebug = () => {
	return '_self' in React.createElement('div');
}

export const arrayBufferToBase64 = (buffer) => {
	var binary = '';
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

export const isReachable = async (link, cb = null) => {
	de("isReachable link:" + link);
	fetch(link, {
		mode: 'no-cors',
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		},
		body: null
	}).then(res => {
		cb && cb(res.ok == true)
	})
}

/**
 * Parse cms_module
 * @param { Array|Object } module
 * @param { String } key
 * @param { Object } params with key & values for type checking
 */
export const getSetting = (module, key, params = {}) => {
	if (_.isArray(module)) {
		for (let i = 0; i < module.length; i++) {
			const {name, value} = module[i];
			if (name === key) {
				const v = jstring(value).isJson();
				if (v) {
					const parsedV = v.toJson();
					if (!_.isEmpty(params)) {
						if (_.isArray(parsedV)) {
							for (let ii = 0; ii < parsedV.length; ii++) {
								const obj = parsedV[ii];
								for (const prop in params) {
									const expectedType = params[prop];
									if (_.has(obj, prop)) {
										return typeof (obj[prop]) === typeof (expectedType) ? obj[prop] : expectedType;
									}
								}
							}
						} else {
							for (const prop in params) {
								const expectedType = params[prop];
								if (_.has(parsedV, prop)) {
									return typeof (parsedV[prop]) === typeof (expectedType) ? parsedV[prop] : expectedType;
								}
							}
						}
					}
					return parsedV;
				}
				return value;
			}
		}
	} else {
		if (_.has(module, key)) {
			return module[key];
		}
	}
	var typeDef = [];
	for (const prop in params) {
		typeDef = params[prop];
	}
	return typeDef;
}
export const sanitize_regx = text => {
	return String(text).split('?').join('').split('[').join('').split(']').join('').split("(").join('').split(')').join('');
}
/**
 * @deprecated use getSetting instead
 * @param {Object|Array} _module
 * @param { String } _name
 */
export const getModule = (_module, _name) => {
	if (_module && _name) {
		for (let i = 0; i < _module.length; i++) {
			const {name, value} = _module[i];
			if (name === _name) {
				try {
					return jstring(value).toJson() || [];
				} catch (error) {
					de(error.message);
				}
			}
		}
	}
	return [];
}
/**
 * @param { String } product_code
 * @param { Object } axiosConfig
 * @param { Func<Array|Object> } callback
 */
export const getProductInfoByCode = (product_code, axiosConfig, callback) => {
	const params = axiosURIencode({product_code: product_code});
	API_Service.get(`${req_stock_info}?${params}`, axiosConfig).then(({data}) => {
		const {result, extraData} = data;
		if (result) {
			callback(extraData);
		} else {
			callback([]);
		}
	});
}
export const makeRandomString = (size = 10) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < size; i++) {
		result += characters.charAt(Math.floor(Math.random() *
			charactersLength));
	}
	return result;
}
/**
 * @param { String } text
 * @param { Object } axiosConfig
 * @param { Function<object> } callback { type:<Query | Result>, value:<String | Array> }
 */
export const searchProductFromAPI = (text, axiosConfig, callback) => {
	const response = {type: null, value: null};
	const searchQuery = jstring(text);
	if (searchQuery.isValid()) {
		const split = searchQuery
			.instanceOfString()
			.split(' ')
			.filter((item) => jstring(item).isValid());
		const formdata = new FormData();
		formdata.append('query', JSON.stringify(split));

		response.type = 'Query';
		response.value = split;
		callback(response);

		API_Service.post(req_stock_def, formdata, axiosConfig).then(({data}) => {
			var {result, extraData, sql} = data;
			result = jstring(result).toNumber();
			if (result === 0) {
				response.type = 'Result';
				response.value = [];
			} else {
				const {stock} = extraData;
				response.type = 'Result';
				response.value = stock;
			}
			callback(response);
		}).catch((err) => de(err.message));
	} else {
		response.type = 'Result';
		response.value = [];
		callback(response);
	}
}
