import CryptoJS from 'crypto-js';
import {empty} from './Constants';

/**
 * Encryption, Decryption, Hash
 */
export default class CryptData {
	/**
	 * @description Accepts message/data and key. For encryption and decryption `key` is must
	 * @example new CryptData('your data','your key')
	 *                  .isString()
	 *                  .encryptAES()
	 *                  .getValue();
	 * @param { String } data
	 * @param { String } key
	 */
	constructor(data, key = empty) {
		this.value = empty;
		this.data = data;
		this.key = key;
	}

	isString() {
		this.data = String(this.data);
		return this;
	}

	isObject() {
		this.data = JSON.stringify(this.data);
		return this;
	}

	encryptAES() {
		this.value = CryptoJS.AES.encrypt(this.data, this.key);
		return this;
	}

	decryptAES() {
		var bytes = CryptoJS.AES.decrypt(this.data.toString(), this.key);
		this.value = bytes.toString(CryptoJS.enc.Utf8);
		return this;
	}

	hash() {
		this.value = CryptoJS.SHA512(this.data);
	}

	getValue() {
		return String(this.value);
	}
}
