import React, {useCallback, useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {fade, lighten, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import red from '@material-ui/core/colors/red';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import {CircularProgress} from '@material-ui/core';
import API_Service, {axiosURIencode} from '../../Services/API_Service';
import {req_cust_deepsearch} from '../../Helper/Constants';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: '1 1 100%',
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
}));
const useSearchStyle = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
		marginLeft: 0,
		paddingLeft: 0,
	},
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.10),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.15),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '35ch',
			'&:focus': {
				width: '60ch',
			},
		},
	},
}));

export default function CustomerFullTextSearch({setClose, open, preset = [], title, language, axiosGet}) {

	const [custList, setCustList] = useState([]);
	const classes = useToolbarStyles();
	const searchClass = useSearchStyle();

	const [searchText, setSearchText] = useState('');
	const [filteredList, setFilteredList] = useState([]);
	const [checkedList, setCheckedList] = useState({});
	const [isSearching, setIsSearching] = useState(false);

	useEffect(() => {
		for (let i = 0; i < preset.length; i++) {
			const {} = preset[i];

		}
	}, [preset]);

	const onSelectCustomer = (_cur) => {
		const {customer, cust_code, branch_code} = _cur;
		var tmp = Object.assign({}, checkedList);
		var value = parseInt(customer) === 0 ? branch_code : cust_code;
		if (value in tmp) {
			delete tmp[`${value}`];
		} else {
			tmp[`${value}`] = _cur;
		}
		setCheckedList(tmp);
	};

	const savecustomer = () => {
		const list = Object.values(checkedList);
		setClose(list);
	};

	const reset = () => {
		setCheckedList({});
	};

	const RenderRow = ({props}) => {
		const {index, style} = props;
		const _cur = custList[index];
		const isChecked = ({customer, cust_code, branch_code}) => {
			var value = parseInt(customer) === 0 ? branch_code : cust_code;
			return value in checkedList;
		}

		return (
			<ListItem style={style} key={index} className={searchClass.margin}>
				<Checkbox
					id={_cur?.cust_code + index}
					onChange={(e) => onSelectCustomer(_cur)}
					inputProps={{'aria-labelledby': {index}}}
					checked={isChecked(_cur)}
					color={isChecked(_cur) ? 'primary' : 'secondary'}
				/>
				<ListItemText
					primary={_cur?.cust_company_name}
					secondary={_cur?.address2 || _cur?.address1}
				/>
			</ListItem>
		);
	}

	const onChangeSearchText = value => {
		setSearchText(value);
		setCustList([]);
		setIsSearching(true);
		setCheckedList({});
		const params = axiosURIencode({
			query: value
		});
		var tmp = {};
		API_Service.get(`${req_cust_deepsearch}?${params}`, axiosGet).then(({data}) => {
			const {result, extraData} = data;
			if (result == '1') {
				for (let i = 0; i < extraData.length; i++) {
					const obj = extraData[i];
					const {customer, cust_code, branch_code} = obj;
					var value = parseInt(customer) === 0 ? branch_code : cust_code;
					if (value in tmp) {
						delete tmp[`${value}`];
					} else {
						tmp[`${value}`] = obj;
					}
				}
				setCheckedList(tmp);
				setCustList(extraData || []);
			}
			setIsSearching(false);
		}).catch(e => setIsSearching(false));
	}

	const changeSearchText = useCallback(_.debounce(onChangeSearchText, 500), [searchText]);

	return (
		<React.Fragment>
			<Dialog
				fullScreen={false}
				maxWidth={'lg'}
				open={open}
				onClose={() => {
				}}
				aria-labelledby="max-width-dialog-title">
				<DialogTitle id="max-width-dialog-title">
					<ListItemText
						primary={title}
						secondary={custList.length === 0 ? '' : `${custList.length} customers found`}
					/>
					<div style={{marginTop: 15}}/>
					<div className={classes.viewInRow}>
						<div className={searchClass.search}>
							<div className={searchClass.searchIcon}>
								{isSearching ? <CircularProgress size={20} color={'primary'}/> : <SearchIcon/>}
							</div>
							<InputBase
								placeholder={language.search}
								classes={{
									root: searchClass.inputRoot,
									input: searchClass.inputInput,
								}}
								inputProps={{'aria-label': 'search'}}
								onChange={e => changeSearchText(e.target.value)}
							/>
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<FixedSizeList
						height={400}
						width={600}
						itemSize={85}
						itemCount={custList ? custList.length : 0}>
						{
							children =>
								<RenderRow props={children}/>
						}
					</FixedSizeList>
				</DialogContent>
				<DialogActions>
					<Button
						color="inherit"
						onClick={() => {
							reset();
						}}
						size="small">
						<Typography component="p" style={{color: red[400]}}>
							{language.reset_selection}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={() => {
							setClose(null)
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.cancel}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={savecustomer}
						size="small">
						<Typography component="p" color="inherit">
							{"DONE"}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
