import Translation from '../Assets/translation.js'

export default class PersistantData {

	static instance = null;

	_selectedLang = 'en';
	_username = '';
	_client = '';

	static getInstance() {
		if (PersistantData.instance === null) {
			PersistantData.instance = new PersistantData();
		}
		return this.instance;
	}

	/**
	 * @param { String } name
	 */
	setlang(name) {
		this._selectedLang = name || 'en';
	}

	/**
	 * @returns { Object } language
	 */
	getlang() {
		return Translation[this._selectedLang];
	}

	/**
	 * @param { String } name
	 */
	setUsername(name) {
		this._username = name;
	}

	/**
	 * @returns { String } username
	 */
	getUsername() {
		return this._username;
	}

	setClient(name) {
		this._client = name;
	}

	getClient() {
		return this._client;
	}

	selectedLang() {
		return this._selectedLang;
	}
}
