import React, {useEffect, useMemo, useRef, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {google_api_key, place_api} from '../../Helper/Constants';
import _ from 'lodash';
import {de} from "../../Helper/Helper";

function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const googleAutocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
}));

export default function LocationSearch({label, onFound, onClear, onLocationInfo, preset}) {
	const classes = useStyles();
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [searchedPreset, setSearchedPreset] = useState(false);
	const loaded = useRef(false);

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				place_api(google_api_key),
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const fetch = useMemo(
		() =>
			throttle((request, callback) => {
				googleAutocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[],
	);

	useEffect(() => {
		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}
	}, [value, inputValue, fetch]);

	useEffect(() => {
		const hasPresetAddress = !_.isEmpty(preset) && _.isObject(preset) && 'name' in preset;
		if (hasPresetAddress && searchedPreset === false) {
			setValue(preset.address);
			const ref = _.debounce(function () {
				onPresetPlaceSearch(preset.name);
				setSearchedPreset(true);
			}, 1000);
			ref();
			de('preset address', preset.address);
		}
	}, [preset, fetch]);

	const onChangeSearch = (text) => {
		setInputValue(text);
		if (!googleAutocompleteService.current && window.google) {
			googleAutocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!googleAutocompleteService.current) {
			return undefined;
		}
		fetch({input: text, componentRestrictions: {country: 'MY'}}, (results) => {
			let newOptions = [];

			if (value) {
				newOptions = [value];
			}

			if (results) {
				newOptions = [...newOptions, ...results];
			}
			setOptions(newOptions);
		});
	}

	const onPresetPlaceSearch = (query) => {
		de('keep searching?1');
		const PlacesService = new window.google.maps.places.PlacesService(document.querySelector('#google-maps'));
		PlacesService.findPlaceFromQuery({
			query,
			fields: ['place_id', 'name'],
		}, (results, status) => {
			if (!_.isEmpty(results)) {
				const res = results[0];
				const obj = {description: res.name, place_id: res.place_id, query};
				onAutocompletValueChange(obj, true);
			}
		});
	}

	const onAutocompletValueChange = (newValue, ignoreState = false) => {
		if (ignoreState === false) {
			setOptions(newValue ? [newValue, ...options] : options);
			setValue(newValue);
		}
		de('keep searching?2');
		const PlacesService = new window.google.maps.places.PlacesService(document.querySelector('#google-maps'));
		onLocationInfo && newValue?.place_id && PlacesService.getDetails({
			placeId: newValue.place_id,
			fields: ['formatted_address', 'geometry', 'name', 'photos', 'url', 'website', 'opening_hours'],
		}, (res) => {
			if (!res) return;
			let {formatted_address, geometry, name, photos, website, url, opening_hours} = res;
			var operating_days = [];
			if (Object.keys(opening_hours || {}).length > 0) {
				const {weekday_text} = opening_hours;
				for (let i = 0; i < weekday_text.length; i++) {
					const str = String(weekday_text[i]).split(':');
					operating_days.push({
						day: String(str[0]).trim().toUpperCase(),
						hour: String(weekday_text[i]).replace(str[0] + ':', '').trim().toUpperCase()
					});
				}
			}
			if (ignoreState) {
				formatted_address = preset.address;
			}
			de('found address', formatted_address);
			const obj = {
				formatted_address,
				name,
				geometry: {
					latitude: geometry.location.lat(),
					longitude: geometry.location.lng()
				},
				website,
				url,
				photos: [],
				operating_days
			};
			for (let i = 0; i < (photos || []).length; i++) {
				const photo = photos[i];
				obj.photos.push(photo.getUrl());
			}
			onLocationInfo && onLocationInfo(obj);
		});
		onFound && newValue?.place_id && onFound({description: newValue.description, place_id: newValue.place_id});

		_.isEmpty(newValue) && onClear();
	}
	return (
		<Autocomplete
			fullWidth
			id="google-map-sched"
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={(event, newValue) => {
				onAutocompletValueChange(newValue);
			}}
			onInputChange={(event, newInputValue) => {
				onChangeSearch(newInputValue);
			}}
			renderInput={(params) => (
				<TextField margin={'dense'} {...params} label={label} variant="outlined" fullWidth/>
			)}
			renderOption={(option) => {
				de(option, '--option mah--');
				const matches = option?.structured_formatting?.main_text_matched_substrings;
				if (matches instanceof Array) {
					const parts = parse(
						option.structured_formatting.main_text,
						matches.map((match) => [match.offset, match.offset + match.length]),
					);
					return (
						<Grid container alignItems="center">
							<Grid item>
								<LocationOnIcon className={classes.icon}/>
							</Grid>
							<Grid item xs>
								{parts.map((part, index) => (
									<span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
								))}

								<Typography variant="body2" color="textSecondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					);
				}
			}}
		/>
	);
}
