import React, {useEffect, useState} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {FixedSizeList} from 'react-window';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import {fade, lighten, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import red from '@material-ui/core/colors/red';
import JString from '@easytech-international-sdn-bhd/jstring';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: '1 1 100%',
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
}));

const useSearchStyle = makeStyles((theme) => ({
	margin: {
		marginTop: theme.spacing(1),
		marginLeft: 0,
		paddingLeft: 0
	},
	root: {
		flexGrow: 1,
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.black, 0.10),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.black, 0.15),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '35ch',
			'&:focus': {
				width: '60ch',
			},
		},
	},
}));

const selectDropdownStyles = {
	control: (base, state) => ({
		...base,
		marginLeft: "25px",
		marginRight: "25px",
		background: "#424242",
		borderColor: state.isFocused ? "#f4e25e" : "#f4e25e",
		boxShadow: state.isFocused ? null : null,
		"&:hover": {
			borderColor: state.isFocused ? "#f4e25e" : "#f4e25e"
		},
		text: "black"
	}),
	menu: base => ({
		...base,
		background: "#424242",
		text: "black",
		"&:hover": {
			background: "#4e4c3f"
		}
	}),
	option:(provided, state) => ({
		...provided,
		backgroundColor: "#424242",
		"&:hover": {
			backgroundColor: "#4e4c3f"
		}
	}),
	multiValue: (styles) => ({
		...styles,
			backgroundColor: "#f4e25e",
	}),
	input: base => ({
		...base,
		color: 'white'
	})
};

export default function VirtualListSTI({setClose, open, title, data, language, itemItemType, optionsList, itemType}) {
	const [dataArr, setDataArr] = useState([]);
	const classes = useToolbarStyles();
	const searchClass = useSearchStyle();
	const [checkedList, setCheckedList] = useState({});
	const [listReturn, setListReturn] = useState([]);

	useEffect(() => {
		setDataArr(dataArr => (data));
	}, [data]);

	const onSelectChange = (_cur) => {
		const {name, value} = _cur;
		var tmp = Object.assign({}, checkedList);
		if (value in tmp) {
			delete tmp[`${value}`];
		} else {
			tmp[`${value}`] = _cur;
		}
		setCheckedList(tmp);
	};

	const checkAll = () => {
		var tmp = Object.assign({}, checkedList);
		for (var i = 0; i < dataArr.length; i++) {
			const {name, value, hide} = dataArr[i];
			if(hide == false || hide == undefined){
				if (value in tmp) {
					delete tmp[`${value}`];
				} else {
					tmp[`${value}`] = dataArr[i];
				}
			}
		}
		setCheckedList(tmp);
	};

	const savecustomer = () => {
		const list = Object.values(checkedList);
		var selectedData = [];

		for (var i = 0; i < list.length; i++) {
			selectedData.push(
				{
					name: list[i].name,
					value: list[i].value
				}
			);
		}
		setDataArr(data);
		setListReturn(selectedData);
		setClose(selectedData);
	};

	const reset = () => {
		setCheckedList({});
		setListReturn([]);
	};

	const RenderRow = ({props}) => {
		const {index, style} = props;
		const _cur = dataArr[index];
		const isChecked = ({value}) => {
			return value in checkedList;
		}
		if (index !== dataArr.length) {
			return (
				<ListItem style={style} key={index} className={searchClass.margin}>
					<Checkbox
						id={_cur?.value}
						disabled={_cur?.hide === true}
						onChange={(e) => _cur?.hide ? null : onSelectChange(_cur)}
						inputProps={{'aria-labelledby': {index}}}
						checked={isChecked(_cur)}
						color={isChecked(_cur) ? 'primary' : 'secondary'}
					/>
					<ListItemText
						primary={_cur?.name}
						secondary={_cur?.description != null ?
							<div>
								<div>{_cur?.description}</div>
								<div>{_cur?.value}</div>
							</div>
							: _cur?.value}
						secondaryTypographyProps={{
							style:{
								textDecorationLine: _cur?.hide ? "line-through" : undefined
							}
						}}
						primaryTypographyProps={{
							style:{
								textDecorationLine: _cur?.hide ? "line-through" : undefined
							}
						}}
					/>
				</ListItem>
			);
		} else {
			return (
				null
			);
		}
	}

	const onChangeSearchText = props => event => {
		var value = event.target.value;
		value = String(value).split('?').join('').split('[').join('').split(']').join('').split("(").join('').split(')').join('');
		var text = JString(value);
		var final = [];
		var search = (text.str).split(' ');
		if (text.isValid()) {
			for (let idx = 0; idx < data.length; idx++) {
				var count = 0;
				const row = data[idx];
				var searchString = row.value + row.name;
				for (let i = 0; i < search.length; i++) {
					var isFound = JString(searchString).searchDeep(search[i]);
					if (isFound) {
						count++;
					}
				}
				if (count === search.length) {
					final.push(row);
				}
			}
			setDataArr(final);
		} else {
			setDataArr(data);
		}
	}

	const handleChange = (options) => {
		var all_prod_cat = [];
		var cat_arr = [];
		var prod_arr = [];

		for (let i = 0; i < itemItemType.length; i++) {
			all_prod_cat.push(itemItemType[i].name)
		}
		for (let i = 0; i < options.length; i++) {
			cat_arr.push(options[i].value)
		}
		for (let i = 0; i < itemItemType.length; i++) {
			if (cat_arr.indexOf(itemItemType[i].value) !== -1){
				prod_arr.push(itemItemType[i].name)
			}
		}

		var tmp = Object.assign({}, checkedList);

		for (let i = 0; i < itemItemType.length; i++) {
			if (all_prod_cat[i] in tmp) {
				delete tmp[`${all_prod_cat[i]}`];
			}
		}

		for (var i = 0; i < dataArr.length; i++) {
			const {name, value, hide} = dataArr[i];
			if (hide == false || hide == undefined) {
				if (prod_arr.indexOf(value) !== -1) {
					tmp[`${value}`] = dataArr[i];
				}
			}
		}

		setCheckedList(tmp);
	};

	return (
		<React.Fragment>
			<Dialog
				fullScreen={false}
				maxWidth={'lg'}
				open={open}
				onClose={() => {
				}}
				aria-labelledby="max-width-dialog-title">
				<DialogTitle id="max-width-dialog-title">
					{title}
					<div style={{marginTop: 15}}/>
					<div className={classes.viewInRow}>
						<div className={searchClass.search}>
							<div className={searchClass.searchIcon}>
								<SearchIcon/>
							</div>
							<InputBase
								placeholder={language.search}
								classes={{
									root: searchClass.inputRoot,
									input: searchClass.inputInput,
								}}
								inputProps={{'aria-label': 'search'}}
								onChange={onChangeSearchText('search')}
							/>
						</div>
						<Button onClick={() => checkAll()} size="small">
							<Typography component="p" color="inherit">
								{language.check_all}
							</Typography>
						</Button>
					</div>
				</DialogTitle>
				{
					itemType === 2 && (
						<Select options={optionsList}
								isMulti
								placeholder="Select items"
								onChange={handleChange}
								styles={selectDropdownStyles}
						/>
					)
				}
				<DialogContent>
					<FixedSizeList
						height={400}
						width={600}
						itemSize={70}
						itemCount={dataArr ? dataArr.length : 0}>
						{
							children =>
								<RenderRow props={children}/>
						}
					</FixedSizeList>
				</DialogContent>
				<DialogActions>
					<Button
						color="inherit"
						onClick={() => {
							setClose({name: 'reset', value: ''});
							reset();
							setDataArr(data);
						}}
						size="small">
						<Typography component="p" style={{color: red[400]}}>
							{language.reset_selection}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={() => {
							setClose(false);
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.cancel}
						</Typography>
					</Button>
					<Button
						color="inherit"
						onClick={() => {
							savecustomer();
							reset();
							setDataArr(data);
						}}
						size="small">
						<Typography component="p" color="inherit">
							{language.done}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
